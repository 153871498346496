import PageContainer from 'components/atoms/page-container'
import UsersEditForm from 'components/organisms/users/users-edit-form'

const UsersEdit = (): JSX.Element => (
  <PageContainer>
    <UsersEditForm />
  </PageContainer>
)

export default UsersEdit
