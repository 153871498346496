import { FC, useEffect, useState } from 'react'

import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'

import useGetUsers from 'hooks/queries/user/use-get-users'
import ResourceFilter from 'components/molecules/resource-filter'
import useAdministrationPermissions, { AdministrationSections } from 'hooks/permissions/use-administration-permissions'
import useFilters from 'hooks/use-filters'
import usePagination from 'hooks/use-pagination'
import routes from 'config/routes'
import UserTable from 'components/organisms/users/users-table'
import CountChip from 'components/atoms/count-chip'
import NewResourceButton from 'components/atoms/new-resource-button'
import ResourceHeader from 'components/atoms/resource-header'
import UsersFilter from 'components/organisms/users/users-filter'
import { ListProps } from 'models/list'
import { userFilterDefaultValues } from 'validations/user'
import { TableTypes } from 'config/constants'

const UsersList: FC<ListProps> = listProps => {
  const { t } = useTranslation()

  const { pageSize, offset, page, setPageSize, setPage } = usePagination()
  const { canWrite } = useAdministrationPermissions(AdministrationSections.users)
  const { sortModel, badge, openFilter, filters, setSortModel, onSearchQ, onSearchFilter, onToggleFilter } =
    useFilters(userFilterDefaultValues)

  const { response, isLoading, count, queryKey } = useGetUsers({
    offset,
    limit: pageSize,
    filters: { ...filters, showInactive: !filters.showActiveOnly, showActiveOnly: undefined },
    sort: sortModel || 'id',
  })

  const [searchQuery, setSearchQuery] = useState('')
  const [loadingStoredQuery, setLoadingStoredQuery] = useState(false)
  const [sessionStorageKey, setSessionStorageKey] = useState<string | null>(null)

  useEffect(() => {
    if (listProps?.isRelationsSearch) {
      setSessionStorageKey('relationsUsersSearch')
      return
    }
    setSessionStorageKey('usersSearch')

  }, [listProps?.isRelationsSearch])

  const handleSearchQ = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (sessionStorageKey) sessionStorage.setItem(sessionStorageKey, event.target.value)
    setSearchQuery(event.target.value)
    onSearchQ(event)
  }

  const handleToggleFilter = () => {
    onToggleFilter()
  }

  const handleClearSearch = () => {
    if (sessionStorageKey) sessionStorage.removeItem(sessionStorageKey)
    setSearchQuery('')
    onSearchQ({ target: { value: '' } } as React.ChangeEvent<HTMLInputElement>)
  }

  useEffect(() => {
    const storedQuery = sessionStorageKey && sessionStorage.getItem(sessionStorageKey)
    if (storedQuery) {
      setLoadingStoredQuery(true)
      setSearchQuery(storedQuery)
      onSearchQ({ target: { value: storedQuery } } as React.ChangeEvent<HTMLInputElement>)
      // Simulate loading
      setTimeout(() => {
        setLoadingStoredQuery(false)
      }, 500)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionStorageKey])

  return (
    <Box width='100%'>
      <ResourceHeader>
        <CountChip label={t('usuarios')} labelSingular={t('usuario')} count={count} isLoading={isLoading} />
        <ResourceFilter
          searchQuery={searchQuery}
          badge={badge}
          label={t`Nombre, email, rol, último acceso ...`}
          openFilter={openFilter}
          onSearchQ={handleSearchQ}
          onToggleFilter={handleToggleFilter}
          handleClearSearch={handleClearSearch}
        />
        {canWrite && (!listProps.type || listProps.type === TableTypes.default) && (
          <NewResourceButton label={t('Nuevo usuario')} to={routes.usersCreate} />
        )}
      </ResourceHeader>
      {openFilter && <UsersFilter onSearch={onSearchFilter} isRelationsFilter={listProps.isRelationsSearch} />}
      <UserTable
        {...listProps}
        rows={response}
        count={count}
        page={page}
        pageSize={pageSize}
        isLoading={isLoading || loadingStoredQuery}
        onSortModelChange={setSortModel}
        onPageSizeChange={setPageSize}
        onPageChange={setPage}
        queryKey={queryKey}
      />
    </Box>
  )
}

export default UsersList
