import React, { useEffect, useMemo, useRef, useState } from 'react'

import _debounce from 'lodash.debounce'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import { Control, Controller, FieldError } from 'react-hook-form'

import useGetCarparks from 'hooks/queries/prebooks/use-get-carparks'
import { Center } from 'models/center'

import { CarparksCellContainer } from './styled'

interface State {
  search: string
  preloading: boolean
}

interface Args {
  name: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>
  error?: FieldError
  multiple?: boolean
  disabled?: boolean
  initialValue?: Center | Center[]
  initialInputValue?: string | string[]
  fullValue?: boolean
  required?: boolean
  customKey?: keyof Center
}

const CarparkSearcher: React.FC<Args> = ({
  name,
  control,
  error,
  multiple = false,
  disabled,
  initialValue,
  fullValue,
  required = false,
  customKey = 'carparkCode',
}) => {
  const { t } = useTranslation()

  const [search, setSearch] = useState<State['search']>('')
  const [preloading, setPreloading] = useState<State['preloading']>(false)
  const [selectedValues, setSelectedValues] = useState<Center[]>([])
  const [inputValue, setInputValue] = useState<string>('')

  const { isLoading, response } = useGetCarparks({ search, sort: 'name' })

  useEffect(() => {
    if (initialValue) {
      const initialValues = Array.isArray(initialValue) ? initialValue : [initialValue]
      setSelectedValues(initialValues)
      setInputValue(initialValues.map(carpark => carpark.name).join(', '))
    }
  }, [initialValue])

  const options = useMemo(() => {
    if (!selectedValues?.length) {
      return response
    }

    const filteredValues = selectedValues.filter(({ id }) => !response.some(carpark => carpark.id === id))
    return [...response, ...filteredValues]
  }, [selectedValues, response])

  const debouncer = useRef(
    _debounce((data: string) => {
      const searchTerm = data?.length > 2 ? data : ''
      setSearch(searchTerm)
      setInputValue(data)
      setPreloading(false)
    }, 300)
  ).current

  useEffect(() => {
    return () => {
      debouncer.cancel()
    }
  }, [debouncer])

  const handleTextInputOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPreloading(true)
    setInputValue(event.target.value)
    debouncer(event.target.value)
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange, ...rest } }) => {
        const handleChange = (data: Center | readonly Center[] | null) => {
          if (Array.isArray(data)) {
            onChange(fullValue ? data : data?.map(carpark => carpark[customKey]))
            setSelectedValues(data)
            setInputValue(data.map(carpark => carpark.name).join(', '))
          } else if (data) {
            onChange(fullValue ? data : (data as Center)?.[customKey])
            setInputValue((data as Center)?.name)
          } else {
            setPreloading(true)
            setInputValue('')
            onChange(fullValue ? [] : '')
            debouncer('')
          }
        }

        return (
          <Autocomplete
            {...rest}
            defaultValue={initialValue}
            disabled={disabled}
            multiple={multiple}
            id='carpark-autocomplete'
            sx={{ width: '100%' }}
            options={options}
            clearOnBlur={false}
            loading={isLoading || preloading}
            value={multiple ? selectedValues : selectedValues[0] || null}
            inputValue={inputValue}
            filterOptions={x => x}
            getOptionLabel={option => {
              if (!option) return ''
              return option.name
            }}
            isOptionEqualToValue={(option, valueToCompare) => option.id === valueToCompare.id}
            onChange={(e, data) => handleChange(data)}
            renderOption={(props, carpark) => (
              <CarparksCellContainer {...props} key={`carpark-li-cell-${carpark.id}`}>
                <Typography>{carpark.name}</Typography>
              </CarparksCellContainer>
            )}
            renderInput={params => (
              <TextField
                {...params}
                onChange={handleTextInputOnChange}
                required={required}
                label={multiple ? t('Aparcamientos') : t('Aparcamiento')}
                name={name}
                error={!!error}
                helperText={error && error.message}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {isLoading || preloading ? <CircularProgress color='inherit' size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        )
      }}
    />
  )
}

export default CarparkSearcher
