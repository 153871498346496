import React from 'react'

import { useTranslation } from 'react-i18next'
import BarChartIcon from '@mui/icons-material/BarChart'
import TransportationIcon from '@mui/icons-material/EmojiTransportation'
import EuroIcon from '@mui/icons-material/Euro'
import NotificationsIcon from '@mui/icons-material/Notifications'
import SettingsIcon from '@mui/icons-material/Settings'
import SsidChartIcon from '@mui/icons-material/SsidChart'

import routes from 'config/routes'
import { colors } from 'config/theme'
import { ReactComponent as HeaderEnd } from 'assets/images/svg/header-end.svg'
import { ReactComponent as HeaderStart } from 'assets/images/svg/header-start.svg'
import HeaderLink from 'components/atoms/header-link'
import useAdministrationPermissions, { AdministrationSections } from 'hooks/permissions/use-administration-permissions'
import useAlertsPermissions from 'hooks/permissions/use-alerts-permissions'
import useCentersPermissions from 'hooks/permissions/use-centers-permissions'
import usePrebooksPermissions from 'hooks/permissions/use-prebooks-permissions'
import useProfilesPermissions from 'hooks/permissions/use-profiles-permissions'
import useSimulatorPermissions from 'hooks/permissions/use-simulator-permissions'

import * as S from './styled'

const AppBarNavigation = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { canRead: centersCanRead } = useCentersPermissions()
  const { canRead: prebooksCanRead } = usePrebooksPermissions()
  const { canRead: alertsCanRead } = useAlertsPermissions()
  const { canRead: simulatorCanRead } = useSimulatorPermissions()
  const { canRead: profilesCanRead } = useProfilesPermissions()

  const { canRead: configurationCanRead } = useAdministrationPermissions(AdministrationSections.configuration)
  const { canRead: usersCanRead } = useAdministrationPermissions(AdministrationSections.users)
  const { canRead: applicationsCanRead } = useAdministrationPermissions(AdministrationSections.applications)
  const { canRead: eventsCanRead } = useAdministrationPermissions(AdministrationSections.events)
  const administrationCanRead = configurationCanRead || usersCanRead || applicationsCanRead || eventsCanRead

  const { centers, prebooks, administration, alerts, simulator, profiles } = routes

  return (
    <S.AppBarNavigationContainer>
      <HeaderStart fill={colors.whiteShadow} />
      <S.AppBarNavigationLinks>
        {centersCanRead && (
          <HeaderLink title={t('centros')} href={centers} icon={<TransportationIcon color='primary' />} />
        )}
        {prebooksCanRead && <HeaderLink title={t('ventas')} href={prebooks} icon={<BarChartIcon color='primary' />} />}
        {alertsCanRead && (
          <HeaderLink title={t('alertas')} href={alerts} icon={<NotificationsIcon color='primary' />} />
        )}
        {simulatorCanRead && <HeaderLink title={t('simulador')} href={simulator} icon={<EuroIcon color='primary' />} />}
        {profilesCanRead && <HeaderLink title={t('curvas')} href={profiles} icon={<SsidChartIcon color='primary' />} />}
        {administrationCanRead && (
          <HeaderLink title={t('admin')} href={administration} icon={<SettingsIcon color='primary' />} />
        )}
      </S.AppBarNavigationLinks>
      <HeaderEnd fill={colors.whiteShadow} />
    </S.AppBarNavigationContainer>
  )
}

export default AppBarNavigation
