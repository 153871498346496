import * as yup from 'yup'

import { isMinorDate } from './utils'

export const searchDayPricesSchema = yup
  .object({
    from: yup.string().required(),
    to: yup.string().test(isMinorDate).required(),
  })
  .required()

export const exportDayPricesSchema = yup.object({
  from: yup.string().default(new Date().toISOString()),
  to: yup
    .string()
    .nullable(true)
    .when(['from'], () => yup.string().nullable(true).test(isMinorDate))
    .default(null),
})
