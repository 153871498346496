import { FC, useEffect } from 'react'

import { useNavigate, useParams } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'

import routes from 'config/routes'
import CancelButton from 'components/atoms/cancel-button'
import FormButtonsContainer from 'components/atoms/form-buttons-container'
import PapperGridContainer from 'components/atoms/papper-grid-container-form'
import GridItem from 'components/atoms/grid-item'
import useFormErrorScroll from 'hooks/use-form-error-scroll'
import { RoleRelationFormFields, roleRelationSchema } from 'validations/users-relations'
import BasicButton from 'components/atoms/basic-button'
import useGetOneRoleRelation from 'hooks/queries/relations/use-get-one-role-relation'
import useUpdateRoleRelation from 'hooks/queries/relations/use-update-role-relation'
import useCreateRoleRelation from 'hooks/queries/relations/use-create-role-relation'
import { Roles } from 'models/auth.d'
import ControllerInputSelect from 'components/molecules/controller-input-select'
import CarparkSearcher from 'components/molecules/locations-searcher'
import BasicInputText from 'components/atoms/basic-input-text'

const defaultValues: RoleRelationFormFields = {
  centerId: 0,
  role: Roles.CERCA,
}

interface RoleRelationsCreateFormProps {
  readonly?: boolean
}

const RolesRelationsForm: FC<RoleRelationsCreateFormProps> = ({ readonly }) => {
  const { id = '', version = '' } = useParams()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { response: relation, isLoading } = useGetOneRoleRelation({
    id: parseInt(id),
    version: isNaN(parseInt(version)) ? undefined : parseInt(version),
  })

  const createRelation = useCreateRoleRelation()
  const updateRelation = useUpdateRoleRelation()

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<RoleRelationFormFields>({
    defaultValues,
    resolver: yupResolver(roleRelationSchema),
  })

  useFormErrorScroll(errors)

  useEffect(() => {
    if (!relation) {
      return
    }

    reset({
      centerId: relation.centers[0].id,
      role: relation.role,
    })
  }, [relation, reset])

  const onSubmit = (relationFields: RoleRelationFormFields) => {
    if (id) {
      updateRelation.mutate({ id: parseInt(id), ...relationFields })
    } else {
      createRelation.mutate(relationFields)
    }

    navigate(routes.relationsList)
  }

  return (
    <Grid container data-testid='relations-create-form'>
      <PapperGridContainer>
        <GridItem item xs={8}>
          {isLoading && id ? (
            <BasicInputText label={t`Aparcamiento`} size='full' disabled />
          ) : (
            <CarparkSearcher
              name='centerId'
              control={control}
              error={errors.centerId}
              disabled={readonly}
              initialValue={relation?.centers[0]}
              customKey='id'
            />
          )}
        </GridItem>

        <GridItem item xs={4}>
          <ControllerInputSelect
            name='role'
            control={control}
            error={errors.role}
            options={[
              { value: Roles['ADMIN-CERCA'], text: t(Roles['ADMIN-CERCA']) },
              { value: Roles.CERCA, text: t(Roles.CERCA) },
            ]}
            label={t`Rol`}
            disabled={readonly}
          />
        </GridItem>
      </PapperGridContainer>

      <FormButtonsContainer>
        <CancelButton to={version ? undefined : routes.relationsList} />
        {!readonly && <BasicButton handleClick={handleSubmit(onSubmit)} text={t('Guardar cambios')} />}
      </FormButtonsContainer>
    </Grid>
  )
}

export default RolesRelationsForm
