import { RequireAuth } from 'providers/auth'

import MainLayout from '../main-layout'

const AuthLayout = (): JSX.Element => (
  <RequireAuth>
    <MainLayout />
  </RequireAuth>
)

export default AuthLayout
