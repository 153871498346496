import * as yup from 'yup'

export const applicationsSchema = yup
  .object({
    role: yup.string().required(),
    name: yup.string().required(),
    active: yup.boolean().required(),
    token: yup.string(),
    createDate: yup.string(),
    lastLogin: yup.string().nullable(),
  })
  .required()

export type ApplicationsFormSchema = yup.InferType<typeof applicationsSchema>

export interface ApplicationFormFields {
  role: string
  name: string
  active: boolean
  token?: string
  lastLogin?: string | null
}

export const applicationsFilterSchema = yup.object({
  name: yup.string(),
  from: yup.string().nullable(),
  to: yup.string().nullable(),
  showActiveOnly: yup.boolean().default(true),
})

export type ApplicationsFilterFormSchema = yup.InferType<typeof applicationsFilterSchema>

export interface ApplicationsFilterFormFields {
  name?: string
  from?: string | null
  to?: string | null
  q?: string
  showActiveOnly?: boolean
  showInactive?: boolean
}

export const applicationFilterDefaultValues: ApplicationsFilterFormFields = {
  name: '',
  from: null,
  to: null,
  showActiveOnly: true,
}
