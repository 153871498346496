import Box from '@mui/material/Box'
import styled from 'styled-components'

export const Container = styled(Box)`
  margin-bottom: 16px;
  row-gap: 12px;

  & > .MuiBox-root {
    margin-bottom: 0;
  }

  & > .MuiButton-root {
    align-self: flex-end;
  }
`

export const Actions = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
`
