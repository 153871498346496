import { FC, useEffect } from 'react'

import { GridEnrichedColDef } from '@mui/x-data-grid'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'

import ShowGridActionCellItem from 'components/atoms/show-grid-action-cell-item'
import TextCell from 'components/atoms/text-cell'
import BasicTable from 'components/molecules/basic-table'
import { RowHeight, TableColumnWidth } from 'config/constants'
import usePagination from 'hooks/use-pagination'
import useSort from 'hooks/use-sort'
import { Version } from 'models/version'
import { dateFormatter } from 'utils/date-formatter'

export interface TableProps {
  offset: number
  limit: number
  sort: string
}

interface HistoricTableProps {
  id?: string
  rows: Version[]
  count: number
  isLoading: boolean
  onNavigateToShow: (version: number) => void
  onTablePropsChange: (tableProps: TableProps) => void
}

const HistoricTable: FC<HistoricTableProps> = ({ id, onNavigateToShow, onTablePropsChange, ...props }) => {
  const { t } = useTranslation()

  const { pageSize, offset, page, setPageSize, setPage } = usePagination()
  const { sortModel, setSortModel } = useSort()

  useEffect(
    () => onTablePropsChange({ offset, limit: pageSize, sort: sortModel || '-id' }),
    [offset, pageSize, sortModel, onTablePropsChange]
  )

  const columns: GridEnrichedColDef[] = [
    {
      field: 'version',
      headerName: `${t('Versión')}`,
      flex: TableColumnWidth.xs,
      align: 'center',
      headerAlign: 'center',
      renderCell: ({ row }: { row: Version }) => <TextCell text={row.version.toString()} />,
    },
    {
      field: 'user',
      headerName: `${t('Usuario')}`,
      flex: TableColumnWidth.base,
      renderCell: ({ row }: { row: Version }) => <TextCell text={row.user ?? ''} />,
    },
    {
      field: 'updateDate',
      headerName: `${t('Fecha de modificación')}`,
      flex: TableColumnWidth.base,
      renderCell: ({ row }: { row: Version }) => <Typography>{dateFormatter({ date: row.updateDate })}</Typography>,
    },
    {
      field: 'actions',
      type: 'actions',
      flex: TableColumnWidth.base,
      getActions: ({ row }: { row: Version }) => [
        <ShowGridActionCellItem key={`${row.version}-show`} onClick={() => onNavigateToShow(row.version)} />,
      ],
    },
  ]

  return (
    <BasicTable
      {...props}
      columns={columns}
      page={page}
      pageSize={pageSize}
      rowHeight={RowHeight.small}
      onRowClick={({ row }: { row: Version }) => onNavigateToShow(row.version)}
      onSortModelChange={setSortModel}
      onPageSizeChange={setPageSize}
      onPageChange={setPage}
    />
  )
}

export default HistoricTable
