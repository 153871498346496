import React from 'react'

import AddCircle from '@mui/icons-material/AddCircle'
import IconButton from '@mui/material/IconButton'

interface Args {
  handleClick?: React.MouseEventHandler<HTMLButtonElement>
}

const AddIconButton: React.FC<Args> = ({ handleClick }) => (
  <IconButton sx={{ padding: '4px' }} onClick={handleClick}>
    <AddCircle />
  </IconButton>
)

export default AddIconButton
