import { useQuery } from '@tanstack/react-query'

import { GET_DAILY_OCCUPATION } from 'config/queries'
import { DailyOccupation, GetDailyOccupation, UseGetDailyOccupation } from 'models/occupation'
import { getDailyOccupation } from 'services/prebooks'

const useGetDailyOccupation = ({ from, to, carparkCode }: GetDailyOccupation): UseGetDailyOccupation => {
  const queryKey = [GET_DAILY_OCCUPATION, from, to, carparkCode]

  const {
    data: response,
    isLoading,
    isPreviousData,
  } = useQuery({
    queryKey,
    queryFn: () => getDailyOccupation({ from, to, carparkCode }),
    refetchOnMount: true,
    keepPreviousData: true,
  })

  const result: Array<DailyOccupation> = response?.data ?? []
  const count: number = parseInt(response?.headers?.['x-total-count'] || 0)

  return { response: result, count, isLoading: isLoading || isPreviousData }
}

export default useGetDailyOccupation
