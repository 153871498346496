import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import * as Sentry from '@sentry/react'

import { GET_PROFILES } from 'config/queries'
import { CreateProfile } from 'models/profiles'
import { createProfile } from 'services/profiles'

const useCreateProfile = () => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  return useMutation({
    mutationFn: (payload: CreateProfile) => createProfile(payload),
    onSuccess: data => {
      queryClient.invalidateQueries([GET_PROFILES])
      toast(t('Curva creada'), { type: 'success' })
      return data
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => {
      const message = error?.response?.data?.description ?? t('Ha ocurrido un error')
      toast(message, { type: 'error' })
      Sentry.captureException(error)
    },
  })
}

export default useCreateProfile
