import styled from 'styled-components'
import MuiAccordionSummary from '@mui/material/AccordionSummary'

import { colors } from 'config/theme'

export const AccordionSummary = styled(MuiAccordionSummary)<{ readOnly?: boolean }>`
  .MuiAccordionSummary-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 16px 6px;
    min-height: 48px;

    .MuiTypography-root {
      grid-column-start: 2;
      text-align: center;
      line-height: 1;

      strong {
        color: ${colors.orange};
      }
    }

    .MuiButtonBase-root {
      justify-content: space-between;
      margin-left: auto;
    }
  }
`
