import { AxiosResponse } from 'axios'

import { GetSimulatedPrebook, SimulatedPrebook } from 'models/simulator'
import { exposeApi } from 'services'
import { removeEmpty } from 'utils/remove-empty'

export const getSimulatedPrebook = (payload: GetSimulatedPrebook): Promise<AxiosResponse<SimulatedPrebook>> =>
  exposeApi.get('v1/pricing/prebooks', {
    params: { ...removeEmpty(payload) },
  })
