import { AxiosResponse } from 'axios'

import { GetRelations, Relation, type RelationDTO, RoleRelation, RoleRelationDTO } from 'models/relation'
import { adminApi } from 'services'
import { removeEmpty } from 'utils/remove-empty'

export const getUsersRelations = (payload: GetRelations): Promise<AxiosResponse<RelationDTO[]>> =>
  adminApi.get('/user-centers', {
    params: removeEmpty({ ...payload }),
  })

export const getRolesRelations = (payload: GetRelations): Promise<AxiosResponse<RoleRelationDTO[]>> =>
  adminApi.get('/relation/roles', {
    params: removeEmpty({ ...payload }),
  })

export const getOneUsersRelation = (id: number): Promise<AxiosResponse<RelationDTO>> =>
  adminApi.get(`/user-centers/${id}`)

export const getOneRoleRelation = (id: number): Promise<AxiosResponse<RoleRelationDTO>> =>
  adminApi.get(`/relation/roles/${id}`)

export const createOneUsersRelation = (payload: Omit<Relation, 'id'>): Promise<AxiosResponse<Relation>> =>
  adminApi.post('/user-centers', payload)

export const createOneRoleRelation = (payload: Omit<RoleRelation, 'id'>): Promise<AxiosResponse<RoleRelation>> =>
  adminApi.post('/relation/roles', payload)

export const updateOneUsersRelation = (id: number, payload: Partial<Relation>): Promise<AxiosResponse<Relation>> =>
  adminApi.patch(`/user-centers/${id}`, payload)

export const updateOneRoleRelation = (
  id: number,
  payload: Partial<RoleRelation>
): Promise<AxiosResponse<RoleRelation>> => adminApi.patch(`/relation/roles/${id}`, payload)

export const getRoleRelationVersions = (id: string, payload: GetRelations) =>
  adminApi.get(`relation/roles/${id}/versions`, { params: { ...payload } })

export const getOneRoleRelationVersion = async (id: number, version: number) => {
  const response = await adminApi.get(`relation/roles/${id}/versions/${version}`)
  response.data.centers = response.data.center
  return response
}

export const getUserRelationVersions = (id: string, payload: GetRelations) =>
  adminApi.get(`/v1/user-centers/${id}/versions`, { params: { ...payload } })

export const getOneUserRelationVersion = async (id: number, version: number) =>
  adminApi.get(`/v1/user-centers/${id}/versions/${version}`)
