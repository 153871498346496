import styled from 'styled-components'

import { colors } from 'config/theme'
import { FontFamily, FontSizes } from 'config/constants'

export const CalendarPeriodInfoContainer = styled.div`
  text-align: center;
  font-family: ${FontFamily.medium};
  color: ${colors.deepBlue};
  font-size: ${FontSizes.lg};
  max-width: 800px;
  margin: 1.5rem auto;

  strong {
    font-family: ${FontFamily.bold};
    color: ${colors.orange};
  }
`
