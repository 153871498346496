import React from 'react'

import { Container } from '@mui/material'

interface Props {
  children?: JSX.Element | JSX.Element[]
}

const PageContainer: React.FC<Props> = ({ children }) => <Container>{children}</Container>

export default PageContainer
