import { useState } from 'react'

import _debounce from 'lodash.debounce'

import { AlertsFilters } from 'models/alert'
import { CentersFilters } from 'models/center'
import { UserFilterFormFields } from 'validations/user'
import { ApplicationsFilterFormFields } from 'validations/applications'
import { EventsFilterFormFields } from 'validations/events'

import useSort from './use-sort'

type Filters = CentersFilters &
  UserFilterFormFields &
  ApplicationsFilterFormFields &
  EventsFilterFormFields &
  AlertsFilters

interface State {
  sortMode: string
  bagde: number
  openFilter: boolean
  filters: Filters
}

const useFilters = (filterDefaultValues: Filters) => {
  const { sortModel, setSortModel } = useSort()

  const [badge, setBadge] = useState<State['bagde']>(0)
  const [openFilter, setOpenFilter] = useState<State['openFilter']>(false)
  const [filters, setFilters] = useState<State['filters']>(filterDefaultValues)

  const onSearchQ = _debounce((event: React.ChangeEvent<HTMLInputElement>) => {
    setFilters(prev => ({ ...prev, search: event.target.value, q: event.target.value }))
  }, 500)

  const onFilter = (currentFilters: Filters, reset?: boolean) => {
    const { showActiveOnly, ...search } = currentFilters
    if (showActiveOnly !== undefined) {
      setBadge(Object.values({ ...search, showInactive: !showActiveOnly }).filter(element => !!element).length)
    } else {
      setBadge(Object.values(currentFilters).filter(element => !!element).length)
    }

    setFilters(prev => (reset ? { ...currentFilters } : { ...prev, ...currentFilters }))
  }

  const onToggleFilter = () => {
    setOpenFilter(prev => !prev)
    onFilter(filterDefaultValues, true)
  }

  const onSearchFilter = ({ showActiveOnly, ...search }: Filters) => {
    if (showActiveOnly !== undefined) {
      setBadge(Object.values({ ...search, showInactive: !showActiveOnly }).filter(element => !!element).length)
      setFilters(({ q, ...prev }) => ({ ...prev, ...search, showActiveOnly }))
    } else {
      setBadge(Object.values(search).filter(element => !!element).length)
      setFilters(({ q, ...prev }) => ({ ...prev, ...search }))
    }
  }

  return {
    sortModel,
    badge,
    openFilter,
    filters,
    setSortModel,
    setBadge,
    setOpenFilter,
    onSearchQ,
    onFilter,
    onToggleFilter,
    onSearchFilter,
  }
}

export default useFilters
