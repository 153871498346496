import Box from '@mui/material/Box'
import { useQueryClient } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import dayjs from 'dayjs'
import { Trans, useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import ConfigurationTable from 'components/organisms/configuration-table'
import PrebooksExport from 'components/organisms/prebooks-export'
import DayPricesExport from 'components/organisms/day-prices-export'
import { DateFormats } from 'config/constants'
import {
  GET_CALENDAR,
  GET_CENTERS,
  GET_DAILY_OCCUPATION,
  GET_IMPORT_JOBS,
  GET_ONE_CENTER,
  GET_PERIODS,
} from 'config/queries'
import { exportCurves, uploadCurves } from 'services/configuration'
import downloadBlob from 'utils/download-blob'
import CentersExport from 'components/organisms/centers-export'
import PeriodsExport from 'components/organisms/periods-export'
import useAdministrationPermissions, { AdministrationSections } from 'hooks/permissions/use-administration-permissions'
import ImportJobs from 'components/organisms/import-jobs'

const Configuration = () => {
  const { canWrite } = useAdministrationPermissions(AdministrationSections.configuration)
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const handleCsvFormData = (csvFile: Blob) => {
    const formData = new FormData()
    if (!csvFile) {
      toast(t('Ha ocurrido un error en la subida del CSV'), { type: 'error' })
      return formData
    }

    formData.append('file', csvFile)
    return formData
  }

  const uploadFile = async (blob: Blob, uploadService: (formData: FormData) => Promise<AxiosResponse>) => {
    const formData = handleCsvFormData(blob)
    try {
      await uploadService(formData)
      queryClient.invalidateQueries({ queryKey: [GET_CENTERS] })
      queryClient.invalidateQueries({ queryKey: [GET_ONE_CENTER] })
      queryClient.invalidateQueries({ queryKey: [GET_PERIODS] })
      queryClient.invalidateQueries({ queryKey: [GET_CALENDAR] })
      queryClient.invalidateQueries({ queryKey: [GET_DAILY_OCCUPATION] })
      queryClient.invalidateQueries({ queryKey: [GET_IMPORT_JOBS] })
      toast(t('Fichero subido correctamente'), { type: 'success' })
    } catch (error) {
      toast(t('Ha ocurrido un error en la subida del fichero'), { type: 'error' })
    }
  }

  const downloadFile = async (filename: string, downloadService: () => Promise<AxiosResponse<Blob>>) => {
    try {
      const { data } = await downloadService()
      const date = dayjs().format(DateFormats.fileDate)
      downloadBlob(data, filename.replace('.', `_${date}.`))
    } catch (error) {
      toast(t('Ha ocurrido un error en la descarga del fichero'), { type: 'error' })
    }
  }

  const rows = [
    {
      id: 3,
      operative: (
        <Trans>
          <span className='strong'>Curvas</span> de duración/anticipación
        </Trans>
      ),
      download: () => downloadFile('curves.xlsx', exportCurves),
      update: (blob: Blob) => uploadFile(blob, uploadCurves),
    },
  ]

  return (
    <Box
      width='75%'
      sx={{
        margin: '0 auto',
        '& .MuiDataGrid-row:hover': {
          cursor: 'default',
        },
        '& .MuiDataGrid-cell:focus-within': {
          outline: 'none !important',
        },
      }}
    >
      <ConfigurationTable rows={rows} />
      <CentersExport />
      <PeriodsExport />
      <DayPricesExport />
      <PrebooksExport />
      {canWrite && (
        <ImportJobs />
      )}
    </Box>
  )
}

export default Configuration
