import React, { useCallback, useState } from 'react'

import PageContainer from 'components/atoms/page-container'
import { SearchSimulatorFormFields } from 'models/simulator'
import SimulatorSearcherForm from 'components/organisms/simulator/simulator-searcher-form'
import SimulatorPrices from 'components/organisms/simulator/simulator-prices'

const Simulator = (): React.JSX.Element => {
  const [filters, setFilters] = useState<SearchSimulatorFormFields>()

  const handleFilters = useCallback(
    (newFilters: SearchSimulatorFormFields) => setFilters(prev => ({ ...prev, ...newFilters })),
    []
  )

  return (
    <div data-testid='simulator-container'>
      <PageContainer>
        <SimulatorSearcherForm onSubmit={handleFilters} />
        <>{filters && <SimulatorPrices filters={filters} />}</>
      </PageContainer>
    </div>
  )
}

export default Simulator
