import { AxiosResponse } from 'axios'

import CurveType from 'models/curve-type'
import { CreateProfile, GetProfiles, Point, Profile, GetProfilesVersions, IntervalDto } from 'models/profiles'
import { adminApi } from 'services'
import { removeEmpty } from 'utils/remove-empty'

export const getProfiles = ({ filters, ...payload }: GetProfiles): Promise<AxiosResponse<Profile[]>> =>
  adminApi.get('/curves', {
    params: removeEmpty({ ...payload, ...filters }),
  })

export const getProfilesVersions = ({ id, ...params }: GetProfilesVersions): Promise<AxiosResponse<Profile[]>> =>
  adminApi.get(`/v1/curves/${id}/versions`, { params })

export const getAverageDiscount = (points?: Point[]) =>
  points?.length ? points.reduce((acc, point) => acc + point.discount, 0) / points.length : 0

export const getMaxDiscount = (points?: Point[]) =>
  points?.length ? points.reduce((acc, point) => Math.max(acc, point.discount), -Infinity) : 0

export const getMinDiscount = (points?: Point[]) =>
  points?.length ? points.reduce((acc, point) => Math.min(acc, point.discount), +Infinity) : 0

export const getAverageDiscountIntervals = (intervals?: IntervalDto[]) =>
  intervals?.length ? intervals.reduce((acc, interval) => acc + interval.percentageValue, 0) / intervals.length : 0

export const getMaxDiscountIntervals = (intervals?: IntervalDto[]) =>
  intervals?.length ? intervals.reduce((acc, interval) => Math.max(acc, interval.percentageValue), -Infinity) : 0

export const getMinDiscountIntervals = (intervals?: IntervalDto[]) =>
  intervals?.length ? intervals.reduce((acc, interval) => Math.min(acc, interval.percentageValue), +Infinity) : 0

export const createProfile = (payload: CreateProfile) => {
  if (payload.curveType === CurveType.DISCOUNT){
    const averageDiscount = getAverageDiscount(payload.points)
    const maxDiscount = getMaxDiscount(payload.points)
    const minDiscount = getMinDiscount(payload.points)
    return adminApi.post('/curves', { ...payload, averageDiscount, maxDiscount, minDiscount })
  } else {
    const averageDiscount = getAverageDiscountIntervals(payload.intervals)
    const maxDiscount = getMaxDiscountIntervals(payload.intervals)
    const minDiscount = getMinDiscountIntervals(payload.intervals)
    return adminApi.post('/curves', { ...payload, averageDiscount, maxDiscount, minDiscount })
  }
}

export const getOneProfile = (id: string | undefined) => {
  return adminApi.get(`/curves/${id}`)
}

export const getOneProfileVersion = (id?: string, version?: string) =>
  adminApi.get(`/v1/curves/${id}/versions/${version}`)

export const updateOneProfile = (
  id: string | undefined,
  profile: Partial<Profile>
): Promise<AxiosResponse<Profile>> => {
  const payload = { ...profile }
  if (profile.curveType === CurveType.DISCOUNT && profile.points) {
    payload.averageDiscount = getAverageDiscount(profile.points)
    payload.maxDiscount = getMaxDiscount(profile.points)
    payload.minDiscount = getMinDiscount(profile.points)
  } else {
    payload.averageDiscount = getAverageDiscountIntervals(payload.intervals)
    payload.maxDiscount = getMaxDiscountIntervals(payload.intervals)
    payload.minDiscount = getMinDiscountIntervals(payload.intervals)
  }

  return adminApi.patch(`/curves/${id}`, { ...payload })
}
