import styled from 'styled-components'

import { colors } from 'config/theme'

export const KpiGridHeaderContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.25rem;
  margin: 1rem 0 0.25rem 0;
`

export const KpiGridDivider = styled('hr')`
  width: 100%;
  height: 0.1875rem;
  border: 0;
  border-radius: 0.1875rem;
  background-color: ${colors.orange};
`
