import { useQuery } from '@tanstack/react-query'

import { Center } from 'models/center'
import { GetCarparks, UseGetCarparks } from 'models/prebooks'
import { getCarparks } from 'services/prebooks'
import { GET_CARPARKS } from 'config/queries'

const useGetCarparks = ({ search, sort }: GetCarparks): UseGetCarparks => {
  const queryKey = [GET_CARPARKS, search]

  const {
    data: response,
    isLoading,
    isPreviousData,
  } = useQuery({
    queryKey,
    queryFn: () => getCarparks({ search, sort }),
    refetchOnMount: true,
    keepPreviousData: true,
  })

  const result: Array<Center> = response?.data ?? []
  const count: number = parseInt(response?.headers?.['x-total-count'] || 0)

  return { response: result, count, isLoading: isLoading || isPreviousData }
}

export default useGetCarparks
