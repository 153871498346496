import * as yup from 'yup'

import { IntervalDto, Point } from 'models/profiles'
import CurveType from 'models/curve-type'

export const profileSchema = yup
  .object({
    name: yup.string().required(),
    code: yup.string().required(),
    points: yup.array().of(
      yup.object({
        day: yup.number().required(),
        discount: yup.number().required(),
      })
    ),
    active: yup.bool(),
    curveType: yup.mixed<CurveType>().required(),
    intervals: yup.array().of(
      yup.object({
        from: yup.number().required().min(0).max(yup.ref('to'), 'Debe ser menor'),
        to: yup.number().required().min(0).moreThan(yup.ref('from'), 'Debe ser mayor').max(17520, 'Máximo 17520 horas'),
        percentageValue: yup.number().required().min(-100).max(100),
      })
    ),
  })
  .required()

export type ProfileFormData = yup.InferType<typeof profileSchema>

export interface ProfileFormFields {
  id?: string
  name: string
  code: string
  points?: Point[]
  active?: boolean
  curveType: CurveType
  intervals?: IntervalDto[]
}

export const profileFilterSchema = yup.object({
  name: yup.string(),
  code: yup.string(),
  curveType: yup.string(),
})

export type ProfileFilterFormData = yup.InferType<typeof profileFilterSchema>

export interface ProfileFilterFormFields {
  name?: string
  code?: string
  curveType?: CurveType
}

export const profileFilterDefaultValues: ProfileFilterFormFields = {
  name: '',
  code: '',
  curveType: CurveType.DISCOUNT,
}
