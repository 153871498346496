import { useEffect, useMemo, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import Grid from '@mui/material/Grid'
import TagIcon from '@mui/icons-material/Tag'
import ChartIcon from '@mui/icons-material/SsidChart'
import { useTranslation } from 'react-i18next'
import { Box, Button, FormControlLabel, IconButton, Switch } from '@mui/material'
import { DeleteOutline } from '@mui/icons-material'

import useFormErrorScroll from 'hooks/use-form-error-scroll'
import routes from 'config/routes'
import { ProfileFormData, ProfileFormFields, profileSchema } from 'validations/profiles'
import FormButtonsContainer from 'components/atoms/form-buttons-container'
import PapperGridContainer from 'components/atoms/papper-grid-container-form'
import CancelButton from 'components/atoms/cancel-button'
import SubmitButton from 'components/atoms/submit-button'
import ControllerInputText from 'components/molecules/controller-input-text'
import H2 from 'components/atoms/h2'
import InputIcon from 'components/atoms/input-icon'
import useCreateProfile from 'hooks/queries/profiles/use-create-profile'
import { CreateProfile, Point, IntervalDto } from 'models/profiles'
import useGetProfiles from 'hooks/queries/profiles/use-get-profiles'
import CurveType from 'models/curve-type'
import ControllerInputSelect from 'components/molecules/controller-input-select'
import ProfilesPointsChart from 'components/molecules/profiles-points-chart'
import ProfilesIntervalsChart from 'components/molecules/profiles-intervals-chart'
import { useFormStore } from 'store/useFormStore'
import { usePrompt } from 'hooks/use-prompt'

export const CURVETYPES = [
  { value: 'DISCOUNT', text: 'Duración' },
  { value: 'ANTICIPATION', text: 'Anticipación' },
]
const defaultValues: ProfileFormFields = {
  name: '',
  code: '',
  points: Array.from(Array(31).keys()).map(value => ({ day: value, discount: 0 })),
  curveType: CURVETYPES[0].value as CurveType,
  intervals: Array(1).fill({ from: 0, to: 1, percentageValue: 0 }),
}

const ProfilesCreateForm = (): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [showAllProfiles, setShowAllProfiles] = useState<boolean>(false)
  const [cachePoints, setCachePoints] = useState<Point[]>(defaultValues.points ?? [])
  const [cacheIntervals, setCacheIntervals] = useState<IntervalDto[]>(defaultValues.intervals ?? [])
  const [cacheCurveType, setCacheCurveType] = useState<CurveType>(defaultValues.curveType ?? CurveType.DISCOUNT)

  const { mutate: createProfile } = useCreateProfile()

  const { response: allProfiles } = useGetProfiles({ limit: 100, offset: 0, filters: {}, sort: '' })

  const profiles = useMemo(
    () => (showAllProfiles ? allProfiles.filter(profile => profile.curveType === cacheCurveType) : []),
    [allProfiles, showAllProfiles, cacheCurveType]
  )

  const isFormDirty = useFormStore(store => store.isDirty)
  const setDirty = useFormStore(store => store.setDirty)
  const resetForm = useFormStore(store => store.resetForm)

  usePrompt({
    isDirty: isFormDirty,
    onConfirm: resetForm
  })

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
    setError,
    reset,
    watch,
    getValues,
  } = useForm<ProfileFormData>({
    defaultValues,
    resolver: yupResolver(profileSchema),
  })

  useFormErrorScroll(errors)

  const checkHoursOverlap = (intervals: IntervalDto[] | undefined): boolean => {
    if (!intervals) return false
    if (intervals.length === 1) return false
    const sortedIntervals = intervals.sort((a, b) => a.from - b.from)
    for (let i = 0; i < sortedIntervals.length - 1; i++) {
      if (Number(sortedIntervals[i].to) > Number(sortedIntervals[i + 1].from)) {
        setError('intervals', { type: 'manual', message: t('*No puede haber solapamiento de horas') })
        return true
      }
    }
    return false
  }

  const onSubmit = ({ name, code, points, curveType, intervals }: ProfileFormFields) => {
    const hasOverlap = checkHoursOverlap(intervals)
    if (hasOverlap) return
    resetForm()
    createProfile(
      { name, code, points, active: true, curveType, intervals },
      {
        onSuccess: ({ data }) => {
          navigate(routes.profilesEdit.replace(':id', data.id.toString()))
        },
      }
    )
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowAllProfiles(event.target.checked)
  }

  const [name, code, points, curveType, intervals] = watch(['name', 'code', 'points', 'curveType', 'intervals'])

  useEffect(() => {
    setCacheCurveType(curveType)
  }, [curveType])

  useEffect(() => {
    const subscription = watch(value => {
      const newPoints = value.points?.map(p => ({ day: p?.day, discount: p?.discount }) as Point) ?? []
      setCachePoints(newPoints)
      const newIntervals =
        value.intervals?.map(
          i =>
            ({
              from: Number(i?.from),
              to: Number(i?.to),
              percentageValue: Number(i?.percentageValue),
            }) as IntervalDto
        ) ?? []
      setCacheIntervals(newIntervals)
    })
    return () => subscription.unsubscribe()
  }, [watch])

  useEffect(() => {
    if (isFormDirty) return
    setDirty(isDirty)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty])

  const onDragPointsEnd = (_e: MouseEvent, _chart: number, index: number, newValue: number) => {
    const formPoints =
      getValues('points')?.map(({ day, discount }) => ({ day, discount: index === day ? newValue : discount })) ?? []
    reset({ name: name ?? '', code: code ?? '', points: formPoints, intervals: cacheIntervals, curveType })
    setDirty(true)
  }
  const onDragIntervalsEnd = (_e: MouseEvent, _chart: number, index: number, newValue: number) => {
    const formIntervals =
      getValues('intervals')?.map((interval, i) =>
        i === index ? { ...interval, percentageValue: newValue } : interval
      ) ?? []
    reset({ name: name ?? '', code: code ?? '', points: cachePoints, intervals: formIntervals, curveType })
    setDirty(true)
  }
  const addNewInterval = () => {
    const prevIntervalTo = cacheIntervals[cacheIntervals.length - 1].to
    const newInterval = { from: prevIntervalTo, to: prevIntervalTo + 1, percentageValue: 0 }
    setCacheIntervals([...cacheIntervals, newInterval])

    reset({
      name: name ?? '',
      code: code ?? '',
      points: cachePoints,
      intervals: [...cacheIntervals, newInterval],
      curveType,
    })
    setDirty(true)
  }

  const deleteInterval = (index: number) => {
    if (index === 0) return
    const newIntervals = cacheIntervals.filter((_, i) => i !== index)
    setCacheIntervals(newIntervals)
    reset({ name: name ?? '', code: code ?? '', points: cachePoints, intervals: newIntervals, curveType })
    setDirty(true)
  }

  const profile: CreateProfile = { name, code, points: cachePoints, intervals: cacheIntervals, curveType }

  return (
    <Grid container component='form' onSubmit={handleSubmit(onSubmit)} data-testid='profiles-create-form'>
      <PapperGridContainer>
        <Grid item xs={12}>
          <H2 text={t('Datos generales')} />
        </Grid>
        <Grid container gap={2}>
          <Grid item>
            <ControllerInputText
              mandatory
              control={control}
              name='code'
              error={errors.code}
              label={t('Código')}
              size='large'
              nativeInputProps={{ maxLength: 50 }}
              inputProps={{ endAdornment: <InputIcon Icon={TagIcon} /> }}
            />
          </Grid>
          <Grid item>
            <ControllerInputText
              mandatory
              control={control}
              name='name'
              error={errors.name}
              label={t('Nombre')}
              size='full'
              nativeInputProps={{ maxLength: 50 }}
              inputProps={{ endAdornment: <InputIcon Icon={ChartIcon} /> }}
            />
          </Grid>
          <Grid item xs={2}>
            <ControllerInputSelect
              control={control}
              name='curveType'
              error={errors.curveType}
              label={t`Tipo de curva`}
              size='full'
              options={CURVETYPES}
            />
          </Grid>
        </Grid>
        <Box width={'100%'} marginTop={'2rem'}>
          <H2 text={t('Detalle curva')} />
        </Box>
        <Grid container>
          <Grid item xs={12} md={6}>
            {curveType === CurveType.DISCOUNT && (
              <Box display='grid' gridTemplateColumns='repeat(7, 14.3%)'>
                {points?.map(({ day }) => (
                  <ControllerInputText
                    key={day}
                    mandatory
                    control={control}
                    name={`points[${day}].discount`}
                    label={t('Día {{value}}', { value: day + 1 })}
                    size='full'
                    variant='standard'
                    nativeInputProps={{ type: 'number', min: '0', max: '100' }}
                  />
                ))}
              </Box>
            )}
            {curveType === CurveType.ANTICIPATION && (
              <Box display='flex' width='100%' flexDirection={'column'}>
                <Box display='flex' height={'2rem'} width='100%' flexDirection={'column'}>
                  {errors.intervals && (
                    <Box fontSize={12} color='red'>
                      {errors.intervals.message}
                    </Box>
                  )}
                </Box>
                {intervals?.map((interval, index) => (
                  <Box display='grid' gridTemplateColumns={'6rem 6rem 1fr 2rem'} key={`from-${interval}_${index}`}>
                    <ControllerInputText
                      control={control}
                      name={`intervals[${index}].from`}
                      label={t('Desde(horas)')}
                      size='full'
                      variant='standard'
                      error={errors.intervals?.[index]?.from}
                      nativeInputProps={{ type: 'number', min: '0' }}
                    />
                    <ControllerInputText
                      control={control}
                      name={`intervals[${index}].to`}
                      label={t('Hasta(horas)')}
                      size='full'
                      variant='standard'
                      error={errors.intervals?.[index]?.to}
                      nativeInputProps={{ type: 'number', min: '0' }}
                    />
                    <ControllerInputText
                      control={control}
                      name={`intervals[${index}].percentageValue`}
                      label={t('(%)Sobrecoste/Descuento')}
                      size='full'
                      variant='standard'
                      nativeInputProps={{ type: 'number', min: '-100', max: '100' }}
                    />
                    {index > 0 && (
                      <IconButton size='small' type='button' sx={{ height: '2rem' }}>
                        <DeleteOutline onClick={() => deleteInterval(index)} />
                      </IconButton>
                    )}
                  </Box>
                ))}
                <Button onClick={addNewInterval} variant='contained' size='small'>
                  {t('Agregar Intervalo')}
                </Button>
              </Box>
            )}
          </Grid>
          <Grid item xs={12} md={6} paddingLeft={4} textAlign='right'>
            <Box marginBottom={4}>
              <FormControlLabel
                control={<Switch checked={showAllProfiles} onChange={handleChange} />}
                label={t('Comparar curvas')}
              />
            </Box>
            {curveType === CurveType.ANTICIPATION && (
              <ProfilesIntervalsChart
                profile={profile}
                profiles={profiles}
                dragEnabled={!showAllProfiles}
                onDragEnd={onDragIntervalsEnd}
                intervals={cacheIntervals}
              />
            )}
            {curveType === CurveType.DISCOUNT && (
              <ProfilesPointsChart
                profile={profile}
                profiles={profiles}
                dragEnabled={!showAllProfiles}
                onDragEnd={onDragPointsEnd}
                points={cachePoints}
              />
            )}
          </Grid>
        </Grid>
      </PapperGridContainer>
      <FormButtonsContainer>
        <CancelButton to={routes.profiles} />
        <SubmitButton />
      </FormButtonsContainer>
    </Grid>
  )
}

export default ProfilesCreateForm
