import { FC, useState } from 'react'

import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'

import CentersTableHeader from 'components/molecules/centers-table-header'
import CentersTable from 'components/organisms/centers/centers-table'
import { TableTypes } from 'config/constants'
import useFilters from 'hooks/use-filters'
import usePagination from 'hooks/use-pagination'
import useGetCenters from 'hooks/queries/centers/use-get-centers'
import { ListProps } from 'models/list'
import ControllerSwitch from 'components/molecules/controller-switch'

const CentersList: FC<ListProps> = listProps => {
  const { offset, page, pageSize, setPage, setPageSize } = usePagination()
  const { sortModel, filters, onFilter, onSearchQ, setSortModel } = useFilters({ active: true })
  const { t } = useTranslation()

  const { response, isLoading, count, queryKey } = useGetCenters({
    offset,
    limit: pageSize,
    filters,
    sort: sortModel || 'id',
  })

  const [isLoadingStoredData, setIsLoadingStoredData] = useState(false)

  return (
    <Box width='100%'>
      <CentersTableHeader
        count={count}
        isLoading={isLoading}
        setIsLoadingStoredData={setIsLoadingStoredData}
        onFilter={onFilter}
        onSearchQ={onSearchQ}
        hideUpdateCenters={listProps.type && listProps.type !== TableTypes.default}
        isRelationsSearch={listProps.isRelationsSearch}
      />
      {
        listProps?.control
        && <ControllerSwitch control={listProps?.control} name='allCenters' label={t('Todos los centros')} variant='full' />
      }
      <CentersTable
        {...listProps}
        rows={response}
        count={count}
        page={page}
        pageSize={pageSize}
        isLoading={isLoading || isLoadingStoredData}
        onSortModelChange={setSortModel}
        onPageChange={setPage}
        onPageSizeChange={setPageSize}
        queryKey={queryKey}
        disabled={listProps?.disabled}
      />
    </Box>
  )
}

export default CentersList
