import styled from 'styled-components'
import Box from '@mui/material/Box'

import { colors } from 'config/theme'

export const InfoCardContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid ${colors.grey};
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  gap: 1rem;
  min-height: 122px;
`
