import React from 'react'

import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { IconButton, Tooltip } from '@mui/material'
import { t } from 'i18next'

interface Args {
  handleClick?: React.MouseEventHandler<HTMLButtonElement>
}

const CopyIconButton: React.FC<Args> = ({ handleClick }) => (
  <Tooltip title={t('Copiar al portapapeles')}>
    <IconButton sx={{ padding: '4px' }} onClick={handleClick}>
      <ContentCopyIcon />
    </IconButton>
  </Tooltip>
)

export default CopyIconButton
