import { useQuery } from '@tanstack/react-query'

import { GET_HEALTH } from 'config/queries'
import { UseGetHealth } from 'models/health'
import { getHealth } from 'services/health'

const useGetHealth = (): UseGetHealth => {
  const queryKey = [GET_HEALTH]
  const { data: response } = useQuery({
    queryKey,
    queryFn: () => getHealth(),
    refetchOnMount: true,
    keepPreviousData: true,
  })

  const result: unknown = response?.data || {version:'Versión no disponible', commit: ''}

  return { response: result }
}

export default useGetHealth
