import { ChangeEvent, FC, useCallback, useEffect, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow'
import EventIcon from '@mui/icons-material/Event'
import TodayIcon from '@mui/icons-material/Today'
import AccordionDetails from '@mui/material/AccordionDetails'
import Grid from '@mui/material/Grid'
import dayjs from 'dayjs'
import { Trans, useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import { SaveOutlined } from '@mui/icons-material'

import H3 from 'components/atoms/h3'
import InputIcon from 'components/atoms/input-icon'
import SubmitButton from 'components/atoms/submit-button'
import { PERIOD_PRIORITY_OPTIONS } from 'config/centers-constants'
import { DateFormats } from 'config/constants'
import useFormErrorScroll from 'hooks/use-form-error-scroll'
import { Roles } from 'models/auth.d'
import { CenterPeriod, PeriodPriority, PeriodWeekday } from 'models/center-period'
import { SwitchControllerField } from 'models/form'
import { useAuth } from 'providers/auth'
import { dateFormatter } from 'utils/date-formatter'
import { CenterPeriodFields, centerPeriodSchema } from 'validations/periods'
import { useFormStore } from 'store/useFormStore'
import { usePrompt } from 'hooks/use-prompt'

import ControllerInputDatePicker from '../controller-input-datepicker'
import ControllerInputSelect from '../controller-input-select'
import ControllerInputText from '../controller-input-text'
import ControllerInputWeekdays from '../controller-input-weekdays'
import PeriodPrices from '../period-prices'

import * as S from './styled'

interface PeriodAccordionContentProps {
  period: CenterPeriod
  isPeriodicPrice: boolean
  isProtected?: boolean
  isAggregationCenter?: boolean
  isFromProtected?: boolean
  readonly?: boolean
  onSave?: (periodId: number, period: Partial<CenterPeriod>) => void
}

const defaultValues: CenterPeriodFields = {
  from: dayjs().format(DateFormats.full),
  to: dayjs().format(DateFormats.full),
  priority: PeriodPriority.MEDIUM,
  weekdays: [
    PeriodWeekday.MONDAY,
    PeriodWeekday.TUESDAY,
    PeriodWeekday.WEDNESDAY,
    PeriodWeekday.THURSDAY,
    PeriodWeekday.FRIDAY,
    PeriodWeekday.SATURDAY,
    PeriodWeekday.SUNDAY,
  ],
  minDays: 1,
  maxDays: 31,
  prices: Array(31).map(() => 0),
}

const PeriodAccordionContent: FC<PeriodAccordionContentProps> = ({
  period,
  isPeriodicPrice,
  isAggregationCenter,
  readonly,
  onSave,
  isProtected,
  isFromProtected,
}) => {
  const { t } = useTranslation()
  const { user } = useAuth()
  const setDirty = useFormStore(store => store.setDirty)
  const isFormDirty = useFormStore(store => store.isDirty)
  const resetForm = useFormStore(store => store.resetForm)

  usePrompt({
    isDirty: isFormDirty,
    onConfirm: resetForm,
  })

  const [blockedPeriod, setBlockedPeriod] = useState(false)
  const [days, setDays] = useState({ minDays: defaultValues.minDays, maxDays: defaultValues.maxDays })

  const {
    control,
    formState: { isDirty, errors, dirtyFields, defaultValues: formDefaultValues },
    handleSubmit,
    reset,
    setValue,
    watch,
  } = useForm<CenterPeriodFields>({
    defaultValues: period ?? defaultValues,
    resolver: yupResolver(centerPeriodSchema),
  })

  useEffect(() => {
    if (!period) {
      return
    }

    reset({
      ...defaultValues,
      ...period,
      from: dateFormatter({ date: period.from, isUTC: true, pattern: DateFormats.iso }),
      to: dateFormatter({ date: period.to, isUTC: true, pattern: DateFormats.iso }),
    })
  }, [period, reset])

  useFormErrorScroll(errors)

  const [minDaysWatch, maxDaysWatch, pricesWatch, fromWatch, toWatch] = watch([
    'minDays',
    'maxDays',
    'prices',
    'from',
    'to',
  ])

  useEffect(() => {
    const newPrices = [...pricesWatch]
    const minDays = Math.max(minDaysWatch, 1)
    const newPricesLength = maxDaysWatch > 0 ? maxDaysWatch - minDays + 1 : 0

    if (pricesWatch.length > newPricesLength) {
      newPrices.splice(newPricesLength)
    } else if (pricesWatch.length < newPricesLength) {
      const diff = newPricesLength - pricesWatch.length
      for (let i = 0; i < diff; i++) {
        newPrices.push(0)
      }
    }

    setValue('prices', newPrices, { shouldDirty: true })

    setBlockedPeriod(maxDaysWatch == 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minDaysWatch, maxDaysWatch])

  useEffect(() => {
    if (!toWatch || dayjs(fromWatch) > dayjs(toWatch)) {
      const newTo = dayjs(fromWatch).add(1, 'day').format(DateFormats.iso)
      setValue('to', newTo, { shouldDirty: true })
    }
  }, [fromWatch, toWatch, setValue])

  useEffect(() => {
    setDirty(isDirty)
  }, [isDirty, setDirty])

  const handleDirtySubmit = useCallback(
    (formValues: CenterPeriodFields) => {
      const newPeriodValues: Partial<CenterPeriod> = (Object.keys(dirtyFields) as (keyof CenterPeriodFields)[]).reduce(
        (prev, curr) => (formDefaultValues?.[curr] !== formValues[curr] ? { ...prev, [curr]: formValues[curr] } : prev),
        {}
      )

      // Needed for price proper update in backend
      if ('maxDays' in dirtyFields || 'minDays' in dirtyFields || 'prices' in dirtyFields) {
        newPeriodValues.maxDays = formValues.maxDays
        newPeriodValues.minDays = formValues.minDays
        newPeriodValues.prices = formValues.prices
      }

      if ('from' in dirtyFields) {
        newPeriodValues.from = dateFormatter({ date: formValues.from, pattern: DateFormats.isoZ })
      }

      if ('to' in dirtyFields) {
        newPeriodValues.to = dateFormatter({ date: formValues.to, pattern: DateFormats.isoZ })
      }

      onSave?.(period.id, newPeriodValues)
    },
    [dirtyFields, formDefaultValues, period.id, onSave]
  )

  const handleBlockChange = (_: ChangeEvent, value: boolean) => {
    setBlockedPeriod(value)

    if (value) {
      setDays({ minDays: minDaysWatch, maxDays: maxDaysWatch })
      setValue('minDays', 0)
      setValue('maxDays', 0)
    } else {
      setValue('minDays', days.minDays)
      setValue('maxDays', days.maxDays)
    }
  }

  return (
    <AccordionDetails>
      <S.Form onSubmit={handleSubmit(handleDirtySubmit)} noValidate>
        {!(readonly || isProtected) && (
          <S.StyledIconButton type='submit'>
            <SaveOutlined />
          </S.StyledIconButton>
        )}
        <H3 text={t`Periodo efectivo`} textTransform='uppercase' />
        <Grid container spacing={2} marginBottom={4} justifyContent='center'>
          <Grid item className='date-container'>
            <ControllerInputDatePicker
              label={t('Desde (INC)')}
              name='from'
              control={control}
              error={errors.from}
              size='full'
              disabled={readonly || isProtected || isFromProtected}
              mandatory
              minDate={dayjs().startOf('day')}
            />
          </Grid>
          <Grid item className='date-container'>
            <ControllerInputDatePicker
              label={t('Hasta (EXC)')}
              name='to'
              control={control}
              error={errors.to}
              size='full'
              disabled={readonly || isProtected}
              mandatory
              minDate={dayjs().add(1, 'day').startOf('day')}
            />
          </Grid>
          <Grid item xs>
            <ControllerInputSelect
              control={control}
              name='priority'
              error={errors.priority}
              label={t`Prioridad`}
              size='full'
              options={PERIOD_PRIORITY_OPTIONS}
              disabled={readonly || isProtected}
              mandatory
            />
          </Grid>

          <Grid item xs={12}>
            <ControllerInputWeekdays control={control} name='weekdays' disabled={readonly || isProtected} />
          </Grid>

          <Grid item xs={12}>
            <H3 text={t`Productos disponibles`} textTransform='uppercase' />
          </Grid>

          <Grid item xs={4}>
            <ControllerInputText
              control={control}
              name='minDays'
              error={errors.minDays}
              label={t`Días de estancia mínima`}
              size='full'
              inputProps={{ endAdornment: <InputIcon Icon={TodayIcon} /> }}
              nativeInputProps={{ type: 'number', min: '0', max: maxDaysWatch, step: '1' }}
              disabled={
                readonly ||
                isProtected ||
                ((isPeriodicPrice || isAggregationCenter) &&
                  (user?.role === Roles.CERCA || user?.role === Roles['ADMIN-CERCA']))
              }
              mandatory
            />
          </Grid>
          <Grid item xs={2} display='flex' justifyContent='center' alignItems='center' height='74px'>
            <DoubleArrowIcon />
          </Grid>
          <Grid item xs={4}>
            <ControllerInputText
              control={control}
              name='maxDays'
              error={errors.maxDays}
              label={t`Días de estancia máxima`}
              size='full'
              inputProps={{ endAdornment: <InputIcon Icon={EventIcon} /> }}
              nativeInputProps={{ type: 'number', min: minDaysWatch, step: '1' }}
              disabled={
                readonly ||
                isProtected ||
                ((isPeriodicPrice || isAggregationCenter) &&
                  (user?.role === Roles.CERCA || user?.role === Roles['ADMIN-CERCA']))
              }
              mandatory
            />
          </Grid>

          {!(
            readonly ||
            isProtected ||
            ((isPeriodicPrice || isAggregationCenter) &&
              (user?.role === Roles.CERCA || user?.role === Roles['ADMIN-CERCA']))
          ) && (
              <Grid item xs={12} marginBottom={2} textAlign='center'>
                <Trans>
                  Para <strong>bloquear la venta de prebooks</strong> en un periodo concreto basta con establecer el
                  número de días de estancia máxima a 0
                </Trans>
                <S.InputSwitch
                  field={
                    {
                      name: 'isPeriodBlocked',
                      value: blockedPeriod,
                      onChange: handleBlockChange,
                    } as SwitchControllerField
                  }
                  label={t`Bloquear periodo`}
                  boxDirection='row'
                  variant='full'
                />
              </Grid>
            )}

          {isPeriodicPrice && !blockedPeriod && (
            <>
              <Grid item xs={12}>
                <H3 text={t`Precios por periodos`} textTransform='uppercase' />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name='prices'
                  disabled={readonly || isProtected}
                  render={({ field: { ref, ...field } }) => (
                    <PeriodPrices {...field} startDay={Math.max(minDaysWatch, 1)} />
                  )}
                />
              </Grid>
            </>
          )}

          <Grid item xs={12} display='flex' justifyContent='center' marginTop={0.5}>
            {!(readonly || isProtected) && <SubmitButton />}
          </Grid>
        </Grid>
      </S.Form>
    </AccordionDetails>
  )
}

export default PeriodAccordionContent
