import { Roles } from 'models/auth.d'
import { LocationTypes } from 'models/locations'

import i18n from './i18n'

export const ADMIN_API_BASE_URL = process.env.REACT_APP_API_URL ?? 'https://dev-adminapi.dps.telpark.com/'
export const EXPOSE_API_BASE_URL = process.env.REACT_APP_EXPOSE_API ?? 'https://dev-exposeapi.dps.telpark.com/'
export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT ?? 'DEV'
export const COGNITO_URL = process.env.REACT_APP_COGNITO_URL ?? 'https://dev-dps.auth.eu-west-1.amazoncognito.com'
export const COGNITO_CLIENT_ID = process.env.REACT_APP_COGNITO_CLIENT_ID ?? 'dq3r1kvd2rk1c7cm3rdqrd1pp'
export const COGNITO_REDIRECT_URL =
  process.env.REACT_APP_COGNITO_REDIRECT_URL ?? 'http://localhost:3000/cognito_redirect'
export const REACT_APP_LOGIN_MODE = process.env.REACT_APP_LOGIN_MODE ?? 'cognito'
export const SENTRY_DSN =
  process.env.REACT_APP_SENTRY_DSN ??
  'https://d50b5c21f9ad9cb09a5348680fa38b44@o1045333.ingest.sentry.io/4505883438678016'

export const ROWS_PER_PAGE_OPTIONS = [5, 10, 15]

export const INITIAL_PAGE_OPTION = 5

export enum FontSizes {
  sm = '0.7rem',
  xxs = '0.6rem',
  md = '0.75rem',
  base = '0.85rem',
  lg = '1rem',
  xl = '1.2rem',
  xxl = '1.5rem',
}

export enum FontStyles {
  italic = 'italic',
  bold = 'bold',
}

export enum FontFamily {
  bold = 'Poppins-Bold',
  light = 'Poppins-Light',
  medium = 'Poppins-Medium',
  regular = 'Poppins-Regular',
  semiBold = 'Poppins-Semibold',
}

export enum DateFormats {
  full = 'DD/MM/YYYY HH:mm',
  day = 'DD/MM/YYYY',
  hour = 'HH:mm',
  iso = 'YYYY-MM-DDTHH:mm:ss',
  isoZ = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]',
  iso8601 = 'YYYY-MM-DDTHH:mm:ss.SSSZ',
  isoCeros = 'YYYY-MM-DDT00:00:00.000Z',
  monthYear = 'MMMM YYYY',
  fileDate = 'YYYY-MM-DD',
}

export const INVALID_DATE = 'Invalid Date'

export const inputsWidth = {
  small: '160px',
  medium: '220px',
  large: '290px',
  full: '100%',
}

export const INPUT_CONTAINER_HEIGHT = '90px'

export const INPUT_BASE_HEIGHT = '55px'

export enum TableTypes {
  default = 'default',
  selection = 'selection',
  disabled = 'disabled',
}

export enum TableColumnWidth {
  xxs = 0.2,
  xs = 0.6,
  small = 0.75,
  base = 1,
  large = 1.25,
  xl = 1.5,
}

export enum RowHeight {
  small = 55,
  medium = 65,
  large = 80,
}

export enum Sort {
  asc = 'asc',
  desc = 'desc',
}

export const buttonsWidth = {
  small: '140px',
  medium: '200px',
  large: '280px',
  full: '100%',
}

export const APPLICATIONS_ROLES = [
  {
    value: 'CONSULTA',
    text: i18n.t('CONSULTA'),
  },
]

export const USER_ROLES = Object.values(Roles).map(role => ({ value: role, text: i18n.t(role) }))

export const ACTION_OPTIONS = [
  {
    value: 'CREATE',
    text: i18n.t('Crear'),
  },
  {
    value: 'UPDATE',
    text: i18n.t('Actualizar'),
  },
  {
    value: 'DELETE',
    text: i18n.t('Eliminar'),
  },
]

export const RESOURCE_TYPE_OPTIONS = [
  {
    value: 'IDENTITY',
    text: i18n.t('Identidad'),
  },
  {
    value: 'RELATION',
    text: i18n.t('Relación'),
  },
  {
    value: 'GROUP',
    text: i18n.t('Grupo'),
  },
  {
    value: 'USER',
    text: i18n.t('Usuario'),
  },
  {
    value: 'APPLICATION',
    text: i18n.t('Aplicación'),
  },
  {
    value: 'CENTER',
    text: i18n.t('Centro'),
  },
  {
    value: 'PERIOD',
    text: i18n.t('Periodo'),
  },
  {
    value: 'CURVE',
    text: i18n.t('Curva'),
  },
  {
    value: 'DAY',
    text: i18n.t('Día'),
  },
]

export const LOCATION_RESOURCETYPE = 'LOCATION'

export const LOCATION_TYPES: Array<LocationTypes> = [
  {
    id: 1,
    value: 'COUNTRY',
    text: i18n.t('País'),
  },
  {
    id: 2,
    value: 'PROVINCE',
    text: i18n.t('Provincia'),
  },
  {
    id: 3,
    value: 'MUNICIPALITY',
    text: i18n.t('Municipio'),
  },
  {
    id: 4,
    value: 'POSTAL_CODE',
    text: i18n.t('Código postal'),
  },
  {
    id: 5,
    value: 'CAR_PARK',
    text: i18n.t('Aparcamiento'),
  },
  {
    id: 6,
    value: 'COORDINATE',
    text: i18n.t('Coordenadas'),
  },
]

export const KEY_TYPES = [
  {
    value: 'ACCOUNT_ID',
    text: i18n.t('Id de la cuenta'),
  },
  {
    value: 'DOCUMENT_ID',
    text: i18n.t('Id del documento'),
  },
  {
    value: 'PASSPORT',
    text: i18n.t('Pasaporte'),
  },
  {
    value: 'PLATE_NUMBER',
    text: i18n.t('Matrícula'),
  },
  {
    value: 'EMAIL',
    text: i18n.t('Email'),
  },
  {
    value: 'UUID',
    text: i18n.t('Uuid'),
  },
  {
    value: 'GROUP',
    text: i18n.t('Grupo'),
  },
  {
    value: 'RELATION',
    text: i18n.t('Relación'),
  },
  {
    value: 'OTHER',
    text: i18n.t('Otros'),
  },
]

export enum EvolutionChartTypes {
  price = 'price',
  sales = 'sales',
}

export const HISTORIC_END_PATH = 'versions'

export const UPLOAD_FILE_FORMATS = ['text/csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']

export const DAYS_IN_MONTH = 30.5

export const CHANNELS = [
  { value: 'TELPARK_APP', text: 'Telpark app' },
  { value: 'EASYPREBOOKS', text: 'Easyprebooks' },
]

export const CENTER_TYPES = [
  { value: 'AGGREGATION', text: 'AGGREGATION' },
  { value: 'TELPARK', text: 'TELPARK' },
]

export enum RelationTabsValue {
  centers,
  relationTarget,
  config,
  resume,
}
