import { KeyboardEvent, ClipboardEvent, forwardRef } from 'react'

import { TextFieldProps } from '@mui/material/TextField'

import { StyledInput } from './styled'

type BasicPositiveNumberProps = TextFieldProps & { isInteger?: boolean }

const numberInputProps = (isInteger?: boolean) => ({
  type: 'number',
  min: 0,
  step: 1,
  onKeyPress: (evt: KeyboardEvent<HTMLInputElement>) => {
    const isIntegerCondition = !isInteger ? evt.code !== 'Comma' && evt.code !== 'Period' : true
    if (isIntegerCondition && isNaN(parseInt(evt.key))) {
      evt.preventDefault()
      return false
    }
  },
  onPaste: (evt: ClipboardEvent<HTMLInputElement>) => {
    const { currentTarget } = evt
    setTimeout(() => (currentTarget.value = currentTarget.value.replace('-', '').replace('e', '')), 0)
  },
})

const BasicPositiveNumber = forwardRef<HTMLInputElement, BasicPositiveNumberProps>(({ isInteger, ...props }, ref) => (
  <StyledInput
    {...props}
    ref={ref}
    type={'number'}
    inputProps={{ ...numberInputProps(isInteger), ...props.inputProps }}
  />
))

BasicPositiveNumber.displayName = 'BasicPositiveNumber'

export default BasicPositiveNumber
