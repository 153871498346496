import { AxiosResponse } from 'axios'

import { AddAlertRecipient, Alert, AlertRecipient, GetAlerts } from 'models/alert'
import { adminApi } from 'services'
import { removeEmpty } from 'utils/remove-empty'

export const getAlerts = ({ filters, ...payload }: GetAlerts): Promise<AxiosResponse<Alert[]>> =>
  adminApi.get('/alerts', {
    params: removeEmpty({ ...payload, ...filters }),
  })

export const updateOneAlert = (id: number, isRead: boolean): Promise<AxiosResponse<Alert>> =>
  adminApi.put(`/alerts/${id}`, { isRead })

export const getAlertsByEmail = (email: string): Promise<AxiosResponse<AlertRecipient[]>> =>
  adminApi.get(`/alert-recipients?email=${email}`)

export const addAlertRecipient = (payload: AddAlertRecipient): Promise<AxiosResponse<AlertRecipient>> =>
  adminApi.post('/alert-recipients', payload)

export const removeAlertRecipient = (id: number): Promise<AxiosResponse<boolean>> =>
  adminApi.delete(`/alert-recipients/${id}`)

export const setAllCentersWithAlerts = ({
  email,
  allCentersAlerts,
}: {
  email: string
  allCentersAlerts: boolean
}): Promise<AxiosResponse<boolean>> => adminApi.post('/alert-recipients', { email, allCentersAlerts })
export const updateAllCentersWithAlerts = ({
  id,
  allCentersAlerts,
}: {
  id: number
  allCentersAlerts: boolean
}): Promise<AxiosResponse<boolean>> => adminApi.patch(`/alert-recipients/${id}`, { allCentersAlerts })
