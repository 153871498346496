import * as React from 'react'

import { InputBaseComponentProps, InputProps } from '@mui/material'
import TextField, { TextFieldVariants } from '@mui/material/TextField/TextField'

import { Sizes } from 'models/sizes'
import { inputsWidth } from 'config/constants'
import { ControllerField } from 'models/form'

import { StyledBox } from './styled'

interface Props {
  field?: ControllerField
  label: string
  mandatory?: boolean
  defaultRequired?: boolean
  helperText?: string
  error?: boolean
  size: Sizes
  disabled?: boolean
  inputProps?: InputProps
  nativeInputProps?: InputBaseComponentProps
  variant?: TextFieldVariants
}

const BasicInputText: React.FC<Props> = ({
  field,
  label,
  mandatory,
  defaultRequired,
  helperText,
  error,
  size,
  disabled,
  inputProps,
  nativeInputProps,
  variant,
}) => (
  <StyledBox width={inputsWidth[size]}>
    <TextField
      {...field}
      required={defaultRequired}
      label={mandatory ? `${label} *` : label}
      helperText={helperText}
      error={error}
      InputProps={inputProps}
      inputProps={nativeInputProps}
      disabled={disabled}
      variant={variant ?? 'outlined'}
      fullWidth
    />
  </StyledBox>
)

export default BasicInputText
