import { QueryKey, useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { GET_CENTERS } from 'config/queries'
import { Center, CentersFilters } from 'models/center'
import { getCenters } from 'services/centers'

interface UseGetCentersProps {
  limit: number
  offset: number
  filters: CentersFilters
  sort: string
}

interface UseGetCenters {
  response: Center[]
  count: number
  isLoading: boolean
  queryKey: QueryKey
}

const useGetCenters = ({ limit, offset, filters, sort }: UseGetCentersProps): UseGetCenters => {
  const { t } = useTranslation()
  const queryKey = [GET_CENTERS, limit, offset, filters, sort]

  const {
    data: response,
    isLoading,
    isPreviousData,
  } = useQuery({
    queryKey,
    queryFn: () => getCenters({ limit, offset, filters, sort }),
    onError: () => {
      toast(t('Ha ocurrido un error al obtener los centros'), { type: 'error' })
    },
    refetchOnMount: true,
    keepPreviousData: true,
  })

  const result: Center[] = response?.data ?? []
  const count: number = parseInt(response?.headers?.['x-total-count'] || 0)

  return { response: result, count, isLoading: isLoading || isPreviousData, queryKey }
}

export default useGetCenters
