import React from 'react'

import InputLabel from '@mui/material/InputLabel'
import Switch from '@mui/material/Switch'
import Tooltip from '@mui/material/Tooltip'

import { Sizes } from 'models/sizes'
import { SwitchControllerField } from 'models/form'

import { Container, MainContainer } from './styled'

interface Props {
  field: SwitchControllerField
  label: string | null
  boxDirection: 'column' | 'row'
  variant: Sizes
  disabled?: boolean
  autoHeight?: boolean
  className?: string
}

const BasicInputSwitch: React.FC<Props> = ({
  field,
  label,
  boxDirection,
  variant,
  disabled,
  autoHeight,
  className,
}) => (
  <MainContainer $autoHeight={autoHeight} className={className}>
    <Container $direction={boxDirection} $variant={variant}>
      {label && (
        <Tooltip title={label}>
          <InputLabel disabled={disabled}>{label}</InputLabel>
        </Tooltip>
      )}
      <Switch {...field} checked={field.value} disabled={disabled} />
    </Container>
  </MainContainer>
)
export default BasicInputSwitch
