import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'

export const Container = styled(Grid)`
  padding: 0 1rem;
  margin-top: 0;

  .MuiGrid-item .MuiBox-root {
    height: auto;

    .MuiFormControl-root {
      height: auto;
      min-height: 55px;

      .MuiFormHelperText-root {
        margin-left: 2px;
        margin-right: 4px;
      }
    }
  }

  .MuiGrid-root > .MuiButton-root {
    width: 100%;
  }
`
