import { QueryKey, useQuery } from '@tanstack/react-query'

import { GET_ONE_CENTER } from 'config/queries'
import { Center } from 'models/center'
import { getOneCenter } from 'services/centers'

interface UseGetOneCenterProps {
  id: number
}

interface UseGetOneCenter {
  response: Center | null
  isLoading: boolean
  queryKey: QueryKey
}

const useGetOneCenter = ({ id }: UseGetOneCenterProps): UseGetOneCenter => {
  const queryKey = [GET_ONE_CENTER, id]

  const {
    data: response,
    isLoading,
    isPreviousData,
  } = useQuery({
    queryKey,
    queryFn: () => getOneCenter(id),
    refetchOnMount: true,
    keepPreviousData: true,
    enabled: !isNaN(id),
  })

  const result = response?.data ?? null

  return { response: result, isLoading: isLoading || isPreviousData, queryKey }
}

export default useGetOneCenter
