import { useQuery } from '@tanstack/react-query'

import { GET_USER_RELATION_VERSIONS } from 'config/queries'
import { Version } from 'models/version'
import { getUserRelationVersions } from 'services/relations'

interface Args {
  id: string
  limit: number
  offset: number
  sort: string
}

const useGetUserRelationVersions = ({ id, limit, offset, sort }: Args) => {
  const queryKey = [GET_USER_RELATION_VERSIONS, id, limit, offset, sort]
  const { data: response, isLoading } = useQuery({
    queryKey,
    queryFn: () => getUserRelationVersions(id, { limit, offset, sort }),
  })

  const result: Array<Version> = response?.data || []
  const count: number = parseInt(response?.headers?.['x-total-count'] || 0)

  return { response: result, count, isLoading }
}

export default useGetUserRelationVersions
