import { useCallback, useEffect } from 'react'

import { useBlocker } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useConfirm } from 'hooks/use-confirm'
import { ConfirmOptions } from 'models/confirm'

export const usePrompt = ({
  isDirty = false,
  title,
  subtitle,
  confirmText,
  cancelText,
  onConfirm,
  onCancel,
}: ConfirmOptions & { isDirty?: boolean }) => {
  const { t } = useTranslation()
  const blocker = useBlocker(isDirty)
  const { show } = useConfirm()
  const confirm = useCallback(() => {
    if (!isDirty) return Promise.resolve(true)
    return new Promise<boolean>(resolve => {
      show({
        title: title || t('Cambios sin guardar'),
        subtitle: subtitle || t('¿Estás seguro de que quieres salir sin guardar los cambios?'),
        confirmText: confirmText || t('Salir sin guardar'),
        cancelText: cancelText || t('Cancelar'),
        onConfirm: () => {
          resolve(true)
          onConfirm?.()
        },
        onCancel: () => {
          resolve(false)
          onCancel?.()
        },
      })
    })
  }, [cancelText, confirmText, isDirty, onCancel, onConfirm, show, subtitle, title, t])
  useEffect(() => {
    if (blocker.state === 'blocked') {
      confirm().then(result => {
        if (result) blocker.proceed()
        else blocker.reset()
      })
    }
  }, [blocker, confirm])
  useEffect(() => {
    if (isDirty) {
      window.onbeforeunload = () => subtitle
    }
    return () => {
      window.onbeforeunload = null
    }
  }, [isDirty, subtitle])
  return {
    confirm,
  }
}
