import React, { useEffect, useRef } from 'react'

import SearchIcon from '@mui/icons-material/Search'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'

import * as S from './styled'

interface Props {
  label: string
  disabled?: boolean
  value: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const SearchdAdornment = (): JSX.Element => (
  <InputAdornment position='end'>
    <SearchIcon />
  </InputAdornment>
)

const SearchInput: React.FC<Props> = ({ disabled, label, onChange, value }) => {
  const inputRef = useRef<HTMLInputElement>()

  useEffect(() => {
    if (disabled !== undefined && inputRef?.current) {
      inputRef.current.value = ''
    }
  }, [disabled])

  return (
    <S.SearchInputContainer>
      <TextField
        fullWidth
        variant='outlined'
        id='search-input'
        label={label}
        value={value}
        inputRef={inputRef}
        disabled={disabled}
        InputProps={{ endAdornment: <SearchdAdornment /> }}
        onChange={onChange}
      />
    </S.SearchInputContainer>
  )
}

export default SearchInput
