import { FC } from 'react'

import EuroIcon from '@mui/icons-material/Euro'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import CancelButton from 'components/atoms/cancel-button'
import FormButtonsContainer from 'components/atoms/form-buttons-container'
import SpinnerLoading from 'components/atoms/spinner-loading'
import PageTitle from 'components/atoms/page-title'
import { DateFormats } from 'config/constants'
import useGetOneCenterDayVersion from 'hooks/queries/centers/use-get-one-center-day-version'
import { dateFormatter } from 'utils/date-formatter'
import BasicInputText from 'components/atoms/basic-input-text'
import { ControllerField } from 'models/form'
import InputIcon from 'components/atoms/input-icon'
import PapperGridContainer from 'components/atoms/papper-grid-container-form'

const CenterDaysHistoricShow: FC = () => {
  const { id = '', version = '' } = useParams()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { response: day, isLoading } = useGetOneCenterDayVersion({ id, version })

  if (isLoading) {
    return <SpinnerLoading fullHeight />
  } else if (!day) {
    navigate(-1)
    return null
  }

  const title = `${t('Día')} ${dateFormatter({
    date: day.date,
    pattern: DateFormats.day,
  })} - ${t`Versión`} ${day.version}`

  return (
    <Box data-testid='day-version'>
      <PageTitle title={title} />

      <PapperGridContainer spacing={1}>
        <Grid item xs={3}></Grid>
        <Grid item xs={6}>
          <BasicInputText
            disabled
            label={t`Precio`}
            field={{ value: day.price } as ControllerField}
            size='full'
            inputProps={{ endAdornment: <InputIcon Icon={EuroIcon} /> }}
          />
        </Grid>
      </PapperGridContainer>

      <FormButtonsContainer>
        <CancelButton />
      </FormButtonsContainer>
    </Box>
  )
}

export default CenterDaysHistoricShow
