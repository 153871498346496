import React from 'react'

import { Controller, FieldError, Control } from 'react-hook-form'
import { InputProps } from '@mui/material/Input'
import { InputBaseComponentProps } from '@mui/material/InputBase'
import { TextFieldVariants } from '@mui/material'

import BasicInputText from 'components/atoms/basic-input-text'
import { Sizes } from 'models/sizes'

interface Props {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  control: Control<any, any>
  error?: FieldError
  disabled?: boolean
  name: string
  label: string
  size?: Sizes
  mandatory?: boolean
  inputProps?: InputProps
  nativeInputProps?: InputBaseComponentProps
  variant?: TextFieldVariants
}
const ControllerInputText: React.FC<Props> = ({
  control,
  error,
  disabled,
  name,
  label,
  inputProps,
  nativeInputProps,
  mandatory = false,
  size = 'medium',
  variant,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <BasicInputText
          mandatory={mandatory}
          field={field}
          label={label}
          error={!!error}
          helperText={error && (error.message as string)}
          size={size}
          disabled={disabled}
          inputProps={inputProps}
          nativeInputProps={nativeInputProps}
          variant={variant}
        />
      )}
    />
  )
}

export default ControllerInputText
