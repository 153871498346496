import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'

import { colors } from 'config/theme'

export const FooterContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${colors.deepBlue};
  margin-top: 6em;
  padding-top: 40px;
  padding-bottom: 100px;
  gap: 50px;
`
