import { AxiosError } from 'axios'
import { QueryKey, useMutation, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { GET_CALENDAR, GET_CENTERS, GET_DAILY_OCCUPATION, GET_ONE_CENTER } from 'config/queries'
import { Center, UpdateCenter } from 'models/center'
import { updateOneCenter } from 'services/centers'

const useUpdateCenter = (queryKey?: QueryKey) => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  return useMutation({
    mutationFn: ({ id, ...payload }: UpdateCenter) => updateOneCenter(id, payload),
    onSuccess: (data, variables) => {
      const getCenterQueryKey = [GET_ONE_CENTER, variables.id]
      queryClient.setQueryData(getCenterQueryKey, data)
      toast(t('Centro actualizado'), { type: 'success' })
    },
    onMutate: async newValue => {
      await queryClient.cancelQueries({ queryKey: [GET_CENTERS] })
      const previous = queryClient.getQueryData<{ data: Center[] }>(queryKey ?? [GET_CENTERS])
      const center = previous?.data.find(({ id }) => id === newValue.id)

      if (center) {
        Object.assign(center, newValue)
      }

      return { previous }
    },
    onError: (error: AxiosError<{ payload: object }>, newValue, context) => {
      queryClient.setQueryData([GET_CENTERS], context?.previous)
      if (error.response?.data?.payload) {
        const firstMessage = Object.values(Object.values(error.response?.data?.payload)[0] ?? {})[0]
        if (firstMessage && typeof firstMessage) {
          toast(t(firstMessage as string), { type: 'error' })
          return
        }
      }

      toast(t('Ha ocurrido un error'), { type: 'error' })
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [GET_CENTERS] })
      queryClient.invalidateQueries({ queryKey: [GET_CALENDAR] })
      queryClient.invalidateQueries({ queryKey: [GET_DAILY_OCCUPATION] })
    },
  })
}

export default useUpdateCenter
