import React from 'react'

import { Control, Controller, FieldError } from 'react-hook-form'
import { Dayjs } from 'dayjs'

import { Sizes } from 'models/sizes'
import BasicDateTimePicker from 'components/atoms/basic-datetime-picker'

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>
  error?: FieldError
  disabled?: boolean
  name: string
  label: string
  size?: Sizes
  mandatory?: boolean
  minDate?: Dayjs
  maxDate?: Dayjs
  pattern?: string
  onlyDate?: boolean
  isUTC?: boolean
}

const ControllerInputDateTimePicker: React.FC<Props> = ({
  control,
  disabled,
  name,
  label,
  error,
  mandatory = false,
  size = 'medium',
  minDate,
  maxDate,
  pattern,
  onlyDate,
  isUTC,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, ...props }) => (
        <BasicDateTimePicker
          {...props}
          mandatory={mandatory}
          error={!!error}
          helperText={error && error.message}
          field={field}
          label={label}
          disabled={disabled}
          size={size}
          isUTC={isUTC}
          maxDate={maxDate}
          minDate={minDate}
          pattern={pattern}
          onlyDate={onlyDate}
        />
      )}
    />
  )
}

export default ControllerInputDateTimePicker
