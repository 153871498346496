import { FC, useEffect, useState } from 'react'

import { useNavigate, useParams } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/system'
import { toast } from 'react-toastify'

import routes from 'config/routes'
import CancelButton from 'components/atoms/cancel-button'
import FormButtonsContainer from 'components/atoms/form-buttons-container'
import PapperGridContainer from 'components/atoms/papper-grid-container-form'
import GridItem from 'components/atoms/grid-item'
import useFormErrorScroll from 'hooks/use-form-error-scroll'
import { UsersRelationFormFields, usersRelationSchema } from 'validations/users-relations'
import { RelationTabsValue, TableTypes } from 'config/constants'
import BasicButton from 'components/atoms/basic-button'
import RelationTabContainer from 'components/molecules/relation-tab-container'
import RelationTabs from 'components/molecules/relation-tabs'
import RelationFormResume from 'components/molecules/relation-form-resume'
import FormTitle from 'components/atoms/form-title'
import ControllerRelationConfig from 'components/molecules/controller-relation-config'
import ControllerCentersList from 'components/molecules/controller-centers-list'
import ControllerUsersList from 'components/molecules/controller-users-list'
import useGetOneUsersRelation from 'hooks/queries/relations/use-get-one-users-relation'
import useCreateRelation from 'hooks/queries/relations/use-create-users-relation'
import useUpdateRelation from 'hooks/queries/relations/use-update-users-relation'
import CentersTable from 'components/organisms/centers/centers-table'
import UsersTable from 'components/organisms/users/users-table'
import useGetCenters from 'hooks/queries/centers/use-get-centers'
import { useFormStore } from 'store/useFormStore'
import { usePrompt } from 'hooks/use-prompt'

const defaultValues: UsersRelationFormFields = {
  managedCenters: [],
  managedUsers: [],
  name: '',
  active: true,
  allCenters: false,
}

interface UsersRelationsCreateFormProps {
  readonly?: boolean
}

const UsersRelationsForm: FC<UsersRelationsCreateFormProps> = ({ readonly }) => {
  const { id = '', version = '' } = useParams()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const isFormDirty = useFormStore(store => store.isDirty)
  const setDirty = useFormStore(store => store.setDirty)
  const resetForm = useFormStore(store => store.resetForm)

  usePrompt({
    isDirty: isFormDirty,
    onConfirm: resetForm,
  })

  const [tabValue, setTabValue] = useState<RelationTabsValue>(
    version ? RelationTabsValue.config : RelationTabsValue.centers
  )

  const { response: relation, isLoading } = useGetOneUsersRelation({
    id: parseInt(id),
    version: isNaN(parseInt(version)) ? undefined : parseInt(version),
  })

  const { count } = useGetCenters({
    offset: 0,
    limit: 1,
    filters: {},
    sort: 'id',
  })

  const createRelation = useCreateRelation()
  const updateRelation = useUpdateRelation()

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
    setValue,
    watch,
    getValues,
    trigger,
    reset,
  } = useForm<UsersRelationFormFields>({
    defaultValues,
    resolver: yupResolver(usersRelationSchema),
  })

  useFormErrorScroll(errors)

  useEffect(() => {
    if (!relation) {
      return
    }
    reset({
      active: relation.active,
      name: relation.name,
      managedCenters: (relation.managedCenters ?? []).map(center => center.id),
      managedUsers: (relation.managedUsers ?? []).map(user => parseInt(`${user.id}`)),
      allCenters: relation.allCenters,
    })
    // eslint-disable-next-line
  }, [relation])


  useEffect(() => {
    setDirty(isDirty)
  }, [isDirty, setDirty])

  const { managedCenters, managedUsers, allCenters } = watch()

  // If allCenters is false and relation.allCenters is true, set managedCenters to empty array and update relation
  // const allCentersValue = useWatch({ control, name: 'allCenters' })
  useEffect(() => {
    if (allCenters === false && isDirty && relation?.allCenters === true) {
      setValue('managedCenters', [])
      updateRelation.mutate({ id: parseInt(id), allCenters: false, managedCenters: [] })
    }
    // eslint-disable-next-line
  }, [allCenters])

  const handleOnTabChange = async (tab: RelationTabsValue) => {
    if (tab < 0) return
    if (readonly) {
      setTabValue(tab)
      return
    }

    if (!allCenters && !managedCenters.length) {
      return toast(t('Seleccione al menos un centro'), { type: 'error' })
    }

    if (!managedUsers.length && tab > RelationTabsValue.relationTarget) {
      return toast(t('Seleccione al menos un usuario'), { type: 'error' })
    }

    const fieldsToValidate: (keyof UsersRelationFormFields)[] = ['name', 'active', 'allCenters']
    if (!allCenters) {
      fieldsToValidate.push('managedCenters')
    }

    const isConfigFullfilled = tab === RelationTabsValue.resume && (await trigger(fieldsToValidate))
    if (!isConfigFullfilled && tab === RelationTabsValue.resume) {
      return toast(t('Rellene todos los campos requeridos'), { type: 'error' })
    }

    setTabValue(tab)
  }

  const onSubmit = (relationFields: UsersRelationFormFields) => {
    const handleSuccess = () => {
      resetForm()
      navigate(routes.relationsList)
    }

    if (id) {
      updateRelation.mutate(
        { id: parseInt(id), ...relationFields },
        {
          onSuccess: handleSuccess,
          onError: () => {
            toast(t('Error al actualizar la relación'), { type: 'error' })
          }
        }
      )
    } else {
      createRelation.mutate(
        relationFields,
        {
          onSuccess: handleSuccess,
          onError: () => {
            toast(t('Error al crear la relación'), { type: 'error' })
          }
        }
      )
    }
  }

  return (
    <Grid container data-testid='relations-create-form'>
      <PapperGridContainer>
        <GridItem item xs={12}>
          <RelationTabs
            onTabsChange={handleOnTabChange}
            value={tabValue}
            relationTargetTitle={t`Usuarios`}
            isHistoric={!!version}
          />

          {!version && (
            <>
              <RelationTabContainer value={tabValue} index={RelationTabsValue.centers}>
                <FormTitle text={t('Centros')} />
                {readonly && relation ? (
                  <Box width='100%'>
                    <CentersTable
                      rows={relation.managedCenters}
                      count={managedCenters.length}
                      isLoading={isLoading}
                      type={TableTypes.disabled}
                    />
                  </Box>
                ) : (
                  <ControllerCentersList control={control} disabled={allCenters} isRelationsSearch />
                )}
              </RelationTabContainer>
              <RelationTabContainer value={tabValue} index={RelationTabsValue.relationTarget}>
                <FormTitle text={t('Usuarios')} />
                {readonly && relation ? (
                  <Box width='100%'>
                    <UsersTable
                      rows={relation.managedUsers}
                      count={relation.managedUsers.length}
                      isLoading={isLoading}
                      type={TableTypes.disabled}
                    />
                  </Box>
                ) : (
                  <ControllerUsersList control={control} disabled={readonly} isRelationsSearch />
                )}
              </RelationTabContainer>
            </>
          )}
          <RelationTabContainer value={tabValue} index={RelationTabsValue.config}>
            <ControllerRelationConfig control={control} errors={errors} disabled={readonly} />
          </RelationTabContainer>
          <RelationTabContainer value={tabValue} index={RelationTabsValue.resume}>
            <RelationFormResume getValues={getValues} count={count} />
          </RelationTabContainer>
          <Box display='flex' gap={2}>
            {((!version && tabValue !== RelationTabsValue.centers) || tabValue !== RelationTabsValue.config) && (
              <BasicButton
                text={t('Atrás')}
                type='button'
                variant='text'
                size='small'
                handleClick={() => handleOnTabChange(tabValue - 1)}
              />
            )}
            {tabValue !== RelationTabsValue.resume && (
              <BasicButton
                text={t('Siguiente')}
                type='button'
                variant='text'
                size='small'
                handleClick={() => handleOnTabChange(tabValue + 1)}
              />
            )}
          </Box>
        </GridItem>
      </PapperGridContainer>
      <FormButtonsContainer>
        <CancelButton to={routes.relationsList} />
        {tabValue === 3 && !readonly && (
          <BasicButton handleClick={handleSubmit(onSubmit)} text={t('Guardar cambios')} />
        )}
      </FormButtonsContainer>
    </Grid>
  )
}

export default UsersRelationsForm
