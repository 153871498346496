import { t } from 'i18next'

import { KEY_TYPES, LOCATION_TYPES } from 'config/constants'

export const keysFormatter = (key: string) => {
  const result = KEY_TYPES.find(k => k.value === key)
  return t(result?.text ?? '')
}

export const locationKeysFormatter = (key: string) => {
  const result = LOCATION_TYPES.find(k => k.value === key)
  return t(result?.text ?? '')
}
