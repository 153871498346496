import { useQuery } from '@tanstack/react-query'

import { getUsers } from 'services/user'
import { GET_USERS } from 'config/queries'
import { GetUsers, UseGetUsers, User } from 'models/users'

const useGetUsers = ({ limit, offset, filters, sort }: GetUsers): UseGetUsers => {
  const queryKey = [GET_USERS, limit, offset, sort, filters]
  const {
    data: response,
    isLoading,
    isPreviousData,
  } = useQuery({
    queryKey,
    queryFn: () => getUsers({ limit, offset, filters, sort }),
    refetchOnMount: true,
    keepPreviousData: true,
  })

  const result: User[] = response?.data ?? []
  const count: number = parseInt(response?.headers?.['x-total-count'] || 0)

  return { response: result, count, isLoading: isLoading || isPreviousData, queryKey }
}

export default useGetUsers
