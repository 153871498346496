import { FC, useCallback, useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'

import BasicButton from 'components/atoms/basic-button'
import CountChip from 'components/atoms/count-chip'
import ResourceHeader from 'components/atoms/resource-header'
import SearchInput from 'components/atoms/search-input'
import useUpdateMFL from 'hooks/queries/centers/use-update-mfl'
import { CenterType, CentersFilters } from 'models/center'
import BasicInputSelect from 'components/atoms/basic-input-select'
import { ClearFiltersButton } from 'components/atoms/clear-filter-button'
import { useAuth } from 'providers/auth'
import { Roles } from 'models/auth.d'

import * as S from './styled'

interface CentersTableHeaderProps {
  count: number
  isLoading?: boolean
  hideUpdateCenters?: boolean
  onFilter: (filters: CentersFilters) => void
  onSearchQ: (event: React.ChangeEvent<HTMLInputElement>) => void
  setIsLoadingStoredData: (value: boolean) => void
  isRelationsSearch?: boolean
}

const STATUS_CENTER_OPTIONS = [
  { value: 'Todos', text: 'Todos' },
  { value: 'false', text: 'Inactivos' },
  { value: 'true', text: 'Activos' },
]

const CENTER_OPTIONS = [
  { value: 'Todos', text: 'Todos' },
  { value: CenterType.AGGREGATION, text: 'Agregación' },
  { value: CenterType.TELPARK, text: 'Telpark' },
]

const initialData = {
  search: '',
  centerType: CENTER_OPTIONS[0].value as CenterType,
  status: 'true',
}

const CentersTableHeader: FC<CentersTableHeaderProps> = ({
  count,
  isLoading,
  hideUpdateCenters,
  onFilter,
  onSearchQ,
  setIsLoadingStoredData,
  isRelationsSearch = false,
}) => {
  const { t } = useTranslation()
  const { user } = useAuth()

  const { mutate: updateCenters, isLoading: isLoadingMFLCenters } = useUpdateMFL()

  const [selectedCenterType, setSelectedCenterType] = useState<CenterType | undefined>(initialData.centerType)
  const [selectedStatusCenter, setSelectedStatusCenter] = useState<string | undefined>(initialData.status)
  const [searchQuery, setSearchQuery] = useState<string>(initialData.search)
  const [sessionStorageKey, setSessionStorageKey] = useState<string | null>(null)

  useEffect(() => {
    if (isRelationsSearch) {
      setSessionStorageKey('relationsCentersFilters')
      return
    }
    setSessionStorageKey('centersFilters')

  }, [isRelationsSearch])

  const handleSelectedCenterType = useCallback(
    (event: Event) => {
      const { value } = event.target as HTMLInputElement
      setSelectedCenterType(value as CenterType)
      const status = value !== 'Todos' ?? true
      if (status) {
        onFilter({ centerType: value as CenterType })
      } else {
        onFilter({ centerType: undefined })
      }
    },
    [onFilter]
  )

  const handleSelectedStatusCenter = useCallback(
    (event: Event) => {
      const { value } = event.target as HTMLInputElement
      setSelectedStatusCenter(value)
      const status = value !== 'Todos' ?? true
      if (status) {
        onFilter({ active: JSON.parse(value) })
      } else {
        onFilter({ active: undefined })
      }
    },
    [onFilter]
  )

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setSearchQuery(value)
    onSearchQ(event)
  }

  const handleClearFilters = () => {
    setSelectedCenterType(initialData.centerType)
    setSelectedStatusCenter(initialData.status)
    setSearchQuery(initialData.search)
    if (sessionStorageKey) sessionStorage.removeItem(sessionStorageKey)
    onFilter({ search: initialData.search, centerType: undefined, active: true })
  }

  const refreshCenters = useCallback(
    () =>
      updateCenters(undefined, {
        onSuccess: ({ data }) => {
          if (data?.length) {
            onFilter({ active: undefined })
          }
        },
      }),
    [updateCenters, onFilter]
  )

  // Load stored data
  useEffect(() => {
    const storedFilters = sessionStorageKey && sessionStorage.getItem(sessionStorageKey)
    if (storedFilters) {
      setIsLoadingStoredData(true)
      const parsedData = JSON.parse(storedFilters)
      setSelectedCenterType(parsedData.centerType)
      setSelectedStatusCenter(parsedData.active)
      setSearchQuery(parsedData.search)

      const typeCenter = parsedData.centerType !== 'Todos' ?? true
      if (typeCenter) {
        onFilter({ centerType: parsedData.centerType, active: parsedData.active })
      } else {
        onFilter({ centerType: undefined, active: parsedData.active })
      }
      const status = parsedData.active !== 'Todos' ?? true
      if (status) {
        onFilter({ active: JSON.parse(parsedData.active) })
      } else {
        onFilter({ active: undefined })
      }

      onSearchQ({ target: { value: parsedData.search } } as React.ChangeEvent<HTMLInputElement>)

      setTimeout(() => {
        setIsLoadingStoredData(false)
      }, 500)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionStorageKey])

  // Set stored data when filters change
  useEffect(() => {
    if (
      selectedCenterType === initialData.centerType &&
      selectedStatusCenter === initialData.status &&
      searchQuery === initialData.search
    )
      return
    if (sessionStorageKey) {
      sessionStorage.setItem(
        sessionStorageKey,
        JSON.stringify({
          centerType: selectedCenterType,
          active: selectedStatusCenter,
          search: searchQuery,
        }))
    }
  }, [selectedCenterType, selectedStatusCenter, searchQuery, sessionStorageKey])

  return (
    <S.Container width='100%' display='flex' flexDirection='column'>
      <ResourceHeader>
        <CountChip label={t`Centros`} labelSingular={t`Centro`} count={count} isLoading={isLoading} />
        <SearchInput value={searchQuery} label={t`Código, nombre, provincia ...`} onChange={handleSearch} />
        <BasicInputSelect
          label={t`Tipo centro`}
          options={CENTER_OPTIONS}
          size='small'
          height='auto'
          mandatory
          field={{
            name: 'selectCenterType',
            ref: null,
            value: selectedCenterType,
            onChange: handleSelectedCenterType,
            onBlur: () => undefined,
          }}
        />
        <BasicInputSelect
          label={t`Estado`}
          options={STATUS_CENTER_OPTIONS}
          size='small'
          height='auto'
          mandatory
          field={{
            name: 'selectStatusCenter',
            ref: null,
            value: selectedStatusCenter,
            onChange: handleSelectedStatusCenter,
            onBlur: () => undefined,
          }}
        />
      </ResourceHeader>
      <S.Actions>
        <ClearFiltersButton handleClearFilters={handleClearFilters} color={'default'} />
        {!hideUpdateCenters && (
          <BasicButton
            disabled={isLoadingMFLCenters || user?.role === Roles.CERCA || user?.role === Roles['ADMIN-CERCA']}
            loading={isLoadingMFLCenters}
            size='large'
            text={t`Actualizar centros`}
            handleClick={refreshCenters}
          />
        )}
      </S.Actions>
    </S.Container>
  )
}

export default CentersTableHeader
