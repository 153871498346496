import styled from 'styled-components'
import Box from '@mui/material/Box'

import { colors } from 'config/theme'

export const AppBarUserList = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
`

export const AppBarUserContainer = styled(Box)`
  position: relative;
  width: 9em;
`

export const AppBarUserSelected = styled(Box)`
  margin-right: 1em;
  height: 3em;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

export const AppBarUserItem = styled(Box)`
  width: 11em;
  background-color: ${colors.whiteShadow};
  height: 3em;
  padding-right: 1em;
  padding-left: 1em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5em;
  cursor: pointer;

  &:hover {
    background-color: ${colors.grey};
  }
`
