import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { GET_RELATIONS } from 'config/queries'
import { Relation } from 'models/relation'
import { createOneUsersRelation } from 'services/relations'

const useCreateUsersRelation = () => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const createUserMutation = useMutation({
    mutationFn: (payload: Omit<Relation, 'id'>) => createOneUsersRelation(payload),
    onSuccess: () => {
      toast(t('Relación creada'), { type: 'success' })
      queryClient.invalidateQueries({ queryKey: [GET_RELATIONS] })
    },
    onError: () => {
      toast(t('Ha ocurrido un error'), { type: 'error' })
    },
  })

  return createUserMutation
}

export default useCreateUsersRelation
