import { FC } from 'react'

import PercentIcon from '@mui/icons-material/Percent'
import { Trans, useTranslation } from 'react-i18next'

import FormGridRow from 'components/atoms/form-grid-row'
import InputIcon from 'components/atoms/input-icon'
import ControllerInputText from 'components/molecules/controller-input-text'
import ControllerSwitch from 'components/molecules/controller-switch'

import { FormSection, SectionProps } from '.'

const Section3: FC<SectionProps> = ({ control, errors, readonly }) => {
  const { t } = useTranslation()

  return (
    <FormSection>
      <FormGridRow>
        <ControllerInputText
          control={control}
          name='telparkAppDiscount'
          error={errors.telparkAppDiscount}
          label={t`Tasa app Telpark`}
          size='full'
          inputProps={{ endAdornment: <InputIcon Icon={PercentIcon} /> }}
          nativeInputProps={{ type: 'number', min: '-100', max: '100', step: '0.01' }}
          disabled={readonly}
          mandatory
        />
        <Trans i18nKey='generalSettingsForm.telparkAppDiscount' shouldUnescape />
      </FormGridRow>
      <FormGridRow>
        <ControllerInputText
          control={control}
          name='easyPrebooksDiscount'
          error={errors.easyPrebooksDiscount}
          label={t`Tasa Easyprebooks`}
          size='full'
          inputProps={{ endAdornment: <InputIcon Icon={PercentIcon} /> }}
          nativeInputProps={{ type: 'number', min: '-100', max: '100', step: '0.01' }}
          disabled={readonly}
          mandatory
        />
        <Trans i18nKey='generalSettingsForm.easyPrebooksDiscount' shouldUnescape />
      </FormGridRow>
      <FormGridRow className='controller-switch'>
        <ControllerSwitch
          label={t`Mostrar tasa canal`}
          name='showChannelDiscount'
          variant='full'
          control={control}
          disabled={readonly}
        />
        <Trans i18nKey='generalSettingsForm.showChannelDiscount' shouldUnescape />
      </FormGridRow>
    </FormSection>
  )
}

export default Section3
