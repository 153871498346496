import React, { useState } from 'react'

import CircularProgress from '@mui/material/CircularProgress'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { UPLOAD_FILE_FORMATS } from 'config/constants'

import * as S from './styled'

interface Props {
  onButtonClick: (file?: Blob) => Promise<void> // Rewrited name to prevent 'onClick' overwrite from MUI lib
  Icon: React.FunctionComponent
  label: string
  isUpload?: boolean
  disabled?: boolean
}

const ButtonGridActionCellItem: React.FC<Props> = ({ onButtonClick, Icon, label, isUpload, disabled = false }) => {
  const { t } = useTranslation()

  const [isLoading, setIsLoading] = useState(false)

  const isLoadingWrapper = async (fn: () => Promise<void>) => {
    setIsLoading(true)
    await fn()
    setIsLoading(false)
  }

  const handleClick = () => {
    if (!isUpload) {
      isLoadingWrapper(onButtonClick)
    }
  }

  const handleOnFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!UPLOAD_FILE_FORMATS.includes(event.target.files?.[0]?.type ?? '')) {
      toast(t('Solo se admiten archivos CSV o Excel'), { type: 'warning' })
      return
    }

    await isLoadingWrapper(() => onButtonClick(event.target.files?.[0] as Blob))
    event.target.value = ''
  }

  return (
    <S.CustomButton variant='outlined' startIcon={<Icon />} onClick={handleClick} size='small' disabled={isLoading || disabled}>
      {label}
      {isUpload ? <S.VisuallyHiddenInput type='file' onChange={handleOnFileChange} /> : null}
      {isLoading && (
        <S.LoadingBackdrop>
          <CircularProgress size={20} />
        </S.LoadingBackdrop>
      )}
    </S.CustomButton>
  )
}

export default ButtonGridActionCellItem
