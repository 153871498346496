import { QueryKey, useMutation, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { GET_RELATIONS } from 'config/queries'
import { Relation, UpdateRelation } from 'models/relation'
import { updateOneUsersRelation } from 'services/relations'

const useUpdateUsersRelation = (queryKey?: QueryKey) => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  const updateRelation = useMutation({
    mutationFn: ({ id, ...payload }: UpdateRelation) => updateOneUsersRelation(id, payload),
    onSuccess: () => {
      toast(t('Relación actualizada'), { type: 'success' })
    },
    onMutate: async newValue => {
      await queryClient.cancelQueries({ queryKey: [GET_RELATIONS] })
      const previous = queryClient.getQueryData<{ data: Relation[] }>(queryKey ?? [GET_RELATIONS])
      const relation = previous?.data.find(o => o.id === newValue.id)

      if (relation) {
        relation.active = !!newValue.active
      }
      return { previous }
    },
    onError: (error, newValue, context) => {
      queryClient.setQueryData([GET_RELATIONS], context?.previous)
      toast(t('Ha ocurrido un error'), { type: 'error' })
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [GET_RELATIONS] })
    },
  })

  return updateRelation
}

export default useUpdateUsersRelation
