import PageContainer from 'components/atoms/page-container'
import ProfilesCreateForm from 'components/organisms/profiles/profiles-create-form'

const ProfilesCreate = (): JSX.Element => (
  <PageContainer>
    <ProfilesCreateForm />
  </PageContainer>
)

export default ProfilesCreate
