import React from 'react'

import { Typography } from '@mui/material'

import { FontSizes } from 'config/constants'

import * as S from './styled'

type Props = {
  label: string
  description?: string
}

const KpiGridHeader: React.FC<Props> = ({ label, description }) => (
  <>
    <S.KpiGridHeaderContainer>
      <Typography fontWeight={700} fontSize={FontSizes.xl}>
        {label}
      </Typography>
      {description && <Typography fontWeight={700} fontSize={FontSizes.md}>{`(${description})`}</Typography>}
    </S.KpiGridHeaderContainer>
    <S.KpiGridDivider />
  </>
)

export default KpiGridHeader
