import { FC } from 'react'

import {
  GridSortModel,
  GridEnrichedColDef,
  GridRowParams,
  GridSelectionModel,
  GridValidRowModel,
  GridRowClassNameParams,
} from '@mui/x-data-grid'

import CustomPagination from 'components/atoms/custom-pagination'
import NoRows from 'components/atoms/no-rows'
import { ROWS_PER_PAGE_OPTIONS, Sort, TableTypes } from 'config/constants'

import { StyledDataGrid } from './styled'

interface BasicTableProps {
  type?: TableTypes
  rows: GridValidRowModel[]
  columns: GridEnrichedColDef[]
  pageSize?: number
  page?: number
  isLoading?: boolean
  count?: number
  onPageSizeChange?: (pageSize: number) => void
  onPageChange?: (page: number) => void
  onRowClick?: (row: GridRowParams) => void
  onSortModelChange?: (sortString: string) => void
  rowHeight?: number
  selectionModel?: GridSelectionModel
  onSelectionModelChange?: (selectionModel: GridSelectionModel) => void
  dataTestId?: string
  getRowClassName?: (params: GridRowClassNameParams) => string
  disabled?: boolean
  errorInRow?: (row: GridValidRowModel) => boolean
}

const BasicTable: FC<BasicTableProps> = ({
  type,
  rows,
  columns,
  page,
  pageSize,
  count,
  isLoading,
  onPageChange,
  onPageSizeChange,
  onSortModelChange,
  onRowClick,
  dataTestId,
  rowHeight = undefined,
  selectionModel,
  onSelectionModelChange,
  getRowClassName,
  disabled,
  errorInRow
}) => {
  const handleSortModelChange = onSortModelChange
    ? (sortModel: GridSortModel) => {
      const [sortItem]: GridSortModel = sortModel

      if (sortItem.sort === Sort.asc) return onSortModelChange(sortItem.field)
      if (sortItem.sort === Sort.desc) return onSortModelChange(`-${sortItem.field}`)

      return onSortModelChange('')
    }
    : undefined

  const isDisableSelection = ![TableTypes.selection].includes(type || TableTypes.default)
  const isCheckBoxSelection = [TableTypes.selection].includes(type || TableTypes.default)

  return (
    <StyledDataGrid
      componentsProps={{ header: { 'data-testid': dataTestId ?? 'basic-table' } }}
      autoHeight
      disableColumnMenu={disabled}
      disableColumnFilter={disabled}
      disableSelectionOnClick={isDisableSelection || disabled}
      checkboxSelection={isCheckBoxSelection && !disabled}
      keepNonExistentRowsSelected
      paginationMode={onPageChange ? 'server' : 'client'}
      sortingMode={onSortModelChange ? 'server' : 'client'}
      rowHeight={rowHeight}
      rows={rows}
      columns={columns}
      page={page}
      pageSize={pageSize}
      rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
      loading={isLoading}
      disableColumnSelector={disabled}
      rowCount={count}
      onPageChange={onPageChange}
      onPageSizeChange={onPageSizeChange}
      onRowClick={row => onRowClick?.(row)}
      onSortModelChange={handleSortModelChange}
      sortingOrder={[Sort.desc, Sort.asc]}
      selectionModel={selectionModel}
      onSelectionModelChange={onSelectionModelChange}
      getRowClassName={(params) => {
        let className = getRowClassName ? getRowClassName(params) : ''
        if (errorInRow && errorInRow(params.row)) {
          className += ' error-row'
        }
        return className
      }}
      initialState={{ pagination: { pageSize: pageSize ?? ROWS_PER_PAGE_OPTIONS[0], page: page ?? 0 } }}
      components={{
        NoRowsOverlay: NoRows,
        Pagination: CustomPagination,
      }}
    />
  )
}

export default BasicTable
