import { FC } from 'react'

import PercentIcon from '@mui/icons-material/Percent'
import TollIcon from '@mui/icons-material/Toll'
import { Trans, useTranslation } from 'react-i18next'

import FormGridRow from 'components/atoms/form-grid-row'
import InputIcon from 'components/atoms/input-icon'
import ControllerInputText from 'components/molecules/controller-input-text'
import ControllerSwitch from 'components/molecules/controller-switch'

import { FormSection, SectionProps } from '.'

const Section5: FC<SectionProps> = ({ control, errors, readonly, watch }) => {
  const { t } = useTranslation()

  const [managementFeeWatch] = watch(['managementFee'])

  return (
    <FormSection>
      <FormGridRow>
        <ControllerInputText
          control={control}
          name='managementFee'
          error={errors.managementFee}
          label={t`Gastos gestión`}
          size='full'
          inputProps={{ endAdornment: <InputIcon Icon={PercentIcon} /> }}
          nativeInputProps={{ type: 'number', max: '100', min: '0', step: '0.01' }}
          disabled={readonly}
          mandatory
        />
        <Trans i18nKey='generalSettingsForm.managementFee' values={{ managementFeeWatch }} />
      </FormGridRow>

      <FormGridRow>
        <ControllerInputText
          control={control}
          name='maxManagementFee'
          error={errors.maxManagementFee}
          label={t`Valor máximo gastos gestión`}
          size='full'
          inputProps={{ endAdornment: <InputIcon Icon={TollIcon} /> }}
          nativeInputProps={{ type: 'number', min: '0', step: '0.01' }}
          disabled={readonly || managementFeeWatch == 0}
          mandatory
        />
        <Trans i18nKey='generalSettingsForm.maxManagementFee' />
      </FormGridRow>

      <FormGridRow className='controller-switch'>
        <ControllerSwitch
          label={t`Mostrar gastos gestión`}
          name='showManagementFee'
          variant='full'
          control={control}
          disabled={readonly}
        />
        <Trans i18nKey='generalSettingsForm.showManagementFee' shouldUnescape />
      </FormGridRow>
    </FormSection>
  )
}

export default Section5
