import Box from '@mui/material/Box'
import styled from 'styled-components'

import { INPUT_BASE_HEIGHT, INPUT_CONTAINER_HEIGHT } from 'config/constants'

interface Props {
  width: string
  height?: string
}

export const StyledBox = styled(Box)`
  width: ${(props: Props) => props.width};
  height: ${(props: Props) => (props.height ? props.height : INPUT_CONTAINER_HEIGHT)};
  display: flex;
  align-items: flex-start;

  & .MuiFormControl-root {
    width: 100%;
    height: ${INPUT_BASE_HEIGHT};
  }
`
