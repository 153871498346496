import React from 'react'

import Fab from '@mui/material/Fab'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Box, Typography } from '@mui/material'
import { Dayjs } from 'dayjs'

import { dateFormatter } from 'utils/date-formatter'
import { DateFormats } from 'config/constants'

interface Props {
  date: Dayjs
  onPrev: () => void
  onNext: () => void
}

const MonthSelector: React.FC<Props> = ({ date, onPrev, onNext }) => (
  <Box display='flex' alignItems='center' m={2}>
    <Fab color='primary' size='small' onClick={onPrev}>
      <ArrowBackIcon />
    </Fab>
    <Typography width={200} textAlign='center'>
      {dateFormatter({ date, pattern: DateFormats.monthYear })}
    </Typography>
    <Fab color='primary' size='small' onClick={onNext}>
      <ArrowForwardIcon />
    </Fab>
  </Box>
)

export default MonthSelector
