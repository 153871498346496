import { useQuery } from '@tanstack/react-query'

import { EvolutionItem, GetEvolution, UseGetEvolution } from 'models/prebooks'
import { getEvolution } from 'services/prebooks'
import { GET_EVOLUTION } from 'config/queries'

const useGetEvolution = ({ from, to, carparkCode, channelCodes, centerType }: GetEvolution): UseGetEvolution => {
  const queryKey = [GET_EVOLUTION, from, to, carparkCode, channelCodes, centerType]

  const {
    data: response,
    isLoading,
    isPreviousData,
  } = useQuery({
    queryKey,
    queryFn: () => getEvolution({ from, to, carparkCode, channelCodes, centerType }),
    refetchOnMount: true,
    keepPreviousData: true,
  })

  const result: Array<EvolutionItem> = response?.data ?? []
  const count: number = parseInt(response?.headers?.['x-total-count'] || 0)

  return { response: result, count, isLoading: isLoading || isPreviousData }
}

export default useGetEvolution
