/* eslint-disable camelcase */
import { AxiosResponse, InternalAxiosRequestConfig } from 'axios'

import routes from 'config/routes'
import { COGNITO_REDIRECT_URL, COGNITO_URL, COGNITO_CLIENT_ID } from 'config/constants'
import { LogIn, ResetPassword, User } from 'models/auth'
import { adminApi, exposeApi } from 'services'
import { getCurrentLanguage } from 'config/i18n'

import storage from './storage'

export const cognitoUrl =
  `${COGNITO_URL}/login?response_type=code&client_id=${COGNITO_CLIENT_ID}` +
  `&redirect_uri=${COGNITO_REDIRECT_URL}&scope=openid+profile+aws.cognito.signin.user.admin`

export const getCognitoToken = (code: string) => {
  return fetch(`${COGNITO_URL}/oauth2/token`, {
    method: 'POST',
    headers: new Headers({ 'content-type': 'application/x-www-form-urlencoded' }),
    body: Object.entries({
      grant_type: 'authorization_code',
      client_id: COGNITO_CLIENT_ID,
      redirect_uri: COGNITO_REDIRECT_URL,
      code,
    })
      .map(([k, v]) => `${k}=${v}`)
      .join('&'),
  })
}

export const refreshAccessToken = (refreshToken: string) => {
  return fetch(`${COGNITO_URL}/oauth2/token`, {
    method: 'POST',
    headers: new Headers({ 'content-type': 'application/x-www-form-urlencoded' }),
    body: Object.entries({
      grant_type: 'refresh_token',
      client_id: COGNITO_CLIENT_ID,
      redirect_uri: COGNITO_REDIRECT_URL,
      refresh_token: refreshToken,
    })
      .map(([k, v]) => `${k}=${v}`)
      .join('&'),
  })
}

export const setAccessTokenHeader = (accessToken: string | null): void => {
  if (accessToken) {
    adminApi.defaults.headers.common['X-CLIENT-TOKEN'] = accessToken
  } else {
    delete adminApi.defaults.headers.common['X-CLIENT-TOKEN']
  }
}

export const getSessionUser = () => {
  try {
    const sessionUser = JSON.parse(storage.getItem('user') ?? '')
    return sessionUser
  } catch (error) {
    return null
  }
}

export const setSessionUser = (user: string) => storage.setItem('user', user)

export const setSessionToken = (token: string) => storage.setItem('token', token)

const getSessionToken = () => storage.getItem('token') || ''

export const setSessionRefreshToken = (token: string) => storage.setItem('refreshToken', token)

const getSessionRefreshToken = () => storage.getItem('refreshToken') || ''

const logout = async () => {
  storage.clear()
  window.location.replace(routes.login)
}

export const signInAdmin = (body: User): Promise<AxiosResponse<LogIn>> => {
  return adminApi.post('/auth/login', body)
}

export const resetPasswordRequest = (email: string) => {
  return adminApi.post('/password-reset/request', { email })
}

export const resetPassword = (body: ResetPassword) => {
  return adminApi.post('/password-reset/reset', body)
}

export const getMe = () => {
  return adminApi.get('/me')
}

const setApiHeaders = (config: InternalAxiosRequestConfig) => {
  config.headers['X-CLIENT-TOKEN'] = getSessionToken()
  config.headers['accept-language'] = getCurrentLanguage()
  return config
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const handleApiError = (error: any) => Promise.reject(error)

adminApi.interceptors.request.use(setApiHeaders, handleApiError)
exposeApi.interceptors.request.use(setApiHeaders, handleApiError)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const handleRefreshToken = async function (error: any, api: any) {
  const originalRequest = error.config

  if (error?.response?.status === 401 && !originalRequest?._retry) {
    originalRequest._retry = true

    const refreshToken = getSessionRefreshToken()

    if (!refreshToken) {
      return logout()
    }

    let res = null
    try {
      res = await refreshAccessToken(refreshToken)
    } catch {
      return Promise.reject(error)
    }

    if (!res?.ok) {
      return logout()
    }

    const newToken = await res.json()

    setAccessTokenHeader(newToken.id_token)
    originalRequest.headers['X-CLIENT-TOKEN'] = newToken.id_token

    setSessionToken(newToken.id_token)
    setSessionRefreshToken(newToken.refreshToken)

    return api(originalRequest)
  }
  return Promise.reject(error)
}

adminApi.interceptors.response.use(
  response => response,
  error => handleRefreshToken(error, adminApi)
)

exposeApi.interceptors.response.use(
  response => response,
  error => handleRefreshToken(error, exposeApi)
)
