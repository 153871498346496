import { useQuery } from '@tanstack/react-query'

import { GET_APPLICATIONS } from 'config/queries'
import { getApplications } from 'services/applications'
import { Application, UseGetApplications } from 'models/applications'
import { ApplicationsFilterFormFields } from 'validations/applications'

interface Args {
  pageSize: number
  offset: number
  filters: ApplicationsFilterFormFields
  sort: string
}

const useGetApplications = ({ pageSize, offset, filters, sort }: Args): UseGetApplications => {
  const queryKey = [GET_APPLICATIONS, pageSize, offset, filters, sort]

  const {
    data: response,
    isLoading,
    isPreviousData,
  } = useQuery({
    queryKey,
    queryFn: () =>
      getApplications({
        limit: pageSize,
        offset,
        filters,
        sort,
      }),
    refetchOnMount: true,
    keepPreviousData: true,
  })

  const result: Array<Application> = response?.data || []
  const count: number = parseInt(response?.headers?.['x-total-count'] || 0)

  return { response: result, count, isLoading: isLoading || isPreviousData, queryKey }
}

export default useGetApplications
