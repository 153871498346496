import React from 'react'

import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'

import { StyledDialog } from './styled'

interface Props {
  open: boolean
  onCancel?: () => void
  onConfirm?: () => void
  title?: string
  message?: string
  confirmLabel?: string
  cancelLabel?: string
  hideCancelButton?: boolean
}

const ModalConfirm: React.FC<Props> = ({
  open,
  onCancel,
  onConfirm,
  title,
  message,
  cancelLabel,
  confirmLabel,
  hideCancelButton,
}) => {
  return (
    <StyledDialog
      open={open}
      onClose={onCancel}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      fullWidth
      maxWidth='sm'
    >
      <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>{message}</DialogContentText>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        {hideCancelButton ? null : (
          <Button onClick={onCancel} variant='text'>
            {cancelLabel}
          </Button>
        )}
        <Button onClick={onConfirm} autoFocus variant='contained'>
          {confirmLabel}
        </Button>
      </DialogActions>
    </StyledDialog>
  )
}

export default ModalConfirm
