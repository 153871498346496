import { FC } from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'

const NoRows: FC = () => {
  const { t } = useTranslation()

  return (
    <Box display='flex' justifyContent='center' alignItems='center' margin='3em'>
      <Typography>{t('Sin datos')}</Typography>
    </Box>
  )
}

export default NoRows
