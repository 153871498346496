import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'

import H3 from 'components/atoms/h3'

import {
  AverageContainer,
  CalendarLeyendContainer,
  DemandContainer,
  HightDemandContainer,
  LimitReachedContainer,
  MediumDemandContainer,
  NoProductsContainer,
  WeekendContainer,
} from './styled'

const CalendarLeyend = (): JSX.Element => {
  const { t } = useTranslation()

  return (
    <CalendarLeyendContainer>
      <H3 text={t('Leyenda')} />
      <Box display='flex' gap={0.25}>
        <WeekendContainer>{t('Precio fin de semana')}</WeekendContainer>
        <AverageContainer>{t('Precio superior a la media del mes')}</AverageContainer>
        <DemandContainer>
          <MediumDemandContainer>{t('Alta demanda 50%')}</MediumDemandContainer>
          <HightDemandContainer>{t('Alta demanda 80%')}</HightDemandContainer>
        </DemandContainer>
        <NoProductsContainer>{t('Bloqueo de plazas, no hay productos definidos')}</NoProductsContainer>
        <LimitReachedContainer>{t('Bloqueo de plazas, limite de plazas alcanzado')}</LimitReachedContainer>
      </Box>
    </CalendarLeyendContainer>
  )
}

export default CalendarLeyend
