import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'

export const AppBarLogoContainer = styled(Box)`
  width: 25em;
  height: 4em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-self: center;
  padding-left: 2em;
`
