import CurveType from 'models/curve-type'
import { IntervalDto, Point } from 'models/profiles'

type Args = {
  id?: string
  code: string
  name: string
  points?: Point[]
  active?: boolean
  curveType?: CurveType
  intervals?: IntervalDto[]
}

export const formatProfile = ({ id, code, name, points, active, curveType, intervals }: Args) => ({
  id,
  code,
  name,
  active,
  points: points?.map(point => ({
    ...point,
    day: +point.day,
    discount: +point.discount,
  })),
  intervals: intervals?.map(interval => ({
    ...interval,
    from: +interval.from,
    to: +interval.to,
    percentageValue: +interval.percentageValue,
  })),
  curveType,
})
