import { useQuery } from '@tanstack/react-query'

import { getOneEvent } from 'services/events'
import { Event, UseGetOneEvent } from 'models/events'
import { GET_ONE_EVENT } from 'config/queries'

interface Args {
  id?: string
}

const useGetOneEvent = ({ id }: Args): UseGetOneEvent => {
  const queryKey = [GET_ONE_EVENT, id]
  const { data: response, isLoading } = useQuery({
    queryKey,
    queryFn: () => getOneEvent(id),
    refetchOnMount: true,
    staleTime: 0,
  })

  const result: Event = response?.data

  return { response: result, isLoading }
}

export default useGetOneEvent
