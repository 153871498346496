import styled from 'styled-components'
import Box from '@mui/material/Box'

export const H3Container = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-block-start: 2.1em;
  margin-block-end: 2.1em;
`
