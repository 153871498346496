import React, { useCallback, useEffect } from 'react'

import { GridEnrichedColDef } from '@mui/x-data-grid'
import { QueryKey } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import EditGridActionCellItem from 'components/atoms/edit-grid-action-cell-item'
import HistoricGridActionCellItem from 'components/atoms/historic-grid-action-cell-item'
import ShowGridActionCellItem from 'components/atoms/show-grid-action-cell-item'
import TextCell from 'components/atoms/text-cell'
import ToggleGridActionCellItem from 'components/atoms/toggle-grid-action-cell-item'
import BasicTable from 'components/molecules/basic-table'
import { DateFormats, RowHeight, TableColumnWidth, TableTypes } from 'config/constants'
import routes from 'config/routes'
import { Relation, RelationDTO } from 'models/relation'
import useAdministrationPermissions, { AdministrationSections } from 'hooks/permissions/use-administration-permissions'
import { dateFormatter } from 'utils/date-formatter'
import useUpdateRelation from 'hooks/queries/relations/use-update-users-relation'

interface Props {
  type?: TableTypes
  rows: RelationDTO[]
  count: number
  page: number
  pageSize: number
  isLoading: boolean
  onSortModelChange: (sortString: string) => void
  onPageChange: (page: number) => void
  onPageSizeChange: (pageSize: number) => void
  queryKey: QueryKey
}

const UsersRelationsTable: React.FC<Props> = ({ queryKey, type = TableTypes.default, ...props }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { canWrite } = useAdministrationPermissions(AdministrationSections.relations)

  const updateRelation = useUpdateRelation(queryKey)

  const navigateToEdit = useCallback(
    (id: number) => navigate(routes.relationsUsersEdit.replace(':id', id.toString())),
    [navigate]
  )

  const navigateToShow = useCallback(
    (id: number) => type === TableTypes.default && navigate(routes.relationsUsersShow.replace(':id', id.toString())),
    [navigate, type]
  )

  const navigateToHistoric = useCallback(
    (id: number) =>
      type === TableTypes.default && navigate(routes.relationsUsersHistoric.replace(':id', id.toString())),
    [navigate, type]
  )

  const handleToggleIdentityActive = useCallback(
    ({ active, id }: Relation) => {
      updateRelation.mutate({ id, active: !active })
    },
    [updateRelation]
  )

  const getTableActions = useCallback(
    ({ row }: { row: Relation }) => {
      const actions = [
        <ShowGridActionCellItem key={`${row.id}-show`} onClick={() => navigateToShow(row.id)} />,
        <HistoricGridActionCellItem key={`${row.id}-historic`} onClick={() => navigateToHistoric(row.id)} />,
      ]

      if (canWrite) {
        actions.unshift(
          <ToggleGridActionCellItem
            checked={row.active}
            key={`${row.id}-toggle`}
            onClick={() => handleToggleIdentityActive(row)}
          />
        )

        actions.splice(-1, 0, <EditGridActionCellItem key={`${row.id}-edit`} onClick={() => navigateToEdit(row.id)} />)
      }

      return actions
    },
    [canWrite, handleToggleIdentityActive, navigateToEdit, navigateToShow, navigateToHistoric]
  )

  const columns: GridEnrichedColDef[] = [
    {
      field: 'id',
      headerName: '',
      flex: TableColumnWidth.xs,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'name',
      headerName: `${t('Nombre')}`,
      flex: TableColumnWidth.large,
      renderCell: ({ row }: { row: Relation }) => <TextCell text={row.name} />,
    },
    {
      field: 'managedCenters',
      headerName: `${t('Nº Centros')}`,
      flex: TableColumnWidth.small,
      sortable: false,
      renderCell: ({ row }: { row: Relation }) => <TextCell text={row.managedCenters.length.toString()} />,
    },
    {
      field: 'managedUsers',
      headerName: `${t('Nº Usuarios')}`,
      flex: TableColumnWidth.small,
      sortable: false,
      renderCell: ({ row }: { row: Relation }) => <TextCell text={row.managedUsers.length.toString()} />,
    },
    {
      field: 'updateDate',
      headerName: `${t('Modificado')}`,
      flex: TableColumnWidth.small,
      renderCell: ({ row }: { row: Relation }) => (
        <TextCell text={dateFormatter({ date: row.updateDate, pattern: DateFormats.day })} />
      ),
    },
    {
      field: 'actions',
      type: 'actions',
      flex: TableColumnWidth.large,
      sortable: false,
      getActions: getTableActions,
    },
  ]

  const isManagedCentersEmpty = props.rows.some((row) => row.managedCenters.length === 0)

  useEffect(() => {
    const timeOut = setTimeout(() => {
      if (isManagedCentersEmpty) {
        toast(t`Hay relaciones sin centros asociados.`, {
          type: 'error',
          autoClose: 5000,
          draggable: false,
          closeButton: false,
          closeOnClick: false
        })
      }
    }, 1000)
    return () => clearTimeout(timeOut)
    // eslint-disable-next-line
  }, [isManagedCentersEmpty])

  return (
    <BasicTable
      {...props}
      dataTestId='users-centers-relations-table'
      type={type}
      rowHeight={RowHeight.large}
      columns={columns}
      onRowClick={({ row }) => (canWrite ? navigateToEdit(row.id) : navigateToShow(row.id))}
      errorInRow={(row) => row.managedCenters.length === 0 || row.managedUsers.length === 0}
    />
  )
}

export default UsersRelationsTable
