import React from 'react'

import { Trans, useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

import { formatNumber } from 'utils/number-formatter'
import { useAuth } from 'providers/auth'
import { TI } from 'config/i18n'
import { DateFormats } from 'config/constants'
import { Center } from 'models/center'
import { SimulatedPrebook } from 'models/simulator'

import {
  AvailableParkingContainer,
  CenterContainer,
  DatesContainer,
  InfoContainer,
  InfoTextContainer,
  InfoTitleContainer,
  PriceCardContainer,
  PriceContainer,
} from './styled'

// eslint-disable-next-line import/no-named-as-default-member
dayjs.extend(timezone)
// eslint-disable-next-line import/no-named-as-default-member
dayjs.extend(utc)

interface Props {
  data: SimulatedPrebook
  center: Center
}

const PriceCard: React.FC<Props> = ({ data, center }) => {
  const { t } = useTranslation()
  const { language } = useAuth()

  const transformToTZ = (date: string) => dayjs.utc(date).tz(center.timezone).format(DateFormats.full)

  const { price } = data

  const availableParkingLots = price.availableParkingSpots
  const finalPrice = formatNumber(price.finalPrice, language, true)
  const carparkName = price.carparkCode
  const from = transformToTZ(price.from)
  const to = transformToTZ(price.to)
  const days = dayjs(price.to).diff(dayjs(price.from), 'days')
  const basePrice = formatNumber(price.baseDailyPrice, language, true)
  const priceModifier = formatNumber(price.priceModifier, language, true)
  const percentModifier = formatNumber(price.percentModifier, language)
  const dayPrice = formatNumber(price.finalPrice / days, language, true)
  const modifiersList = price.modifiers ?? []

  return (
    <PriceCardContainer>
      <AvailableParkingContainer>
        {t('{{value}} plazas disponibles', { value: availableParkingLots })}
      </AvailableParkingContainer>
      <PriceContainer>
        <Trans>
          Precio: <strong>{{ value: finalPrice } as TI}</strong>
        </Trans>
      </PriceContainer>
      <CenterContainer>{`(${carparkName})`}</CenterContainer>
      <DatesContainer>
        <span>
          {t('Entrada: ')}
          <strong>{from}</strong>
        </span>
        <span>
          {t('Salida: ')}
          <strong>{to}</strong>
        </span>
        <span>
          {t('Días: ')}
          <strong>{days}</strong>
        </span>
      </DatesContainer>
      <InfoContainer>
        <div>
          <InfoTitleContainer>{t('DETALLE PRECIOS')}</InfoTitleContainer>
          <InfoTextContainer>{t(`PRECIO BASE: ${basePrice}`)}</InfoTextContainer>
          <InfoTextContainer red>{t(`PRECIO MODIF: ${priceModifier}`)}</InfoTextContainer>
          <InfoTextContainer red>{t(`PORCEN MODIF: ${percentModifier} %`)}</InfoTextContainer>
          <InfoTextContainer>{t(`PRECIO TOTAL: ${finalPrice}`)}</InfoTextContainer>
          <InfoTextContainer>{t(`PRECIO POR DÍA: ${dayPrice}`)}</InfoTextContainer>
        </div>
        <div>
          <InfoTitleContainer>{t('DESGLOSE MODIFICADORES')}</InfoTitleContainer>
          {modifiersList.map(modifier => {
            const amount = formatNumber(modifier.amount, language, true)
            const percent = formatNumber(modifier.percent, language)
            if (modifier.amount !== 0) {
              return (
                <InfoTextContainer red={modifier.amount > 0} green={modifier.amount < 0} key={modifier.key}>
                  {`${amount} (${percent} %) ${modifier.description}`} ({modifier.show ? t`Visible` : t`No visible`})
                </InfoTextContainer>
              )
            }
          })}
        </div>
      </InfoContainer>
    </PriceCardContainer>
  )
}

export default PriceCard
