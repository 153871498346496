import React from 'react'

import { GridActionsCellItem } from '@mui/x-data-grid'
import InfoIcon from '@mui/icons-material/InfoOutlined'
import { Tooltip } from '@mui/material'
import { t } from 'i18next'

interface Props {
  onClick: () => void
}

const HistoricGridActionCellItem: React.FC<Props> = ({ onClick }) => (
  <Tooltip title={t('Ver histórico')}>
    <GridActionsCellItem icon={<InfoIcon color='primary' />} label='show' onClick={onClick} />
  </Tooltip>
)

export default HistoricGridActionCellItem
