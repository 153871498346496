/* eslint-disable prefer-rest-params */
import * as i18next from 'i18next'
import * as yup from 'yup'

import { CenterPricingType, CenterTimezone, CenterType, IndirectType } from 'models/center'
import { emptyStringToNull, emptyStringToZero } from 'utils/validators'

export const centerGeneralSettingsSchema = yup
  .object({
    carparkCode: yup.string().required(),
    name: yup.string().required(),
    province: yup.string().required(),
    active: yup.boolean().required(),
    rounding: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
      .when('active', {
        is: true,
        then: () => yup.number().required(),
      }),
    indirectType: yup.string().nullable(),
    minPrice: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
      .when('indirectType', {
        is: (value: string) => Object.values(IndirectType).includes(value as IndirectType),
        then: () =>
          yup
            .number()
            .required()
            .positive(i18next.t('El valor debe ser mayor que 0'))
            .test('decimal-places', i18next.t('El valor debe tener como máximo 2 decimales'), value => {
              if (!value) return false
              return /^\d+(\.\d{1,2})?$/.test(value.toString())
            }),
      }),
    pricingType: yup.string().when('active', {
      is: true,
      then: () => yup.string().required(),
    }),
    curveId: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
      .when('pricingType', {
        is: CenterPricingType.DAILY,
        then: () => yup.number().required(),
      }),
    maxDaysEntry: yup
      .number()
      .min(1)
      .max(99)
      .transform(emptyStringToNull)
      .nullable()
      .when('active', {
        is: true,
        then: () => yup.number().min(1).max(99).required(),
      }),
    telparkWebDiscount: yup
      .number()
      .min(-100)
      .max(100)
      .transform(emptyStringToNull)
      .nullable()
      .when('active', {
        is: true,
        then: () => yup.number().min(-100).max(100).required(),
      }),
    telparkAppDiscount: yup
      .number()
      .min(-100)
      .max(100)
      .transform(emptyStringToNull)
      .nullable()
      .when('active', {
        is: true,
        then: () => yup.number().min(-100).max(100).required(),
      }),
    easyPrebooksDiscount: yup
      .number()
      .min(-100)
      .max(100)
      .transform(emptyStringToNull)
      .nullable()
      .when('active', {
        is: true,
        then: () => yup.number().min(-100).max(100).required(),
      }),
    minutesBeforeEntry: yup
      .number()
      .min(0)
      .transform(emptyStringToNull)
      .nullable()
      .when('active', {
        is: true,
        then: () => yup.number().min(0).required(),
      }),
    managementFee: yup
      .number()
      .min(0)
      .max(100)
      .transform(emptyStringToNull)
      .nullable()
      .when('active', {
        is: true,
        then: () => yup.number().min(0).max(100).required(),
      }),
    maxManagementFee: yup.number().when(['active', 'managementFee'], function() {
      const active = arguments[0]
      const managementFee = arguments[1]
      const schema = arguments[2]

      return active && managementFee > 0
        ? schema.moreThan(0, i18next.t('El valor debe ser superior a {{max}}', { max: 0 })).required()
        : schema.transform(emptyStringToZero)
    }),
    lastMinuteFeeEnabled: yup.boolean(),
    lastMinuteFee: yup
      .number()
      .min(0)
      .transform(emptyStringToNull)
      .nullable()
      .when('lastMinuteFeeEnabled', {
        is: true,
        then: () => yup.number().min(0).max(100).required(),
      }),
    salesBlocked: yup.boolean(),
    salesBlockedMinutes: yup
      .number()
      .min(0)
      .transform(emptyStringToNull)
      .nullable()
      .when('salesBlocked', {
        is: true,
        then: () => yup.number().min(0).required(),
      }),
    timezone: yup.string().required(),
    showChannelDiscount: yup.boolean().required(),
    showLastMinuteFee: yup.boolean().required(),
    showManagementFee: yup.boolean().required(),
    showRounding: yup.boolean().required(),
    showVolumeDiscount: yup.boolean().required(),
    curveAnticipationId: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
      .when('lastMinuteFeeEnabled', {
        is: true,
        then: () => yup.number().required(),
      }),
    centerType: yup.string().required(),
  })
  .required()

export interface CenterGeneralSettingsFields {
  carparkCode: string
  name: string
  province: string
  active: boolean
  rounding: number
  pricingType: CenterPricingType
  curveId?: number
  telparkWebDiscount?: number
  telparkAppDiscount?: number
  easyPrebooksDiscount?: number
  maxDaysEntry: number
  minutesBeforeEntry: number
  managementFee?: number
  maxManagementFee?: number
  lastMinuteFeeEnabled?: boolean
  minutesBeforeLastMinuteFee?: number
  lastMinuteFee?: number
  futurePrices?: boolean
  salesBlocked?: boolean
  salesBlockedMinutes?: number
  timezone: CenterTimezone
  showChannelDiscount: boolean
  showLastMinuteFee: boolean
  showManagementFee: boolean
  showRounding: boolean
  showVolumeDiscount: boolean
  curveAnticipationId: number
  curveAnticipationName: string
  centerType: CenterType
  indirectType: IndirectType | null
  minPrice?: number
}

export const centerStockSettingsSchema = yup
  .object({
    stockSize: yup.number().min(0).max(9999).required(),
    prebookLimitEnabled: yup.boolean().required(),
    preblockLimitEnabled: yup.boolean().required(),
    preblockLimit: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
      .when('preblockLimitEnabled', {
        is: true,
        then: () => yup.number().min(0).required().max(yup.ref('stockSize')),
      }),
    highDemandFeeEnabled: yup.boolean().required(),
    highDemand50Fee: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
      .when(['highDemandFeeEnabled', 'highDemand80Fee'], function() {
        const highDemandFeeEnabled = arguments[0]
        const highDemand80Fee = arguments[1]
        const schema = arguments[2]

        return highDemandFeeEnabled ? schema.min(0).max(highDemand80Fee).required() : schema
      }),
    highDemand80Fee: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
      .when('highDemandFeeEnabled', {
        is: true,
        then: () => yup.number().min(0).max(100).required(),
      }),
    showHighDemandFee: yup.boolean().required(),
  })
  .required()

export interface CenterStockSettingsFields {
  stockSize?: number
  prebookLimitEnabled: boolean
  preblockLimitEnabled: boolean
  preblockLimit?: number
  highDemandFeeEnabled: boolean
  highDemand50Fee?: number
  highDemand80Fee?: number
  showHighDemandFee: boolean
}
