import { QueryKey, useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { GET_CALENDAR, GET_DAILY_OCCUPATION } from 'config/queries'
import { createPeriod } from 'services/periods'
import { CreatePeriod } from 'models/center-period'

const useCreatePeriod = (centerId: number, queryKey: QueryKey) => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  return useMutation({
    mutationFn: ({ ...payload }: CreatePeriod) => createPeriod(centerId, payload),
    onSuccess: () => {
      toast(t('Periodo creado'), { type: 'success' })
    },
    onError: (error: AxiosError<{ description: string }>) => {
      if (error?.response?.status === 409) {
        toast(error?.response?.data?.description, { type: 'error' })
        return
      }

      toast(t('Ha ocurrido un error al crear el periodo'), { type: 'error' })
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey })
      queryClient.invalidateQueries({ queryKey: [GET_CALENDAR] })
      queryClient.invalidateQueries({ queryKey: [GET_DAILY_OCCUPATION] })
    },
  })
}

export default useCreatePeriod
