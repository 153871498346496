import React from 'react'

import RemoveCircle from '@mui/icons-material/RemoveCircle'
import IconButton from '@mui/material/IconButton'

interface Args {
  handleClick?: React.MouseEventHandler<HTMLButtonElement>
}

const RemoveIconButton: React.FC<Args> = ({ handleClick }) => (
  <IconButton sx={{ padding: '4px' }} onClick={handleClick}>
    <RemoveCircle />
  </IconButton>
)

export default RemoveIconButton
