import ApplicationsCreateForm from 'components/organisms/applications/applications-create-form'
import PageContainer from 'components/atoms/page-container'

const ApplicationsCreate = (): JSX.Element => (
  <PageContainer>
    <ApplicationsCreateForm />
  </PageContainer>
)

export default ApplicationsCreate
