import 'react-toastify/dist/ReactToastify.css'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { ToastContainer } from 'react-toastify'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers'
import * as Sentry from '@sentry/react'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import { SENTRY_DSN } from 'config/constants'
import 'assets/css/overrides.css'
import 'assets/css/fonts.css'
import 'assets/css/toastify.css'
import 'config/i18n'
import queryClient from 'config/react-query'
import { GlobalStyle, getTheme } from 'config/theme'
import AuthProvider, { useAuth } from 'providers/auth'
import ProviderComposer from 'utils/provider-composer'
import ConfirmProvider from 'providers/confirm/confirm-provider'

import Routes from './routes'

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [new Sentry.BrowserTracing()],
  tracesSampleRate: 0.2,
})

export const ReactQueryProvider = ({ children }: React.PropsWithChildren) => (
  <QueryClientProvider client={queryClient}>
    {children}
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>
)

export const I18nProvider = ({ children }: React.PropsWithChildren) => {
  const { language } = useAuth()

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={language}>
      {children}
    </LocalizationProvider>
  )
}

export const StyleProvider = ({ children }: React.PropsWithChildren) => {
  const { language } = useAuth()

  return (
    <ThemeProvider theme={getTheme(language)}>
      <CssBaseline />
      <GlobalStyle />
      {children}
    </ThemeProvider>
  )
}

const providers = [ReactQueryProvider, I18nProvider, AuthProvider, StyleProvider, ConfirmProvider]

const App = () => (
  <ProviderComposer with={providers}>
    <Routes />
    <ToastContainer hideProgressBar position='top-right' theme='colored' />
  </ProviderComposer>
)

export default App
