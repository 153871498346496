import React, { PropsWithChildren } from 'react'

import Box from '@mui/material/Box'

const ResourceHeader: React.FC<PropsWithChildren<{ className?: string }>> = ({ children, className }) => (
  <Box display='flex' alignItems='center' gap='2em' marginBottom='1em' className={className}>
    {children}
  </Box>
)

export default ResourceHeader
