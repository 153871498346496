import { useQuery } from '@tanstack/react-query'

import { getOneUser } from 'services/user'
import { GET_ONE_USER } from 'config/queries'
import { User, UseGetOneUser } from 'models/users'

interface Args {
  id?: string
}

const useGetOneUser = ({ id }: Args): UseGetOneUser => {
  const queryKey = [GET_ONE_USER, id]
  const { data: response, isLoading } = useQuery({
    queryKey,
    queryFn: () => getOneUser(id),
    refetchOnMount: true,
    staleTime: 0,
  })

  const result: User = response?.data

  return { response: result, isLoading }
}

export default useGetOneUser
