import { FC, useMemo } from 'react'

import { useTranslation } from 'react-i18next'

import { PeriodPriority } from 'models/center-period'

import { PriorityText } from './styled'

interface PeriodPriorityTextProps {
  priority: PeriodPriority
}

const PeriodPriorityText: FC<PeriodPriorityTextProps> = ({ priority }) => {
  const { t } = useTranslation()

  const priorityText = useMemo(() => t(PeriodPriority[priority]), [priority, t])
  const priorityNumber = useMemo(() => (typeof priority === 'string' ? parseInt(priority) : priority), [priority])

  return (
    <>
      {t`Prioridad`}{' '}
      <PriorityText priority={priority}>
        {priorityText} ({priorityNumber})
      </PriorityText>
    </>
  )
}

export default PeriodPriorityText
