import { useState } from 'react'

import dayjs, { Dayjs } from 'dayjs'
import 'react-calendar/dist/Calendar.css'

import useGetCalendar from 'hooks/queries/centers/use-get-calendar'
import useMonthSelector from 'hooks/use-month-selector'
import useUpdateCenterDay from 'hooks/queries/centers/use-update-center-day'
import { GET_CALENDAR } from 'config/queries'
import { dateFormatter } from 'utils/date-formatter'
import { DateFormats } from 'config/constants'
import useGetDailyOccupation from 'hooks/queries/prebooks/use-get-daily-occupation'
import { Center, CenterPricingType } from 'models/center'
import usePostCenterDay from 'hooks/queries/centers/use-post-center-day'

const useData = ({ center, readonly }: { center: Center; readonly?: boolean }) => {
  const { date, onPrev, onNext } = useMonthSelector()

  const [selected, setSelected] = useState<Set<string>>(new Set())

  const { response: occupation } = useGetDailyOccupation({
    from: dateFormatter({ date: date.startOf('month'), pattern: DateFormats.isoCeros }),
    to: dateFormatter({ date: date.endOf('month'), pattern: DateFormats.isoCeros }),
    carparkCode: center?.carparkCode,
  })

  const calendarQueryKey = [GET_CALENDAR, center?.id, date.month() + 1, date.year()]

  const { response: calendar } = useGetCalendar(
    {
      month: date.month() + 1, // january is 0 for dayjs
      year: date.year(),
      id: center?.id,
    },
    calendarQueryKey
  )

  const updateDay = useUpdateCenterDay(calendarQueryKey)
  const postDay = usePostCenterDay(calendarQueryKey)

  const isInterval = center?.pricingType === CenterPricingType.PERIOD

  const onClickDay = (value: Date) => {
    if (!isInterval && !readonly) {
      setSelected(prev => new Set(prev).add(dayjs(value).toString()))
    }
  }

  const resetSelected = (day: Dayjs) =>
    setSelected(prev => {
      const set = new Set(prev)
      set.delete(day.toString())
      return set
    })

  const onChangeOccupation = (day: Dayjs, price: number) => {
    resetSelected(day)

    if (!center?.id) {
      return
    }

    const calendarInfo = calendar?.find(c => dayjs(c.date).isSame(day, 'day'))
    if (calendarInfo?.id) {
      updateDay.mutate({ dayId: calendarInfo.id, centerId: center.id, price })
    } else {
      postDay.mutate({
        centerId: center.id,
        price,
        date: dateFormatter({ date: day, pattern: DateFormats.isoCeros }).replace('Z', ''),
      })
    }
  }

  const daysWithPrice = calendar?.filter(({ price }) => !!price)
  const totalPrice = daysWithPrice?.reduce((acc, current) => acc + current.price, 0)
  const priceAverage = totalPrice ? totalPrice / daysWithPrice.length : 0

  return {
    priceAverage,
    onChangeOccupation,
    onClickDay,
    resetSelected,
    occupation,
    date,
    onPrevMonth: onPrev,
    onNextMonth: onNext,
    selected: selected,
    calendar,
    isInterval,
  }
}

export default useData
