import { useParams } from 'react-router-dom'

import PageContainer from 'components/atoms/page-container'
import CentersEditForm from 'components/organisms/centers/centers-form'
import useGetOneCenterVersion from 'hooks/queries/centers/use-get-one-center-version'

const IdentitiesShow = (): JSX.Element => {
  const { id, version } = useParams()
  const { isLoading, response } = useGetOneCenterVersion({ id, version })

  return (
    <PageContainer>
      <CentersEditForm viewOnly historic isLoading={isLoading} center={response} />
    </PageContainer>
  )
}

export default IdentitiesShow
