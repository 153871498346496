const routes = {
  root: '/',
  login: '/login',
  cognitoRedirect: '/cognito_redirect',
  prebooks: '/prebooks',

  centers: '/centers',
  centersEdit: '/centers/:id/edit',
  centersShow: '/centers/:id',
  centersHistoric: '/centers/:id/versions',
  centersHistoricShow: '/centers/:id/versions/:version',
  centersPeriodsHistoric: '/centers/:centerId/period-versions/:id',
  centersPeriodsHistoricShow: '/centers/:centerId/period-versions/:id/:version',
  centersDaysHistoric: '/centers/:centerId/day-versions/:id',
  centersDaysHistoricShow: '/centers/:centerId/day-versions/:id/:version',

  administration: '/administration',

  usersList: '/administration/users',
  usersCreate: '/administration/users/new',
  usersEdit: '/administration/users/:id/edit',
  usersShow: '/administration/users/:id',

  applicationsList: '/administration/applications',
  applicationsCreate: '/administration/applications/new',
  applicationsEdit: '/administration/applications/:id/edit',
  applicationsShow: '/administration/applications/:id',

  eventsList: '/administration/audits',
  eventsShow: '/administration/audits/:id',

  configuration: '/administration/configuration',

  relationsList: '/administration/relations',
  relationsUsersCreate: '/administration/relations/users/new',
  relationsUsersShow: '/administration/relations/users/:id',
  relationsUsersEdit: '/administration/relations/users/:id/edit',
  relationsUsersHistoric: '/administration/relations/users/:id/versions',
  relationsUsersHistoricShow: '/administration/relations/users/:id/versions/:version',
  relationsRolesCreate: '/administration/relations/roles/new',
  relationsRolesShow: '/administration/relations/roles/:id',
  relationsRolesEdit: '/administration/relations/roles/:id/edit',
  relationsRolesHistoric: '/administration/relations/roles/:id/versions',
  relationsRolesHistoricShow: '/administration/relations/roles/:id/versions/:version',

  alerts: '/alerts',

  simulator: '/simulator',

  profiles: '/profiles',
  profilesCreate: '/profiles/new',
  profilesEdit: '/profiles/:id/edit',
  profilesShow: '/profiles/:id',
  profilesHistoric: '/profiles/:id/versions',
  profilesHistoricShow: '/profiles/:id/versions/:version',
}

export default routes
