import { useEffect } from 'react'

import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { GET_SIMULATED_PREBOOK } from 'config/queries'
import { GetSimulatedPrebook, UseGetSimulatedPrebook } from 'models/simulator'
import { getSimulatedPrebook } from 'services/simulator'

const useGetSimulatedPrebook = (filters: GetSimulatedPrebook): UseGetSimulatedPrebook => {
  const { t } = useTranslation()
  const { carparkCode, from, to, channelCode } = filters
  const queryKey = [GET_SIMULATED_PREBOOK, carparkCode, from, to, channelCode]

  const {
    data: response,
    isLoading,
    refetch,
  } = useQuery({
    queryKey,
    queryFn: () => getSimulatedPrebook({ carparkCode, from, to, channelCode }),
    onError: (error: AxiosError<{ description?: string }>) => {
      const message = error?.response?.data?.description ?? t('Ha ocurrido un error al obtener los productos')
      toast(message, { type: 'error' })
    },
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    staleTime: 0,
    cacheTime: 0,
    retry: 1,
  })

  useEffect(() => {
    if (!isLoading) {
      refetch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters])

  return { response: response?.data, isLoading: isLoading }
}

export default useGetSimulatedPrebook
