import PageContainer from 'components/atoms/page-container'
import ProfilesEditForm from 'components/organisms/profiles/profiles-edit-form'

const ProfilesShow = (): JSX.Element => (
  <PageContainer>
    <ProfilesEditForm disabled viewOnly />
  </PageContainer>
)

export default ProfilesShow
