import { FC, PropsWithChildren, useCallback, useEffect, useMemo } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import dayjs from 'dayjs'
import { Control, FieldErrors, UseFormWatch, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import CancelButton from 'components/atoms/cancel-button'
import H2 from 'components/atoms/h2'
import SubmitButton from 'components/atoms/submit-button'
import { ROUNDING_OPTIONS } from 'config/centers-constants'
import { DateFormats } from 'config/constants'
import routes from 'config/routes'
import useFormErrorScroll from 'hooks/use-form-error-scroll'
import { Center, CenterPricingType, CenterTimezone, CenterType } from 'models/center'
import { daysToMonths, monthsToDays } from 'utils/month-to-days'
import { CenterGeneralSettingsFields, centerGeneralSettingsSchema } from 'validations/centers'
import { useFormStore } from 'store/useFormStore'
import { usePrompt } from 'hooks/use-prompt'
import useSubmitButtonVisibility from 'hooks/useSubmitButtonVisibility'
import FloatingSubmitButton from 'components/atoms/floating-submit-button'

import Section1 from './section1'
import Section2 from './section2'
import Section3 from './section3'
import Section4 from './section4'
import Section5 from './section5'
import Section6 from './section6'
import { GeneralSettingsForm } from './styled'

interface GeneralSettingsProps {
  center: Center
  readonly?: boolean
  onSubmit: (center: Partial<Center>) => void
  historic?: boolean
}

const defaultValues: CenterGeneralSettingsFields = {
  carparkCode: '',
  name: '',
  province: '',
  active: true,
  rounding: ROUNDING_OPTIONS[0].value,
  pricingType: CenterPricingType.DAILY,
  curveId: 0,
  telparkWebDiscount: 0,
  telparkAppDiscount: 0,
  easyPrebooksDiscount: 0,
  maxDaysEntry: 1,
  minutesBeforeEntry: 0,
  managementFee: 0,
  maxManagementFee: 0,
  lastMinuteFeeEnabled: false,
  minutesBeforeLastMinuteFee: 0,
  lastMinuteFee: 0,
  futurePrices: false,
  salesBlocked: false,
  salesBlockedMinutes: 0,
  timezone: CenterTimezone.MADRID,
  showChannelDiscount: false,
  showLastMinuteFee: false,
  showManagementFee: false,
  showRounding: false,
  showVolumeDiscount: false,
  curveAnticipationId: 0,
  curveAnticipationName: 'Antelación',
  centerType: CenterType.TELPARK,
  indirectType: null,
  minPrice: 0,
}

export const FormSection: FC<PropsWithChildren> = ({ children }) => (
  <Paper elevation={3}>
    <Grid container spacing={3} rowSpacing={6} alignItems='center'>
      {children}
    </Grid>
  </Paper>
)

export interface SectionProps {
  control: Control<CenterGeneralSettingsFields, unknown>
  errors: FieldErrors<CenterGeneralSettingsFields>
  watch: UseFormWatch<CenterGeneralSettingsFields>
  readonly?: boolean
}

const GeneralSettings: FC<GeneralSettingsProps> = ({ center, readonly, onSubmit, historic }) => {
  const { t } = useTranslation()
  const isFormDirty = useFormStore(store => store.isDirty)
  const setDirty = useFormStore(store => store.setDirty)
  const resetForm = useFormStore(store => store.resetForm)
  const { submitButtonRef, isSubmitButtonVisible } = useSubmitButtonVisibility()

  usePrompt({
    isDirty: isFormDirty,
    onConfirm: resetForm,
  })

  const {
    control,
    formState: { isDirty, errors, dirtyFields, defaultValues: formDefaultValues },
    handleSubmit,
    reset,
    setValue,
    watch,
  } = useForm<CenterGeneralSettingsFields>({
    defaultValues: center ?? defaultValues,
    resolver: yupResolver(centerGeneralSettingsSchema),
  })

  useEffect(() => {
    if (!center) {
      return
    }

    reset({ ...defaultValues, ...center, maxDaysEntry: daysToMonths(center.maxDaysEntry) })
  }, [center, reset])

  useFormErrorScroll(errors)

  const [activeWatch, maxDaysEntryWatch, managementFeeWatch] = watch([
    'active',
    'maxDaysEntry',
    'managementFee',
    'salesBlockedMinutes',
  ])

  useEffect(() => {
    if (!managementFeeWatch || parseFloat(`${managementFeeWatch}`) <= 0) {
      setValue('maxManagementFee', 0, { shouldValidate: true })
    }
  }, [managementFeeWatch, setValue])

  useEffect(() => {
    setDirty(isDirty)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty])

  const maxEntryDate = useMemo(
    () =>
      (dirtyFields.maxDaysEntry && maxDaysEntryWatch
        ? dayjs().add(maxDaysEntryWatch, 'months')
        : dayjs().add(center?.maxDaysEntry ?? 0, 'days')
      ).format(DateFormats.day),

    [center, dirtyFields, maxDaysEntryWatch]
  )

  const handleDirtySubmit = useCallback(
    (formValues: CenterGeneralSettingsFields) => {
      const newCenterValues: Partial<Center> = (
        Object.keys(dirtyFields) as (keyof CenterGeneralSettingsFields)[]
      ).reduce(
        (prev, curr) =>
          formDefaultValues?.[curr] !== formValues[curr] && formValues[curr] !== null
            ? { ...prev, [curr]: formValues[curr] }
            : prev,
        {}
      )

      if (newCenterValues.maxDaysEntry) {
        newCenterValues.maxDaysEntry = monthsToDays(newCenterValues.maxDaysEntry)
      }

      onSubmit(newCenterValues)
    },
    [dirtyFields, formDefaultValues, onSubmit]
  )

  const handleInvalid = (invalidFields: FieldErrors<CenterGeneralSettingsFields>) => {
    if (!activeWatch) {
      const errorKeys = Object.keys(invalidFields) as (keyof CenterGeneralSettingsFields)[]

      if (errorKeys.length) {
        const [firstErrorKey] = errorKeys
        const errorMessage = invalidFields[firstErrorKey]?.message
        toast.warning(t('Revise el formulario: {{errorMessage}}.', { errorMessage }))
      }
    }
  }

  const commonSectionProps = { control, errors, watch, readonly }

  return (
    <GeneralSettingsForm
      onSubmit={handleSubmit(handleDirtySubmit, handleInvalid)}
      data-testid='general-settings-form'
      noValidate
    >
      <H2 text={t`Configuración general`} />

      <Section1 {...commonSectionProps} />

      <Section2 {...commonSectionProps} curveName={center?.curveName ?? ''} />
      <Section3 {...commonSectionProps} />
      <Section4 {...commonSectionProps} maxEntryDate={maxEntryDate} />
      <Section5 {...commonSectionProps} />
      <Section6 {...commonSectionProps} />

      <Grid item xs={12} display='flex' alignItems='center' justifyContent='center' columnGap='16px' mt={4}>
        <CancelButton
          to={historic ? routes.centersHistoric.replace(':id', center.originalID.toString()) : routes.centers}
        />
        {!readonly && (
          <div ref={submitButtonRef}>
            <SubmitButton />
          </div>
        )}
        {!readonly && !isSubmitButtonVisible && <FloatingSubmitButton text={t('Guardar cambios')} size='medium' />}
      </Grid>
    </GeneralSettingsForm>
  )
}

export default GeneralSettings
