import { FC, useState } from 'react'

import Box from '@mui/material/Box'

import useFilters from 'hooks/use-filters'
import usePagination from 'hooks/use-pagination'
import { ListProps } from 'models/list'
import ProfilesTableHeader from 'components/molecules/profiles-table-header'
import useGetProfiles from 'hooks/queries/profiles/use-get-profiles'
import ProfilesTable from 'components/organisms/profiles/profiles-table'

const ProfilesList: FC<ListProps> = listProps => {
  const { offset, page, pageSize, setPage, setPageSize } = usePagination()
  const { sortModel, filters, onFilter, onSearchQ, setSortModel } = useFilters({ active: true })

  const { response, isLoading, count, queryKey } = useGetProfiles({
    offset,
    limit: pageSize,
    filters,
    sort: sortModel || 'id',
  })

  const [isLoadingStoredData, setIsLoadingStoredData] = useState(false)

  return (
    <Box width='100%'>
      <ProfilesTableHeader
        count={count}
        isLoading={isLoading}
        setIsLoadingStoredData={setIsLoadingStoredData}
        onFilter={onFilter}
        onSearchQ={onSearchQ}
      />
      <ProfilesTable
        {...listProps}
        rows={response}
        count={count}
        page={page}
        pageSize={pageSize}
        isLoading={isLoading || isLoadingStoredData}
        onSortModelChange={setSortModel}
        onPageChange={setPage}
        onPageSizeChange={setPageSize}
        queryKey={queryKey}
      />
    </Box>
  )
}

export default ProfilesList
