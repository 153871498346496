import { FC } from 'react'

import TimerIcon from '@mui/icons-material/Timer'
import { Trans, useTranslation } from 'react-i18next'
import { Controller } from 'react-hook-form'

import FormGridRow from 'components/atoms/form-grid-row'
import InputIcon from 'components/atoms/input-icon'
import ControllerInputText from 'components/molecules/controller-input-text'
import ControllerSwitch from 'components/molecules/controller-switch'
import BasicInputText from 'components/atoms/basic-input-text'
import { ControllerField } from 'models/form'
import ProfilesSearcher from 'components/molecules/profiles-searcher'
import CurveType from 'models/curve-type'

import { FormSection, SectionProps } from '.'

const Section6: FC<SectionProps> = ({ control, errors, readonly, watch }) => {
  const { t } = useTranslation()

  const [lastMinuteFeeEnabledWatch, salesBlockedWatch, salesBlockedMinutesWatch, curveAnticipationName] = watch([
    'lastMinuteFeeEnabled',
    'salesBlocked',
    'salesBlockedMinutes',
    'curveAnticipationName',
  ])

  return (
    <FormSection>
      <FormGridRow>
        <ControllerSwitch
          label={t`Antelación`}
          name='lastMinuteFeeEnabled'
          variant='full'
          control={control}
          disabled={readonly}
        />
        <Trans i18nKey='generalSettingsForm.lastMinuteFeeEnabled' />
      </FormGridRow>
      {lastMinuteFeeEnabledWatch && (
        <>
          <FormGridRow>
            {readonly ? (
              <BasicInputText
                mandatory
                disabled
                field={{ value: curveAnticipationName } as ControllerField}
                label={t('Curva antelación')}
                size={'full'}
              />
            ) : (
              <Controller
                control={control}
                name='curveAnticipationId'
                disabled={readonly}
                render={({ field: { ref, ...field } }) => {
                  return (
                    <ProfilesSearcher
                      {...field}
                      curveType={CurveType.ANTICIPATION}
                      error={errors.curveAnticipationId}
                      mandatory={lastMinuteFeeEnabledWatch}
                    />
                  )
                }}
              />
            )}
            <Trans i18nKey='generalSettingsForm.curveAnticipation' shouldUnescape />
          </FormGridRow>
          <FormGridRow className='controller-switch'>
            <ControllerSwitch
              label={t`Mostrar tasa último min.`}
              name='showLastMinuteFee'
              variant='full'
              control={control}
              disabled={readonly}
            />
            <Trans i18nKey='generalSettingsForm.showLastMinuteFee' shouldUnescape />
          </FormGridRow>
        </>
      )}
      <FormGridRow className='controller-switch'>
        <ControllerSwitch
          label={t`Bloqueo último min.`}
          name='salesBlocked'
          variant='full'
          control={control}
          disabled={readonly}
        />
        <Trans i18nKey='generalSettingsForm.salesBlocked' />
      </FormGridRow>
      {salesBlockedWatch && (
        <>
          <FormGridRow>
            <ControllerInputText
              control={control}
              name='salesBlockedMinutes'
              error={errors.salesBlockedMinutes}
              label={t`Minutos antelación`}
              size='full'
              inputProps={{ endAdornment: <InputIcon Icon={TimerIcon} /> }}
              nativeInputProps={{ type: 'number', min: '0', step: '1' }}
              disabled={readonly}
              mandatory
            />
            <Trans i18nKey='generalSettingsForm.salesBlockedMinutes' values={{ salesBlockedMinutesWatch }} />
          </FormGridRow>
        </>
      )}
    </FormSection>
  )
}

export default Section6
