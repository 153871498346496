import React, { useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Grid } from '@mui/material'
import dayjs from 'dayjs'

import ControllerInputSelect from 'components/molecules/controller-input-select'
import ControllerInputText from 'components/molecules/controller-input-text'
import ControllerInputDateTimePicker from 'components/molecules/controller-input-datetimepicker'
import BasicButton from 'components/atoms/basic-button'
import { ACTION_OPTIONS, DateFormats, RESOURCE_TYPE_OPTIONS } from 'config/constants'
import {
  EventsFilterFormFields,
  EventsFilterFormSchema,
  eventsFilterSchema,
  eventsFilterDefaultValues,
} from 'validations/events'
import { ClearFiltersButton } from 'components/atoms/clear-filter-button'

import { FilterGridContainer } from './styled'

interface Props {
  onSearch: (search: EventsFilterFormFields) => void
}

const EventsFilter: React.FC<Props> = ({ onSearch }) => {
  const { t } = useTranslation()

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<EventsFilterFormSchema>({
    defaultValues: eventsFilterDefaultValues,
    resolver: yupResolver(eventsFilterSchema),
  })

  const handleReset = () => {
    sessionStorage.removeItem('eventsFilter')
    reset(eventsFilterDefaultValues)
    onSearch(eventsFilterDefaultValues)
  }

  const onSubmit = (payload: EventsFilterFormFields) => {
    sessionStorage.setItem('eventsFilter', JSON.stringify(payload))
    onSearch(payload)
  }

  useEffect(() => {
    const savedFilter = sessionStorage.getItem('eventsFilter')
    if (savedFilter) {
      const parsedFilter = JSON.parse(savedFilter)
      reset(parsedFilter)
      onSearch(parsedFilter)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <FilterGridContainer container pr={1}>
      <Grid item md={4} pr={1}>
        <ControllerInputText
          control={control}
          name='username'
          error={errors.username}
          label={t('Nombre')}
          size='full'
        />
      </Grid>
      <Grid item md={4} pr={1}>
        <ControllerInputSelect
          options={ACTION_OPTIONS}
          control={control}
          name='action'
          error={errors.action}
          label={t('Acción')}
          size='full'
        />
      </Grid>
      <Grid item md={4} pr={1}>
        <ControllerInputSelect
          options={RESOURCE_TYPE_OPTIONS}
          control={control}
          name='resourceType'
          error={errors.resourceType}
          label={t('Tipo')}
          size='full'
        />
      </Grid>
      <Grid item md={6} pr={1}>
        <ControllerInputDateTimePicker
          control={control}
          name='from'
          error={errors.from}
          label={t('Desde')}
          maxDate={dayjs()}
          size='full'
          isUTC={true}
          pattern={DateFormats.isoZ}
        />
      </Grid>
      <Grid item md={6} pr={1}>
        <ControllerInputDateTimePicker
          control={control}
          name='to'
          error={errors.to}
          label={t('Hasta')}
          maxDate={dayjs()}
          isUTC={true}
          size='full'
          pattern={DateFormats.isoZ}
        />
      </Grid>
      <Grid item md={12} display='flex' justifyContent='flex-end' flexDirection='row' gap={3}>
        <ClearFiltersButton handleClearFilters={handleReset} />
        <BasicButton handleClick={handleSubmit(onSubmit)} text={t('Buscar')} size='small' />
      </Grid>
    </FilterGridContainer>
  )
}

export default EventsFilter
