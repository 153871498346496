import { useQuery } from '@tanstack/react-query'

import { GET_ONE_APPLICATION } from 'config/queries'
import { Application, UseGetOneApplication } from 'models/applications'
import { getOneApplication } from 'services/applications'

interface Args {
  id?: string
}

const parseOldRoles = (role: string) => {
  if (role === 'READER') {
    return 'CONSULTA'
  } else if (role === 'OPERATOR') {
    return 'ADMIN-GESTOR'
  }

  return role
}

const useGetOneApplication = ({ id }: Args): UseGetOneApplication => {
  const queryKey = [GET_ONE_APPLICATION, id]
  const { data: response, isLoading } = useQuery({
    queryKey,
    queryFn: () => getOneApplication(id),
    refetchOnMount: true,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  })

  const result: Application = response?.data ? { ...response.data, role: parseOldRoles(response.data.role) } : undefined

  return { response: result, isLoading }
}

export default useGetOneApplication
