import { QueryKey, useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { GET_CALENDAR, GET_DAILY_OCCUPATION } from 'config/queries'
import { CenterPeriod, UpdatePeriod } from 'models/center-period'
import { updateOnePeriod } from 'services/periods'

const useUpdatePeriod = (centerId: number, queryKey: QueryKey) => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  return useMutation({
    mutationFn: ({ id, ...payload }: UpdatePeriod) => updateOnePeriod(id, centerId, payload),
    onSuccess: () => {
      toast(t('Periodo actualizado'), { type: 'success' })
    },
    onMutate: async newValue => {
      await queryClient.cancelQueries({ queryKey })
      const previous = queryClient.getQueryData<{ pages: { data: CenterPeriod[] }[] }>(queryKey)
      const period = previous?.pages
        .map(axiosResponse => axiosResponse.data)
        .flat()
        .find(({ id }) => id === newValue.id)

      if (period) {
        Object.assign(period, newValue)
      }

      return { previous }
    },
    onError: (error: AxiosError<{ description: string }>, newValue, context) => {
      queryClient.setQueryData(queryKey, context?.previous)

      if (error?.response?.status === 409) {
        toast(error?.response?.data?.description, { type: 'error' })
        return
      }

      toast(t('Ha ocurrido un error al actualizar el periodo'), { type: 'error' })
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey })
      queryClient.invalidateQueries({ queryKey: [GET_CALENDAR] })
      queryClient.invalidateQueries({ queryKey: [GET_DAILY_OCCUPATION] })
    },
  })
}

export default useUpdatePeriod
