import styled from 'styled-components'
import { Box } from '@mui/material'
import Grid from '@mui/material/Grid'

export const ApplicationGridContainer = styled(Grid)`
  box-shadow:
    0 2px 1px -1px rgb(0 0 0 / 20%),
    0 1px 1px 0 rgb(0 0 0 / 14%),
    0 1px 3px 0 rgb(0 0 0 / 12%);
  padding: 2rem 4rem;
  background-color: white;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
`

export const ApplicationGridItem = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const StyledContainer = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: 'row';
  gap: 1rem;
  justify-content: center;
  align-items: flex-start;
`
