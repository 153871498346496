import styled from 'styled-components'
import Box from '@mui/material/Box'

import { colors } from 'config/theme'

export const PageTitleContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-block-start: 2.1em;
  margin-block-end: 2.1em;
`

export const PageTitleHorizontalLine = styled.div`
  margin-top: 0.6em;
  height: 1px;
  background-color: #e6e6e6;
  width: 100%;
  max-width: 66em;
  background: linear-gradient(to right, ${colors.whiteShadow}, 20%, ${colors.orange}, ${colors.whiteShadow});
`
