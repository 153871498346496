import { FC, PropsWithChildren } from 'react'

import { Navigate, useLocation } from 'react-router-dom'

import routes from 'config/routes'
import useAdministrationPermissions, { AdministrationSections } from 'hooks/permissions/use-administration-permissions'
import useAlertsPermissions from 'hooks/permissions/use-alerts-permissions'
import useCentersPermissions from 'hooks/permissions/use-centers-permissions'
import usePrebooksPermissions from 'hooks/permissions/use-prebooks-permissions'
import useSimulatorPermissions from 'hooks/permissions/use-simulator-permissions'
import useProfilesPermissions from 'hooks/permissions/use-profiles-permissions'

interface RequirePermissionsProps {
  canRead: boolean
  canWrite: boolean
}

const RequirePermissions: FC<PropsWithChildren<RequirePermissionsProps>> = ({ canRead, canWrite, children }) => {
  const location = useLocation()

  const isWriteRoute = location?.pathname.includes('/new') || location?.pathname.includes('/edit')

  if (!canRead) {
    return <Navigate to={routes.root} state={{ from: location }} replace />
  }

  if (!canWrite && isWriteRoute) {
    return <Navigate to={location.pathname.replace(/\b(new|edit)\b/g, '')} state={{ from: location }} replace />
  }

  return <>{children}</>
}

export const RequireCentersRolesPermissions = ({ children }: { children: JSX.Element }) => {
  const { canRead, canWrite } = useCentersPermissions()

  return (
    <RequirePermissions canRead={canRead} canWrite={canWrite}>
      {children}
    </RequirePermissions>
  )
}

export const RequirePrebooksRolesPermissions = ({ children }: { children: JSX.Element }) => {
  const { canRead, canWrite } = usePrebooksPermissions()

  return (
    <RequirePermissions canRead={canRead} canWrite={canWrite}>
      {children}
    </RequirePermissions>
  )
}

export const RequireAlertsRolesPermissions = ({ children }: { children: JSX.Element }) => {
  const { canRead } = useAlertsPermissions()

  return (
    <RequirePermissions canRead={canRead} canWrite={false}>
      {children}
    </RequirePermissions>
  )
}

export const RequireSimulatorRolesPermissions = ({ children }: { children: JSX.Element }) => {
  const { canRead } = useSimulatorPermissions()

  return (
    <RequirePermissions canRead={canRead} canWrite={false}>
      {children}
    </RequirePermissions>
  )
}

export const RequireProfilesRolesPermissions = ({ children }: { children: JSX.Element }) => {
  const { canRead, canWrite } = useProfilesPermissions()

  return (
    <RequirePermissions canRead={canRead} canWrite={canWrite}>
      {children}
    </RequirePermissions>
  )
}

export const RequireAdministrationRolesPermissions = ({ children }: { children: JSX.Element }) => {
  const { canRead: configurationCanRead, canWrite: configurationCanWrite } = useAdministrationPermissions(
    AdministrationSections.configuration
  )
  const { canRead: usersCanRead, canWrite: usersCanWrite } = useAdministrationPermissions(AdministrationSections.users)
  const { canRead: applicationsCanRead, canWrite: applicationsCanWrite } = useAdministrationPermissions(
    AdministrationSections.applications
  )
  const { canRead: eventsCanRead, canWrite: eventsCanWrite } = useAdministrationPermissions(
    AdministrationSections.events
  )
  const { canRead: relationsCanRead, canWrite: relationsCanWrite } = useAdministrationPermissions(
    AdministrationSections.relations
  )

  const location = useLocation()

  // CHECK ROLE CAN READ DEFAULT SECTION
  if (location?.pathname === routes.administration) {
    if (configurationCanRead) {
      return children
    } else if (usersCanRead) {
      return <Navigate to={routes.usersList} state={{ from: location }} replace />
    } else if (eventsCanRead) {
      return <Navigate to={routes.eventsList} state={{ from: location }} replace />
    } else {
      return <Navigate to={routes.root} state={{ from: location }} replace />
    }
  }

  // CHECK ROLE CAN READ SPECIFIC SECTION
  let canRead = false
  let canWrite = false
  if (location?.pathname.includes(routes.configuration)) {
    canRead = configurationCanRead
    canWrite = configurationCanWrite
  } else if (location?.pathname.includes(routes.usersList)) {
    canRead = usersCanRead
    canWrite = usersCanWrite
  } else if (location?.pathname.includes(routes.applicationsList)) {
    canRead = applicationsCanRead
    canWrite = applicationsCanWrite
  } else if (location?.pathname.includes(routes.eventsList)) {
    canRead = eventsCanRead
    canWrite = eventsCanWrite
  } else if (location?.pathname.includes(routes.relationsList)) {
    canRead = relationsCanRead
    canWrite = relationsCanWrite
  }

  const isWriteRoute = location?.pathname.includes('/new') || location?.pathname.includes('/edit')
  if (!canRead || (!canWrite && isWriteRoute)) {
    return <Navigate to={routes.login} state={{ from: location }} replace />
  }

  return children
}
