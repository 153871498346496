import MuiTextField from '@mui/material/TextField'
import styled from 'styled-components'

import { colors } from 'config/theme'

export const TextField = styled(MuiTextField)`
  .MuiFormLabel-root {
    color: ${colors.orange};
  }

  .MuiInputBase-root {
    input {
      padding-top: 12px;
      padding-bottom: 6px;
    }

    fieldset {
      border-width: 0 0 1px 0;
      border-radius: 0;
    }
  }
`
