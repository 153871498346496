import React from 'react'

import Badge from '@mui/material/Badge'
import IconButton from '@mui/material/IconButton'
import FilterListIcon from '@mui/icons-material/FilterList'

interface Props {
  onClick: () => void
  badge: number
}

const FilterIconButton: React.FC<Props> = ({ onClick, badge }) => (
  <IconButton aria-label='open' onClick={onClick}>
    <Badge badgeContent={badge} color='primary'>
      <FilterListIcon fontSize='large' />
    </Badge>
  </IconButton>
)

export default FilterIconButton
