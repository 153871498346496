import React from 'react'

import { GridActionsCellItem } from '@mui/x-data-grid'
import { t } from 'i18next'
import { Notifications, NotificationsOff } from '@mui/icons-material'
import { Tooltip } from '@mui/material'

interface Props {
  checked?: boolean
  onClick: () => void
  variant?: 'header' | 'cell'
}

interface Colors {
  header: 'action' | 'info'
  cell: 'disabled' | 'primary'
}

const ToggleCenterAlert: React.FC<Props> = ({ checked, onClick, variant = 'cell' }) => {
  const color: Colors = {
    header: checked ? 'info' : 'action',
    cell: checked ? 'primary' : 'disabled',
  }

  return (
    <Tooltip title={t('Alertas')}>
      <GridActionsCellItem
        icon={!checked ? <NotificationsOff color={color[variant]} /> : <Notifications color={color[variant]} />}
        label='toggle'
        onClick={onClick}
      />
    </Tooltip>
  )
}

export default ToggleCenterAlert
