import React from 'react'

import { Chip } from '@mui/material'

import { FontSizes } from 'config/constants'

interface Args {
  label: string
  fontSize?: string
}
const ChipCell: React.FC<Args> = ({ label, fontSize = FontSizes.md }) => {
  return <Chip label={label} size='small' sx={{ fontSize }} />
}

export default ChipCell
