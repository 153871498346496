import React, { useState } from 'react'

import Grid from '@mui/material/Grid'

import { SearchCarparksFormFields } from 'models/prebooks'
import PageContainer from 'components/atoms/page-container'
import PrebooksSearcherForm from 'components/organisms/prebooks/prebooks-searcher-form'
import PrebooksKeyIndicators from 'components/organisms/prebooks/prebooks-key-indicators'
import PrebooksDiscounts from 'components/organisms/prebooks/prebooks-discounts'
import PrebooksSurcharges from 'components/organisms/prebooks/prebooks-surcharges'
import PrebooksEvolutionCharts from 'components/organisms/prebooks/prebooks-evolution-charts'

const Prebooks = (): React.JSX.Element => {
  const [filters, setFilters] = useState<SearchCarparksFormFields>()

  return (
    <div data-testid='prebooks-container'>
      <PageContainer>
        <PrebooksSearcherForm
          onSubmit={formData =>
            setFilters({
              ...formData,
              centerType: formData.centerType,
              cancellationFilterDateType: formData.cancellationFilterDateType,
            })
          }
        />
        <>
          {filters && (
            <>
              <PrebooksKeyIndicators filters={filters} />
              <PrebooksEvolutionCharts filters={filters} />
              <Grid container spacing={4} marginTop={2}>
                <Grid item xs={6}>
                  <PrebooksSurcharges filters={filters} />
                </Grid>
                <Grid item xs={6}>
                  <PrebooksDiscounts filters={filters} />
                </Grid>
              </Grid>
            </>
          )}
        </>
      </PageContainer>
    </div>
  )
}

export default Prebooks
