import { useQuery } from '@tanstack/react-query'

import { getOneCenterVersion } from 'services/centers'
import { Center, UseGetOneCenterVersion } from 'models/center'
import { GET_ONE_CENTER_VERSION } from 'config/queries'

interface Args {
  id?: string
  version?: string
}

const useGetOneCenterVersion = ({ id, version }: Args): UseGetOneCenterVersion => {
  const queryKey = [GET_ONE_CENTER_VERSION, id, version]
  const { data: response, isLoading } = useQuery({
    queryKey,
    queryFn: () => getOneCenterVersion(id, version),
    refetchOnMount: true,
    staleTime: 0,
  })

  const result: Center = response?.data

  return { response: result, isLoading }
}

export default useGetOneCenterVersion
