import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'

export const AppBarAppNameContainer = styled(Box)`
  width: 8em;
  height: 4em;
  margin-top: 5px;
  text-align: center;
  padding-right: 1em;
`
