import React from 'react'

import { useTranslation } from 'react-i18next'
import Grid from '@mui/material/Grid'

import InfoCard from 'components/atoms/info-card'
import useGetKpis from 'hooks/queries/prebooks/use-get-kpis'
import { SearchCarparksFormFields } from 'models/prebooks'
import CircularProgress from 'components/atoms/circular-progress'
import KpiGridHeader from 'components/atoms/kpi-grid-header'

interface Props {
  filters: SearchCarparksFormFields
}

const PrebooksKeyIndicators: React.FC<Props> = ({ filters }) => {
  const { t } = useTranslation()

  const { response, isLoading } = useGetKpis(filters)
  if (!response) {
    return null
  }

  return (
    <>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <KpiGridHeader label={t('Ventas')} description={t('excluye prebooks cancelados')} />
            </Grid>
            <Grid item xs={4}>
              <InfoCard label={t('Número de prebooks')} value={response.numSales} />
            </Grid>
            <Grid item xs={4}>
              <InfoCard label={t('Importe total')} value={response.totalFinalPrice} showCurrency />
            </Grid>
            <Grid item xs={4}>
              <InfoCard label={t('Número días reservados')} value={response.totalDaysBooked} />
            </Grid>
            <Grid item xs={4}>
              <InfoCard label={t('Precio medio por día')} value={response.averagePricePerDay} showCurrency />
            </Grid>
            <Grid item xs={4}>
              <InfoCard label={t('Precio medio por hora')} value={response.averagePricePerHour} showCurrency />
            </Grid>
            <Grid item xs={4}>
              <InfoCard label={t('Media días compra antic.')} value={response.averageDaysInAdvance} />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <KpiGridHeader label={t('Detalle Precios')} description={t('excluye prebooks cancelados')} />
            </Grid>
            <Grid item xs={4}>
              <InfoCard label={t('Precio base diario')} value={response.baseDailyPrice} showCurrency />
            </Grid>
            <Grid item xs={4}>
              <InfoCard label={t('Gastos de gestión')} value={response.fees?.MANAGEMENT_FEES ?? 0} showCurrency />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <KpiGridHeader label={t('Cancelaciones')} description={t(`${filters.cancellationFilterDateType}`)} />
            </Grid>
            <Grid item xs={4}>
              <InfoCard label={t('Número de cancelaciones')} value={response.numCancellations} />
            </Grid>
            <Grid item xs={4}>
              <InfoCard label={t('Importe cancelaciones')} value={response.cancellationTotalAmout} showCurrency />
            </Grid>
            <Grid item xs={4}>
              <InfoCard label={t('Porcentaje de cancelaciones')} value={response.cancellationPercent} showPercentage />
            </Grid>
            <Grid item xs={4}>
              <InfoCard
                label={t('Gastos de gestión cancelaciones')}
                value={
                  response.cancellationFees?.MANAGEMENT_FEES ?? 0 // TODO: Gastos de gestión de cancelaciones
                }
                showCurrency
              />
            </Grid>
          </Grid>
        </>
      )}
    </>
  )
}

export default PrebooksKeyIndicators
