import styled from 'styled-components'

import { colors } from 'config/theme'
import { FontFamily, FontSizes } from 'config/constants'

export const CalendarLeyendContainer = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 12px auto 0 auto;
`

export const WeekendContainer = styled.div`
  height: 80px;
  border: 1px solid ${colors.deepBlue};
  margin: 4px;
  border-radius: 5px;
  padding: 2px 5px;
  background-color: ${colors.grey};
  display: flex;
  flex: 1;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: ${colors.deepBlue};
  font-family: ${FontFamily.medium};
  font-size: ${FontSizes.base};
`

export const AverageContainer = styled.div`
  height: 80px;
  border: 1px solid ${colors.deepBlue};
  margin: 4px;
  border-radius: 5px;
  padding: 2px 5px;
  background-color: ${colors.white};
  display: flex;
  flex: 1;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: ${colors.red};
  font-family: ${FontFamily.bold};
  font-size: ${FontSizes.base};
`

export const DemandContainer = styled.div`
  height: 80px;
  border: 1px solid ${colors.deepBlue};
  margin: 4px;
  border-radius: 5px;
  padding: 0px;
  background-color: ${colors.white};
  display: flex;
  flex: 1;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: ${colors.red};
  font-family: ${FontFamily.bold};
  font-size: ${FontSizes.base};
  flex-direction: column;
  gap: 12px;
`

export const MediumDemandContainer = styled.div`
  padding: 0px 5px;
  background-color: ${colors.orange};
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: ${colors.white};
  font-family: ${FontFamily.medium};
  font-size: ${FontSizes.md};
  line-height: 18px;
  border-radius: 10px;
  padding: 0 5px;
`

export const HightDemandContainer = styled.div`
  padding: 0px 5px;
  background-color: ${colors.red};
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: ${colors.white};
  font-family: ${FontFamily.medium};
  font-size: ${FontSizes.md};
  line-height: 18px;
  border-radius: 10px;
  padding: 0 5px;
`

export const NoProductsContainer = styled.div`
  height: 80px;
  border: 1px solid ${colors.deepBlue};
  margin: 4px;
  border-radius: 5px;
  padding: 5px;
  background-color: ${colors.grey};
  display: flex;
  flex: 1;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: ${colors.deepBlue};
  font-family: ${FontFamily.semiBold};
  font-size: ${FontSizes.base};
  background-image: linear-gradient(
    45deg,
    #e3e3e3 25%,
    #bdbdbd 25%,
    #bdbdbd 50%,
    #e3e3e3 50%,
    #e3e3e3 75%,
    #bdbdbd 75%,
    #bdbdbd 100%
  );
  background-size: 14.14px 14.14px;

  span {
    background-color: ${colors.darkGreyShadow};
  }
`

export const LimitReachedContainer = styled.div`
  height: 80px;
  border: 1px solid ${colors.deepBlue};
  margin: 4px;
  border-radius: 5px;
  padding: 5px;
  background-color: ${colors.grey};
  display: flex;
  flex: 1;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: ${colors.deepBlue};
  font-family: ${FontFamily.semiBold};
  font-size: ${FontSizes.base};
  background-image: linear-gradient(
    45deg,
    #e3e3e3 25%,
    #d67c7c 25%,
    #d67c7c 50%,
    #e3e3e3 50%,
    #e3e3e3 75%,
    #d67c7c 75%,
    #d67c7c 100%
  );
  background-size: 14.14px 14.14px;
`
