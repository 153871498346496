import { FC, useEffect } from 'react'

import Box from '@mui/material/Box'
import { useLocation } from 'react-router-dom'

import useFilters from 'hooks/use-filters'
import usePagination from 'hooks/use-pagination'
import { ListProps } from 'models/list'
import AlertsTableHeader from 'components/molecules/alerts-table-header'
import AlertsFilter from 'components/organisms/alerts/alerts-filter'
import AlertsTable from 'components/organisms/alerts/alerts-table'
import useGetAlerts from 'hooks/queries/alerts/use-get-alerts'

const AlertsList: FC<ListProps> = listProps => {
  const location = useLocation()
  const initialState = location.state?.tableState
    ? {
        ...location.state.tableState,
        offset: location.state.tableState.page * location.state.tableState.pageSize,
      }
    : undefined

  const { offset, page, pageSize, setPage, setPageSize } = usePagination(initialState)
  const { badge, filters, openFilter, sortModel, setSortModel, onFilter, onSearchQ, onToggleFilter } = useFilters({})

  const { response, isLoading, count, queryKey } = useGetAlerts({
    offset,
    limit: pageSize,
    filters,
    sort: sortModel || '-createDate',
  })

  useEffect(() => {
    if (location.state?.tableState) {
      window.history.replaceState(null, '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box width='100%'>
      <AlertsTableHeader
        badge={badge}
        count={count}
        isLoading={isLoading}
        openFilter={openFilter}
        onSearchQ={onSearchQ}
        onToggleFilter={onToggleFilter}
      />
      {openFilter && <AlertsFilter onFilter={onFilter} />}
      <AlertsTable
        {...listProps}
        rows={response}
        count={count}
        page={page}
        pageSize={pageSize}
        isLoading={isLoading}
        onSortModelChange={setSortModel}
        onPageChange={setPage}
        onPageSizeChange={setPageSize}
        queryKey={queryKey}
      />
    </Box>
  )
}

export default AlertsList
