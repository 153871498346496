import { AxiosResponse } from 'axios'

import { CenterType } from 'models/center'
import { DayPricesExportFilters } from 'models/day-prices'
import { adminApi } from 'services'

export const uploadCenters = (formData: FormData): Promise<AxiosResponse> =>
  adminApi.post('/csv/add-centers', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

export const uploadPeriods = (formData: FormData): Promise<AxiosResponse> =>
  adminApi.post('/csv/add-periods', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

export const uploadPrices = (formData: FormData): Promise<AxiosResponse> =>
  adminApi.post('/csv/add-prices', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

export const uploadCurves = (formData: FormData): Promise<AxiosResponse> =>
  adminApi.post('/csv/add-curves', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

export const exportCenters = (centerType?: CenterType): Promise<AxiosResponse<Blob>> =>
  adminApi.get(`/csv/centers${centerType != null ? `?centerType=${centerType}` : ''}`, { responseType: 'blob' })

export const exportPeriods = (centerType?: CenterType): Promise<AxiosResponse<Blob>> =>
  adminApi.get(`/csv/periods${centerType != null ? `?centerType=${centerType}` : ''}`, { responseType: 'blob' })

export const exportPrices = (params: DayPricesExportFilters): Promise<AxiosResponse<Blob>> => {
  return adminApi.get('/csv/prices', { params, responseType: 'blob' })
}

export const exportCurves = (): Promise<AxiosResponse<Blob>> => adminApi.get('/csv/curves', { responseType: 'blob' })
