import { v4 as uuid } from 'uuid'
import { Trans } from 'react-i18next'

import { CalendarPeriodInfoContainer } from './styled'

const CalendarPeriodInfo = () => (
  <CalendarPeriodInfoContainer>
    <Trans
      i18nKey='Los precios se definen por <0>periodo</0>, no por día. Los precios mostrados se correponden el
    <0>primer día del periodo</0> vigente.'
      components={[<strong key={uuid()} />]}
      shouldUnescape
    />
  </CalendarPeriodInfoContainer>
)

export default CalendarPeriodInfo
