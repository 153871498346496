import React from 'react'

import { useTranslation } from 'react-i18next'

import { CalendarDay, Center } from 'models/center'
import { formatNumber } from 'utils/number-formatter'
import { useAuth } from 'providers/auth'
import { DailyOccupation } from 'models/occupation'

import {
  CalendarDayDays,
  CalendarDayOccupation,
  CalendarDayPrice,
  CalendarDayPriceContainer,
  CalendarDaySurchage,
} from './styled'

interface Props {
  calendar?: CalendarDay
  occupation?: DailyOccupation
  center?: Center
}

const CalendarTileInfo: React.FC<Props> = ({ calendar, occupation, center }) => {
  const { language } = useAuth()
  const { t } = useTranslation()

  let priceText = t('N/A')
  if (calendar?.price) {
    priceText = formatNumber(calendar?.price, language, true)
  }

  const daysText =
    calendar?.maxDays === 0
      ? t('Días: 0')
      : t('Días: {{minDays}} - {{maxDays}}', { minDays: calendar?.minDays, maxDays: calendar?.maxDays })

  const occupationText = t('Ocupación: {{occupation}}', {
    occupation: occupation?.occupation || occupation?.occupation === 0 ? occupation?.occupation : '-',
  })

  let red = false
  let orange = false
  let surchage = 0
  if (center?.stockSize && occupation?.occupation) {
    const total = center.prebookLimitEnabled ? center?.stockSize : center.stockSize
    const occupation50 = Math.floor(total * 0.5)
    const occupation80 = Math.floor(total * 0.8)

    orange = occupation.occupation >= occupation50
    red = occupation.occupation >= occupation80

    if (occupation.occupation >= occupation80 && center?.highDemandFeeEnabled) {
      surchage = center.highDemand80Fee
    } else if (occupation.occupation >= occupation50 && center?.highDemandFeeEnabled) {
      surchage = center.highDemand50Fee
    }
  }

  return (
    <>
      <CalendarDayPriceContainer>
        <CalendarDayPrice>{priceText}</CalendarDayPrice>
        {surchage > 0 && (
          <CalendarDaySurchage orange={orange} red={red}>{`+${formatNumber(surchage, language)}%`}</CalendarDaySurchage>
        )}
      </CalendarDayPriceContainer>

      <CalendarDayDays>{daysText}</CalendarDayDays>
      <CalendarDayOccupation>{occupationText}</CalendarDayOccupation>
    </>
  )
}

export default CalendarTileInfo
