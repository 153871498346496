import React, { useCallback, useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Search from '@mui/icons-material/Search'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

import CarparkSearcher from 'components/molecules/locations-searcher'
import ControllerInputSelect from 'components/molecules/controller-input-select'
import ControllerInputDateTimePicker from 'components/molecules/controller-input-datetimepicker'
import { searchSimulatorSchema } from 'validations/simulator'
import { DateFormats, FontSizes, CHANNELS } from 'config/constants'
import { SearchSimulatorFormFields } from 'models/simulator'
import { dateFormatter } from 'utils/date-formatter'
import { ClearFiltersButton } from 'components/atoms/clear-filter-button'

import { SimulatorSearcherFormContainer } from './styled'

// eslint-disable-next-line import/no-named-as-default-member
dayjs.extend(timezone)
// eslint-disable-next-line import/no-named-as-default-member
dayjs.extend(utc)

const defaultValues: SearchSimulatorFormFields = {
  from: '',
  to: '',
  carparkCode: undefined,
  channelCode: '',
}

interface Args {
  onSubmit: (formData: SearchSimulatorFormFields) => void
}

const SimulatorSearcherForm: React.FC<Args> = ({ onSubmit }): React.JSX.Element => {
  const { t } = useTranslation()
  const [minDate, setMinDate] = useState(dayjs())
  const [storedData, setStoredData] = useState<SearchSimulatorFormFields | null>(null)
  const [reRenderKey, setReRenderKey] = useState(0)

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm<SearchSimulatorFormFields>({
    defaultValues,
    resolver: yupResolver(searchSimulatorSchema),
  })

  const [fromWatch, carparkWatch] = watch(['from', 'carpark'])

  useEffect(() => {
    setValue('carparkCode', carparkWatch?.carparkCode)

    const minD = dayjs()
      .tz(carparkWatch?.timezone)
      .add(1, 'minute')

    setMinDate(minD)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carparkWatch])

  const submitHandler = useCallback(
    (values: SearchSimulatorFormFields) => {
      if (!values.carpark) return
      const tz = values.carpark.timezone
      const valueFrom = dayjs.tz(values.from, tz).second(0).millisecond(0)
      const valueTo = dayjs
        .tz(values.to, tz)
        .hour(valueFrom.get('hour'))
        .minute(valueFrom.get('minute'))
        .second(0)
        .millisecond(0)

      const from = valueFrom.utc()
      const to = valueTo.utc()

      sessionStorage.setItem('simulator_search', JSON.stringify({ ...values, from: values.from, to: values.to }))
      onSubmit({
        ...values,
        from: dateFormatter({ date: from, pattern: DateFormats.isoZ }),
        to: dateFormatter({ date: to, pattern: DateFormats.isoZ }),
      })
    },
    [onSubmit]
  )

  const handleClearFilters = () => {
    sessionStorage.removeItem('simulator_search')
    setValue('carpark', undefined)
    setValue('from', defaultValues.from)
    setValue('to', defaultValues.to)
    setValue('channelCode', defaultValues.channelCode)
    setValue('carparkCode', defaultValues.carparkCode)
    setReRenderKey(Math.random())
    setStoredData(null)
  }

  useEffect(() => {
    const storage = sessionStorage.getItem('simulator_search')
    if (storage) {
      const parsedData = JSON.parse(storage)
      for (const key in parsedData) {
        setValue(key as keyof SearchSimulatorFormFields, parsedData[key])
      }
      setStoredData(parsedData)
      submitHandler(parsedData)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <SimulatorSearcherFormContainer container spacing={2} mt={5} rowGap={1}>
      <Grid item xs={3}>
        <CarparkSearcher
          key={reRenderKey}
          fullValue
          name='carpark'
          control={control}
          error={errors.carparkCode}
          initialValue={storedData?.carpark}
        />
      </Grid>
      <Grid item xs={3}>
        <ControllerInputDateTimePicker
          key={reRenderKey}
          label={t('Desde')}
          name='from'
          control={control}
          error={errors.from}
          size='full'
          minDate={minDate}
        />
      </Grid>
      <Grid item xs={3}>
        <ControllerInputDateTimePicker
          key={reRenderKey}
          onlyDate
          label={t('Hasta')}
          name='to'
          control={control}
          error={errors.to}
          size='full'
          pattern={DateFormats.iso}
          minDate={fromWatch ? dayjs(fromWatch) : dayjs()}
        />
      </Grid>
      <Grid item xs={3}>
        <ControllerInputSelect
          key={reRenderKey}
          control={control}
          name='channelCode'
          error={errors.channelCode}
          label={t`Canales`}
          size='full'
          options={CHANNELS}
        />
      </Grid>

      <Grid item xs={8}></Grid>
      <Grid item xs={2} alignSelf='center'>
        <ClearFiltersButton handleClearFilters={handleClearFilters} />
      </Grid>
      <Grid item xs={2}>
        <Button
          fullWidth
          onClick={handleSubmit(submitHandler)}
          color='primary'
          type='submit'
          variant='contained'
          startIcon={<Search />}
        >
          <Typography mt={0.5} color='white' fontSize={FontSizes.base}>
            {t('Buscar')}
          </Typography>
        </Button>
      </Grid>
    </SimulatorSearcherFormContainer>
  )
}

export default SimulatorSearcherForm
