import { FC, useCallback, useEffect, useMemo } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import GarageIcon from '@mui/icons-material/Garage'
import PercentIcon from '@mui/icons-material/Percent'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import { useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'

import CancelButton from 'components/atoms/cancel-button'
import FormGridRow from 'components/atoms/form-grid-row'
import H2 from 'components/atoms/h2'
import InputIcon from 'components/atoms/input-icon'
import SubmitButton from 'components/atoms/submit-button'
import ControllerInputText from 'components/molecules/controller-input-text'
import ControllerSwitch from 'components/molecules/controller-switch'
import routes from 'config/routes'
import useFormErrorScroll from 'hooks/use-form-error-scroll'
import { Center, CenterType } from 'models/center'
import { CenterStockSettingsFields, centerStockSettingsSchema } from 'validations/centers'
import { useFormStore } from 'store/useFormStore'
import { useTabStore } from 'store/useTabStore'
import { usePrompt } from 'hooks/use-prompt'
import useSubmitButtonVisibility from 'hooks/useSubmitButtonVisibility'
import FloatingSubmitButton from 'components/atoms/floating-submit-button'

import { StockSettingsForm } from './styled'

interface StockSettingsProps {
  center: Center
  readonly?: boolean
  onSubmit: (center: Partial<Center>) => void
  historic?: boolean
  handleTabChange?: (event: React.SyntheticEvent, tabIndex: number) => void
}

const defaultValues: CenterStockSettingsFields = {
  stockSize: 0,
  prebookLimitEnabled: true,
  preblockLimitEnabled: false,
  preblockLimit: 0,
  highDemandFeeEnabled: false,
  highDemand50Fee: 0,
  highDemand80Fee: 0,
  showHighDemandFee: false,
}

const StockSettings: FC<StockSettingsProps> = ({ center, readonly, onSubmit, historic, handleTabChange }) => {
  const { t } = useTranslation()
  const isFormDirty = useFormStore(store => store.isDirty)
  const setDirty = useFormStore(store => store.setDirty)
  const resetForm = useFormStore(store => store.resetForm)
  const tabIndex = useTabStore(store => store.tabIndex)
  const setTabIndex = useTabStore(store => store.setTabIndex)
  const { submitButtonRef, isSubmitButtonVisible } = useSubmitButtonVisibility()

  usePrompt({
    isDirty: isFormDirty,
    onConfirm: resetForm,
  })

  const {
    control,
    formState: { isDirty, errors, dirtyFields, defaultValues: formDefaultValues },
    handleSubmit,
    reset,
    watch,
  } = useForm<CenterStockSettingsFields>({
    defaultValues: center ?? defaultValues,
    resolver: yupResolver(centerStockSettingsSchema),
  })

  useEffect(() => {
    if (!center) {
      return
    }

    reset({ ...defaultValues, ...center })
  }, [center, reset])

  useFormErrorScroll(errors)

  const [
    stockSizeWatch,
    prebookLimitEnabledWatch,
    preblockLimitEnabledWatch,
    highDemandFeeEnabledWatch,
    highDemand50FeeWatch,
    highDemand80FeeWatch,
  ] = watch([
    'stockSize',
    'prebookLimitEnabled',
    'preblockLimitEnabled',
    'highDemandFeeEnabled',
    'highDemand50Fee',
    'highDemand80Fee',
  ])

  const handleGoBack = () => {
    if (handleTabChange) {
      handleTabChange({} as React.SyntheticEvent, tabIndex - 1)
      return
    }
    setTabIndex((prevIndex) => Math.max(prevIndex - 1, 0))
  }

  const handleDirtySubmit = useCallback(
    (formValues: CenterStockSettingsFields) => {
      const newCenterValues: Partial<Center> = (Object.keys(dirtyFields) as (keyof CenterStockSettingsFields)[]).reduce(
        (prev, curr) => (formDefaultValues?.[curr] !== formValues[curr] ? { ...prev, [curr]: formValues[curr] } : prev),
        {}
      )

      if (newCenterValues.prebookLimitEnabled === false) {
        newCenterValues.preblockLimitEnabled = false
      }

      newCenterValues.highDemand50Fee = highDemandFeeEnabledWatch ? formValues?.highDemand50Fee : undefined
      newCenterValues.highDemand80Fee = highDemandFeeEnabledWatch ? formValues?.highDemand80Fee : undefined

      if (formValues.highDemand50Fee === null || formValues.highDemand80Fee === null) {
        newCenterValues.highDemand50Fee = 0
        newCenterValues.highDemand80Fee = 0
      }

      onSubmit(newCenterValues)
    },
    [dirtyFields, formDefaultValues, onSubmit, highDemandFeeEnabledWatch]
  )

  useEffect(() => {
    setDirty(isDirty)
  }, [isDirty, setDirty])

  const prebooksLimitNumber = useMemo(
    () => Math.floor((stockSizeWatch ?? 0) * ((prebookLimitEnabledWatch ? stockSizeWatch ?? 0 : 100) / 100)),
    [stockSizeWatch, prebookLimitEnabledWatch]
  )

  const occupation50 = useMemo(() => Math.floor(prebooksLimitNumber * 0.5), [prebooksLimitNumber])

  const occupation80 = useMemo(() => Math.floor(prebooksLimitNumber * 0.8), [prebooksLimitNumber])

  return (
    <StockSettingsForm onSubmit={handleSubmit(handleDirtySubmit)} data-testid='stock-settings-form' noValidate>
      <H2 text={t`Configuración de stock`} />

      <Paper elevation={3}>
        <Grid container spacing={3} rowSpacing={6} alignItems='center'>
          <FormGridRow>
            <ControllerInputText
              control={control}
              name='stockSize'
              error={errors.stockSize}
              label={t`Nº máximo de prebooks`}
              size='full'
              inputProps={{ endAdornment: <InputIcon Icon={GarageIcon} /> }}
              nativeInputProps={{ type: 'number', min: '0', step: '1' }}
              disabled={readonly}
            />
            <Trans i18nKey='stockSettingsForm.stockSize' />
          </FormGridRow>
        </Grid>
      </Paper>
      <Paper elevation={3}>
        <Grid container spacing={3} rowSpacing={6} alignItems='center'>
          <FormGridRow>
            <ControllerSwitch
              label={t`Limitar stock`}
              name='prebookLimitEnabled'
              variant='full'
              control={control}
              disabled={readonly}
            />
            <Trans i18nKey='stockSettingsForm.prebookLimitEnabled' />
          </FormGridRow>
        </Grid>
      </Paper>
      {center.centerType != CenterType.AGGREGATION &&
        prebookLimitEnabledWatch &&
        stockSizeWatch &&
        stockSizeWatch > 0 && (
          <Paper elevation={3}>
            <Grid container spacing={3} rowSpacing={6} alignItems='center'>
              <FormGridRow>
                <ControllerSwitch
                  label={t`Pre-venta de stock`}
                  name='preblockLimitEnabled'
                  variant='full'
                  control={control}
                  disabled={readonly}
                />
                <Trans i18nKey='stockSettingsForm.preblockLimitEnabled' />
              </FormGridRow>
              {preblockLimitEnabledWatch && (
                <FormGridRow>
                  <ControllerInputText
                    control={control}
                    name='preblockLimit'
                    error={errors.preblockLimit}
                    label={t`Nº de prebooks para preventa`}
                    size='full'
                    inputProps={{ endAdornment: <InputIcon Icon={GarageIcon} /> }}
                    nativeInputProps={{ type: 'number', min: '0', max: stockSizeWatch }}
                    disabled={readonly}
                  />
                  <Trans i18nKey='stockSettingsForm.preblockLimit' />
                </FormGridRow>
              )}
            </Grid>
          </Paper>
        )}

      <Paper elevation={3}>
        <Grid container spacing={3} rowSpacing={6} alignItems='center'>
          <FormGridRow>
            <ControllerSwitch
              label={t`Sobrecoste`}
              name='highDemandFeeEnabled'
              variant='full'
              control={control}
              disabled={readonly}
            />
            <Trans i18nKey='stockSettingsForm.highDemandFeeEnabled' />
          </FormGridRow>

          {highDemandFeeEnabledWatch && (
            <>
              <FormGridRow>
                <ControllerInputText
                  control={control}
                  name='highDemand50Fee'
                  error={errors.highDemand50Fee}
                  label={t`Ocupac. > 50%`}
                  size='full'
                  inputProps={{ endAdornment: <InputIcon Icon={PercentIcon} /> }}
                  nativeInputProps={{
                    type: 'number',
                    min: '0',
                    max: `${highDemand80FeeWatch ?? 100}`,
                    step: '1',
                  }}
                  disabled={readonly}
                />
                <Trans i18nKey='stockSettingsForm.highDemand50Fee' values={{ highDemand50FeeWatch, occupation50 }} />
              </FormGridRow>
              <FormGridRow>
                <ControllerInputText
                  control={control}
                  name='highDemand80Fee'
                  error={errors.highDemand80Fee}
                  label={t`Ocupac. > 80%`}
                  size='full'
                  inputProps={{ endAdornment: <InputIcon Icon={PercentIcon} /> }}
                  nativeInputProps={{ type: 'number', min: `${highDemand50FeeWatch ?? 0}`, max: '100', step: '1' }}
                  disabled={readonly}
                />
                <Trans i18nKey='stockSettingsForm.highDemand80Fee' values={{ highDemand80FeeWatch, occupation80 }} />
              </FormGridRow>
            </>
          )}

          <FormGridRow className='controller-switch'>
            <ControllerSwitch
              label={t`Mostrar tasa ocupación`}
              name='showHighDemandFee'
              variant='full'
              control={control}
              disabled={readonly}
            />
            <Trans i18nKey='stockSettingsForm.showHighDemandFee' shouldUnescape />
          </FormGridRow>
        </Grid>
      </Paper>

      <Box display='flex' alignItems='center' justifyContent='center' columnGap='16px' paddingTop={5}>
        <CancelButton
          to={historic ? routes.centersHistoric.replace(':id', center.originalID.toString()) : routes.centers}
          onClick={handleGoBack}
        />
        {!readonly && (
          <div ref={submitButtonRef}>
            <SubmitButton />
          </div>
        )}
        {!readonly && !isSubmitButtonVisible && <FloatingSubmitButton text={t('Guardar cambios')} size='medium' />}
      </Box>
    </StockSettingsForm>
  )
}

export default StockSettings
