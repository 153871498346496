import { FC } from 'react'

import { useNavigate, useParams } from 'react-router-dom'

import useGetOneCenterPeriodVersion from 'hooks/queries/centers/use-get-one-center-period-version'
import SpinnerLoading from 'components/atoms/spinner-loading'

import CenterPeriodsHistoricShowDetails from './center-periods-historic-show-details'

const CenterPeriodsHistoricShow: FC = () => {
  const { id = '', version = '' } = useParams()
  const navigate = useNavigate()
  const { response: period, isLoading } = useGetOneCenterPeriodVersion({ id, version })

  if (isLoading) {
    return <SpinnerLoading fullHeight display={'flex'} alignItems={'center'} />
  } else if (!period) {
    navigate(-1)
    return null
  }

  return <CenterPeriodsHistoricShowDetails period={period} />
}

export default CenterPeriodsHistoricShow
