import Box from '@mui/material/Box'
import { useParams } from 'react-router-dom'

import ProfilesHistoricTable from 'components/organisms/profiles/profiles-historic-table'
import useGetProfilesVersions from 'hooks/queries/profiles/use-get-profiles-versions'
import CancelButton from 'components/atoms/cancel-button'
import FormButtonsContainer from 'components/atoms/form-buttons-container'
import usePagination from 'hooks/use-pagination'
import useSort from 'hooks/use-sort'
import routes from 'config/routes'

const ProfilesHistoric = (): JSX.Element => {
  const { id } = useParams()

  const { pageSize, offset, page, setPageSize, setPage } = usePagination()

  const { sortModel, setSortModel } = useSort()

  const { response, isLoading, count } = useGetProfilesVersions({
    id,
    offset,
    limit: pageSize,
    sort: sortModel || '-id',
  })

  return (
    <Box>
      <ProfilesHistoricTable
        id={id}
        rows={response}
        count={count}
        page={page}
        pageSize={pageSize}
        isLoading={isLoading}
        onSortModelChange={setSortModel}
        onPageSizeChange={setPageSize}
        onPageChange={setPage}
      />
      <FormButtonsContainer>
        <CancelButton to={routes.profiles} />
      </FormButtonsContainer>
    </Box>
  )
}

export default ProfilesHistoric
