import { FC } from 'react'

import InputAdornment from '@mui/material/InputAdornment'
import SvgIcon from '@mui/material/SvgIcon'

import { colors } from 'config/theme'

interface InputIconProps {
  Icon: typeof SvgIcon
  color?: string
  position?: 'end' | 'start'
}

const InputIcon: FC<InputIconProps> = ({ Icon, color = colors.darkGrey, position = 'end' }) => (
  <InputAdornment position={position}>
    <Icon style={{ color }} />
  </InputAdornment>
)

export default InputIcon
