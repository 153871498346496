import styled from 'styled-components'
import { Button } from '@mui/material'

import { colors } from 'config/theme'

export const CustomButton = styled(Button)`
  box-shadow: none;
  border-radius: 4px;
  color: ${colors.deepBlue};
  border-color: ${colors.grey};
  padding: 0 15px;
  height: 36px;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1.25px;
  position: relative;

  &:hover {
    border-color: ${colors.grey};
    box-shadow: none;
  }
`

export const VisuallyHiddenInput = styled('input')`
  opacity: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;

  &[type='file']::-webkit-file-upload-button {
    cursor: pointer;
  }
`

export const LoadingBackdrop = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);

  .MuiCircularProgress-root {
    margin-top: 7px;
  }
`
