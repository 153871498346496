import { FC } from 'react'

import LoginCognito from 'components/organisms/login-cognito'
import LoginForm from 'components/organisms/login-form'

import { REACT_APP_LOGIN_MODE } from '../../../config/constants'

const Login: FC = () => (REACT_APP_LOGIN_MODE === 'cognito' ? <LoginCognito /> : <LoginForm />)

export default Login
