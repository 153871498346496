import React from 'react'

import { Control, FieldErrors } from 'react-hook-form'
import { CircularProgress, Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'

import ControllerInputText from 'components/molecules/controller-input-text'
import ControllerSwitch from 'components/molecules/controller-switch'
import FormTitle from 'components/atoms/form-title'
import { UsersRelationFormFields } from 'validations/users-relations'

import { GridItem } from './styled'

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>
  isLoading?: boolean
  disabled?: boolean
  errors: FieldErrors<UsersRelationFormFields>
}

const ControllerRelationConfig: React.FC<Props> = ({ isLoading, control, disabled, errors }) => {
  const { t } = useTranslation()

  if (isLoading) {
    return (
      <Grid container sx={{ display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </Grid>
    )
  }

  return (
    <Grid container spacing={1}>
      <GridItem item xs={12}>
        <FormTitle text={t('Información')} />
      </GridItem>

      <GridItem item md={3} xs={false}></GridItem>

      <GridItem item md={3} xs={12}>
        <ControllerInputText
          mandatory
          control={control}
          name='name'
          error={errors.name}
          label={t('Nombre')}
          size='full'
          disabled={disabled}
        />
      </GridItem>

      <GridItem item md={6} xs={12}>
        <ControllerSwitch label={t('Activo')} name='active' variant='full' control={control} disabled={disabled} />
      </GridItem>
    </Grid>
  )
}

export default ControllerRelationConfig
