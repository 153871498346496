import styled from 'styled-components'
import { IconButton } from '@mui/material'

export const ClearFiltersButton = styled(IconButton)`
  display: flex;
  align-items: center;
  justify-content: flex-center;
  border-radius: 0px;
  font-size: 1rem;

  &:hover,
  &:focus {
    cursor: pointer;
    background-color: transparent;
  }
  .MuiTouchRipple-root {
    display: none;
  }
`
