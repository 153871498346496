import React, { FC, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import TextField from '@mui/material/TextField'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'

import { ReactComponent as Logo } from 'assets/images/svg/logo.svg'
import routes from 'config/routes'
import { useAuth } from 'providers/auth'

import { DescriptionText, Form, MainBox, MainContainer } from './styled'
import { schema, FormData, FormFields } from './validations'

const defaultValues: FormFields = {
  email: '',
  password: '',
}

const SubmitButton = () => {
  const { t } = useTranslation()

  return (
    <Button type='submit' fullWidth variant='contained' sx={{ my: 2 }}>
      {t('Acceder')}
    </Button>
  )
}

interface RememberPasswordCheckboxProps {
  checked: boolean
  onChange: () => void
}

const RememberPasswordCheckbox: React.FC<RememberPasswordCheckboxProps> = ({ checked, onChange }) => {
  const { t } = useTranslation()

  return (
    <FormControlLabel
      control={<Checkbox value='remember' color='primary' checked={checked} />}
      label={t('Recordarme')}
      onChange={onChange}
    />
  )
}

const LoginForm: FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const auth = useAuth()
  const { t } = useTranslation()

  const [remember, setRemember] = useState<boolean>(false)

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues,
    resolver: yupResolver(schema),
  })
  const from = location.state?.from?.pathname || routes.root

  const onSubmit = (data: FormFields): void => {
    const { email, password } = data
    auth
      .signin({ email, password }, remember)
      .then(() => navigate(from, { replace: true }))
      .catch(e => toast(e.message, { type: 'error' }))
  }

  return (
    <MainContainer component='main' maxWidth='xs' data-testid='login-form'>
      <MainBox>
        <Box sx={{ my: 2 }}>
          <Logo width='200' />
        </Box>
        <DescriptionText component='p'>{t('Acceder con usuario y contraseña')}</DescriptionText>
        <Form component='form' onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name='email'
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                id='email'
                label={t('Email')}
                name='email'
                autoComplete='email'
                autoFocus
                sx={{ mb: 2 }}
                error={!!errors.email}
                helperText={errors?.email?.message?.toString()}
              />
            )}
          />
          <Controller
            name='password'
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label={t('Contraseña')}
                type='password'
                id='password'
                name='password'
                autoComplete='current-password'
                autoFocus
                sx={{ mb: 2 }}
                error={!!errors.password}
                helperText={errors?.password?.message?.toString()}
              />
            )}
          />
          <RememberPasswordCheckbox checked={remember} onChange={() => setRemember(prev => !prev)} />
          <SubmitButton />
        </Form>
      </MainBox>
    </MainContainer>
  )
}

export default LoginForm
