import React from 'react'

import { Controller, Control } from 'react-hook-form'

import WeekdaysInput from 'components/atoms/weekdays-input'

interface Props {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  control: Control<any, any>
  name: string
  disabled?: boolean
}
const ControllerInputWeekdays: React.FC<Props> = ({ control, name, disabled }) => (
  <Controller
    name={name}
    disabled={disabled}
    control={control}
    render={({ field: { ref, ...field } }) => <WeekdaysInput {...field} />}
  />
)

export default ControllerInputWeekdays
