import { FC } from 'react'

import { useTranslation } from 'react-i18next'

import CountChip from 'components/atoms/count-chip'
import ResourceHeader from 'components/atoms/resource-header'

import * as S from './styled'

interface ImportJobsTableHeaderProps {
  count: number
  isLoading?: boolean
}

const ImportJobsTableHeader: FC<ImportJobsTableHeaderProps> = ({ count, isLoading }) => {
  const { t } = useTranslation()

  return (
    <S.Container width='100%' display='flex' flexDirection='column'>
      <ResourceHeader>
        <CountChip label={t`Ficheros`} labelSingular={t`Fichero`} count={count} isLoading={isLoading} />
      </ResourceHeader>
    </S.Container>
  )
}

export default ImportJobsTableHeader
