import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { GET_ROLES_RELATIONS } from 'config/queries'
import { RoleRelation } from 'models/relation'
import { createOneRoleRelation } from 'services/relations'

const useCreateRoleRelation = () => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const createUserMutation = useMutation({
    mutationFn: (payload: Omit<RoleRelation, 'id'>) => createOneRoleRelation(payload),
    onSuccess: () => {
      toast(t('Relación creada'), { type: 'success' })
      queryClient.invalidateQueries({ queryKey: [GET_ROLES_RELATIONS] })
    },
    onError: () => {
      toast(t('Ha ocurrido un error'), { type: 'error' })
    },
  })

  return createUserMutation
}

export default useCreateRoleRelation
