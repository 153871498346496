import { DataGrid } from '@mui/x-data-grid'
import styled from 'styled-components'

export const StyledDataGrid = styled(DataGrid)`
  opacity: ${({ disableColumnMenu }) => disableColumnMenu && '0.5'};
  .MuiDataGrid-virtualScroller {
    min-height: ${({ loading, rows, rowHeight = 80 }) =>
    (loading || rows.length >= 5) && `${rowHeight * (rows.length || 5)}px`};
  }

  .error-row {
    background-color: ${({ theme }) => theme.palette.error.main};

    &:hover {
      background-color: ${({ theme }) => theme.palette.error.light};
    }

    p,div {
      color: #fff;
    }
  }
`
