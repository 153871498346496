import { QueryKey, useQuery } from '@tanstack/react-query'

import { GET_CALENDAR } from 'config/queries'
import { CalendarDay, GetCalendar, UseGetCarparkCalendar } from 'models/center'
import { getCalendar } from 'services/centers'

const useGetCalendar = ({ id, month, year }: GetCalendar, queryKey?: QueryKey): UseGetCarparkCalendar => {
  const {
    data: response,
    isLoading,
    isPreviousData,
  } = useQuery({
    queryKey: queryKey ?? [GET_CALENDAR, id, month, year],
    queryFn: () => getCalendar({ id, month, year }),
    refetchOnMount: true,
    keepPreviousData: true,
  })

  const result: CalendarDay[] = response?.data ?? []
  const count: number = parseInt(response?.headers?.['x-total-count'] || 0)

  return { response: result, count, isLoading: isLoading || isPreviousData }
}

export default useGetCalendar
