import MuiFormGroup from '@mui/material/FormGroup'
import styled from 'styled-components'

export const FormGroup = styled(MuiFormGroup)`
  justify-content: center;

  .MuiFormControlLabel-root {
    margin: 0 10px;
  }
`
