import { FC } from 'react'

import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'

import { FontFamily } from 'config/constants'

import DayPricesExportForm from '../day-prices/day-prices-export-form'

const DayPricesExport: FC = () => {
  const { t } = useTranslation()

  return (
    <Paper elevation={2} sx={{ marginTop: 4, paddingY: 2 }}>
      <Typography component={'h4'} fontFamily={FontFamily.bold} textAlign='center'>{t`Precios diarios`}</Typography>
      <DayPricesExportForm />
    </Paper>
  )
}

export default DayPricesExport
