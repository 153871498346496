import { FC, useCallback } from 'react'

import { GridEnrichedColDef, GridValidRowModel } from '@mui/x-data-grid'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import { t } from 'i18next'

import { TableColumnWidth } from 'config/constants'
import { Configuration } from 'models/configuration'
import ButtonGridActionCellItem from 'components/atoms/button-grid-action-cell-item'
import TextCell from 'components/atoms/text-cell'
import useAdministrationPermissions, { AdministrationSections } from 'hooks/permissions/use-administration-permissions'

import { StyledDataGrid } from './styled'

interface ConfigurationTableProps {
  rows: GridValidRowModel[]
}

const ConfigurationTable: FC<ConfigurationTableProps> = ({ rows }) => {

  const { canWrite } = useAdministrationPermissions(AdministrationSections.configuration)

  const getDownloadAction = useCallback(({ row }: { row: Configuration }) => {
    const actions = [
      <ButtonGridActionCellItem
        key={`${row.id}-edit`}
        onButtonClick={row.download}
        label={t('Descargar')}
        Icon={() => <FileDownloadIcon color='primary' />}
      />,
    ]

    return actions
  }, [])

  const getUpdateAction = useCallback(({ row }: { row: Configuration }) => {
    const actions = [
      <ButtonGridActionCellItem
        isUpload
        key={`${row.id}-edit`}
        onButtonClick={row.update}
        label={t('Actualizar')}
        Icon={() => <FileUploadIcon color='primary' />}
        disabled={!canWrite}
      />,
    ]

    return actions
  }, [canWrite])

  const columns: GridEnrichedColDef[] = [
    {
      field: 'operative',
      headerName: `${t('Operativa')}`,
      flex: TableColumnWidth.large,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      renderCell: ({ row }: { row: Configuration }) => <TextCell text={row.operative} />,
    },
    {
      field: 'download',
      headerName: `${t('Descargar')}`,
      type: 'actions',
      flex: TableColumnWidth.small,
      sortable: false,
      getActions: getDownloadAction,
    },
    {
      field: 'update',
      headerName: `${t('Actualizar')}`,
      type: 'actions',
      flex: TableColumnWidth.small,
      sortable: false,
      getActions: getUpdateAction,
    },
  ]

  return (
    <StyledDataGrid
      componentsProps={{ header: { 'data-testid': 'basic-table' } }}
      autoHeight
      disableColumnMenu
      disableColumnFilter
      disableSelectionOnClick
      keepNonExistentRowsSelected
      rows={rows}
      rowCount={rows.length}
      columns={columns}
      loading={false}
      hideFooter
    />
  )
}

export default ConfigurationTable
