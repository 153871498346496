import { QueryKey, useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { GET_ROLES_RELATIONS } from 'config/queries'
import { RoleRelationDTO } from 'models/relation'
import { getRolesRelations } from 'services/relations'

interface UseGetRelationsProps {
  limit: number
  offset: number
  sort: string
}

interface UseGetRelations {
  response: RoleRelationDTO[]
  count: number
  isLoading: boolean
  queryKey: QueryKey
}

const useGetRolesRelations = ({ limit, offset, sort }: UseGetRelationsProps): UseGetRelations => {
  const { t } = useTranslation()
  const queryKey = [GET_ROLES_RELATIONS, limit, offset, sort]

  const {
    data: response,
    isLoading,
    isPreviousData,
  } = useQuery({
    queryKey,
    queryFn: () => getRolesRelations({ limit, offset, sort }),
    onError: () => {
      toast(t('Ha ocurrido un error al obtener los relaciones entre roles y centros'), { type: 'error' })
    },
    refetchOnMount: true,
    keepPreviousData: true,
  })

  const result: RoleRelationDTO[] = response?.data ?? []
  const count: number = parseInt(response?.headers?.['x-total-count'] || 0)

  return { response: result, count, isLoading: isLoading || isPreviousData, queryKey }
}

export default useGetRolesRelations
