import React from 'react'

import { GridActionsCellItem } from '@mui/x-data-grid'
import EditIcon from '@mui/icons-material/EditOutlined'
import { Tooltip } from '@mui/material'
import { t } from 'i18next'

interface Props {
  onClick: () => void
}

const EditGridActionCellItem: React.FC<Props> = ({ onClick }) => (
  <Tooltip title={t('Editar')}>
    <GridActionsCellItem icon={<EditIcon color='primary' />} label='edit' onClick={onClick} />
  </Tooltip>
)

export default EditGridActionCellItem
