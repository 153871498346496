import React, { useMemo } from 'react'

import { useTranslation } from 'react-i18next'

import CircularProgress from 'components/atoms/circular-progress'
import H3 from 'components/atoms/h3'
import PrebooksDoughnutChart from 'components/molecules/prebooks-doughnut-chart'
import useGetKpis from 'hooks/queries/prebooks/use-get-kpis'
import { SearchCarparksFormFields } from 'models/prebooks'

interface Props {
  filters: SearchCarparksFormFields
}

const PrebooksSurcharges: React.FC<Props> = ({ filters }) => {
  const { t } = useTranslation()

  const { response, isLoading } = useGetKpis(filters)
  const surcharges = useMemo(() => response?.fees ?? {}, [response])

  if (!response) {
    return null
  }

  return (
    <>
      <H3 text={t('Sobrecargos')} />
      {isLoading ? <CircularProgress /> : <PrebooksDoughnutChart data={surcharges} />}
    </>
  )
}

export default PrebooksSurcharges
