import React from 'react'

import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import CheckIcon from '@mui/icons-material/Check'
import { Box } from '@mui/material'

import BasicPositiveNumber from 'components/atoms/basic-positive-number'

interface Props {
  value: string
  onChange: (event: React.ChangeEvent, value: string) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onAccept: (e: any) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onCancel: (e: any) => void
}

const CalendarTileEdit: React.FC<Props> = ({ value, onCancel, onAccept, onChange }) => (
  <>
    <BasicPositiveNumber
      autoFocus
      value={value}
      inputProps={{ step: 0.01, min: 1, max: 999 }}
      onChange={e => onChange(e, e.target.value)}
      onKeyDown={e => e.key === 'Enter' && onAccept(e)}
    />
    <Box display='flex'>
      <IconButton component='div' size='small' color='success' onClick={onAccept}>
        <CheckIcon />
      </IconButton>
      <IconButton component='div' size='small' color='error' onClick={onCancel}>
        <CloseIcon />
      </IconButton>
    </Box>
  </>
)

export default CalendarTileEdit
