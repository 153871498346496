import { useEffect, useState } from 'react'

import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'

import EventsTable from 'components/organisms/events/events-table'
import CountChip from 'components/atoms/count-chip'
import ResourceHeader from 'components/atoms/resource-header'
import ResourceFilter from 'components/molecules/resource-filter'
import EventsFilter from 'components/organisms/events/events-filter'
import useGetEvents from 'hooks/queries/event/use-get-events'
import usePagination from 'hooks/use-pagination'
import useFilters from 'hooks/use-filters'
import { eventsFilterDefaultValues } from 'validations/events'

const EventsList = (): JSX.Element => {
  const { t } = useTranslation()

  const { pageSize, offset, page, setPageSize, setPage } = usePagination()

  const { sortModel, badge, openFilter, filters, setSortModel, onSearchQ, onSearchFilter, onToggleFilter } =
    useFilters(eventsFilterDefaultValues)

  const { response, isLoading, count, queryKey } = useGetEvents({
    offset,
    limit: pageSize,
    filters: filters,
    sort: sortModel || '-updateDate',
  })

  const [searchQuery, setSearchQuery] = useState('')

  const handleSearchQ = (event: React.ChangeEvent<HTMLInputElement>) => {
    sessionStorage.setItem('eventsSearch', JSON.stringify(event.target.value))
    setSearchQuery(event.target.value)
    onSearchQ(event)
  }
  const handleClearSearch = () => {
    sessionStorage.removeItem('eventsSearch')
    setSearchQuery('')
    onSearchQ({ target: { value: '' } } as React.ChangeEvent<HTMLInputElement>)
  }

  useEffect(() => {
    const storedData = sessionStorage.getItem('eventsSearch')
    if (storedData) {
      const parsedData = JSON.parse(storedData)
      onSearchQ({ target: { value: parsedData } } as React.ChangeEvent<HTMLInputElement>)
      setSearchQuery(parsedData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box>
      <ResourceHeader>
        <CountChip label={t('Registros')} labelSingular={t('Registro')} count={count} isLoading={isLoading} />
        <ResourceFilter
          searchQuery={searchQuery}
          handleClearSearch={handleClearSearch}
          badge={badge}
          label={t`Nombre, acción, tipo, clave ...`}
          openFilter={openFilter}
          onSearchQ={handleSearchQ}
          onToggleFilter={onToggleFilter}
        />
      </ResourceHeader>
      {openFilter && <EventsFilter onSearch={onSearchFilter} />}
      <EventsTable
        rows={response}
        count={count}
        page={page}
        pageSize={pageSize}
        isLoading={isLoading}
        onSortModelChange={setSortModel}
        onPageSizeChange={setPageSize}
        onPageChange={setPage}
        queryKey={queryKey}
      />
    </Box>
  )
}

export default EventsList
