import { useTranslation } from 'react-i18next'
import { CleaningServicesRounded } from '@mui/icons-material'

import * as S from './styled'

interface Props {
  handleClearFilters: () => void
  type?: 'button' | 'submit' | 'reset'
  size?: 'small' | 'medium' | 'large'
  color?: 'primary' | 'secondary' | 'default'
  iconOnly?: boolean
}

export const ClearFiltersButton = ({
  type = 'button',
  size = 'small',
  color = 'default',
  handleClearFilters,
  iconOnly = false,
}: Props) => {
  const { t } = useTranslation()

  return (
    <S.ClearFiltersButton
      className='clear-filters'
      type={type}
      onClick={handleClearFilters}
      size={size}
      color={color}
      aria-label='clear-filters'
    >
      <CleaningServicesRounded />
      {!iconOnly && t`Limpiar filtros`}
    </S.ClearFiltersButton>
  )
}
