import { createContext, type ReactNode, useCallback, useMemo, useState } from 'react'

import { ConfirmContext, ConfirmOptions, Nullable } from 'models/confirm'
import ModalConfirm from 'components/molecules/modal-confirm'

export const ConfirmCtx = createContext<Nullable<ConfirmContext>>(null)
interface Props {
  children?: ReactNode
}
function ConfirmProvider({ children }: Props): JSX.Element {
  const [confirm, setConfirm] = useState<Nullable<ConfirmOptions>>(null)
  const [open, toggle] = useState(false)
  const show = useCallback(
    (confirmOptions: Nullable<ConfirmOptions>) => {
      setConfirm(confirmOptions)
      toggle(true)
    },
    [toggle]
  )
  const onConfirm = () => {
    confirm?.onConfirm?.()
    toggle(false)
  }
  const onCancel = () => {
    confirm?.onCancel?.()
    toggle(false)
  }
  const value = useMemo(() => ({ show }), [show])
  return (
    <ConfirmCtx.Provider value={value}>
      <ModalConfirm
        title={confirm?.title}
        message={confirm?.subtitle}
        confirmLabel={confirm?.confirmText}
        cancelLabel={confirm?.cancelText}
        {...confirm}
        onCancel={onCancel}
        onConfirm={onConfirm}
        open={open}
      />
      {children}
    </ConfirmCtx.Provider>
  )
}
export default ConfirmProvider
