import React from 'react'

import { Box, CircularProgress as MUICircularProgress } from '@mui/material'

const CircularProgress: React.FC = () => (
  <Box textAlign='center'>
    <MUICircularProgress />
  </Box>
)

export default CircularProgress
