import { useMemo } from 'react'

import { useAuth } from 'providers/auth'

const useAlertsPermissions = () => {
  const { user } = useAuth()

  const canRead = useMemo(() => !!user?.role, [user?.role])

  return { canRead }
}

export default useAlertsPermissions
