import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import Grid from '@mui/material/Grid'

import ControllerInputDateTimePicker from 'components/molecules/controller-input-datetimepicker'
import ControllerInputSelect from 'components/molecules/controller-input-select'
import CarparkSearcher from 'components/molecules/locations-searcher'
import PrebooksDownload from 'components/molecules/prebooks-download'
import { CHANNELS, DateFormats } from 'config/constants'
import { PrebooksExportFilters } from 'models/prebooks'
import { exportPrebooksSchema } from 'validations/prebooks'
import { CenterType } from 'models/center'

import { Container } from './styled'

const defaultValues: PrebooksExportFilters = {
  from: '',
  to: '',
  carparkCodes: [],
  channelCode: '',
  centerType: 'Todos',
}

const CENTER_OPTIONS = [
  { value: 'Todos', text: 'Todos' },
  { value: CenterType.AGGREGATION, text: 'Agregación' },
  { value: CenterType.TELPARK, text: 'Telpark' },
]

interface PrebooksExportFormProps {
  onSubmit: (formData: PrebooksExportFilters) => void
}

const PrebooksExportForm: FC<PrebooksExportFormProps> = ({ onSubmit }) => {
  const { t } = useTranslation()

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<PrebooksExportFilters>({
    defaultValues,
    resolver: yupResolver(exportPrebooksSchema),
  })

  return (
    <Container container spacing={2}>
      <Grid item xs={9}>
        <CarparkSearcher multiple name='carparkCodes' control={control} />
      </Grid>
      <Grid item xs={3}>
        <ControllerInputSelect
          control={control}
          name='centerType'
          label={t`Tipo centro`}
          size='full'
          options={CENTER_OPTIONS}
        />
      </Grid>

      <Grid item xs={3}>
        <ControllerInputDateTimePicker
          mandatory
          onlyDate
          label={t('Desde')}
          name='from'
          control={control}
          error={errors.from}
          size='full'
          pattern={DateFormats.isoCeros}
        />
      </Grid>
      <Grid item xs={3}>
        <ControllerInputDateTimePicker
          mandatory
          onlyDate
          label={t('Hasta')}
          name='to'
          control={control}
          error={errors.to}
          size='full'
          pattern={DateFormats.isoCeros}
        />
      </Grid>
      <Grid item xs={3}>
        <ControllerInputSelect
          noSelection
          control={control}
          name='channelCode'
          error={errors.channelCode}
          label={t`Canal`}
          size='full'
          options={CHANNELS}
        />
      </Grid>
      <Grid item xs={3}>
        <PrebooksDownload onDownload={handleSubmit(onSubmit)} />
      </Grid>
    </Container>
  )
}

export default PrebooksExportForm
