import * as yup from 'yup'

import { PeriodPriority, PeriodWeekday } from 'models/center-period'

import { isMinorDate } from './utils'

export const centerPeriodSchema = yup
  .object({
    from: yup.string().required(),
    to: yup.string().test(isMinorDate).required(),
    priority: yup.string().required(),
    weekdays: yup.array().of(yup.string()).required(),
    minDays: yup.number().min(0).required(),
    maxDays: yup
      .number()
      .required()
      .when('minDays', (minDays, schema) => schema.min(minDays ? minDays : 0)),
    prices: yup.array().of(yup.number()),
  })
  .required()

export interface CenterPeriodFields {
  from: string
  to: string
  priority: PeriodPriority
  weekdays: PeriodWeekday[]
  minDays: number
  maxDays: number
  prices: number[]
}
