import { ChangeEvent, FC, useCallback } from 'react'

import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import dayjs from 'dayjs'
import localeData from 'dayjs/plugin/localeData'

import { PeriodWeekday } from 'models/center-period'

import * as S from './styled'

// eslint-disable-next-line import/no-named-as-default-member
dayjs.extend(localeData)

const WEEKDAYS_LABELS = dayjs.weekdaysMin()

interface WeekdaysInputProps {
  name: string
  value: PeriodWeekday[]
  disabled?: boolean
  onChange: (weekdays: PeriodWeekday[]) => void
}

const WeekdaysInput: FC<WeekdaysInputProps> = ({ value, disabled, onChange }) => {
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      const weekday = event.target.value as PeriodWeekday
      const newValue = checked ? [...value, weekday] : value.filter(day => day !== weekday)

      onChange(newValue)
    },
    [value, onChange]
  )

  return (
    <S.FormGroup row>
      {Object.values(PeriodWeekday).map((weekday, index) => {
        const label = WEEKDAYS_LABELS[(index + 1) % WEEKDAYS_LABELS.length].toUpperCase()

        return (
          <FormControlLabel
            key={weekday}
            value={weekday}
            control={<Checkbox checked={value.includes(weekday)} onChange={handleChange} />}
            label={label}
            labelPlacement='top'
            disabled={disabled}
          />
        )
      })}
    </S.FormGroup>
  )
}

export default WeekdaysInput
