import { FC, useCallback, useState } from 'react'

import CircularProgress from '@mui/material/CircularProgress'
// import DownloadIcon from '@mui/icons-material/Download'
// import Typography from '@mui/material/Typography'
import DownloadIcon from '@mui/icons-material/Download'
import { useTranslation } from 'react-i18next'

// import BasicButton from 'components/atoms/basic-button'

import * as S from './styled'

interface PrebooksDownloadProps {
  onDownload: () => Promise<void>
}

const PrebooksDownload: FC<PrebooksDownloadProps> = ({ onDownload }) => {
  const { t } = useTranslation()

  const [isLoading, setIsLoading] = useState(false)

  const handleClick = useCallback(async () => {
    setIsLoading(true)
    await onDownload()
    setIsLoading(false)
  }, [onDownload])

  return (
    <S.CustomButton
      variant='outlined'
      startIcon={<DownloadIcon color='primary' />}
      onClick={handleClick}
      size='small'
      disabled={isLoading}
    >
      {t('Descargar')}
      {isLoading && (
        <S.LoadingBackdrop>
          <CircularProgress size={20} />
        </S.LoadingBackdrop>
      )}
    </S.CustomButton>
  )
}

export default PrebooksDownload
