import { QueryKey, useMutation, useQueryClient } from '@tanstack/react-query'

import { GET_ALERTS } from 'config/queries'
import { Alert } from 'models/alert'
import { updateOneAlert } from 'services/alerts'

const useMarkAlertAsRead = (queryKey: QueryKey) => {
  const queryClient = useQueryClient()

  const { mutate } = useMutation({
    mutationFn: (id: number) => updateOneAlert(id, true),
    onMutate: async (id: number) => {
      await queryClient.cancelQueries({ queryKey: [GET_ALERTS] })
      const previous = queryClient.getQueryData<{ data: Alert[] }>(queryKey)
      const alert = previous?.data.find(o => o.id === id)

      if (alert) {
        alert.isRead = true
      }

      return { previous }
    },
  })

  return mutate
}

export default useMarkAlertAsRead
