import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import * as Sentry from '@sentry/react'

import { GET_ALERTS_BY_USER } from 'config/queries'
import { removeAlertRecipient } from 'services/alerts'

const useRemoveAlertRecipient = () => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  return useMutation({
    mutationFn: (alertId: number) => removeAlertRecipient(alertId),
    onSuccess: () => {
      queryClient.invalidateQueries([GET_ALERTS_BY_USER])
      toast(t('Alerta eliminada para este centro'), { type: 'success' })
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => {
      const message = error?.response?.data?.description ?? t('Ha ocurrido un error')
      toast(message, { type: 'error' })
      Sentry.captureException(error)
    },
  })
}
export default useRemoveAlertRecipient
