import PageContainer from 'components/atoms/page-container'
import UsersCreateForm from 'components/organisms/users/users-create-form'

const UsersCreate = (): JSX.Element => (
  <PageContainer>
    <UsersCreateForm />
  </PageContainer>
)

export default UsersCreate
