import styled from 'styled-components'

import { colors } from 'config/theme'

export const PeriodSettings = styled.div`
  position: relative;

  .periods-filter-switch {
    height: auto;
  }
`

export const Header = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  align-items: center;
  margin-bottom: 32px;

  & > .MuiTypography-root {
    grid-column-start: 2;
    margin-bottom: 0;
  }

  & > .MuiButtonBase-root {
    margin-left: auto;
  }
`

export const SubHeader = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: flex-end;
`

export const PeriodsSortTable = styled.table`
  thead {
    background-color: ${colors.lightGrey};
    color: ${colors.darkGrey};
  }
  th {
    padding: 8px 16px;
    text-align: left;
  }
  td {
    padding: 8px 16px;
  }
`
