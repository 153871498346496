import { QueryKey, useMutation, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { GET_ROLES_RELATIONS } from 'config/queries'
import { RoleRelation, UpdateRoleRelation } from 'models/relation'
import { updateOneRoleRelation } from 'services/relations'

const useUpdateRoleRelation = (queryKey?: QueryKey) => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  const updateRelation = useMutation({
    mutationFn: ({ id, ...payload }: UpdateRoleRelation) => updateOneRoleRelation(id, payload),
    onSuccess: () => {
      toast(t('Relación actualizada'), { type: 'success' })
    },
    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey: [GET_ROLES_RELATIONS] })
      const previous = queryClient.getQueryData<{ data: RoleRelation[] }>(queryKey ?? [GET_ROLES_RELATIONS])

      return { previous }
    },
    onError: (error, newValue, context) => {
      queryClient.setQueryData([GET_ROLES_RELATIONS], context?.previous)
      toast(t('Ha ocurrido un error'), { type: 'error' })
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [GET_ROLES_RELATIONS] })
    },
  })

  return updateRelation
}

export default useUpdateRoleRelation
