import * as i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import { setLocale } from 'yup'
import { locale as dayjsLocale } from 'dayjs'
import 'dayjs/locale/en-gb'
import 'dayjs/locale/es'
import 'dayjs/locale/fr'
import 'dayjs/locale/pt'

import translationES from 'assets/i18n/es.json'
import translationPT from 'assets/i18n/pt.json'
import translationFR from 'assets/i18n/fr.json'
import translationEN from 'assets/i18n/en.json'
import storage from 'services/storage'

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false
  }
}

// See: https://github.com/i18next/react-i18next/issues/1483#issuecomment-1268455602
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TI = any

const resources = {
  es: { translation: translationES, label: i18next.t('es') },
  en: { translation: translationEN, label: i18next.t('en') },
  pt: { translation: translationPT, label: i18next.t('pt') },
  fr: { translation: translationFR, label: i18next.t('fr') },
}

const LANGUAGE_STORAGE_KEY = 'lng'

export const defaultLanguage = 'es'

export const getCurrentLanguage = () => storage.getItem(LANGUAGE_STORAGE_KEY) || defaultLanguage

const setYupLocale = () =>
  setLocale({
    mixed: {
      default: i18next.t('Campo inválido') || '',
      required: i18next.t('Campo obligatorio') || '',
      notType: ({ type }) =>
        (type === 'number' ? i18next.t('El valor debe ser numérico') : i18next.t('Campo inválido')) || '',
    },
    string: {
      email: i18next.t('Email inválido') || '',
      max: i18next.t('Ha superado los 255 caracteres') || '',
      matches: i18next.t('Ha introducido carácteres no permitidos') || '',
    },
    array: {
      min: i18next.t('Campo obligatorio') || '',
    },
    number: {
      min: ({ min }) => i18next.t('El valor mínimo es {{min}}', { min }) || '',
      max: ({ max }) => i18next.t('El valor máximo es {{max}}', { max }) || '',
    },
  })

export const changeLanguage = async (code: string) => {
  i18next.changeLanguage(code)
  storage.setItem(LANGUAGE_STORAGE_KEY, code)
  setYupLocale()
  dayjsLocale(code)
}

i18next.use(initReactI18next).init({
  resources,
  returnNull: undefined,
  lng: getCurrentLanguage(),
  fallbackLng: defaultLanguage,
})

dayjsLocale(getCurrentLanguage())

setYupLocale()

export default i18next.default
