import dayjs, { Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'

import { DateFormats } from 'config/constants'

// eslint-disable-next-line import/no-named-as-default-member
dayjs.extend(utc)

interface Args {
  date?: string | Dayjs | null
  pattern?: string
  isUTC?: boolean
}
export const dateFormatter = ({ date, pattern, isUTC }: Args): string => {
  if (!date) return ''

  let template = pattern

  if (pattern === DateFormats.iso8601) {
    template = undefined
  } else if (pattern == DateFormats.isoCeros) {
    return dayjs(date).format('YYYY-MM-DD') + 'T00:00:00.000Z'
  } else if (!pattern) {
    template = DateFormats.full
  }

  const dayFn = isUTC ? dayjs.utc : dayjs
  return dayFn(date).format(template)
}

export const zeroTime = (date: Dayjs): Dayjs => date.hour(0).minute(0).second(0).millisecond(0)
