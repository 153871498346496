import Grid from '@mui/material/Grid'
import styled from 'styled-components'

import { colors } from 'config/theme'

export const TextGridItem = styled(Grid)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 6px;
  font-size: 12px;

  strong {
    color: ${colors.orange};
  }
`
