import { FC, useMemo } from 'react'

import Accordion from '@mui/material/Accordion'
import dayjs from 'dayjs'

import PeriodAccordionContent from 'components/molecules/period-accordion-content'
import PeriodAccordionHeader from 'components/molecules/period-accordion-header'
import { CenterPeriod } from 'models/center-period'
import { useAuth } from 'providers/auth'
import { Roles } from 'models/auth.d'
import { CenterType } from 'models/center'

import { PeriodAccordionContainer } from './styled'

interface PeriodAccordionProps {
  expanded: boolean
  period: CenterPeriod
  centerType: string
  isPeriodicPrice: boolean
  isNewPeriod?: boolean
  readonly?: boolean
  onChange: (periodId: number) => void
  onDelete: (periodId: number) => void
  onSave: (periodId: number, period: Partial<CenterPeriod>) => void
}

const PeriodAccordion: FC<PeriodAccordionProps> = ({ expanded,
  period,
  isPeriodicPrice,
  centerType,
  isNewPeriod,
  readonly,
  onChange,
  onDelete,
  onSave,
}) => {
  const isProtected = useMemo(
    () => !isNewPeriod && !dayjs(period.to).isAfter(dayjs(), 'date'),
    [period.to, isNewPeriod]
  )

  const isFromProtected = useMemo(
    () => !isNewPeriod && !dayjs(period.from).isAfter(dayjs(), 'date'),
    [period.from, isNewPeriod]
  )
  const { user } = useAuth()

  const isCercaReadOnly =
    (user?.role === Roles.CERCA || user?.role === Roles['ADMIN-CERCA']) &&
    isPeriodicPrice &&
    !(period.minDays == 0 && period.maxDays == 0)

  const isCercaAggregationReadOnly =
    (user?.role === Roles.CERCA || user?.role === Roles['ADMIN-CERCA']) &&
    centerType === CenterType.AGGREGATION &&
    !(period.minDays == 0 && period.maxDays == 0)

  return (
    <PeriodAccordionContainer>
      <Accordion expanded={expanded} TransitionProps={{ unmountOnExit: true }} onChange={() => onChange(period.id)}>
        <PeriodAccordionHeader
          period={period}
          readonly={readonly}
          isCercaReadOnly={isCercaReadOnly || isCercaAggregationReadOnly}
          isProtected={isProtected}
          onDelete={onDelete}
        />
        <PeriodAccordionContent
          period={period}
          isPeriodicPrice={isPeriodicPrice}
          isAggregationCenter={centerType === CenterType.AGGREGATION}
          readonly={readonly || isCercaReadOnly || isCercaAggregationReadOnly}
          isProtected={isProtected}
          isFromProtected={isFromProtected}
          onSave={onSave}
        />
      </Accordion>
    </PeriodAccordionContainer>
  )
}

export default PeriodAccordion
