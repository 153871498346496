import Box from '@mui/material/Box'

import AppBarAppName from 'components/atoms/app-bar-app-name'
import AppBarLogo from 'components/atoms/app-bar-logo'
import AppBarLanguage from 'components/molecules/app-bar-language'
import AppBarNavigation from 'components/molecules/app-bar-navigation'
import AppBarUser from 'components/molecules/app-bar-user'

const AppBar = (): JSX.Element => (
  <header>
    <Box display='flex' justifyContent='space-between' data-testid='app-bar'>
      <AppBarLogo />
      <AppBarNavigation />
      <Box display='flex'>
        <AppBarLanguage />
        <AppBarUser />
        <AppBarAppName />
      </Box>
    </Box>
  </header>
)

export default AppBar
