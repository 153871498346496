import { useState } from 'react'

import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import CancelButton from 'components/atoms/cancel-button'
import FormButtonsContainer from 'components/atoms/form-buttons-container'
import PageTitle from 'components/atoms/page-title'
import HistoricTable, { TableProps } from 'components/organisms/historic-table'
import { CentersFormTab } from 'config/centers-constants'
import routes from 'config/routes'
import useGetCenterPeriodVersions from 'hooks/queries/centers/use-get-center-period-versions'
import { useTabStore } from 'store/useTabStore'

const CenterPeriodsHistoric = (): JSX.Element => {
  const { centerId = '', id = '' } = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { setTabIndex, setNoReset } = useTabStore(state => state)

  const [tableProps, setTableProps] = useState<TableProps>({} as TableProps)

  const { response, isLoading, count } = useGetCenterPeriodVersions({ id, ...tableProps })

  const navigateToShow = (version: number) => {
    const route = routes.centersPeriodsHistoricShow
      .replace(':centerId', centerId)
      .replace(':id', id)
      .replace(':version', version.toString())
    navigate(route)
  }

  const handleClick = () => {
    setTabIndex(CentersFormTab.periodSettings)
    setNoReset(true)
    navigate(-1)
  }

  return (
    <Box>
      <PageTitle title={t('Histórico de periodos')} />
      <HistoricTable
        id={id}
        rows={response}
        count={count}
        isLoading={isLoading}
        onTablePropsChange={setTableProps}
        onNavigateToShow={navigateToShow}
      />
      <FormButtonsContainer>
        <CancelButton onClick={handleClick} />
      </FormButtonsContainer>
    </Box>
  )
}

export default CenterPeriodsHistoric
