import { useQuery } from '@tanstack/react-query'

import { getCentersVersions } from 'services/centers'
import { Center, UseGetCentersVersions } from 'models/center'
import { GET_CENTERS_VERSIONS } from 'config/queries'

interface Args {
  id?: string
  limit: number
  offset: number
  sort: string
}

const useGetCentersVersions = ({ id, limit, offset, sort }: Args): UseGetCentersVersions => {
  const queryKey = [GET_CENTERS_VERSIONS, id, limit, offset, sort]
  const { data: response, isLoading } = useQuery({
    queryKey,
    queryFn: () => getCentersVersions({ id, limit, offset, sort }),
    refetchOnMount: true,
  })

  const result: Array<Center> = response?.data || []
  const count: number = parseInt(response?.headers?.['x-total-count'] || 0)

  return { response: result, count, isLoading }
}

export default useGetCentersVersions
