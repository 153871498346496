import Box from '@mui/material/Box'
import { useNavigate, useParams } from 'react-router-dom'

import CentersHistoricTable from 'components/organisms/centers/centers-historic-table'
import useGetCentersVersions from 'hooks/queries/centers/use-get-centers-versions'
import CancelButton from 'components/atoms/cancel-button'
import FormButtonsContainer from 'components/atoms/form-buttons-container'
import usePagination from 'hooks/use-pagination'
import useSort from 'hooks/use-sort'
import { useTabStore } from 'store/useTabStore'

const CentersHistoric = (): JSX.Element => {
  const { id } = useParams()
  const { pageSize, offset, page, setPageSize, setPage } = usePagination()
  const { sortModel, setSortModel } = useSort()
  const navigate = useNavigate()
  const { tabIndex, setTabIndex } = useTabStore(store => store)

  const { response, isLoading, count } = useGetCentersVersions({
    id,
    offset,
    limit: pageSize,
    sort: sortModel || '-id',
  })

  const handleGoBack = () => {
    if (tabIndex === 0) return navigate(-1)
    setTabIndex(prevIndex => Math.max(prevIndex - 1, 0))
  }

  return (
    <Box>
      <CentersHistoricTable
        id={id}
        rows={response}
        count={count}
        page={page}
        pageSize={pageSize}
        isLoading={isLoading}
        onSortModelChange={setSortModel}
        onPageSizeChange={setPageSize}
        onPageChange={setPage}
      />
      <FormButtonsContainer>
        <CancelButton onClick={handleGoBack} />
      </FormButtonsContainer>
    </Box>
  )
}

export default CentersHistoric
