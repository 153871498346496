import { useQuery } from '@tanstack/react-query'

import { GET_ONE_CENTER_PERIOD_VERSION } from 'config/queries'
import { UseGetOneCenterPeriodVersion } from 'models/center-period'
import { getOnePeriodVersion } from 'services/periods'

interface Args {
  id: string
  version: string
}

const useGetOneCenterPeriodVersion = ({ id, version }: Args): UseGetOneCenterPeriodVersion => {
  const queryKey = [GET_ONE_CENTER_PERIOD_VERSION, id, version]
  const { data: response, isLoading } = useQuery({
    queryKey,
    queryFn: () => getOnePeriodVersion({ id, version }),
    refetchOnMount: true,
    retry: 1,
  })

  const result = response?.data

  return { response: result, isLoading }
}

export default useGetOneCenterPeriodVersion
