import { useState } from 'react'

import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import CancelButton from 'components/atoms/cancel-button'
import FormButtonsContainer from 'components/atoms/form-buttons-container'
import PageTitle from 'components/atoms/page-title'
import HistoricTable, { TableProps } from 'components/organisms/historic-table'
import routes from 'config/routes'
import useGetUserRelationVersions from 'hooks/queries/relations/use-get-user-relation-versions'

const RelationsUsersHistoric = (): JSX.Element => {
  const { id = '' } = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const [tableProps, setTableProps] = useState<TableProps>({} as TableProps)

  const { response, isLoading, count } = useGetUserRelationVersions({ id, ...tableProps })

  const navigateToShow = (version: number) => {
    const route = routes.relationsUsersHistoricShow.replace(':id', id).replace(':version', version.toString())
    navigate(route)
  }

  const backRoute = routes.relationsList

  return (
    <Box>
      <PageTitle title={t('Histórico de relaciones centros - usuarios')} />
      <HistoricTable
        id={id}
        rows={response}
        count={count}
        isLoading={isLoading}
        onTablePropsChange={setTableProps}
        onNavigateToShow={navigateToShow}
      />
      <FormButtonsContainer>
        <CancelButton to={backRoute} />
      </FormButtonsContainer>
    </Box>
  )
}

export default RelationsUsersHistoric
