import React, { useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Grid } from '@mui/material'

import BasicButton from 'components/atoms/basic-button'
import ControllerInputText from 'components/molecules/controller-input-text'
import ControllerInputDateTimePicker from 'components/molecules/controller-input-datetimepicker'
import ControllerSwitch from 'components/molecules/controller-switch'
import { DateFormats } from 'config/constants'
import {
  applicationFilterDefaultValues,
  ApplicationsFilterFormFields,
  ApplicationsFilterFormSchema,
  applicationsFilterSchema,
} from 'validations/applications'
import { ClearFiltersButton } from 'components/atoms/clear-filter-button'

import { FilterGridContainer } from './styled'

interface Props {
  onSearch: (search: ApplicationsFilterFormFields) => void
}

type AllowedKeys = 'name' | 'from' | 'to' | 'showActiveOnly'
const allowedKeys: AllowedKeys[] = ['name', 'from', 'to', 'showActiveOnly']

const ApplicationsFilter: React.FC<Props> = ({ onSearch }) => {
  const { t } = useTranslation()

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm<ApplicationsFilterFormSchema>({
    defaultValues: applicationFilterDefaultValues,
    resolver: yupResolver(applicationsFilterSchema),
  })

  const onSubmit = (payload: ApplicationsFilterFormFields) => {
    sessionStorage.setItem('applicationsFilter', JSON.stringify(payload))
    onSearch(payload)
  }

  const handleReset = () => {
    sessionStorage.removeItem('applicationsFilter')
    onSearch(applicationFilterDefaultValues)
    reset(applicationFilterDefaultValues)
  }

  useEffect(() => {
    const storedData = sessionStorage.getItem('applicationsFilter')
    if (storedData) {
      const parsedStoredData = JSON.parse(storedData)
      for (const key in parsedStoredData) {
        if (allowedKeys.includes(key as AllowedKeys)) {
          setValue(key as AllowedKeys, parsedStoredData[key])
        }
      }
      onSearch(parsedStoredData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <FilterGridContainer container pr={1}>
      <Grid item md={4} pr={1}>
        <ControllerInputText control={control} name='name' error={errors.name} label={t('Nombre')} size='full' />
      </Grid>
      <Grid item md={4} pr={1}>
        <ControllerInputDateTimePicker
          control={control}
          name='from'
          error={errors.from}
          label={t('Desde')}
          size='full'
          isUTC={true}
          pattern={DateFormats.isoZ}
        />
      </Grid>
      <Grid item md={4} pr={1}>
        <ControllerInputDateTimePicker
          control={control}
          name='to'
          error={errors.to}
          label={t('Hasta')}
          size='full'
          isUTC={true}
          pattern={DateFormats.isoZ}
        />
      </Grid>
      <Grid item md={12} display='flex' justifyContent='flex-end' flexDirection='row' gap={3}>
        <ClearFiltersButton handleClearFilters={handleReset} />
        <ControllerSwitch label={t('Solo activos')} name='showActiveOnly' variant='full' control={control} autoHeight />
        <BasicButton handleClick={handleSubmit(onSubmit)} text={t('Buscar')} size='small' />
      </Grid>
    </FilterGridContainer>
  )
}

export default ApplicationsFilter
