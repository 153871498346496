import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'

import { colors } from 'config/theme'

export const SimulatorSearcherFormContainer = styled(Grid)`
  box-sizing: border-box;
  flex-flow: wrap;
  width: 100%;
  display: flex;
  border-radius: 10px;
  padding: 1rem;
  margin: 2rem 0px 3rem;
  background-color: ${colors.white};
  box-shadow:
    rgba(0, 0, 0, 0.2) 0px 2px 1px -1px,
    rgba(0, 0, 0, 0.14) 0px 1px 1px 0px,
    rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;

  .MuiGrid-item .MuiBox-root {
    height: auto;
  }

  .MuiFormHelperText-root.Mui-error {
    margin: 4px -14px 0px -6px;
    line-height: 1;
  }
`
