import { FC } from 'react'

import BasicButton from '../basic-button'

import * as S from './styled'

interface FloatingSubmitButtonProps {
  onClick?: () => void
  color?: 'primary' | 'secondary'
  size?: 'small' | 'medium' | 'large'
  variant?: 'text' | 'outlined' | 'contained'
  text: string
}

const FloatingSubmitButton: FC<FloatingSubmitButtonProps> = ({
  onClick,
  color = 'primary',
  size = 'small',
  variant = 'contained',
  text,
}) => (
  <S.FloatingSubmitButton>
    <BasicButton handleClick={onClick} variant={variant} color={color} size={size} text={text} type='submit' />
  </S.FloatingSubmitButton>
)

export default FloatingSubmitButton
