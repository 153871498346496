import { t } from 'i18next'

import { ACTION_OPTIONS, RESOURCE_TYPE_OPTIONS } from 'config/constants'

export const eventsActionFormatter = (key: string) => {
  const result = ACTION_OPTIONS.find(k => k.value === key)
  return t(result?.text ?? '')
}

export const eventsTypeFormatter = (key: string) => {
  const result = RESOURCE_TYPE_OPTIONS.find(k => k.value === key)
  return t(result?.text ?? key)
}
