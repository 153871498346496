import styled from 'styled-components'

import { colors } from 'config/theme'

export const Link = styled.a`
  color: ${colors.orange};

  &:hover {
    color: ${colors.lightOrange};
  }
`
