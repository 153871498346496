import { FC, useCallback, useRef, useState } from 'react'

import AddIcon from '@mui/icons-material/Add'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'

import BasicButton from 'components/atoms/basic-button'
import BasicInputSwitch from 'components/atoms/basic-input-switch'
import H2 from 'components/atoms/h2'
import NoRows from 'components/atoms/no-rows'
import SpinnerLoading from 'components/atoms/spinner-loading'
import PeriodAccordion from 'components/organisms/period-accordion'
import { DateFormats } from 'config/constants'
import useCreatePeriod from 'hooks/queries/periods/use-create-period'
import useDeleteOnePeriod from 'hooks/queries/periods/use-delete-one-period'
import useGetPeriods from 'hooks/queries/periods/use-get-periods'
import useUpdatePeriod from 'hooks/queries/periods/use-update-period'
import { Center, CenterPricingType, CenterType } from 'models/center'
import { CenterPeriod, CreatePeriod, PeriodPriority, PeriodWeekday } from 'models/center-period'
import { zeroTime } from 'utils/date-formatter'
import { SwitchControllerField } from 'models/form'
import { useAuth } from 'providers/auth'
import { useTabStore } from 'store/useTabStore'
import { Roles } from 'models/auth.d'
import CancelButton from 'components/atoms/cancel-button'

import * as S from './styled'

interface PeriodSettingsProps {
  center: Center
  readonly?: boolean
  handleTabChange?: (event: React.SyntheticEvent, newValue: number) => void
}

const getPeriod = (centerId: number): CenterPeriod => ({
  centerId,
  id: Math.random(),
  from: zeroTime(dayjs()).format(DateFormats.isoZ),
  to: zeroTime(dayjs().add(1, 'day')).format(DateFormats.isoZ),
  priority: PeriodPriority.MEDIUM,
  minDays: 1,
  maxDays: 31,
  weekdays: Object.values(PeriodWeekday),
  prices: [0],
})

const PeriodSettings: FC<PeriodSettingsProps> = ({ center, readonly, handleTabChange }) => {
  const { t } = useTranslation()
  const { user } = useAuth()

  const [filterOnlyActive, setFilterOnlyActive] = useState(false)
  const [isNewPeriod, setIsNewPeriod] = useState<CenterPeriod['id'][]>([])

  const setTabIndex = useTabStore(store => store.setTabIndex)
  const tabIndex = useTabStore(store => store.tabIndex)

  const isCercaPeriod =
    (user?.role === Roles.CERCA || user?.role === Roles['ADMIN-CERCA']) &&
    (center.pricingType === CenterPricingType.PERIOD || center.centerType === CenterType.AGGREGATION)

  const {
    response: periods,
    queryKey,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useGetPeriods(center.id, { sort: '-id', onlyUnactivePeriods: filterOnlyActive })
  const { mutate: createPeriod, isLoading: isCreating } = useCreatePeriod(center.id, queryKey)
  const { mutate: updatePeriod } = useUpdatePeriod(center.id, queryKey)
  const { mutate: deletePeriod } = useDeleteOnePeriod(center.id, queryKey)

  const [newPeriods, setNewPeriods] = useState<CenterPeriod[]>([])
  const [expandedPeriod, setExpandedPeriod] = useState<number>()

  const [sortField, setSortField] = useState<keyof CenterPeriod>('from')
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc')

  const observer = useRef<IntersectionObserver>()

  const intersectElementRef = useCallback(
    (node: HTMLDivElement) => {
      if (!periods?.length || isFetchingNextPage) {
        return
      }

      if (observer.current) {
        observer.current.disconnect()
      }

      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && hasNextPage) {
          fetchNextPage()
        }
      })

      if (node) {
        observer.current.observe(node)
      }
    },
    [periods, isFetchingNextPage, hasNextPage, fetchNextPage]
  )

  const handleChange = useCallback(
    (periodId: number) => setExpandedPeriod(prev => (prev === periodId ? undefined : periodId)),
    []
  )

  const handleGoBack = () => {
    if (handleTabChange) {
      handleTabChange({} as React.SyntheticEvent, tabIndex - 1)
      return
    }
    setTabIndex((prevIndex) => Math.max(prevIndex - 1, 0))
  }

  const handleCreate = useCallback(() => {
    const newPeriod = getPeriod(center.id)
    setIsNewPeriod(prev => [...prev, newPeriod.id])
    if (isCercaPeriod) {
      newPeriod.minDays = 0
      newPeriod.maxDays = 0
    }
    setNewPeriods(prev => [newPeriod, ...prev])
    setExpandedPeriod(newPeriod.id)
  }, [center, isCercaPeriod])

  const handleSave = useCallback(
    (periodId: number, newPeriodValues: Partial<CenterPeriod>) => {
      const { id, ...newPeriod } = newPeriods.find(nP => nP.id === periodId) ?? {}
      if (Object.keys(newPeriod).length) {
        const periodDTO = { ...newPeriod, ...newPeriodValues } as CreatePeriod

        createPeriod(periodDTO, {
          onSuccess: data => {
            setExpandedPeriod(data.data.id)
            setNewPeriods(prev => prev.filter(nP => nP.id !== periodId))
          },
        })
      } else {
        updatePeriod({ id: periodId, ...newPeriodValues })
      }
      setIsNewPeriod(prev => prev.filter(prevId => prevId !== periodId))
    },
    [newPeriods, createPeriod, updatePeriod]
  )

  const handleDelete = useCallback(
    (periodId: number) => {
      const newPeriod = newPeriods.find(nP => nP.id === periodId)
      if (newPeriod) {
        setNewPeriods(prev => prev.filter(nP => nP !== newPeriod))
      } else {
        deletePeriod(periodId)
      }
    },
    [newPeriods, deletePeriod]
  )

  const sortPeriods = (data: CenterPeriod[]) => {
    return data.sort((a, b) => {
      if (sortField === 'priority') {
        return sortOrder === 'asc' ? Number(b.priority) - Number(a.priority) : Number(a.priority) - Number(b.priority)
      }

      if (sortField === 'from' || sortField === 'to') {
        return sortOrder === 'asc'
          ? dayjs(a[sortField] as string).diff(dayjs(b[sortField] as string))
          : dayjs(b[sortField] as string).diff(dayjs(a[sortField] as string))
      }

      return 0
    })
  }

  const handleSortChange = (field: keyof CenterPeriod) => {
    if (sortField === field) {
      setSortOrder(prev => (prev === 'asc' ? 'desc' : 'asc'))
    } else {
      setSortField(field)
      setSortOrder('asc')
    }
  }

  // Helper component to render the sortable header
  const SortableHeader: FC<{ field: keyof CenterPeriod; label: string }> = ({ field, label }) => {
    return (
      <th onClick={() => handleSortChange(field)} style={{ cursor: 'pointer' }}>
        {label} {sortField === field && (sortOrder === 'asc' ? '▲' : '▼')}
      </th>
    )
  }

  return (
    <S.PeriodSettings data-testid='period-settings'>
      <S.Header>
        <H2 text={t`Disponibilidad de productos`} />

        {!readonly && (
          <BasicButton disabled={isCreating} loading={isCreating} handleClick={handleCreate}>
            <AddIcon />
            {t`Nuevo periodo`}
          </BasicButton>
        )}
      </S.Header>

      <S.SubHeader>
        <BasicInputSwitch
          field={
            {
              value: filterOnlyActive,
              onChange: evt => setFilterOnlyActive(evt.target.checked),
            } as SwitchControllerField
          }
          className='periods-filter-switch'
          boxDirection='row'
          variant='full'
          label={t`Mostrar periodos inactivos`}
        />
        <S.PeriodsSortTable>
          <thead>
            <tr>
              <SortableHeader field='from' label={t`Fecha de Inicio`} />
              <SortableHeader field='to' label={t`Fecha de Fin`} />
              <SortableHeader field='priority' label={t`Prioridad`} />
            </tr>
          </thead>
        </S.PeriodsSortTable>
      </S.SubHeader>

      {isLoading ? (
        <SpinnerLoading fullHeight />
      ) : (
        <>
          {periods.length === 0 && newPeriods.length === 0 ? (
            <NoRows />
          ) : (
            sortPeriods([...newPeriods, ...periods]).map((period, idx, arr) => (
              <div key={period.id} ref={arr.length === idx + 1 ? intersectElementRef : null}>
                <PeriodAccordion
                  key={period.id}
                  expanded={expandedPeriod === period.id}
                  period={period}
                  isPeriodicPrice={center.pricingType === CenterPricingType.PERIOD}
                  centerType={center.centerType}
                  isNewPeriod={isNewPeriod.includes(period.id)}
                  readonly={readonly}
                  onChange={handleChange}
                  onDelete={handleDelete}
                  onSave={handleSave}
                />
              </div>
            ))
          )}
        </>
      )}
      {isFetchingNextPage && <SpinnerLoading />}

      <Box display='flex' alignItems='center' justifyContent='center' columnGap='16px' paddingTop={5}>
        <CancelButton onClick={handleGoBack} />
      </Box>
    </S.PeriodSettings>
  )
}

export default PeriodSettings
