import React from 'react'

import { Control, Controller, FieldError } from 'react-hook-form'

import BasicInputSwitch from 'components/atoms/basic-input-switch'
import { Sizes } from 'models/sizes'

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>
  error?: FieldError
  disabled?: boolean
  name: string
  label: string
  variant: Sizes
  autoHeight?: boolean
}

const ControllerSwitch: React.FC<Props> = ({ control, disabled, name, label, variant, autoHeight }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <BasicInputSwitch
          boxDirection='row'
          field={field}
          label={label}
          variant={variant}
          disabled={disabled}
          autoHeight={autoHeight}
        />
      )}
    />
  )
}

export default ControllerSwitch
