import { useQuery } from '@tanstack/react-query'

import { getProfilesVersions } from 'services/profiles'
import { Profile, UseGetProfilesVersions } from 'models/profiles'
import { GET_PROFILES_VERSIONS } from 'config/queries'

interface Args {
  id?: string
  limit: number
  offset: number
  sort: string
}

const useGetProfilesVersions = ({ id, limit, offset, sort }: Args): UseGetProfilesVersions => {
  const queryKey = [GET_PROFILES_VERSIONS, id, limit, offset, sort]
  const { data: response, isLoading } = useQuery({
    queryKey,
    queryFn: () => getProfilesVersions({ id, limit, offset, sort }),
  })

  const result: Array<Profile> = response?.data || []
  const count: number = parseInt(response?.headers?.['x-total-count'] || 0)

  return { response: result, count, isLoading }
}

export default useGetProfilesVersions
