import { create } from 'zustand'

interface TabState {
  tabIndex: number
  setTabIndex: (index: number | ((prevIndex: number) => number)) => void
  resetTabIndex: () => void
  noReset: boolean
  setNoReset: (noReset: boolean) => void
}

export const useTabStore = create<TabState>(set => ({
  tabIndex: 0,
  setTabIndex: index =>
    set(state => ({
      tabIndex: typeof index === 'function' ? index(state.tabIndex) : index,
    })),
  resetTabIndex: () => set({ tabIndex: 0 }),
  noReset: false,
  setNoReset: (noReset: boolean) => set({ noReset }),
}))
