import { useEffect } from 'react'

import { FieldErrors } from 'react-hook-form'

const notEmptyFilter = <T>(value: T | null | undefined): value is T => value !== null && value !== undefined

const useFormErrorScroll = (errors: FieldErrors): void => {
  useEffect(() => {
    const elements = Object.entries(errors)
      .map(([key, value]) => {
        let elementName = key
        if (Array.isArray(value) && value.length) {
          elementName = `${key}[${Object.keys(value)[0]}]`
        } else if (value && !Object.hasOwn(value, 'ref')) {
          elementName = `${key}.${Object.keys(value)[0]}`
        }

        return document.querySelector<HTMLElement>(`[name^="${elementName}"]`)
      })
      .filter(notEmptyFilter)
      .sort((a, b) => a.getBoundingClientRect().top - b.getBoundingClientRect().top)

    if (elements.length) {
      const errorElement = elements[0]
      errorElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
      errorElement.focus({ preventScroll: true })
    }
  }, [errors])
}

export default useFormErrorScroll
