import React, { useEffect, useState, useCallback } from 'react'

import { useNavigate } from 'react-router-dom'

import HistoricGridActionCellItem from 'components/atoms/historic-grid-action-cell-item'
import { CalendarDay, Center } from 'models/center'
import { DailyOccupation } from 'models/occupation'
import routes from 'config/routes'

import CalendarTileInfo from './calendar-tile-info'
import CalendarTileEdit from './calendar-tile-edit'
import { CalendarDayContainer } from './styled'

interface Props {
  isEditMode: boolean
  calendar?: CalendarDay
  occupation?: DailyOccupation
  onCancel: () => void
  onSubmit: (price: number) => void
  weekend?: boolean
  noProducts?: boolean
  limitReached?: boolean
  aboveAverage?: boolean
  disabled?: boolean
  center?: Center
}

const CalendarTile: React.FC<Props> = ({
  calendar,
  occupation,
  isEditMode,
  onCancel,
  onSubmit,
  weekend,
  noProducts,
  limitReached,
  aboveAverage,
  disabled,
  center,
}) => {
  const [price, setPrice] = useState<string>(calendar?.price?.toString() ?? '')
  const navigate = useNavigate()

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    e.stopPropagation()
    onCancel()
  }

  const handleAccept = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    e.stopPropagation()
    const numericPrice = parseFloat(price ?? '')
    onSubmit(isNaN(numericPrice) ? 0 : numericPrice)
  }

  useEffect(() => {
    setPrice(calendar?.price?.toString() ?? '')
  }, [calendar?.price])

  const navigateToHistoric = useCallback(() => {
    if (center?.id && calendar?.id) {
      navigate(
        routes.centersDaysHistoric.replace(':centerId', center.id.toString()).replace(':id', calendar.id.toString())
      )
    }
  }, [navigate, center?.id, calendar?.id])

  return (
    <CalendarDayContainer
      disabled={disabled}
      weekend={weekend}
      noProducts={noProducts}
      limitReached={limitReached}
      aboveAverage={aboveAverage}
    >
      {calendar?.id && <HistoricGridActionCellItem onClick={navigateToHistoric} />}
      {isEditMode ? (
        <CalendarTileEdit
          value={price}
          onChange={(e, value) => setPrice(value)}
          onAccept={handleAccept}
          onCancel={handleCancel}
        />
      ) : (
        <CalendarTileInfo calendar={calendar} occupation={occupation} center={center} />
      )}
    </CalendarDayContainer>
  )
}

export default CalendarTile
