import React from 'react'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { UseFormGetValues } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { colors } from 'config/theme'
import { UsersRelationFormFields } from 'validations/users-relations'

interface Props {
  getValues: UseFormGetValues<UsersRelationFormFields>
  count?: number
}

const RelationFormResume: React.FC<Props> = ({ getValues, count }) => {
  const { t } = useTranslation()
  const name = getValues('name')
  const managedCenters = getValues('managedCenters')
  const managedUsers = getValues('managedUsers')
  const allCenters = getValues('allCenters')

  return (
    <Grid container sx={{ width: '60%' }}>
      <Grid item xs={6}>
        <Typography color={colors.orange}>{t('NOMBRE')}</Typography>
        <Typography color={colors.orange}>{t('Nº CENTROS A ASOCIAR')}</Typography>
        <Typography color={colors.orange}>{t('Nº USUARIOS A ASOCIAR')}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography noWrap>{name || '-'}</Typography>
        <Typography noWrap>{allCenters ? count || '-' : managedCenters?.length || '-'}</Typography>
        <Typography noWrap>{managedUsers?.length || '-'}</Typography>
      </Grid>
    </Grid>
  )
}

export default RelationFormResume
