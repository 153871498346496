import { create } from 'zustand'

interface FormState {
  isDirty: boolean
  setDirty: (isDirty: boolean) => void
  resetForm: () => void
}

export const useFormStore = create<FormState>(set => ({
  isDirty: false,
  setDirty: isDirty => set({ isDirty }),
  resetForm: () => set({ isDirty: false }),
}))
