import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { GET_CENTERS } from 'config/queries'
import { updateMFLCenters } from 'services/centers'

const useUpdateMFL = () => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  return useMutation({
    mutationFn: updateMFLCenters,
    onSuccess: ({ data }: AxiosResponse<[]>) => {
      const successText = data?.length
        ? t('Se han añadido {{centers}} centros nuevos', { centers: data.length })
        : t`No se han añadido nuevos centros`
      toast(successText, { type: 'success' })
    },
    onError: (error: AxiosError<Record<string, string>>) => {
      if (error.response?.data?.description?.includes('Error calling mfl api')) {
        toast(t('Error al obtener los centros desde MFL'), { type: 'error' })
      } else {
        toast(t('No se han podido actualizar los centros'), { type: 'error' })
      }
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: [GET_CENTERS] }),
  })
}

export default useUpdateMFL
