import React from 'react'

import { GridEnrichedColDef } from '@mui/x-data-grid'
import { useTranslation } from 'react-i18next'

import TextCell from 'components/atoms/text-cell'
import BasicTable from 'components/molecules/basic-table'
import { DateFormats, RowHeight, TableColumnWidth, TableTypes } from 'config/constants'
import { dateFormatter } from 'utils/date-formatter'
import { ImportJob } from 'models/importjobs'

import * as S from './styled'

interface Props {
  type?: TableTypes
  rows: ImportJob[]
  count: number
  page: number
  pageSize: number
  isLoading: boolean
  onPageChange: (page: number) => void
  onPageSizeChange: (pageSize: number) => void
  onSortModelChange: () => void
}

const ImportJobsTable: React.FC<Props> = ({ type = TableTypes.default, ...props }) => {
  const { t } = useTranslation()

  const columns: GridEnrichedColDef[] = [
    {
      field: 'id',
      headerName: '',
      flex: TableColumnWidth.xs,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'jobUUID',
      headerName: `${t('jobUUID')}`,
      flex: TableColumnWidth.small,
      headerAlign: 'center',
      align: 'center',
      renderCell: ({ row }: { row: ImportJob }) => <TextCell text={row.jobUUID} />,
    },
    {
      field: 'type',
      headerName: `${t('tipo')}`,
      flex: TableColumnWidth.large,
      renderCell: ({ row }: { row: ImportJob }) => <TextCell text={row.type} />,
    },
    {
      field: 'username',
      headerName: `${t('usuario')}`,
      flex: TableColumnWidth.large,
      renderCell: ({ row }: { row: ImportJob }) => <TextCell text={row.username} />,
    },
    {
      field: 'uploadedFileUrl',
      headerName: `${t('Fichero')}`,
      flex: TableColumnWidth.base,
      renderCell: ({ row }: { row: ImportJob }) => (
        <S.Link target='_blank' href={row.uploadedFileUrl} rel='noreferrer'>
          Fichero
        </S.Link>
      ),
    },
    {
      field: 'backupUrl',
      headerName: `${t('backup')}`,
      flex: TableColumnWidth.base,
      renderCell: ({ row }: { row: ImportJob }) => (
        <S.Link target='_blank' href={row.backupUrl} rel='noreferrer'>
          Backup
        </S.Link>
      ),
    },
    {
      field: 'status',
      headerName: `${t('status')}`,
      flex: TableColumnWidth.base,
      renderCell: ({ row }: { row: ImportJob }) => <TextCell text={row.status} />,
    },
    {
      field: 'description',
      headerName: `${t('description')}`,
      flex: TableColumnWidth.base,
      renderCell: ({ row }: { row: ImportJob }) => <TextCell text={row.description} />,
    },
    {
      field: 'createDate',
      headerName: `${t('creado')}`,
      flex: TableColumnWidth.base,
      renderCell: ({ row }: { row: ImportJob }) => (
        <TextCell text={dateFormatter({ date: row.updateDate, pattern: DateFormats.full })} />
      ),
    },
  ]

  return (
    <BasicTable
      {...props}
      dataTestId='import-jobs-table'
      type={type}
      rowHeight={RowHeight.large}
      columns={columns}
      onRowClick={() => {
        return
      }}
    />
  )
}

export default ImportJobsTable
