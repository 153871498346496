import React from 'react'

import Typography from '@mui/material/Typography'

import { FontSizes } from 'config/constants'

interface Props {
  text: string
  className?: string
}

const H2: React.FC<Props> = ({ text, className }) => (
  <Typography
    variant='h2'
    fontSize={FontSizes.xl}
    fontWeight={400}
    textAlign='center'
    marginBottom={4}
    className={className}
  >
    {text}
  </Typography>
)

export default H2
