import { yupResolver } from '@hookform/resolvers/yup'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import Grid from '@mui/material/Grid'
import { useTranslation } from 'react-i18next'

import useFormErrorScroll from 'hooks/use-form-error-scroll'
import FormButtonsContainer from 'components/atoms/form-buttons-container'
import PapperGridContainer from 'components/atoms/papper-grid-container-form'
import GridItem from 'components/atoms/grid-item'
import routes from 'config/routes'
import { userSchema, UserFormData, UserFormFields } from 'validations/user'
import CancelButton from 'components/atoms/cancel-button'
import SubmitButton from 'components/atoms/submit-button'
import ControllerInputText from 'components/molecules/controller-input-text'
import ControllerInputSelect from 'components/molecules/controller-input-select'
import { USER_ROLES } from 'config/constants'
import ControllerSwitch from 'components/molecules/controller-switch'
import useCreateUser from 'hooks/queries/user/use-create-user'

const defaultValues: UserFormFields = {
  name: '',
  email: '',
  role: '',
  active: true,
}

const UsersCreateForm = (): JSX.Element => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<UserFormData>({
    defaultValues,
    resolver: yupResolver(userSchema),
  })

  useFormErrorScroll(errors)

  const updateUser = useCreateUser()

  const onSubmit = ({ name, email, role, active }: UserFormFields) => {
    updateUser.mutate({ name, email, role, active })
    navigate(routes.usersList)
  }

  return (
    <Grid container component='form' onSubmit={handleSubmit(onSubmit)} data-testid='users-create-form'>
      <PapperGridContainer>
        <GridItem item md={6} xs={12}>
          <ControllerInputText
            mandatory
            control={control}
            name='name'
            error={errors.name}
            label={t('Nombre')}
            size='large'
          />
          <ControllerInputText
            mandatory
            control={control}
            name='email'
            error={errors.email}
            label={t('Email')}
            size='large'
          />
          <ControllerInputSelect
            mandatory
            control={control}
            name='role'
            error={errors.role}
            label={t('Rol')}
            size='large'
            options={USER_ROLES}
          />
        </GridItem>
        <GridItem item md={6} xs={12}>
          <ControllerSwitch label={t('Activo')} name='active' variant='full' control={control} />
        </GridItem>
      </PapperGridContainer>
      <FormButtonsContainer>
        <CancelButton to={routes.usersList} />
        <SubmitButton />
      </FormButtonsContainer>
    </Grid>
  )
}

export default UsersCreateForm
