import { FC, ReactNode } from 'react'

import CheckIcon from '@mui/icons-material/Check'
import Grid from '@mui/material/Grid'

import * as S from './styled'

interface FormGridRowProps {
  children: [ReactNode, ReactNode]
  className?: string
}

const FormGridRow: FC<FormGridRowProps> = ({ children, className }) => (
  <>
    <Grid item xs={3} className={className}>
      {children[0]}
    </Grid>
    <S.TextGridItem item xs={9}>
      <CheckIcon fontSize='small' />
      <span>{children[1]}</span>
    </S.TextGridItem>
  </>
)

export default FormGridRow
