import React, { useCallback } from 'react'

import { GridEnrichedColDef } from '@mui/x-data-grid'
import { QueryKey } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import EditGridActionCellItem from 'components/atoms/edit-grid-action-cell-item'
import HistoricGridActionCellItem from 'components/atoms/historic-grid-action-cell-item'
import ShowGridActionCellItem from 'components/atoms/show-grid-action-cell-item'
import TextCell from 'components/atoms/text-cell'
import BasicTable from 'components/molecules/basic-table'
import { DateFormats, RowHeight, TableColumnWidth, TableTypes } from 'config/constants'
import routes from 'config/routes'
import { dateFormatter } from 'utils/date-formatter'
import { RoleRelationDTO } from 'models/relation'
import useAdministrationPermissions, { AdministrationSections } from 'hooks/permissions/use-administration-permissions'

interface Props {
  type?: TableTypes
  rows: RoleRelationDTO[]
  count: number
  page: number
  pageSize: number
  isLoading: boolean
  onSortModelChange: (sortString: string) => void
  onPageChange: (page: number) => void
  onPageSizeChange: (pageSize: number) => void
  queryKey: QueryKey
}

const RolesRelationsTable: React.FC<Props> = ({ queryKey, type = TableTypes.default, ...props }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { canWrite } = useAdministrationPermissions(AdministrationSections.relations)

  const navigateToEdit = useCallback(
    (id: number) => navigate(routes.relationsRolesEdit.replace(':id', id.toString())),
    [navigate]
  )

  const navigateToShow = useCallback(
    (id: number) => type === TableTypes.default && navigate(routes.relationsRolesShow.replace(':id', id.toString())),
    [navigate, type]
  )

  const navigateToHistoric = useCallback(
    (id: number) =>
      type === TableTypes.default && navigate(routes.relationsRolesHistoric.replace(':id', id.toString())),
    [navigate, type]
  )

  const getTableActions = useCallback(
    ({ row }: { row: RoleRelationDTO }) => {
      const actions = [
        <ShowGridActionCellItem key={`${row.id}-show`} onClick={() => navigateToShow(row.id)} />,
        <HistoricGridActionCellItem key={`${row.id}-historic`} onClick={() => navigateToHistoric(row.id)} />,
      ]

      if (canWrite) {
        actions.splice(-1, 0, <EditGridActionCellItem key={`${row.id}-edit`} onClick={() => navigateToEdit(row.id)} />)
      }

      return actions
    },
    [canWrite, navigateToEdit, navigateToShow, navigateToHistoric]
  )

  const columns: GridEnrichedColDef[] = [
    {
      field: 'id',
      headerName: '',
      flex: TableColumnWidth.xs,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'carparkCode',
      headerName: `${t('Código')}`,
      flex: TableColumnWidth.small,
      headerAlign: 'center',
      align: 'center',
      renderCell: ({ row }: { row: RoleRelationDTO }) => <TextCell text={row.centers[0].carparkCode} />,
    },
    {
      field: 'name',
      headerName: `${t('Nombre')}`,
      flex: TableColumnWidth.large,
      renderCell: ({ row }: { row: RoleRelationDTO }) => <TextCell text={row.centers[0].name} />,
    },
    {
      field: 'role',
      headerName: `${t('Rol')}`,
      flex: TableColumnWidth.small,
      renderCell: ({ row }: { row: RoleRelationDTO }) => <TextCell text={row.role} />,
    },
    {
      field: 'updateDate',
      headerName: `${t('Modificado')}`,
      flex: TableColumnWidth.small,
      renderCell: ({ row }: { row: RoleRelationDTO }) => (
        <TextCell text={dateFormatter({ date: row.updateDate, pattern: DateFormats.day })} />
      ),
    },
    {
      field: 'actions',
      type: 'actions',
      flex: TableColumnWidth.large,
      sortable: false,
      getActions: getTableActions,
    },
  ]

  return (
    <BasicTable
      {...props}
      dataTestId='roles-centers-relations-table'
      type={type}
      rowHeight={RowHeight.large}
      columns={columns}
      onRowClick={({ row }) => (canWrite ? navigateToEdit(row.id) : navigateToShow(row.id))}
    />
  )
}

export default RolesRelationsTable
