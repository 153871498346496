import PageContainer from 'components/atoms/page-container'
import ProfilesEditForm from 'components/organisms/profiles/profiles-edit-form'

const ProfilesEdit = (): JSX.Element => (
  <PageContainer>
    <ProfilesEditForm />
  </PageContainer>
)

export default ProfilesEdit
