import * as yup from 'yup'

import { Center } from 'models/center'

export const alertsFilterSchema = yup.object({
  carparkCode: yup.string(),
  severity: yup.string(),
  code: yup.string(),
})

export interface AlertsFilterForm {
  carparkCode?: string
  severity: string
  code: string
  carpark?: Center
}

export const alertsFilterDefaultValues: AlertsFilterForm = {
  carparkCode: undefined,
  severity: '',
  code: '',
}
