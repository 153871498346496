import { FC, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react'

import BarChartIcon from '@mui/icons-material/BarChart'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import DomainIcon from '@mui/icons-material/Domain'
import EditIcon from '@mui/icons-material/Edit'
import GarageIcon from '@mui/icons-material/Garage'
import InfoIcon from '@mui/icons-material/InfoOutlined'
import RestoreIcon from '@mui/icons-material/Restore'
import ScheduleIcon from '@mui/icons-material/Schedule'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Tabs from '@mui/material/Tabs'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import PageTitle from 'components/atoms/page-title'
import SpinnerLoading from 'components/atoms/spinner-loading'
import TabPanel from 'components/atoms/tab-panel'
import { CentersFormTab } from 'config/centers-constants'
import useCentersPermissions from 'hooks/permissions/use-centers-permissions'
import useUpdateCenter from 'hooks/queries/centers/use-update-center'
import { Center } from 'models/center'
import CarparkOccupation from 'components/organisms/carpark/carpark-occupation'
import CarparkCalendar from 'components/organisms/carpark/carpark-calendar'
import CentersHistoric from 'components/pages/centers/centers-historic'
import routes from 'config/routes'
import ModalConfirm from 'components/molecules/modal-confirm'
import { useFormStore } from 'store/useFormStore'
import { useTabStore } from 'store/useTabStore'

import * as S from './styled'
import GeneralSettings from './general-settings'
import StockSettings from './stock-settings'
import PeriodSettings from './period-settings'

interface CentersFormProps {
  center?: Center | null
  viewOnly?: boolean
  isLoading: boolean
  historic?: boolean
}

const CentersForm: FC<CentersFormProps> = ({ center, isLoading, viewOnly, historic }) => {
  const { t } = useTranslation()
  const { mutate: updateCenter } = useUpdateCenter()
  const navigate = useNavigate()
  const { state, pathname } = useLocation()
  const { isSectionWritable } = useCentersPermissions()

  const initialTabValue = state?.tabValue ?? 0
  window.history.replaceState({}, document.title)

  const [tempTabValue, setTempTabValue] = useState(initialTabValue)

  const isDirty = useFormStore(store => store.isDirty)
  const setDirty = useFormStore(store => store.setDirty)
  const { resetTabIndex, setTabIndex, tabIndex, noReset, setNoReset } = useTabStore(store => store)

  const [openModal, setOpenModal] = useState(false)

  useEffect(() => {
    if (noReset) return setNoReset(false)
    resetTabIndex()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  const TABS = useMemo(() => {
    let tabsToShow = []
    tabsToShow = [
      {
        index: CentersFormTab.generalSettings,
        label: t`Config general`,
        Icon: DomainIcon,
        Content: GeneralSettings,
        disabled: false,
        historic,
      },
      {
        index: CentersFormTab.stockSettings,
        label: t`Config stock`,
        Icon: GarageIcon,
        Content: StockSettings,
        disabled: false,
        historic,
      },
      {
        index: CentersFormTab.periodSettings,
        label: t`Config periodos`,
        Icon: ScheduleIcon,
        Content: PeriodSettings,
        disabled: false,
      },
      {
        index: CentersFormTab.occupation,
        label: t`Ocupación`,
        Icon: BarChartIcon,
        Content: CarparkOccupation,
        disabled: false,
      },
      {
        index: CentersFormTab.calendar,
        label: t`Calendario`,
        Icon: CalendarMonthIcon,
        Content: CarparkCalendar,
        disabled: false,
      },
      {
        index: CentersFormTab.historic,
        label: t`Histórico`,
        Icon: RestoreIcon,
        Content: CentersHistoric,
        disabled: false,
      },
    ]
    if (historic) tabsToShow = tabsToShow.filter(tab => tab.historic)
    return tabsToShow
  }, [t, historic])

  const handleOnTabChange = useCallback(
    (_: SyntheticEvent, tab: number) => {
      if (isDirty) {
        setOpenModal(true)
        setTempTabValue(tab)
        return
      }
      setTabIndex(tab)
    },
    [isDirty, setTabIndex]
  )

  const handleConfirm = useCallback(() => {
    setTabIndex(tempTabValue)
    setDirty(false)
    setOpenModal(false)
  }, [setDirty, tempTabValue, setTabIndex])

  const handleCancel = useCallback(() => {
    setOpenModal(false)
  }, [])

  const handleSubmit = useCallback(
    (newCenterValues: Partial<Center>) => {
      if (center?.id) {
        updateCenter({ id: center.id, ...newCenterValues })
      }
    },
    [center, updateCenter]
  )

  const renderTabs = useCallback(
    () =>
      TABS.map(({ label, Icon, disabled, index }) => (
        <S.Tab
          key={`${index}-center-tab`}
          label={label}
          icon={<Icon />}
          iconPosition='start'
          tabIndex={index}
          value={index}
          disabled={disabled}
        />
      )),
    [TABS]
  )

  const renderTabsContent = useCallback(
    () =>
      center &&
      TABS.map(({ index, Content }) => (
        <TabPanel key={`${index}-center-content`} value={tabIndex} index={index}>
          <Content
            center={center}
            readonly={!!viewOnly || !isSectionWritable(index)}
            onSubmit={handleSubmit}
            historic={historic}
            handleTabChange={handleOnTabChange}
          />
        </TabPanel>
      )),
    [TABS, tabIndex, center, viewOnly, handleSubmit, historic, isSectionWritable, handleOnTabChange]
  )

  const showEditButton = viewOnly && !historic && isSectionWritable(tabIndex)

  return (
    <>
      {center && (
        <S.Header editIconVisible={showEditButton}>
          <PageTitle title={`${t('centro')} ${center.name}`} />

          <S.Buttons>
            {showEditButton && (
              <IconButton onClick={() => navigate('edit', { state: { tabIndex } })}>
                <EditIcon color='primary' />
              </IconButton>
            )}
            <IconButton
              onClick={() =>
                navigate(routes.centersHistoric.replace(':id', (historic ? center.originalID : center.id).toString()))
              }
            >
              <InfoIcon color='primary' />
            </IconButton>
          </S.Buttons>
        </S.Header>
      )}

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }} data-testid='centers-form'>
        <Tabs value={tabIndex} onChange={handleOnTabChange} variant='fullWidth'>
          {renderTabs()}
        </Tabs>
      </Box>
      {isLoading ? <SpinnerLoading fullHeight alignItems='center' /> : renderTabsContent()}
      <ModalConfirm
        open={openModal}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        title={t('Cambios sin guardar')}
        message={t('¿Estás seguro de que quieres salir sin guardar los cambios?')}
        cancelLabel={t('Cancelar')}
        confirmLabel={t('Salir sin guardar')}
      />
    </>
  )
}

export default CentersForm
