import { useMemo } from 'react'

import { Roles } from 'models/auth.d'
import { useAuth } from 'providers/auth'

const READABLE_ROLES = [Roles['SUPER-ADMIN'], Roles.ADMINISTRADOR, Roles.CONSULTA]
const WRITABLE_ROLES = [Roles['SUPER-ADMIN'], Roles.ADMINISTRADOR]

const usePrebooksPermissions = () => {
  const { user } = useAuth()

  const canRead = useMemo(() => !!user?.role && READABLE_ROLES.includes(user.role), [user?.role])

  const canWrite = useMemo(() => !!user?.role && WRITABLE_ROLES.includes(user.role), [user?.role])

  return { canRead, canWrite }
}

export default usePrebooksPermissions
