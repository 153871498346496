import { Outlet } from 'react-router-dom'
import Grid from '@mui/material/Grid'

import AppBar from 'components/organisms/app-bar'
import Footer from 'components/organisms/footer'

const MainLayout = (): JSX.Element => (
  <Grid display={'flex'} flexDirection='column' minHeight={'100dvh'}>
    <AppBar />
    <Grid container flex={1}>
      <Grid item width='64em' margin='0 auto'>
        <Outlet />
      </Grid>
    </Grid>
    <Footer />
  </Grid>
)

export default MainLayout
