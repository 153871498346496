import styled from 'styled-components'
import Box from '@mui/material/Box'

import { colors } from 'config/theme'

export const AppBarLanguageList = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  z-index: 999;
  background-color: ${colors.whiteShadow};
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  z-index: 9;
`

export const AppBarLanguageContainer = styled(Box)`
  position: relative;
`

export const AppBarLanguageSelected = styled(Box)`
  width: 9em;
  height: 3em;
  padding-right: 1em;
  padding-left: 1em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5em;
  cursor: pointer;
`

export const AppBarLanguageItem = styled(Box)`
  width: 9em;
  background-color: ${colors.whiteShadow};
  height: 3em;
  padding-right: 1em;
  padding-left: 1em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5em;
  cursor: pointer;

  &:hover {
    background-color: ${colors.grey};
  }
`
