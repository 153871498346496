import styled from 'styled-components'
import { Grid } from '@mui/material'

import { colors } from 'config/theme'

export const FilterGridContainer = styled(Grid)`
  display: flex;
  border-radius: 10px;
  padding: 2rem;
  margin: 2rem 0 3rem 0;
  background-color: ${colors.white};
  box-shadow:
    0 2px 1px -1px rgb(0 0 0 / 20%),
    0 1px 1px 0 rgb(0 0 0 / 14%),
    0 1px 3px 0 rgb(0 0 0 / 12%);
`
