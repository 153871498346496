import { useEffect } from 'react'

import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Box, CircularProgress, Typography } from '@mui/material'
import * as Sentry from '@sentry/react'

import { useAuth } from 'providers/auth'
import routes from 'config/routes'

const CognitoRedirect = () => {
  const { signinWithCognito } = useAuth()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useTranslation()

  const from = location.state?.from?.pathname || routes.root
  const code: string | null = searchParams.get('code')

  useEffect(() => {
    const fetchCognitoToken = async () => {
      if (code != null) {
        try {
          await signinWithCognito(code.toString())
          navigate(from, { replace: true })
        } catch (error) {
          Sentry.captureException(error)
          navigate(routes.login)
        }
      }
    }
    fetchCognitoToken()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code])

  return (
    <Box textAlign='center' paddingTop='40vh'>
      <Typography fontSize='2em' marginBottom='1em'>
        {t('Obteniendo información...')}
      </Typography>
      <CircularProgress />
    </Box>
  )
}

export default CognitoRedirect
