import Box from '@mui/material/Box'
import { ListProps } from '@mui/material'

import useGetImportJobs from 'hooks/queries/importjobs/use-get-importjobs'
import usePagination from 'hooks/use-pagination'
import ImportJobsTable from 'components/organisms/importjobs/importjobs-table'
import ImportJobsTableHeader from 'components/molecules/importjobs-table-header'


const ImportJobs = (listProps: ListProps) => {

  const { offset, page, pageSize, setPage, setPageSize } = usePagination()

  const { response, isLoading, count } = useGetImportJobs({
    offset,
    limit: pageSize,
    sort: '-createDate',
  })

  return (
    <Box width='100%' mt={3} >
      <ImportJobsTableHeader count={count} isLoading={isLoading} />
      <ImportJobsTable
        {...listProps}
        rows={response}
        count={count}
        page={page}
        pageSize={pageSize}
        isLoading={isLoading}
        onPageChange={setPage}
        onPageSizeChange={setPageSize}
        onSortModelChange={() => {
          return
        }}
      />
    </Box >
  )
}

export default ImportJobs
