import dayjs from 'dayjs'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import { t } from 'i18next'
import * as yup from 'yup'

import { PeriodDate, PeriodTime } from 'models/groups'
import { DateFormats, INVALID_DATE } from 'config/constants'
import { Center } from 'models/center'

// eslint-disable-next-line import/no-named-as-default-member
dayjs.extend(isSameOrBefore)

type HasDateFields = object & { from: string; to: string }

export const isMinorDate = {
  name: 'isMinorDate',
  message: `${t('La fecha de fin no puede ser menor que la de inicio')}`,
  test: (value: string | undefined | null, { parent }: { parent: HasDateFields }) => {
    if (!parent.to || parent.to === INVALID_DATE) {
      return true
    }

    return dayjs(parent.from, DateFormats.iso).isSameOrBefore(dayjs(parent.to, DateFormats.iso))
  },
}

export const isEqualDate = {
  name: 'isEqualDate',
  message: `${t('La fecha de fin no puede ser igual que la de inicio')}`,
  test: (value: string | undefined | null, { parent }: { parent: HasDateFields }) => {
    if (!parent.to || parent.to === INVALID_DATE) {
      return true
    }

    return dayjs(parent.from, DateFormats.iso).isBefore(dayjs(parent.to, DateFormats.iso))
  },
}

export const handleKeysValidation = (value: string) => {
  if (value === 'EMAIL') return yup.string().required().email().max(255)
  return yup.string().required().trim().max(255)
}

export const currentDateIsMinor = {
  name: 'currentDateIsMinor',
  message: `${t('La fecha de comienzo no puede ser menor que la fecha actual')}`,
  test: (value: string | undefined, { parent }: { parent: PeriodTime }) => {
    if (!parent.from || parent.from === INVALID_DATE) return true
    return dayjs().second(59) < dayjs(parent.from)
  },
}

export const currentDateIsMinorOrEqual = {
  name: 'currentDateIsMinorOrEqual',
  message: `${t('La fecha de comienzo no puede ser menor que la fecha actual del aparcamiento seleccionado')}`,
  test: (value: string | undefined, { parent }: { parent: PeriodTime & { carpark: Center } }) => {
    if (!parent.from || parent.from === INVALID_DATE) return true
    if (!parent.carpark) return true
    return dayjs().tz(parent.carpark.timezone).second(0).isBefore(dayjs(parent.from))
  },
}

export const isMinorHour = {
  name: 'isMinorHour',
  message: `${t('La hora de fin no puede ser menor que la de inicio')}`,
  test: (value: string | undefined, { parent }: { parent: PeriodDate }) => {
    return dayjs(parent.from, DateFormats.hour) < dayjs(parent.to, DateFormats.hour)
  },
}

export const isMinorPeriodDate = {
  name: 'isMinorPeriodDate',
  message: `${t('La fecha de fin no puede ser menor que la de inicio')}`,
  test: (value: string | undefined, { parent }: { parent: PeriodTime }) => {
    if (!parent.to || parent.to === INVALID_DATE) return true
    return dayjs(parent.from, DateFormats.iso) < dayjs(parent.to, DateFormats.iso)
  },
}

export const isSimilarHour = {
  name: 'isSimilarHour',
  message: `${t('La hora no puede ser la misma')}`,
  test: (value: string | undefined, { parent }: { parent: PeriodTime }) => parent.from !== parent.to,
}

export const locationChecker = (value: boolean) => {
  if (!value) return yup.array()
  return yup.array().min(1)
}
