import React from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { ArcElement, Chart, Colors } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'
import { useTranslation } from 'react-i18next'

import { getChartData, getChartOptions } from './utils'

Chart.register(ArcElement, Colors)

interface Props {
  data: Record<string, number>
}

const PrebooksDoughnutChart: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation()

  if (!Object.keys(data).length) {
    return (
      <Box textAlign='center' py={8}>
        <Typography>{t`Sin datos`}</Typography>
      </Box>
    )
  }

  const chartOptions = getChartOptions()
  const chartData = getChartData(data)

  return <Doughnut options={chartOptions} data={chartData} />
}

export default PrebooksDoughnutChart
