import React from 'react'

import { useTranslation } from 'react-i18next'

import BasicButton from 'components/atoms/basic-button'

interface Props {
  text?: string | null
  disabled?: boolean
}

const SubmitButton: React.FC<Props> = ({ text, disabled }) => {
  const { t } = useTranslation()

  return (
    <BasicButton
      text={text || t('Guardar cambios')}
      variant='contained'
      size='medium'
      type='submit'
      disabled={disabled}
    />
  )
}

export default SubmitButton
