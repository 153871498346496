import { FC, useRef, useState } from 'react'

import CircularProgress from '@mui/material/CircularProgress'
import { GridProps } from '@mui/material/Grid'

import { GridContainer } from './styled'

interface SpinnerLoadingProps extends GridProps {
  fullHeight?: boolean
}

const SpinnerLoading: FC<SpinnerLoadingProps> = ({ fullHeight, ...gridProps }) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [offsetTop, setOffsetTop] = useState(containerRef.current?.offsetTop ?? 0)

  setTimeout(() => setOffsetTop(containerRef.current?.offsetTop ?? 0), 50)

  return (
    <GridContainer
      {...gridProps}
      container
      ref={containerRef}
      sx={{ height: fullHeight ? `calc(100vh - ${offsetTop}px)` : '' }}
      data-testid='spinner-loading'
    >
      <CircularProgress />
    </GridContainer>
  )
}

export default SpinnerLoading
