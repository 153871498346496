import React, { useCallback } from 'react'

import Box from '@mui/material/Box'
import { GridEnrichedColDef } from '@mui/x-data-grid'
import { QueryKey } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import AlertSeverityCell from 'components/atoms/alert-severity-cell'
import TextCell from 'components/atoms/text-cell'
import BasicTable from 'components/molecules/basic-table'
import { DateFormats, RowHeight, TableColumnWidth, TableTypes } from 'config/constants'
import routes from 'config/routes'
import useCentersPermissions from 'hooks/permissions/use-centers-permissions'
import useMarkAlertAsRead from 'hooks/queries/alerts/use-mark-alert-as-read'
import { Alert } from 'models/alert'
import { dateFormatter } from 'utils/date-formatter'

interface Props {
  type?: TableTypes
  rows: Alert[]
  count: number
  page: number
  pageSize: number
  isLoading: boolean
  onSortModelChange: (sortString: string) => void
  onPageChange: (page: number) => void
  onPageSizeChange: (pageSize: number) => void
  queryKey: QueryKey
}

const AlertsTable: React.FC<Props> = ({ queryKey, type = TableTypes.default, ...props }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { canRead: centersCanRead, canWrite: centersCanWrite } = useCentersPermissions()
  const markAlert = useMarkAlertAsRead(queryKey)

  const navigateToShow = useCallback(
    (id: number) => {
      if (type !== TableTypes.default) {
        return
      }

      const state = { from: routes.alerts, tableState: { page: props.page, pageSize: props.pageSize } }
      if (centersCanWrite) {
        navigate(routes.centersEdit.replace(':id', id.toString()), { state })
      } else if (centersCanRead) {
        navigate(routes.centersShow.replace(':id', id.toString()), { state })
      }
    },
    [navigate, centersCanRead, centersCanWrite, type, props]
  )

  const handleRowClick = useCallback(
    ({ row }: { row: Alert }) => {
      if (!row.isRead) {
        markAlert(row.id)
      }

      if (row.centerName !== 'undefined') {
        navigateToShow(row.centerId)
      }
    },
    [navigateToShow, markAlert]
  )

  const columns: GridEnrichedColDef[] = [
    {
      field: 'createDate',
      headerName: `${t('Fecha')}`,
      flex: 0.4,
      renderCell: ({ row }: { row: Alert }) => (
        <TextCell
          text={dateFormatter({ date: row.createDate, pattern: DateFormats.day })}
          typographyProps={{ fontWeight: !row.isRead ? 'bold' : undefined }}
        />
      ),
    },
    {
      field: 'centerName',
      headerName: `${t('Centro')}`,
      flex: 0.5,
      renderCell: ({ row }: { row: Alert }) =>
        row.centerName === 'undefined' ? (
          <TextCell
            text={t('CENTRO ELIMINADO')}
            typographyProps={{ fontStyle: 'italic', overflow: 'visible', fontWeight: !row.isRead ? 'bold' : undefined }}
          />
        ) : (
          <TextCell text={row.centerName} typographyProps={{ fontWeight: !row.isRead ? 'bold' : undefined }} />
        ),
    },
    {
      field: 'severity',
      headerName: `${t('Severidad')}`,
      flex: 0.4,
      renderCell: ({ row }: { row: Alert }) => <AlertSeverityCell isRead={row.isRead} severity={row.severity} />,
    },
    {
      field: 'message',
      headerName: `${t('Mensaje')}`,
      flex: TableColumnWidth.xl,
      sortable: false,
      renderCell: ({ row }: { row: Alert }) => <TextCell text={row.message} />,
    },
  ]

  return (
    <Box sx={{ '.MuiDataGrid-row.alerts-table-row-read': { backgroundColor: 'rgba(0, 0, 0, 0.025)' } }}>
      <BasicTable
        {...props}
        type={type}
        rowHeight={RowHeight.large}
        getRowClassName={params => (params.row.isRead ? 'alerts-table-row-read' : '')}
        columns={columns}
        onRowClick={handleRowClick}
      />
    </Box>
  )
}

export default AlertsTable
