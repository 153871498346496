import { QueryKey, useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { GET_RELATIONS } from 'config/queries'
import { RelationDTO } from 'models/relation'
import { getUsersRelations } from 'services/relations'

interface UseGetUsersRelationsProps {
  limit: number
  offset: number
  sort: string
}

interface UseGetUsersRelations {
  response: RelationDTO[]
  count: number
  isLoading: boolean
  queryKey: QueryKey
}

const useGetUsersRelations = ({ limit, offset, sort }: UseGetUsersRelationsProps): UseGetUsersRelations => {
  const { t } = useTranslation()
  const queryKey = [GET_RELATIONS, limit, offset, sort]

  const {
    data: response,
    isLoading,
    isPreviousData,
  } = useQuery({
    queryKey,
    queryFn: () => getUsersRelations({ limit, offset, sort }),
    onError: () => {
      toast(t('Ha ocurrido un error al obtener los relaciones entre usuarios y centros'), { type: 'error' })
    },
    refetchOnMount: true,
    keepPreviousData: true,
  })

  const result: RelationDTO[] = response?.data ?? []
  const count: number = parseInt(response?.headers?.['x-total-count'] || 0)

  return { response: result, count, isLoading: isLoading || isPreviousData, queryKey }
}

export default useGetUsersRelations
