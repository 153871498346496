import React, { useEffect, useRef, useState } from 'react'

import _debounce from 'lodash.debounce'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import { FieldError } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import useGetOneProfile from 'hooks/queries/profiles/use-get-one-profile'
import useGetProfiles from 'hooks/queries/profiles/use-get-profiles'

import { ProfileCellContainer } from './styled'

import type CurveType from 'models/curve-type'

interface State {
  pageSize: number
  offset: number
  page: number
  search: string
  preloading: boolean
}

interface Args {
  name: string
  value?: number
  onChange: (value?: number) => void
  error?: FieldError
  disabled?: boolean
  mandatory?: boolean
  curveType?: CurveType
}

const ProfilesSearcher: React.FC<Args> = ({ name, value, error, disabled, mandatory, onChange, curveType }) => {
  const { t } = useTranslation()

  const [search, setSearch] = useState<State['search']>('')
  const [preloading, setPreloading] = useState<State['preloading']>(false)

  const { isLoading, response } = useGetProfiles({
    filters: { search, active: true, curveType },
    limit: 20,
    offset: 0,
    sort: 'name',
  })
  const { response: selectedProfile } = useGetOneProfile({ id: value?.toString() })

  const debouncer = useRef(
    _debounce((data: string) => {
      setSearch(data)
      setPreloading(false)
    }, 300)
  ).current

  useEffect(() => {
    return () => {
      debouncer.cancel()
    }
  }, [debouncer])

  const handleTextInputOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPreloading(true)
    debouncer(event.target.value)
  }

  return (
    <Autocomplete
      id='profile-autocomplete'
      sx={{ width: '100%' }}
      options={response}
      loading={isLoading || preloading}
      getOptionLabel={option => option?.name ?? ''}
      value={selectedProfile ?? {}}
      onChange={(e, data) => onChange(data?.id)}
      isOptionEqualToValue={(option, valueToCheck) => option.id === valueToCheck.id}
      disabled={disabled}
      renderOption={(props, profile) => (
        <ProfileCellContainer {...props} key={`profile-li-cell-${profile.id}`}>
          <Typography>{profile.name}</Typography>
        </ProfileCellContainer>
      )}
      renderInput={params => (
        <TextField
          {...params}
          onChange={handleTextInputOnChange}
          label={t('Curva') + (mandatory ? ' *' : '')}
          name={name}
          error={!!error}
          helperText={error?.message}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading || preloading ? <CircularProgress color='inherit' size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  )
}

export default ProfilesSearcher
