import { FC } from 'react'

import { Navigate, useParams } from 'react-router-dom'

import PageContainer from 'components/atoms/page-container'
import CentersForm from 'components/organisms/centers/centers-form'
import routes from 'config/routes'
import useGetOneCenter from 'hooks/queries/centers/use-get-one-center'

const CentersView: FC = () => {
  const { id = '' } = useParams()
  const numericId = parseInt(id)

  const { response: center, isLoading } = useGetOneCenter({ id: numericId })

  if (isNaN(numericId)) {
    return <Navigate to={routes.centers} />
  }

  return (
    <PageContainer>
      <CentersForm center={center} isLoading={isLoading} viewOnly />
    </PageContainer>
  )
}

export default CentersView
