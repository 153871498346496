import styled from 'styled-components'
import Box from '@mui/material/Box'

import { colors } from 'config/theme'

export const AppBarNavigationContainer = styled(Box)`
  display: flex;
  height: 3em;
`

export const AppBarNavigationLinks = styled(Box)`
  display: flex;
  background: ${colors.whiteShadow};
`
