import { QueryKey, useMutation, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { GET_CALENDAR, GET_CENTERS } from 'config/queries'
import { CalendarDay, UpdateCenterDay } from 'models/center'
import { updateOneCenterDay } from 'services/centers'

const useUpdateCenterDay = (queryKey?: QueryKey) => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  return useMutation({
    mutationFn: ({ centerId, dayId, price }: UpdateCenterDay) => updateOneCenterDay(centerId, dayId, price),
    onSuccess: () => toast(t('Precio actualizado'), { type: 'success' }),
    onMutate: async newValue => {
      await queryClient.cancelQueries({ queryKey: [GET_CALENDAR] })
      const previous = queryClient.getQueryData<{ data: CalendarDay[] }>(queryKey ?? [GET_CALENDAR])
      const day = previous?.data.find(({ id }) => id === newValue.dayId)

      if (day) {
        Object.assign(day, { price: newValue.price })
      }

      return { previous }
    },
    onError: (error, newValue, context) => {
      queryClient.setQueryData([GET_CENTERS], context?.previous)
      toast(t('Ha ocurrido un error'), { type: 'error' })
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [GET_CALENDAR] })
    },
  })
}

export default useUpdateCenterDay
