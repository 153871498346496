import React, { createContext, useMemo, useState, useContext, useEffect } from 'react'

import * as Sentry from '@sentry/react'
import { useQueryClient } from '@tanstack/react-query'
import { useLocation, Navigate, Location } from 'react-router-dom'
import jwtDecode from 'jwt-decode'

import { defaultLanguage, getCurrentLanguage } from 'config/i18n'
import routes from 'config/routes'
import { CognitoDecodedToken, CognitoToken, LogIn, LoginUser, GetMe, User } from 'models/auth'
import {
  setAccessTokenHeader,
  getCognitoToken,
  setSessionToken,
  setSessionRefreshToken,
  setSessionUser,
  cognitoUrl,
  getSessionUser,
  getMe,
  signInAdmin,
} from 'services/auth'
import storage from 'services/storage'

interface AuthContextType {
  user: LoginUser | null
  signin: (user: User, remember?: boolean) => Promise<LogIn>
  signinWithCognito: (code: string) => Promise<LogIn>
  redirectToCognito: () => void
  signout: () => void
  language: string
  setLanguage: (code: string) => void
}

interface AuthProviderType {
  children?: React.ReactNode
}

const initialState: AuthContextType = {
  user: null,
  signin: () => new Promise<LogIn>(() => {}),
  signinWithCognito: () => new Promise<LogIn>(() => {}),
  redirectToCognito: () => {},
  signout: () => {},
  language: defaultLanguage,
  setLanguage: () => {},
}

export const AuthContext = createContext<AuthContextType>(initialState)

export const useAuth = () => useContext(AuthContext)

const AuthProvider = ({ children }: AuthProviderType): JSX.Element => {
  const queryClient = useQueryClient()
  const [user, setUser] = useState<LoginUser | null>(getSessionUser())
  const [language, setLanguage] = useState<string>(getCurrentLanguage())

  useEffect(() => {
    if (user) {
      Sentry.setUser(user)
      Sentry.setContext('role', { role: user.role })
    }
  }, [user])

  const signin = async (newUser: User, remember?: boolean): Promise<LogIn> => {
    const { data: userData } = await signInAdmin(newUser)

    setUser({ ...userData.user })
    setSessionToken(userData.token.accessToken)
    setAccessTokenHeader(userData.token.accessToken)

    if (remember) {
      setSessionRefreshToken(userData.token.refreshToken)
      setSessionUser(JSON.stringify(userData.user))
    }

    return userData
  }

  const signinWithCognito = async (code: string): Promise<LogIn> => {
    const response = await getCognitoToken(code)
    const token: CognitoToken = await response.json()

    const tokenData: CognitoDecodedToken = jwtDecode(token.id_token)
    setSessionToken(token.id_token)
    setSessionRefreshToken(token.refresh_token)
    setAccessTokenHeader(token.id_token)

    const { data: meData }: GetMe = await getMe()
    const userData = {
      id: tokenData.aud,
      name: tokenData['cognito:username'],
      email: tokenData.email,
      role: meData?.role,
    }

    setUser(userData)
    setSessionUser(JSON.stringify(userData))

    return {
      user: userData,
      token: {
        expiresIn: token.expires_in,
        accessToken: token.access_token,
        refreshToken: token.refresh_token,
      },
    }
  }

  const redirectToCognito = () => {
    window.location.href = cognitoUrl
  }

  const clearQueries = (): void => {
    queryClient.clear()
  }

  const signout = (): void => {
    setUser(null)
    setAccessTokenHeader(null)
    clearQueries()
    storage.clear()
  }

  const value = useMemo(
    () => ({ user, redirectToCognito, signout, language, setLanguage, signinWithCognito, signin }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user, language]
  )

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const RequireAuth = ({ children }: { children: JSX.Element }): JSX.Element => {
  const auth: AuthContextType = useAuth()
  const location: Location = useLocation()

  if (!auth?.user) {
    return <Navigate to={routes.login} state={{ from: location }} replace />
  }

  return children
}

export default AuthProvider
