import { GetEvents } from 'models/events'
import axios from 'services'
import { removeEmpty } from 'utils/remove-empty'

export const getEvents = ({ filters, ...payload }: GetEvents) => {
  return axios.get('/events', { params: removeEmpty({ ...payload, ...filters }) })
}

export const getOneEvent = (id?: string) => {
  return axios.get(`/events/${id}`)
}
