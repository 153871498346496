import Box from '@mui/material/Box'
import styled from 'styled-components'
export const ProfilesHeader = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  & > :first-child {
    place-self: flex-end;
  }
`
export const ProfilesActions = styled(Box)`
  display: flex;
  gap: 1rem;
`
