import { UpdateUser, CreateUser, GetUsers } from 'models/users'
import axios from 'services'
import { removeEmpty } from 'utils/remove-empty'

export const getUsers = ({ filters, ...payload }: GetUsers) => {
  return axios.get('/users', { params: removeEmpty({ ...payload, ...filters }) })
}

export const getOneUser = (id: string | undefined) => {
  return axios.get(`/users/${id}`)
}

export const createUser = (payload: CreateUser) => {
  return axios.post('/users', payload)
}

export const updateOneUser = (id: string | undefined, payload: UpdateUser) => {
  return axios.put(`/users/${id}`, payload)
}
