import React, { useCallback } from 'react'

import { NavigateFunction, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { GridEnrichedColDef } from '@mui/x-data-grid'

import { seniorityInDays } from 'utils/seniority-in-days'
import useUpdateApplication from 'hooks/queries/application/use-update-application'
import useAdministrationPermissions, { AdministrationSections } from 'hooks/permissions/use-administration-permissions'
import BasicTable from 'components/molecules/basic-table'
import routes from 'config/routes'
import ShowGridActionCellItem from 'components/atoms/show-grid-action-cell-item'
import EditGridActionCellItem from 'components/atoms/edit-grid-action-cell-item'
import { RowHeight, TableColumnWidth } from 'config/constants'
import ToggleGridActionCellItem from 'components/atoms/toggle-grid-action-cell-item'
import { Application, ApplicationsQueryKey } from 'models/applications'
import { dateFormatter } from 'utils/date-formatter'
import TextCell from 'components/atoms/text-cell'

interface Props {
  rows: Array<Application>
  count: number
  page: number
  pageSize: number
  isLoading: boolean
  onSortModelChange: (sortString: string) => void
  onPageSizeChange: (pageSize: number) => void
  onPageChange: (page: number) => void
  queryKey: ApplicationsQueryKey
}

const ApplicationsTable: React.FC<Props> = ({
  rows,
  count,
  page,
  pageSize,
  isLoading,
  onSortModelChange,
  onPageChange,
  onPageSizeChange,
  queryKey,
}) => {
  const { t } = useTranslation()
  const navigate: NavigateFunction = useNavigate()
  const { canWrite } = useAdministrationPermissions(AdministrationSections.applications)
  const updateApplication = useUpdateApplication(queryKey)

  const navigateToEdit = useCallback(
    (id: string): void => navigate(routes.applicationsEdit.replace(':id', id)),
    [navigate]
  )

  const navigateToShow = useCallback(
    (id: string): void => navigate(routes.applicationsShow.replace(':id', id)),
    [navigate]
  )

  const handleToggleApplicationActive = useCallback(
    ({ id, active, name, role }: Application): void => {
      updateApplication.mutate({ id, name, role, active: !active, renewToken: false })
    },
    [updateApplication]
  )

  const getSeniority = useCallback(
    ({ createDate }: Application) => {
      const days: number = seniorityInDays(createDate)
      return `${days} ${days === 1 ? t('día') : t('días')}`
    },
    [t]
  )

  const getTableActions = useCallback(
    ({ row }: { row: Application }) => {
      const actions = [<ShowGridActionCellItem key={`${row.id}-show`} onClick={() => navigateToShow(`${row.id}`)} />]

      if (canWrite) {
        actions.unshift(
          <ToggleGridActionCellItem
            checked={row.active}
            key={`${row.id}-toggle`}
            onClick={() => handleToggleApplicationActive(row)}
          />,
          <EditGridActionCellItem key={`${row.id}-edit`} onClick={() => navigateToEdit(`${row.id}`)} />
        )
      }

      return actions
    },
    [canWrite, navigateToShow, handleToggleApplicationActive, navigateToEdit]
  )

  const columns: GridEnrichedColDef[] = [
    { field: 'id', headerName: '', flex: TableColumnWidth.xs, headerAlign: 'center', align: 'center' },
    {
      field: 'name',
      headerName: `${t('Nombre')}`,
      flex: TableColumnWidth.base,
      renderCell: ({ row }: { row: Application }) => <TextCell text={row.name} />,
    },

    {
      field: 'enmaskedToken',
      headerName: `${t('Token')}`,
      flex: TableColumnWidth.large,
      renderCell: ({ row }: { row: Application }) => <TextCell text={row.enmaskedToken} />,
    },
    {
      field: 'createDate',
      headerName: `${t('Creación')}`,
      flex: TableColumnWidth.base,
      renderCell: ({ row }: { row: Application }) => <TextCell text={dateFormatter({ date: row.createDate })} />,
    },
    {
      field: 'seniority',
      headerName: `${t('Antiguedad')}`,
      flex: TableColumnWidth.base,
      renderCell: ({ row }: { row: Application }) => <TextCell text={getSeniority(row)} />,
    },
    {
      field: 'lastLogin',
      headerName: `${t('Último acceso')}`,
      flex: TableColumnWidth.base,
      sortable: false,
      renderCell: ({ row }: { row: Application }) => (
        <TextCell text={row.lastLogin ? dateFormatter({ date: row.lastLogin }) : '-'} />
      ),
    },
    {
      field: 'actions',
      type: 'actions',
      flex: TableColumnWidth.base,
      getActions: getTableActions,
    },
  ]

  return (
    <BasicTable
      count={count}
      isLoading={isLoading}
      onPageChange={onPageChange}
      onPageSizeChange={onPageSizeChange}
      onSortModelChange={onSortModelChange}
      page={page}
      pageSize={pageSize}
      rows={rows}
      columns={columns}
      onRowClick={({ row }) => (canWrite ? navigateToEdit(row.id) : navigateToShow(row.id))}
      rowHeight={RowHeight.medium}
    />
  )
}

export default ApplicationsTable
