import { FC } from 'react'

import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'

import CountChip from 'components/atoms/count-chip'
import NewResourceButton from 'components/atoms/new-resource-button'
import ResourceHeader from 'components/atoms/resource-header'
import { SH2 } from 'components/pages/relations/styled'
import routes from 'config/routes'
import useFilters from 'hooks/use-filters'
import usePagination from 'hooks/use-pagination'
import useAdministrationPermissions, { AdministrationSections } from 'hooks/permissions/use-administration-permissions'
import { ListProps } from 'models/list'
import RolesRelationsTable from 'components/molecules/relations/roles-table'
import useGetRolesRelations from 'hooks/queries/relations/use-get-roles-relations'

const RolesRelationsList: FC<ListProps> = listProps => {
  const { t } = useTranslation()
  const { offset, page, pageSize, setPage, setPageSize } = usePagination()
  const { sortModel, setSortModel } = useFilters({})

  const { canWrite } = useAdministrationPermissions(AdministrationSections.relations)

  const { response, isLoading, count, queryKey } = useGetRolesRelations({
    offset,
    limit: pageSize,
    sort: sortModel || 'id',
  })

  return (
    <Box data-testid='roles-centers-relations-list'>
      <ResourceHeader>
        <CountChip label={t('relaciones')} labelSingular={t('relación')} count={count} isLoading={isLoading} />
        <SH2 text={t`Relaciones centros - roles`} />
        {canWrite && <NewResourceButton label={t('Nueva relación')} to={routes.relationsRolesCreate} />}
      </ResourceHeader>
      <RolesRelationsTable
        {...listProps}
        rows={response}
        count={count}
        page={page}
        pageSize={pageSize}
        isLoading={isLoading}
        onSortModelChange={setSortModel}
        onPageChange={setPage}
        onPageSizeChange={setPageSize}
        queryKey={queryKey}
      />
    </Box>
  )
}

export default RolesRelationsList
