import { AxiosResponse } from 'axios'

import {
  CenterPeriod,
  CenterPeriodVersion,
  CreatePeriod,
  GetOnePeriodVersion,
  GetPeriodVersions,
  GetPeriodsPagination,
} from 'models/center-period'
import { adminApi } from 'services'
import { removeEmpty } from 'utils/remove-empty'

export const getCenterPeriods = (
  centerId: number,
  pagination: GetPeriodsPagination
): Promise<AxiosResponse<CenterPeriod[]>> =>
  adminApi.get(`/centers/${centerId}/periods`, {
    params: removeEmpty({ ...pagination }),
  })

export const createPeriod = (centerId: number, period: CreatePeriod): Promise<AxiosResponse<CenterPeriod>> =>
  adminApi.post(`/centers/${centerId}/periods`, { ...period, priority: period.priority?.toString() ?? '' })

export const updateOnePeriod = (
  id: number,
  centerId: number,
  period: Partial<CenterPeriod>
): Promise<AxiosResponse<CenterPeriod>> => adminApi.patch(`/centers/${centerId}/periods/${id}`, { ...period })

export const deleteOnePeriod = (id: number, centerId: number): Promise<AxiosResponse<void>> =>
  adminApi.delete(`/centers/${centerId}/periods/${id}`)

export const getPeriodVersions = ({
  id,
  ...params
}: GetPeriodVersions): Promise<AxiosResponse<CenterPeriodVersion[]>> =>
  adminApi.get(`/v1/periods/${id}/versions`, { params: removeEmpty({ ...params }) })

export const getOnePeriodVersion = ({
  id,
  version,
}: GetOnePeriodVersion): Promise<AxiosResponse<CenterPeriodVersion>> =>
  adminApi.get(`/v1/periods/${id}/versions/${version}`)
