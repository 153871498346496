import { useRef, useState } from 'react'

import { ButtonBase, Typography } from '@mui/material'
import { ReactCountryFlag } from 'react-country-flag'
import { useTranslation } from 'react-i18next'

import { useClickOutside } from 'hooks/use-click-outside'
import { changeLanguage } from 'config/i18n'
import { useAuth } from 'providers/auth'

import * as S from './styled'

const AppBarLanguage = (): JSX.Element => {
  const { t, i18n } = useTranslation()
  const { setLanguage } = useAuth()

  const containerRef = useRef<HTMLElement>()

  const [showList, setShowList] = useState<boolean>(false)

  const hideLanguagesList = () => setShowList(false)
  const toggleLanguagesList = () => setShowList(prev => !prev)

  useClickOutside(containerRef, hideLanguagesList)

  const handleLanguageChange = (code: string) => {
    hideLanguagesList()
    changeLanguage(code).then(() => setLanguage(code))
  }

  const getFlagFromCode = (code: string) => (code === 'en' ? 'gb' : code)

  return (
    <S.AppBarLanguageContainer ref={containerRef}>
      <S.AppBarLanguageSelected onClick={toggleLanguagesList}>
        <ButtonBase centerRipple sx={{ height: '100%' }}>
          <ReactCountryFlag countryCode={getFlagFromCode(i18n.language)} svg style={{ marginRight: '0.5em' }} />
          <Typography>{t(i18n.language)}</Typography>
        </ButtonBase>
      </S.AppBarLanguageSelected>

      {showList && (
        <S.AppBarLanguageList>
          {Object.keys(i18n.services.resourceStore.data)
            .filter(code => code !== i18n.language)
            .map(code => (
              <S.AppBarLanguageItem key={code} onClick={() => handleLanguageChange(code)}>
                <ReactCountryFlag countryCode={getFlagFromCode(code)} svg />
                <Typography>{t(code)}</Typography>
              </S.AppBarLanguageItem>
            ))}
        </S.AppBarLanguageList>
      )}
    </S.AppBarLanguageContainer>
  )
}

export default AppBarLanguage
