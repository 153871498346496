import React from 'react'

import { Typography } from '@mui/material'
import LockIcon from '@mui/icons-material/Lock'
import EventIcon from '@mui/icons-material/Event'

interface Period {
  minDays: number
  maxDays: number
}

interface PeriodIndicatorProps {
  period: Period
}

const PeriodIndicator: React.FC<PeriodIndicatorProps> = ({ period }) => {
  const getPeriodText = (minDays: number, maxDays: number) => {
    if (minDays === 0 && maxDays === 0) {
      return { text: 'BLOQUEO', icon: <LockIcon /> }
    } else if (minDays === 1 && maxDays === 1) {
      return { text: '1 DÍA', icon: <EventIcon /> }
    } else {
      return { text: `DE ${minDays} A ${maxDays} DÍAS`, icon: <EventIcon /> }
    }
  }

  const { text, icon } = getPeriodText(period.minDays, period.maxDays)

  return (
    <Typography component='div' display='flex' alignItems='center'>
      {icon}
      <span style={{ marginLeft: '8px' }}>{text}</span>
    </Typography>
  )
}

export default PeriodIndicator
