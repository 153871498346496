import Box from '@mui/material/Box'
import styled from 'styled-components'

export const PeriodPrices = styled(Box)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 32px;

  .price-tile {
    flex: 0 0 16.66%;
  }
`
