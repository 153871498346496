import { TFunction } from 'i18next'

import AlertSeverity from 'models/alert-severity'

export const getSeverityText = (t: TFunction, severity: AlertSeverity) => {
  switch (severity) {
    case AlertSeverity.ERR:
      return t('Error')
    case AlertSeverity.WARN:
      return t('Aviso')
    case AlertSeverity.INFO:
      return t('Información')
    default:
      return severity
  }
}
