import React, { useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import Grid from '@mui/material/Grid'
import ReactCalendar from 'react-calendar'
import dayjs from 'dayjs'
import 'react-calendar/dist/Calendar.css'
import Box from '@mui/material/Box'

import MonthSelector from 'components/atoms/month-selector'
import CalendarTile from 'components/molecules/calendar-tile'
import H2 from 'components/atoms/h2'
import CalendarLeyend from 'components/molecules/calendar-leyend'
import { Center } from 'models/center'
import { useAuth } from 'providers/auth'
import CalendarPeriodInfo from 'components/molecules/calendar-period-info'
import { useFormStore } from 'store/useFormStore'
import { useTabStore } from 'store/useTabStore'
import { usePrompt } from 'hooks/use-prompt'
import CancelButton from 'components/atoms/cancel-button'

import { CarparkCalendarContainer } from './styled'
import useData from './use-data'

interface Props {
  center: Center
  readonly?: boolean
  handleTabChange?: (event: React.SyntheticEvent, newValue: number) => void
}

const CarparkCalendar: React.FC<Props> = ({ center, readonly, handleTabChange }) => {
  const { t } = useTranslation()
  const { language } = useAuth()
  const isFormDirty = useFormStore(store => store.isDirty)
  const setDirty = useFormStore(store => store.setDirty)
  const resetForm = useFormStore(store => store.resetForm)
  const setTabIndex = useTabStore(store => store.setTabIndex)
  const tabIndex = useTabStore(store => store.tabIndex)

  usePrompt({
    isDirty: isFormDirty,
    onConfirm: resetForm,
  })

  const data = useData({ center, readonly })

  const { date, selected, calendar, occupation, priceAverage, isInterval } = data
  const { onClickDay, onPrevMonth, onNextMonth, resetSelected, onChangeOccupation } = data

  useEffect(() => {
    setDirty(selected.size > 0)
  }, [selected, setDirty])

  const handleGoBack = () => {
    if (handleTabChange) {
      handleTabChange({} as React.SyntheticEvent, tabIndex - 1)
      return
    }
    setTabIndex((prevIndex) => Math.max(prevIndex - 1, 0))
  }

  return (
    <CarparkCalendarContainer>
      <H2 text={t('Calendario')} />
      <Grid container spacing={2} display='flex' alignItems='center' flexDirection='column'>
        <MonthSelector date={date} onPrev={onPrevMonth} onNext={onNextMonth} />
        <ReactCalendar
          showNavigation={false}
          showNeighboringMonth={false}
          locale={language}
          activeStartDate={date.toDate()}
          onClickDay={onClickDay}
          formatShortWeekday={(l, d) => dayjs(d).format('dddd')}
          tileContent={({ date: currentDate }) => {
            const dayObject = dayjs(currentDate)
            const calendarInfo = calendar?.find(c => dayjs(c.date).isSame(dayObject, 'day'))
            const occupationData = occupation?.find(c => dayjs(c.day).isSame(dayObject, 'day'))
            const weekend = dayObject.day() === 0 || dayObject.day() === 6
            const noProducts = !calendarInfo || calendarInfo?.maxDays === 0 || calendarInfo.price === 0
            const limitReached =
              center?.prebookLimitEnabled && occupationData?.occupation
                ? occupationData.occupation >= center.stockSize
                : false
            const aboveAverage = calendarInfo?.price ? calendarInfo?.price > priceAverage : false

            const onCancel = () => resetSelected(dayObject)
            const onSubmit = (price: number) => onChangeOccupation(dayObject, price)

            return (
              <CalendarTile
                isEditMode={selected.has(dayObject.toString())}
                onCancel={onCancel}
                onSubmit={onSubmit}
                calendar={calendarInfo}
                occupation={occupationData}
                weekend={weekend}
                noProducts={noProducts}
                limitReached={limitReached}
                aboveAverage={aboveAverage}
                disabled={isInterval || readonly}
                center={center}
              />
            )
          }}
        />
        {isInterval && <CalendarPeriodInfo />}
        <CalendarLeyend />
        <Box display='flex' alignItems='center' justifyContent='center' columnGap='16px' paddingTop={5}>
          <CancelButton onClick={handleGoBack} />
        </Box>
      </Grid>
    </CarparkCalendarContainer>
  )
}

export default CarparkCalendar
