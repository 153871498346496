import styled from 'styled-components'

export const GeneralSettingsForm = styled.form`
  .MuiPaper-root {
    padding: 32px 24px;
    margin-bottom: 16px;
  }

  .MuiBox-root {
    height: auto;
  }

  .controller-switch {
    .MuiBox-root {
      gap: 0.1rem;
    }

    .MuiSwitch-root {
      padding-right: 0;
      width: 50px;
    }
  }

  .MuiFormHelperText-root {
    margin-right: -30px;
  }
`
