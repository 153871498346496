/* eslint-disable @typescript-eslint/no-explicit-any */
import { TFunction } from 'i18next'

import { DateFormats } from 'config/constants'
import { dateFormatter } from 'utils/date-formatter'
import { colors } from 'config/theme'
import { formatNumber } from 'utils/number-formatter'
import { DailyOccupation } from 'models/occupation'
import { CarparkConfiguration } from 'models/prebooks'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getChartOptions = (
  t: TFunction,
  locale: string,
  configuration: CarparkConfiguration,
  data: Array<DailyOccupation>
): any => {
  const maxOccupationValue = Math.max(...data.map(({ occupation }) => occupation))
  const chartMaxHeigth = maxOccupationValue + 5
  const line1 = {
    type: 'line',
    borderColor: colors.darkGrey,
    borderWidth: 2,
    label: {
      backgroundColor: colors.darkGrey,
      content: t('Capacidad máxima {{value}}', { value: formatNumber(configuration.stockSize, locale) }),
      display: true,
    },
    scaleID: 'y',
    value: chartMaxHeigth,
    display: true,
  }

  const preblockLimitValue = configuration.preblockLimitEnabled ? configuration.preblockLimit : 0
  const line4 = {
    type: 'line',
    borderColor: colors.red,
    borderWidth: 2,
    label: {
      backgroundColor: colors.red,
      content: t('Prebloqueo de reserva {{value}}', {
        value: formatNumber(preblockLimitValue, locale),
      }),
      display: true,
    },
    scaleID: 'y',
    value: preblockLimitValue,
    display: configuration.preblockLimitEnabled,
  }

  const total = configuration.stockSize

  const line2 = {
    type: 'line',
    borderColor: colors.orange,
    borderWidth: 2,
    label: {
      backgroundColor: colors.orange,
      content: t('Sobrecoste de {{value}}%', {
        value: configuration.highDemand50Fee,
      }),
      display: true,
    },
    scaleID: 'y',
    value: total * 0.5,
    display: configuration.highDemandFeeEnabled,
  }

  const line3 = {
    type: 'line',
    borderColor: colors.lightOrange,
    borderWidth: 2,
    label: {
      backgroundColor: colors.lightOrange,
      content: t('Sobrecoste de {{value}}%', { value: configuration.highDemand80Fee }),
      display: true,
    },
    scaleID: 'y',
    value: total * 0.8,
    display: configuration.highDemandFeeEnabled,
  }

  return {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: false,
      },
      annotation: {
        annotations: {
          line1,
          line2,
          line3,
          line4,
        },
      },
    },
    scales: {
      y: {
        title: {
          display: false,
        },
      },
      x: {
        title: {
          display: false,
        },
      },
    },
  }
}

export const getChartData = (t: TFunction, data: Array<DailyOccupation>) => ({
  labels: data.map(({ day }) => dateFormatter({ date: day, pattern: DateFormats.day })),
  datasets: [
    {
      label: t('Ocupación'),
      data: data.map(({ occupation }) => occupation),
      borderColor: colors.orange,
      backgroundColor: colors.lightOrange,
    },
  ],
})
