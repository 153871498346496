import { Roles } from 'models/auth.d'
import { useAuth } from 'providers/auth'

export enum AdministrationSections {
  configuration = 'configuration',
  users = 'users',
  applications = 'applications',
  events = 'events',
  relations = 'relations',
}

const useAdministrationPermissions = (section: AdministrationSections) => {
  const { user } = useAuth()
  const role = user?.role || ''

  if (section === AdministrationSections.configuration) {
    const canWrite =
      role !== Roles['SUPER-ADMIN'] &&
        role !== Roles['ADMIN-CONSOLA'] &&
        role !== Roles.ADMINISTRADOR &&
        role !== Roles['ADMIN-GESTOR']
        ? false
        : true
    const canRead =
      role !== Roles['SUPER-ADMIN'] &&
        role !== Roles['ADMIN-CONSOLA'] &&
        role !== Roles.ADMINISTRADOR &&
        role !== Roles['ADMIN-GESTOR'] &&
        role !== Roles.CONSULTA
        ? false
        : true
    return { canWrite, canRead }
  } else if (section === AdministrationSections.users) {
    const canWrite =
      role !== Roles['SUPER-ADMIN'] && role !== Roles['ADMIN-CONSOLA'] && role !== Roles['ADMIN-CERCA'] ? false : true
    const canRead =
      role !== Roles['SUPER-ADMIN'] && role !== Roles['ADMIN-CONSOLA'] && role !== Roles['ADMIN-CERCA'] ? false : true
    return { canWrite, canRead }
  } else if (section === AdministrationSections.applications) {
    const canWrite = role !== Roles['SUPER-ADMIN'] ? false : true
    const canRead = role !== Roles['SUPER-ADMIN'] && role !== Roles['ADMIN-CONSOLA'] ? false : true
    return { canWrite, canRead }
  } else if (section === AdministrationSections.events) {
    const canWrite = false
    const canRead = !role || ![Roles.CERCA, Roles['ADMIN-CERCA'], Roles.CONSULTA].includes(role)
    return { canWrite, canRead }
  } else if (section === AdministrationSections.relations) {
    const canWrite = role === Roles['SUPER-ADMIN'] || role === Roles['ADMIN-CERCA']
    const canRead = role === Roles['SUPER-ADMIN'] || role === Roles['ADMIN-CERCA']
    return { canWrite, canRead }
  } else {
    return { canWrite: false, canRead: false }
  }
}

export default useAdministrationPermissions
