import React from 'react'

import Typography, { TypographyProps } from '@mui/material/Typography'

import { FontFamily, FontSizes } from 'config/constants'

import * as S from './styled'

interface Props extends TypographyProps {
  text: string
}

const H3: React.FC<Props> = ({ text, ...props }) => (
  <S.H3Container data-testid='page-subtitle'>
    <Typography
      {...props}
      variant='h3'
      fontSize={FontSizes.lg}
      fontFamily={FontFamily.bold}
      textAlign='center'
      fontWeight={400}
    >
      {text}
    </Typography>
  </S.H3Container>
)

export default H3
