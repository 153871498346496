import * as yup from 'yup'

import { ALPHANUMERIC_REGEX } from 'config/regex'

export const userSchema = yup
  .object({
    name: yup.string().required().max(255).matches(ALPHANUMERIC_REGEX),
    email: yup.string().required().email(),
    role: yup.string().required(),
    active: yup.boolean().required(),
    createDate: yup.string(),
    lastLoginAt: yup.string(),
  })
  .required()

export type UserFormData = yup.InferType<typeof userSchema>

export interface UserFormFields {
  id?: string
  name: string
  email: string
  role: string
  active: boolean
  createDate?: string
  lastLoginAt?: string
}

export const userFilterSchema = yup.object({
  name: yup.string(),
  email: yup.string(),
  role: yup.string(),
  from: yup.string().nullable(),
  to: yup.string().nullable(),
  showActiveOnly: yup.boolean().default(true),
})

export type UserFilterFormData = yup.InferType<typeof userFilterSchema>

export interface UserFilterFormFields {
  name?: string
  email?: string
  role?: string
  from?: string | null
  to?: string | null
  q?: string
  showActiveOnly?: boolean
  showInactive?: boolean
}

export const userFilterDefaultValues: UserFilterFormFields = {
  name: '',
  email: '',
  role: '',
  from: null,
  to: null,
  showActiveOnly: true,
}
