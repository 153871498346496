import React, { useEffect, useMemo, useState } from 'react'

import { useQueryClient } from '@tanstack/react-query'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import Grid from '@mui/material/Grid'
import { useNavigate, useParams } from 'react-router-dom'
import { t } from 'i18next'
import TagIcon from '@mui/icons-material/Tag'
import ChartIcon from '@mui/icons-material/SsidChart'
import { Box, Button, IconButton } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import { DeleteOutline } from '@mui/icons-material'

import PapperGridContainer from 'components/atoms/papper-grid-container-form'
import useFormErrorScroll from 'hooks/use-form-error-scroll'
import routes from 'config/routes'
import CancelButton from 'components/atoms/cancel-button'
import SubmitButton from 'components/atoms/submit-button'
import FormButtonsContainer from 'components/atoms/form-buttons-container'
import SpinnerLoading from 'components/atoms/spinner-loading'
import ControllerInputText from 'components/molecules/controller-input-text'
import { ProfileFormData, ProfileFormFields, profileSchema } from 'validations/profiles'
import useGetOneProfile from 'hooks/queries/profiles/use-get-one-profile'
import useGetOneProfileVersion from 'hooks/queries/profiles/use-get-one-profile-version'
import useUpdateProfile from 'hooks/queries/profiles/use-update-profile'
import InputIcon from 'components/atoms/input-icon'
import H2 from 'components/atoms/h2'
import PageTitle from 'components/atoms/page-title'
import ProfilesPointsChart from 'components/molecules/profiles-points-chart'
import useProfilesPermissions from 'hooks/permissions/use-profiles-permissions'
import useGetProfiles from 'hooks/queries/profiles/use-get-profiles'
import { IntervalDto, Point } from 'models/profiles'
import BasicInputSwitch from 'components/atoms/basic-input-switch'
import { GET_PROFILES_VERSIONS } from 'config/queries'
import CurveType from 'models/curve-type'
import ProfilesIntervalsChart from 'components/molecules/profiles-intervals-chart'
import ControllerInputSelect from 'components/molecules/controller-input-select'
import { useFormStore } from 'store/useFormStore'
import { usePrompt } from 'hooks/use-prompt'

import { formatProfile } from './utils'
import * as S from './styled'

interface Props {
  disabled?: boolean
  viewOnly?: boolean
  historic?: boolean
}

export const CURVETYPES = [
  { value: 'DISCOUNT', text: 'Duración' },
  { value: 'ANTICIPATION', text: 'Anticipación' },
]

const defaultValues: ProfileFormFields = {
  name: '',
  code: '',
  points: [],
  active: false,
  curveType: CurveType.DISCOUNT,
  intervals: Array(1).fill({ from: 0, to: 1, percentageValue: 0 }),
}

const ProfilesEditForm: React.FC<Props> = ({ disabled, viewOnly, historic }) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { id, version } = useParams()

  const { canWrite } = useProfilesPermissions()

  const [showAllProfiles, setShowAllProfiles] = useState<boolean>(false)
  const [cachePoints, setCachePoints] = useState<Point[]>(defaultValues.points ?? [])
  const [cacheIntervals, setCacheIntervals] = useState<IntervalDto[]>(defaultValues.intervals ?? [])
  const [showOnlyActive, setShowOnlyActive] = useState(true)

  const updateProfile = useUpdateProfile()

  const GetOneProfile = () => useGetOneProfile({ id })
  const GetOneProfileVersion = () => useGetOneProfileVersion({ id, version })

  const { response: profile, isLoading } = historic ? GetOneProfileVersion() : GetOneProfile()
  const { response: allProfiles } = useGetProfiles({ limit: 100, offset: 0, filters: {}, sort: '' })

  const isFormDirty = useFormStore(store => store.isDirty)
  const setDirty = useFormStore(store => store.setDirty)
  const resetForm = useFormStore(store => store.resetForm)

  usePrompt({
    isDirty: isFormDirty,
    onConfirm: resetForm
  })

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
    setError,
    reset,
    watch,
    getValues,
  } = useForm<ProfileFormData>({
    defaultValues,
    resolver: yupResolver(profileSchema),
  })

  useFormErrorScroll(errors)

  useEffect(() => {
    if (!profile) return

    reset({
      name: profile?.name ?? '',
      code: profile?.code ?? '',
      points: profile?.points ?? [],
      active: profile?.active ?? false,
      curveType: profile?.curveType ?? CurveType.DISCOUNT,
      intervals: profile?.intervals ?? [],
    })
    setCachePoints(profile?.points ?? [])
    setCacheIntervals(profile?.intervals ?? [])

    if (profile.active !== undefined) {
      setShowOnlyActive(profile.active)
    }
  }, [profile, reset, historic, viewOnly])

  const [name, code, points, curveType, intervals] = watch(['name', 'code', 'points', 'curveType', 'intervals'])

  const profiles = useMemo(
    () => (showAllProfiles ? allProfiles.filter(p => p.curveType === curveType) : []),
    [allProfiles, showAllProfiles, curveType]
  )
  useEffect(() => {
    const subscription = watch(value => {
      const newPoints = value.points?.map(p => ({ day: p?.day, discount: p?.discount }) as Point) ?? []
      setCachePoints(newPoints)
      const newIntervals =
        value.intervals?.map(
          i =>
            ({ from: Number(i?.from), to: Number(i?.to), percentageValue: Number(i?.percentageValue) }) as IntervalDto
        ) ?? []
      setCacheIntervals(newIntervals)
    })
    return () => subscription.unsubscribe()
  }, [watch])

  useEffect(() => {
    if (isFormDirty) return
    setDirty(isDirty)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty])

  const checkHoursOverlap = (): boolean => {
    if (!intervals) return false
    if (intervals.length === 1) return false
    const sortedIntervals = intervals.sort((a, b) => a.from - b.from)
    for (let i = 0; i < sortedIntervals.length - 1; i++) {
      if (Number(sortedIntervals[i].to) > Number(sortedIntervals[i + 1].from)) {
        setError('intervals', { type: 'manual', message: t('*No puede haber solapamiento de horas') })
        return true
      }
    }
    return false
  }

  const onSubmit = () => {
    const hasOverlap = checkHoursOverlap()
    if (hasOverlap) return
    updateProfile.mutate(
      formatProfile({
        name,
        code,
        points: cachePoints,
        id,
        active: showOnlyActive,
        curveType,
        intervals: cacheIntervals,
      })
    )
    resetForm()
    queryClient.invalidateQueries({ queryKey: [GET_PROFILES_VERSIONS] })
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowAllProfiles(event.target.checked)
  }

  const onDragPointsEnd = (_e: MouseEvent, _chart: number, index: number, newValue: number) => {
    const formPoints =
      getValues('points')?.map(({ day, discount }) => ({ day, discount: index === day ? newValue : discount })) ?? []
    reset({
      name: profile.name ?? '',
      code: profile.code ?? '',
      points: formPoints,
      intervals: cacheIntervals,
      curveType,
    })
    setDirty(true)
  }
  const onDragIntervalsEnd = (_e: MouseEvent, _chart: number, index: number, newValue: number) => {
    const formIntervals =
      getValues('intervals')?.map((interval, i) =>
        i === index ? { ...interval, percentageValue: newValue } : interval
      ) ?? []
    reset({
      name: profile.name ?? '',
      code: profile.code ?? '',
      points: cachePoints,
      intervals: formIntervals,
      curveType,
    })
    setDirty(true)
  }

  const handleActiveChange = (event: Event) => {
    const { checked } = event.target as HTMLInputElement
    setShowOnlyActive(checked)
    setDirty(true)
  }

  const addNewInterval = () => {
    const prevIntervalTo = cacheIntervals[cacheIntervals.length - 1].to
    const newInterval = { from: prevIntervalTo, to: prevIntervalTo + 1, percentageValue: 0 }
    setCacheIntervals([...cacheIntervals, newInterval])

    reset({
      name: profile.name ?? '',
      code: profile.code ?? '',
      points: cachePoints,
      intervals: [...cacheIntervals, newInterval],
      curveType,
    })
    setDirty(true)
  }

  const deleteInterval = (index: number) => {
    if (index === 0) return
    const newIntervals = cacheIntervals.filter((_, i) => i !== index)
    setCacheIntervals(newIntervals)
    reset({
      name: profile.name ?? '',
      code: profile.code ?? '',
      points: cachePoints,
      intervals: newIntervals,
      curveType,
    })
    setDirty(true)
  }

  if (isLoading) {
    return <SpinnerLoading minHeight={500} />
  }

  return (
    <>
      {profile && (
        <S.Header>
          <PageTitle
            title={
              historic
                ? `${t('Curva')} ${profile.code} ${t('Versión')} ${profile.version}`
                : `${t('Curva')} ${profile.code}`
            }
          />
          {viewOnly && canWrite && !historic && (
            <S.EditButton onClick={() => navigate('edit')}>
              <EditIcon color='primary' />
            </S.EditButton>
          )}
        </S.Header>
      )}
      <Grid container component='form' onSubmit={handleSubmit(onSubmit)} data-testid='profiles-edit-form'>
        <PapperGridContainer>
          <Grid item xs={12}>
            <H2 text={t('Datos generales')} />
          </Grid>
          <Grid item xs={12} md={3}>
            <ControllerInputText
              mandatory
              control={control}
              name='code'
              error={errors.code}
              label={t('Código')}
              size='full'
              disabled={disabled || historic}
              nativeInputProps={{ maxLength: 50 }}
              inputProps={{ endAdornment: <InputIcon Icon={TagIcon} /> }}
            />
          </Grid>
          <Grid item xs={12} md={5} paddingLeft={1}>
            <ControllerInputText
              mandatory
              control={control}
              name='name'
              error={errors.name}
              label={t('Nombre')}
              size='full'
              disabled={disabled || historic}
              nativeInputProps={{ maxLength: 50 }}
              inputProps={{ endAdornment: <InputIcon Icon={ChartIcon} /> }}
            />
          </Grid>
          <Grid item xs={2} paddingLeft={1}>
            <ControllerInputSelect
              control={control}
              name='curveType'
              error={errors.curveType}
              label={t`Tipo de curva`}
              size='full'
              options={CURVETYPES}
              disabled
            />
          </Grid>
          <Grid item xs={12} md={2} paddingLeft={2}>
            <BasicInputSwitch
              autoHeight
              boxDirection='row'
              label={t`Activa`}
              variant='full'
              disabled={disabled || historic}
              field={{
                name: 'showOnlyActive',
                ref: null,
                value: showOnlyActive,
                onChange: handleActiveChange,
                onBlur: () => undefined,
              }}
            />
          </Grid>
          <Box width={'100%'} marginTop={'2rem'}>
            <H2 text={t('Detalle curva')} />
          </Box>
          <Grid container>
            <Grid item xs={12} md={6}>
              {curveType === CurveType.DISCOUNT && (
                <Box display='grid' gridTemplateColumns='repeat(7, 14.3%)'>
                  {points?.map(({ day }) => (
                    <ControllerInputText
                      key={day}
                      mandatory
                      control={control}
                      name={`points[${day}].discount`}
                      label={t('Día {{value}}', { value: day + 1 })}
                      size='full'
                      variant='standard'
                      disabled={disabled || historic}
                      nativeInputProps={{ type: 'number', min: '0', max: '100' }}
                    />
                  ))}
                </Box>
              )}
              {curveType === CurveType.ANTICIPATION && (
                <Box display='flex' width='100%' flexDirection={'column'}>
                  <Box display='flex' height={'2rem'} width='100%' flexDirection={'column'}>
                    {errors.intervals && (
                      <Box fontSize={12} color='red'>
                        {errors.intervals.message}
                      </Box>
                    )}
                  </Box>
                  {intervals?.map((interval, index) => (
                    <Box display='grid' gridTemplateColumns={'8rem 8rem 1fr 2rem'} key={`from-${interval}_${index}`}>
                      <ControllerInputText
                        control={control}
                        name={`intervals[${index}].from`}
                        label={t('Desde(horas)')}
                        size='full'
                        variant='standard'
                        error={errors.intervals?.[index]?.from}
                        disabled={disabled || historic}
                        nativeInputProps={{ type: 'number', min: '0' }}
                      />
                      <ControllerInputText
                        control={control}
                        name={`intervals[${index}].to`}
                        label={t('Hasta(horas)')}
                        size='full'
                        variant='standard'
                        error={errors.intervals?.[index]?.to}
                        disabled={disabled || historic}
                        nativeInputProps={{ type: 'number', min: '0' }}
                      />
                      <ControllerInputText
                        control={control}
                        name={`intervals[${index}].percentageValue`}
                        label={t('(%)Sobrecoste/Descuento')}
                        size='full'
                        variant='standard'
                        disabled={disabled || historic}
                        nativeInputProps={{ type: 'number', min: '-100', max: '100' }}
                      />
                      {index > 0 && (
                        <IconButton size='small' type='button' disabled={disabled || historic} sx={{ height: '2rem' }}>
                          <DeleteOutline onClick={() => deleteInterval(index)} />
                        </IconButton>
                      )}
                    </Box>
                  ))}
                  <Button onClick={addNewInterval} variant='contained' size='small' disabled={disabled || historic}>
                    {t('Agregar intervalo')}
                  </Button>
                </Box>
              )}
            </Grid>
            <Grid item xs={12} md={6} paddingLeft={4} textAlign='right'>
              <Box marginBottom={4}>
                <FormControlLabel
                  control={<Switch checked={showAllProfiles} onChange={handleChange} disabled={historic} />}
                  label={t('Comparar curvas')}
                />
              </Box>
              {curveType === CurveType.ANTICIPATION && (
                <ProfilesIntervalsChart
                  profile={profile}
                  profiles={profiles}
                  dragEnabled={!showAllProfiles && !disabled && !historic}
                  onDragEnd={onDragIntervalsEnd}
                  intervals={cacheIntervals}
                />
              )}
              {curveType === CurveType.DISCOUNT && (
                <ProfilesPointsChart
                  profile={profile}
                  profiles={profiles}
                  dragEnabled={!showAllProfiles && !disabled && !historic}
                  onDragEnd={onDragPointsEnd}
                  points={cachePoints}
                />
              )}
            </Grid>
          </Grid>
        </PapperGridContainer>
        <FormButtonsContainer>
          <CancelButton
            to={historic ? routes.profilesHistoric.replace(':id', profile?.originalID.toString()) : routes.profiles}
          />
          {!disabled && !historic && <SubmitButton />}
        </FormButtonsContainer>
      </Grid>
    </>
  )
}

export default ProfilesEditForm
