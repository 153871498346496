import styled from 'styled-components'
import Box from '@mui/material/Box'
import MuiTab from '@mui/material/Tab'

export const Header = styled.div<{ editIconVisible?: boolean }>`
  position: relative;

  & > .MuiBox-root:first-of-type {
    max-width: calc(100% - ${({ editIconVisible }) => (editIconVisible ? '140px' : '70px')});
    margin-left: auto;
    margin-right: auto;
  }
`

export const Tab = styled(MuiTab)`
  &.MuiTab-root {
    font-size: 0.85em;
    font-weight: 600;
    min-height: 50px;

    &:not(.Mui-selected):not(.Mui-disabled) {
      color: rgba(0, 0, 0, 0.6);
    }
  }
`

export const Buttons = styled(Box)`
  position: absolute;
  right: 0;
  top: calc(50% - 0.4em);
  transform: translateY(-50%);
  z-index: 1;
`
