import { useQuery } from '@tanstack/react-query'

import { GET_PROFILES } from 'config/queries'
import { Profile, UseGetProfiles, UseGetProfilesProps } from 'models/profiles'
import { getProfiles } from 'services/profiles'

const useGetProfiles = ({ limit, offset, filters, sort }: UseGetProfilesProps): UseGetProfiles => {
  const queryKey = [GET_PROFILES, limit, offset, filters, sort]
  const {
    data: response,
    isLoading,
    isPreviousData,
  } = useQuery({
    queryKey,
    queryFn: () => getProfiles({ limit, offset, filters, sort }),
    refetchOnMount: true,
    keepPreviousData: true,
  })

  const result: Profile[] = response?.data ?? []
  const count: number = parseInt(response?.headers?.['x-total-count'] || 0)

  return { response: result, count, isLoading: isLoading || isPreviousData, queryKey }
}

export default useGetProfiles
