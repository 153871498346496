import { ChartOptions } from 'chart.js'

export const getChartOptions = (): ChartOptions<'doughnut'> => ({
  responsive: true,
})

export const getChartData = (data: Record<string, number>) => ({
  labels: Object.keys(data),
  datasets: [
    {
      data: Object.values(data),
    },
  ],
})
