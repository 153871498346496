import * as yup from 'yup'

import i18n from 'config/i18n'

export const usersRelationSchema = yup
  .object({
    managedCenters: yup.array().of(yup.number()),
    managedUsers: yup.array().of(yup.number()).min(1),
    name: yup.string().required().max(255),
    active: yup.boolean().required(),
    allCenters: yup.boolean().required(),
  })
  .required()

export interface UsersRelationFormFields {
  managedCenters: number[]
  managedUsers: number[]
  name: string
  active: boolean
  allCenters: boolean
}

export const roleRelationSchema = yup
  .object({
    centerId: yup.number().positive(i18n.t('Selecciona un centro válido')).required(),
    role: yup.string().required(),
  })
  .required()

export interface RoleRelationFormFields {
  centerId: number
  role: string
}
