import React, { useCallback } from 'react'

import { GridEnrichedColDef } from '@mui/x-data-grid'
import { QueryKey } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import TextCell from 'components/atoms/text-cell'
import BasicTable from 'components/molecules/basic-table'
import { RowHeight, TableColumnWidth, TableTypes } from 'config/constants'
import routes from 'config/routes'
import { Profile } from 'models/profiles'
import { formatPercent } from 'utils/number-formatter'
import { useAuth } from 'providers/auth'
import ToggleGridActionCellItem from 'components/atoms/toggle-grid-action-cell-item'
import ShowGridActionCellItem from 'components/atoms/show-grid-action-cell-item'
import EditGridActionCellItem from 'components/atoms/edit-grid-action-cell-item'
import HistoricGridActionCellItem from 'components/atoms/historic-grid-action-cell-item'
import useProfilesPermissions from 'hooks/permissions/use-profiles-permissions'
import useUpdateProfile from 'hooks/queries/profiles/use-update-profile'

interface Props {
  type?: TableTypes
  rows: Profile[]
  count: number
  page: number
  pageSize: number
  isLoading: boolean
  onSortModelChange: (sortString: string) => void
  onPageChange: (page: number) => void
  onPageSizeChange: (pageSize: number) => void
  queryKey: QueryKey
}

const ProfilesTable: React.FC<Props> = ({ queryKey, type = TableTypes.default, ...props }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { language } = useAuth()
  const { canWrite } = useProfilesPermissions()

  const updateProfile = useUpdateProfile(queryKey)

  const navigateToShow = useCallback(
    (id: number) => type === TableTypes.default && navigate(routes.profilesShow.replace(':id', id.toString())),
    [navigate, type]
  )
  const navigateToEdit = useCallback(
    (id: number) => type === TableTypes.default && navigate(routes.profilesEdit.replace(':id', id.toString())),
    [navigate, type]
  )

  const navigateToHistoric = useCallback(
    (id: number) => navigate(routes.profilesHistoric.replace(':id', id.toString())),
    [navigate]
  )

  const handleToggleActive = useCallback(
    ({ active, id }: Profile) => {
      updateProfile.mutate({ id: id.toString(), active: !active })
    },
    [updateProfile]
  )

  const getTableActions = useCallback(
    ({ row }: { row: Profile }) => {
      const actions = [<ShowGridActionCellItem key={`${row.id}-show`} onClick={() => navigateToShow(row.id)} />]

      if (canWrite) {
        actions.unshift(
          <ToggleGridActionCellItem
            checked={row.active}
            key={`${row.id}-toggle`}
            onClick={() => handleToggleActive(row)}
          />
        )
        actions.push(<EditGridActionCellItem key={`${row.id}-edit`} onClick={() => navigateToEdit(row.id)} />)
      }

      actions.push(<HistoricGridActionCellItem key={`${row.id}-historic`} onClick={() => navigateToHistoric(row.id)} />)

      return actions
    },
    [handleToggleActive, navigateToEdit, navigateToShow, navigateToHistoric, canWrite]
  )

  const columns: GridEnrichedColDef[] = [
    {
      field: 'id',
      headerName: '',
      flex: TableColumnWidth.xxs,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'code',
      headerName: `${t('Código')}`,
      flex: TableColumnWidth.xs,
      headerAlign: 'center',
      align: 'center',
      renderCell: ({ row }: { row: Profile }) => <TextCell text={row.code} />,
    },
    {
      field: 'name',
      headerName: `${t('Nombre')}`,
      flex: TableColumnWidth.large,
      renderCell: ({ row }: { row: Profile }) => <TextCell text={row.name} />,
    },
    {
      field: 'curveType',
      headerName: `${t('Tipo')}`,
      flex: TableColumnWidth.xs,
      renderCell: ({ row }: { row: Profile }) => <TextCell text={`${t(row.curveType)}`} />,
    },
    {
      field: 'minDiscount',
      headerName: `${t('% Mínimo')}`,
      flex: TableColumnWidth.xs,
      headerAlign: 'center',
      align: 'center',
      renderCell: ({ row }: { row: Profile }) => <TextCell text={formatPercent(row.minDiscount, language)} />,
    },
    {
      field: 'maxDiscount',
      headerName: `${t('% Máximo')}`,
      flex: TableColumnWidth.xs,
      headerAlign: 'center',
      align: 'center',
      renderCell: ({ row }: { row: Profile }) => <TextCell text={formatPercent(row.maxDiscount, language)} />,
    },
    {
      field: 'averageDiscount',
      headerName: `${t('% Medio')}`,
      flex: TableColumnWidth.xs,
      headerAlign: 'center',
      align: 'center',
      renderCell: ({ row }: { row: Profile }) => <TextCell text={formatPercent(row.averageDiscount, language)} />,
    },
    {
      field: 'actions',
      type: 'actions',
      flex: TableColumnWidth.small,
      sortable: false,
      getActions: getTableActions,
    },
  ]

  return (
    <BasicTable
      {...props}
      type={type}
      rowHeight={RowHeight.large}
      columns={columns}
      onRowClick={({ row }) => (canWrite ? navigateToEdit(row.id) : navigateToShow(row.id))}
    />
  )
}

export default ProfilesTable
