import * as yup from 'yup'

import { currentDateIsMinorOrEqual, isMinorDate } from './utils'

export const searchSimulatorSchema = yup
  .object({
    from: yup.string().test(currentDateIsMinorOrEqual).required(),
    to: yup.string().test(isMinorDate).required(),
    carparkCode: yup.string().required(),
    channelCode: yup.string().required(),
  })
  .required()
