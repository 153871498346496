import React from 'react'

import { useTranslation } from 'react-i18next'

import TextCell from 'components/atoms/text-cell'
import AlertSeverity from 'models/alert-severity'

import { getSeverityText } from './utils'

interface Props {
  isRead: boolean
  severity: AlertSeverity
}

const AlertSeverityCell: React.FC<Props> = ({ isRead, severity }) => {
  const { t } = useTranslation()

  const text = getSeverityText(t, severity).toUpperCase()

  return <TextCell text={text} typographyProps={{ fontWeight: !isRead ? 'bold' : undefined }} />
}

export default AlertSeverityCell
