import { FC } from 'react'

import EventIcon from '@mui/icons-material/Event'
import { Trans, useTranslation } from 'react-i18next'

import FormGridRow from 'components/atoms/form-grid-row'
import InputIcon from 'components/atoms/input-icon'
import ControllerInputText from 'components/molecules/controller-input-text'

import { FormSection, SectionProps } from '.'

const Section4: FC<SectionProps & { maxEntryDate: string }> = ({ control, errors, readonly, maxEntryDate }) => {
  const { t } = useTranslation()

  return (
    <FormSection>
      <FormGridRow>
        <ControllerInputText
          control={control}
          name='maxDaysEntry'
          error={errors.maxDaysEntry}
          label={t`Meses máxima entrada`}
          size='full'
          inputProps={{ endAdornment: <InputIcon Icon={EventIcon} /> }}
          nativeInputProps={{ type: 'number', max: '99', min: '0', step: '1' }}
          disabled={readonly}
          mandatory
        />
        <Trans i18nKey='generalSettingsForm.maxDaysEntry' values={{ maxEntryDate }} shouldUnescape />
      </FormGridRow>
    </FormSection>
  )
}

export default Section4
