import styled from 'styled-components'

import { colors } from 'config/theme'
import { FontFamily, FontSizes } from 'config/constants'

export const CarparkCalendarContainer = styled.div`
  .react-calendar {
    width: 100%;
    max-width: 800px;
    margin: auto;
    background: transparent;
    border: none;
    font-family: ${FontFamily.light};
  }

  .react-calendar__tile--active {
    background-color: transparent;
    color: ${colors.deepBlue};

    &:hover {
      background-color: transparent;
    }
  }
  .react-calendar__tile--now {
    background-color: transparent;
  }

  .react-calendar__tile {
    position: relative;
    padding: 4px;

    &:hover {
      background-color: transparent !important;
    }

    &:focus {
      background-color: transparent !important;
    }

    abbr {
      position: absolute;
      left: 10px;
      top: 10px;
      color: ${colors.orange};
      font-family: ${FontFamily.medium};
      font-size: ${FontSizes.lg};
    }
  }

  .react-calendar__month-view__weekdays__weekday {
    abbr {
      text-decoration-line: none;
      text-transform: capitalize;
      color: ${colors.deepBlue};
      font-family: ${FontFamily.bold};
    }
  }

  .react-calendar__month-view__days__day--weekend {
    color: inherit;
  }
`
