import { FC, MouseEvent, useMemo, useState } from 'react'

import InfoIcon from '@mui/icons-material/InfoOutlined'
import RemoveIcon from '@mui/icons-material/Remove'
import Stack from '@mui/material/Stack'
import IconButton from '@mui/material/IconButton'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Typography } from '@mui/material'

import BasicButton from 'components/atoms/basic-button'
import PeriodPriorityText from 'components/atoms/period-priority-text'
import { DateFormats, FontFamily } from 'config/constants'
import routes from 'config/routes'
import { CenterPeriod } from 'models/center-period'
import { dateFormatter } from 'utils/date-formatter'
import ModalConfirm from 'components/molecules/modal-confirm'
import PeriodIndicator from 'components/atoms/period-indicator'

import * as S from './styled'

interface PeriodAccordionHeaderProps {
  period: CenterPeriod
  readonly?: boolean
  isProtected?: boolean
  isCercaReadOnly?: boolean
  onDelete?: (periodId: number) => void
}

const PeriodAccordionHeader: FC<PeriodAccordionHeaderProps> = ({
  period,
  readonly,
  isProtected,
  isCercaReadOnly,
  onDelete,
}) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const [openModal, setOpenModal] = useState(false)

  const handleDelete = (evt: MouseEvent<HTMLButtonElement>) => {
    evt.stopPropagation()
    setOpenModal(true)
  }

  const confirmDelete = () => {
    setOpenModal(false)
    onDelete?.(period.id)
  }

  const navigateToHistoric = (evt: MouseEvent<HTMLButtonElement>) => {
    evt.stopPropagation()
    const route = routes.centersPeriodsHistoric
      .replace(':centerId', period.centerId.toString())
      .replace(':id', period.id.toString())
    navigate(route)
  }

  const dateFrom = useMemo(() => dateFormatter({ date: period.from, pattern: DateFormats.day }), [period.from])
  const dateTo = useMemo(() => dateFormatter({ date: period.to, pattern: DateFormats.day }), [period.to])

  return (
    <S.AccordionSummary
      style={{ backgroundColor: period.maxDays == 0 && period.minDays == 0 ? 'rgb(214, 124, 124, .53)' : '' }}
      aria-controls='panel3d-content'
      id='panel3d-header'
      readOnly={readonly}
    >
      <PeriodIndicator period={period} />

      <Typography fontFamily={FontFamily.medium}>
        <Trans i18nKey='Periodo del {{ dateFrom }} al {{ dateTo }}' values={{ dateFrom, dateTo }} shouldUnescape />
        {' - '}
        <PeriodPriorityText priority={period.priority} />
      </Typography>

      <Stack direction='row' columnGap={1} justifyContent='flex-end' alignItems='center'>
        <IconButton onClick={navigateToHistoric}>
          <InfoIcon color='primary' />
        </IconButton>
        {!readonly && (
          <BasicButton size='small' disabled={isProtected || isCercaReadOnly} handleClick={handleDelete}>
            <RemoveIcon />
            {t`Eliminar`}
          </BasicButton>
        )}
        <ModalConfirm
          open={openModal}
          onCancel={() => setOpenModal(false)}
          onConfirm={() => confirmDelete()}
          title={t`Eliminar periodo`}
          message={t`¿Estás seguro de que deseas eliminar el periodo?`}
          confirmLabel={t`Eliminar`}
          cancelLabel={t`Cancelar`}
        />
      </Stack>
    </S.AccordionSummary>
  )
}

export default PeriodAccordionHeader
