import { FC, useState } from 'react'

import DownloadIcon from '@mui/icons-material/Download'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import Grid from '@mui/material/Grid'
import MenuItem from '@mui/material/MenuItem'
import OutlinedInput from '@mui/material/OutlinedInput'
import Paper from '@mui/material/Paper'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Typography from '@mui/material/Typography'
import { useQueryClient } from '@tanstack/react-query'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import ButtonGridActionCellItem from 'components/atoms/button-grid-action-cell-item'
import { CENTER_TYPES, DateFormats, FontFamily, inputsWidth } from 'config/constants'
import {
  GET_CALENDAR,
  GET_CENTERS,
  GET_DAILY_OCCUPATION,
  GET_IMPORT_JOBS,
  GET_ONE_CENTER,
  GET_PERIODS,
} from 'config/queries'
import { CenterType } from 'models/center'
import { exportCenters, uploadCenters } from 'services/configuration'
import downloadBlob from 'utils/download-blob'
import useAdministrationPermissions, { AdministrationSections } from 'hooks/permissions/use-administration-permissions'

import { Container } from './styled'

const CentersExport: FC = () => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { canWrite } = useAdministrationPermissions(AdministrationSections.configuration)

  const [selectedType, setSelectedType] = useState<CenterType | undefined>(undefined)

  const handleChange = (event: SelectChangeEvent<string>) => {
    if (event.target.value == null || event.target.value === '') {
      setSelectedType(undefined)
    } else {
      setSelectedType(event.target.value as CenterType)
    }
  }

  const handleCsvFormData = (csvFile: Blob) => {
    const formData = new FormData()
    if (!csvFile) {
      toast(t('Ha ocurrido un error en la subida del CSV'), { type: 'error' })
      return formData
    }

    formData.append('file', csvFile)
    return formData
  }

  const uploadFile = async (blob: Blob) => {
    const formData = handleCsvFormData(blob)
    try {
      await uploadCenters(formData)
      queryClient.invalidateQueries({ queryKey: [GET_CENTERS] })
      queryClient.invalidateQueries({ queryKey: [GET_ONE_CENTER] })
      queryClient.invalidateQueries({ queryKey: [GET_PERIODS] })
      queryClient.invalidateQueries({ queryKey: [GET_CALENDAR] })
      queryClient.invalidateQueries({ queryKey: [GET_DAILY_OCCUPATION] })
      queryClient.invalidateQueries({ queryKey: [GET_IMPORT_JOBS] })
      toast(t('Fichero subido correctamente'), { type: 'success' })
    } catch (error) {
      toast(t('Ha ocurrido un error en la subida del fichero'), { type: 'error' })
    }
  }

  const downloadFile = async (filename: string) => {
    try {
      const { data } = await exportCenters(selectedType)
      const date = dayjs().format(DateFormats.fileDate)
      downloadBlob(data, filename.replace('.', `${selectedType != null ? `_${selectedType}` : ''}_${date}.`))
    } catch (error) {
      toast(t('Ha ocurrido un error en la descarga del fichero'), { type: 'error' })
    }
  }

  return (
    <Paper elevation={2} sx={{ marginTop: 4, paddingY: 2 }}>
      <Typography
        component={'h4'}
        fontFamily={FontFamily.bold}
        textAlign='center'
      >{t`Configuración de aparcamiento`}</Typography>
      <Container container spacing={2}>
        <Grid item xs={4}>
          <Select
            style={{ width: inputsWidth.full }}
            displayEmpty
            onChange={handleChange}
            label={t`Tipo de centro`}
            input={<OutlinedInput notched />}
            defaultValue=''
            renderValue={selected => {
              if (!selected) {
                return (
                  <Typography fontStyle='italic' color='gray'>
                    {t`TODOS`}
                  </Typography>
                )
              }

              return selected
            }}
          >
            <MenuItem key='no-selection-value' value=''>
              <Typography fontStyle='italic' color='gray'>
                {t`TODOS`}
              </Typography>
            </MenuItem>

            {CENTER_TYPES.map?.(({ value, text }) => (
              <MenuItem key={value} value={value}>
                {text}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={4}>
          <ButtonGridActionCellItem
            onButtonClick={() => downloadFile('centers.xlsx')}
            label={t('Descargar')}
            Icon={() => <DownloadIcon color='primary' />}
          />
        </Grid>

        <Grid item xs={4}>
          <ButtonGridActionCellItem
            onButtonClick={(blob: Blob | undefined) => (blob ? uploadFile(blob) : Promise.resolve())}
            label={t('Actualizar')}
            Icon={() => <FileUploadIcon color='primary' />}
            isUpload
            disabled={!canWrite}
          />
        </Grid>
      </Container>
    </Paper>
  )
}

export default CentersExport
