import { QueryKey, useInfiniteQuery } from '@tanstack/react-query'

import { GET_PERIODS } from 'config/queries'
import { CenterPeriod } from 'models/center-period'
import { getCenterPeriods } from 'services/periods'

interface UseGetPeriodsProps {
  sort: string
  onlyUnactivePeriods: boolean
}

interface UseGetPeriods {
  response: CenterPeriod[]
  count: number
  isLoading: boolean
  queryKey: QueryKey
  isFetchingNextPage: boolean
  hasNextPage?: boolean
  fetchNextPage: () => void
}

const PERIODS_PAGE_SIZE = 20

const useGetPeriods = (centerId: number, { sort, onlyUnactivePeriods }: UseGetPeriodsProps): UseGetPeriods => {
  const queryKey = [GET_PERIODS, centerId, sort, onlyUnactivePeriods]

  const {
    data: response,
    isLoading,
    isPreviousData,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery({
    queryKey,
    queryFn: ({ pageParam: { limit, offset } = { limit: PERIODS_PAGE_SIZE, offset: 0 } }) =>
      getCenterPeriods(centerId, { limit, offset, sort, onlyUnactivePeriods }),
    getNextPageParam: (lastPage, allPages) =>
      lastPage.headers?.['x-total-count'] > allPages.length * PERIODS_PAGE_SIZE
        ? {
            limit: PERIODS_PAGE_SIZE,
            offset: allPages.length * PERIODS_PAGE_SIZE,
          }
        : undefined,
    refetchOnMount: true,
  })

  const result: CenterPeriod[] = response?.pages.length
    ? response?.pages.map(axiosResponse => axiosResponse.data ?? []).flat()
    : []

  const count: number = response?.pages
    ? parseInt(response.pages[response.pages.length - 1].headers?.['x-total-count'] || 0)
    : 0

  return {
    response: result,
    count,
    isLoading: isLoading || isPreviousData,
    queryKey,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage: () => void fetchNextPage(),
  }
}

export default useGetPeriods
