import React from 'react'

import { toast } from 'react-toastify'

import { SearchSimulatorFormFields } from 'models/simulator'
import PriceCard from 'components/molecules/price-card'
import useGetSimulatedPrebook from 'hooks/queries/simulator/use-get-simulated-prebook'
import SpinnerLoading from 'components/atoms/spinner-loading'

interface Props {
  filters: SearchSimulatorFormFields
}

const SimulatorPrices: React.FC<Props> = ({ filters }) => {
  const { response: simulatedPrebook, isLoading } = useGetSimulatedPrebook(filters)

  if (!simulatedPrebook) {
    return null
  }

  if (simulatedPrebook.message.code !== 'OK') {
    toast(simulatedPrebook.message.description, { type: 'error' })
    return null
  }

  return (
    <>
      {isLoading
        ? <SpinnerLoading />
        : <PriceCard data={simulatedPrebook} center={filters.carpark} />
      }
    </>
  )
}

export default SimulatorPrices
