import React from 'react'

import { useTranslation } from 'react-i18next'
import Grid from '@mui/material/Grid'
import { Box } from '@mui/material'

import CarparkOccupationChart from 'components/molecules/carpark-occupation-chart'
import MonthSelector from 'components/atoms/month-selector'
import useMonthSelector from 'hooks/use-month-selector'
import H2 from 'components/atoms/h2'
import { Center } from 'models/center'
import useGetDailyOccupation from 'hooks/queries/prebooks/use-get-daily-occupation'
import { dateFormatter } from 'utils/date-formatter'
import { useTabStore } from 'store/useTabStore'
import { DateFormats } from 'config/constants'
import CancelButton from 'components/atoms/cancel-button'

interface Props {
  center: Center
}

const CarparkOccupation: React.FC<Props> = ({ center }) => {
  const { t } = useTranslation()

  const { date, onPrev, onNext } = useMonthSelector()

  const setTabIndex = useTabStore(store => store.setTabIndex)

  const { response: occupation } = useGetDailyOccupation({
    from: dateFormatter({ date: date.startOf('month'), pattern: DateFormats.isoCeros }),
    to: dateFormatter({ date: date.endOf('month'), pattern: DateFormats.isoCeros }),
    carparkCode: center?.carparkCode,
  })

  const handleGoBack = () => {
    setTabIndex(prevIndex => Math.max(prevIndex - 1, 0))
  }

  return (
    <>
      <H2 text={t('Ocupación del centro')} />
      <Grid container spacing={2} display='flex' alignItems='center' flexDirection='column'>
        <MonthSelector date={date} onPrev={onPrev} onNext={onNext} />
        {center && <CarparkOccupationChart data={occupation} config={center} />}
      </Grid>
      <Box display='flex' alignItems='center' justifyContent='center' columnGap='16px' paddingTop={5}>
        <CancelButton onClick={handleGoBack} />
      </Box>
    </>
  )
}

export default CarparkOccupation
