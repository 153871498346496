import styled, { css } from 'styled-components'

import { colors } from 'config/theme'
import { FontFamily, FontSizes } from 'config/constants'

interface Props {
  weekend?: boolean
  noProducts?: boolean
  limitReached?: boolean
  aboveAverage?: boolean
  disabled?: boolean
}

export const CalendarDayContainer = styled.div`
  border: 1px solid ${colors.deepBlue};
  background: ${colors.white};
  border-radius: 5px;
  padding: 2px 5px;
  height: 100%;
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  color: ${colors.deepBlue};

  ${(props: Props) =>
    props.aboveAverage &&
    css`
      color: ${colors.red};
    `}

  ${(props: Props) =>
    props.weekend &&
    css`
      background: ${colors.grey};
    `}

  ${(props: Props) =>
    props.limitReached &&
    css`
      background-image: linear-gradient(
        45deg,
        #e3e3e3 25%,
        #d67c7c 25%,
        #d67c7c 50%,
        #e3e3e3 50%,
        #e3e3e3 75%,
        #d67c7c 75%,
        #d67c7c 100%
      );
      background-size: 14.14px 14.14px;
    `}

  ${(props: Props) =>
    props.noProducts &&
    css`
      background-image: linear-gradient(
        45deg,
        #e3e3e3 25%,
        #bdbdbd 25%,
        #bdbdbd 50%,
        #e3e3e3 50%,
        #e3e3e3 75%,
        #bdbdbd 75%,
        #bdbdbd 100%
      );
      background-size: 14.14px 14.14px;
    `}

    ${(props: Props) =>
    !props.disabled &&
    css`
      &:hover {
        border: 1px solid ${colors.orange};
      }
    `}
    
  p {
    margin: 0;
  }

  input {
    width: 100%;
    max-width: 60px;
  }

  button {
    position: absolute;
    top: 3px;
    right: 3px;
  }
`

export const CalendarDayPriceContainer = styled.div`
  display: flex;
  gap: 4px;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
`

export const CalendarDayPrice = styled.div`
  font-family: ${FontFamily.bold};
  font-size: ${FontSizes.xxl};
`

interface CalendarDaySurchageProps {
  red?: boolean
  green?: boolean
  orange?: boolean
}

export const CalendarDaySurchage = styled.span`
  font-family: ${FontFamily.medium};
  font-size: ${FontSizes.xxs};
  background-color: ${colors.grey};
  border-radius: 10px;
  padding: 0 5px;
  vertical-align: middle;
  color: ${colors.white};
  margin: 0;

  ${(props: CalendarDaySurchageProps) =>
    props.green &&
    css`
      background-color: ${colors.green};
    `}
  ${(props: CalendarDaySurchageProps) =>
    props.orange &&
    css`
      background-color: ${colors.orange};
    `}
  ${(props: CalendarDaySurchageProps) =>
    props.red &&
    css`
      background-color: ${colors.red};
    `}
`

export const CalendarDayDays = styled.div`
  font-family: ${FontFamily.medium};
  font-size: ${FontSizes.md};
  margin-bottom: 2px;
`

export const CalendarDayOccupation = styled.div`
  font-family: ${FontFamily.medium};
  font-size: ${FontSizes.md};
  margin-bottom: 8px;
`
