import React from 'react'

import { Pagination as MuiPagination, PaginationItem, TablePaginationProps } from '@mui/material'
import { gridPageCountSelector, useGridApiContext, useGridSelector, GridPagination } from '@mui/x-data-grid'

import { useAuth } from 'providers/auth'
import { formatNumber } from 'utils/number-formatter'

const Pagination = ({
  page,
  onPageChange,
  className,
}: Pick<TablePaginationProps, 'page' | 'onPageChange' | 'className'>) => {
  const apiRef = useGridApiContext()
  const pageCount = useGridSelector(apiRef, gridPageCountSelector)
  const { language } = useAuth()

  return (
    <MuiPagination
      color='primary'
      className={className}
      count={pageCount}
      size='small'
      variant='outlined'
      page={page + 1}
      showFirstButton
      showLastButton
      onChange={(event: unknown, newPage: number) => {
        onPageChange(event as React.MouseEvent<HTMLButtonElement, MouseEvent>, newPage - 1)
      }}
      renderItem={item => <PaginationItem {...item} page={formatNumber(item?.page || 0, language)} />}
    />
  )
}

const CustomPagination = (): JSX.Element => {
  return <GridPagination ActionsComponent={Pagination} />
}

export default CustomPagination
