import { FC, useEffect, useState } from 'react'

import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'

import CountChip from 'components/atoms/count-chip'
import ResourceHeader from 'components/atoms/resource-header'

import ResourceFilter from '../resource-filter'

interface AlertsTableHeaderProps {
  badge: number
  count: number
  openFilter: boolean
  isLoading?: boolean
  onSearchQ: (event: React.ChangeEvent<HTMLInputElement>) => void
  onToggleFilter: () => void
}

const AlertsTableHeader: FC<AlertsTableHeaderProps> = ({
  badge,
  count,
  isLoading,
  openFilter,
  onSearchQ,
  onToggleFilter,
}) => {
  const { t } = useTranslation()

  const [searchQuery, setSearchQuery] = useState('')

  useEffect(() => {
    const storedData = sessionStorage.getItem('alertsSearch')
    if (storedData) {
      const parsedData = JSON.parse(storedData)
      onSearchQ({ target: { value: parsedData } } as React.ChangeEvent<HTMLInputElement>)
      setSearchQuery(parsedData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSearchQ = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value)
    sessionStorage.setItem('alertsSearch', JSON.stringify(event.target.value))
    onSearchQ(event)
  }

  const handleClearSearch = () => {
    setSearchQuery('')
    sessionStorage.removeItem('alertsSearch')
    onSearchQ({ target: { value: '' } } as React.ChangeEvent<HTMLInputElement>)
  }

  return (
    <Box width='100%' display='flex' flexDirection='column' rowGap={2} minHeight={72} justifyContent='center'>
      <ResourceHeader>
        <CountChip label={t`alertas`} labelSingular={t`alerta`} count={count} isLoading={isLoading} />
        <ResourceFilter
          badge={badge}
          searchQuery={searchQuery}
          label={t`Nombre, código de centro, severidad ...`}
          openFilter={openFilter}
          onSearchQ={handleSearchQ}
          onToggleFilter={onToggleFilter}
          handleClearSearch={handleClearSearch}
        />
      </ResourceHeader>
    </Box>
  )
}

export default AlertsTableHeader
