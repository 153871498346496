import { FC, useCallback, useEffect, useMemo, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Grid } from '@mui/material'

import { getSeverityText } from 'components/atoms/alert-severity-cell/utils'
import BasicButton from 'components/atoms/basic-button'
import ControllerInputSelect from 'components/molecules/controller-input-select'
import CarparkSearcher from 'components/molecules/locations-searcher'
import { ALERT_CODE_OPTIONS } from 'config/alerts-constants'
import { AlertsFilters as AlertsFilterFields } from 'models/alert'
import AlertCode from 'models/alert-code'
import AlertSeverity from 'models/alert-severity'
import { AlertsFilterForm, alertsFilterDefaultValues, alertsFilterSchema } from 'validations/alerts'
import { ClearFiltersButton } from 'components/atoms/clear-filter-button'

import { FilterGridContainer } from './styled'

interface AlertsFilterProps {
  onFilter: (filters: AlertsFilterFields, reset?: boolean) => void
}

const AlertsFilter: FC<AlertsFilterProps> = ({ onFilter }) => {
  const { t } = useTranslation()

  const [storedData, setStoredData] = useState<AlertsFilterFields | null>(null)
  const [reRenderKey, setReRenderKey] = useState(0)

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
  } = useForm<AlertsFilterForm>({
    defaultValues: alertsFilterDefaultValues,
    resolver: yupResolver(alertsFilterSchema),
  })

  const alertSeverityOptions = useMemo(
    () =>
      Object.values(AlertSeverity).map(severity => ({
        value: severity,
        text: getSeverityText(t, severity),
      })),
    [t]
  )

  const submitHandler = (useCallback((values: AlertsFilterForm) => {
    const filters: AlertsFilterFields = {
      ...values,
      severity: values.severity ? (values.severity as AlertSeverity) : undefined,
      code: values.code ? (values.code as AlertCode) : undefined,
    }
    sessionStorage.setItem('alertsFilter', JSON.stringify(filters))

    onFilter(filters)
  }, [onFilter]))

  const handleReset = () => {
    onFilter({}, true)
    sessionStorage.removeItem('alertsFilter')
    reset(alertsFilterDefaultValues)
    setStoredData(null)
    setReRenderKey(Math.random()) // Needed to restore carpark searcher value
  }

  useEffect(() => {
    const storage = sessionStorage.getItem('alertsFilter')
    if (storage) {
      const parsedData = JSON.parse(storage)
      for (const key in parsedData) {
        setValue(key as keyof AlertsFilterForm, parsedData[key])
      }

      setStoredData(parsedData)
      onFilter(parsedData)
    }
    // eslint-disable-next-line
  }, [])

  const [carparkWatch] = watch(['carpark'])

  useEffect(() => {
    setValue('carparkCode', carparkWatch?.carparkCode || undefined)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carparkWatch]
  )



  return (
    <FilterGridContainer container pr={1}>
      <Grid item md={4} pr={1}>
        <CarparkSearcher
          key={reRenderKey}
          control={control}
          name='carpark'
          fullValue
          error={errors.carparkCode}
          initialValue={storedData?.carpark}
        />
      </Grid>

      <Grid item md={4} pr={1}>
        <ControllerInputSelect
          control={control}
          name='severity'
          error={errors.severity}
          label={t`Severidad`}
          size='full'
          options={alertSeverityOptions}
        />
      </Grid>

      <Grid item md={4} pr={1}>
        <ControllerInputSelect
          control={control}
          name='code'
          error={errors.code}
          label={t`Mensaje`}
          size='full'
          options={ALERT_CODE_OPTIONS}
        />
      </Grid>

      <Grid item md={12} display='flex' justifyContent='flex-end' flexDirection='row' gap={3}>
        <ClearFiltersButton handleClearFilters={handleReset} color={'default'} />
        <BasicButton handleClick={handleSubmit(submitHandler)} text={t('Buscar')} size='small' />
      </Grid>
    </FilterGridContainer>
  )
}

export default AlertsFilter
