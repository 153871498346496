import styled from 'styled-components'

export const FloatingSubmitButton = styled('div')`
  position: fixed;
  bottom: 1.5rem;
  right: 1.5rem;

  @media (min-width: 1466px) {
    right: calc(50% - 723px);
  }
`
