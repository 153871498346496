import { FC, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import DownloadIcon from '@mui/icons-material/Download'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import Grid from '@mui/material/Grid'
import MenuItem from '@mui/material/MenuItem'
import OutlinedInput from '@mui/material/OutlinedInput'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Typography from '@mui/material/Typography'
import { AxiosResponse } from 'axios'
import dayjs from 'dayjs'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import ButtonGridActionCellItem from 'components/atoms/button-grid-action-cell-item'
import ControllerInputDateTimePicker from 'components/molecules/controller-input-datetimepicker'
import { CENTER_TYPES, DateFormats, inputsWidth } from 'config/constants'
import { CenterType } from 'models/center'
import { DayPricesExportFilters } from 'models/day-prices'
import { exportPrices, uploadPrices } from 'services/configuration'
import downloadBlob from 'utils/download-blob'
import { exportDayPricesSchema } from 'validations/dayPrices'
import useAdministrationPermissions, { AdministrationSections } from 'hooks/permissions/use-administration-permissions'

import { Container } from './styled'

const defaultValues: DayPricesExportFilters = {
  from: new Date().toISOString(),
  to: null,
  centerType: null,
}

const DayPricesExportForm: FC = () => {
  const { t } = useTranslation()
  const { canWrite } = useAdministrationPermissions(AdministrationSections.configuration)

  const [selectedType, setSelectedType] = useState<CenterType | null>(null)

  const {
    control,
    formState: { errors },
    getValues,
  } = useForm<DayPricesExportFilters>({
    defaultValues,
    resolver: yupResolver(exportDayPricesSchema),
  })

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    if (event.target.value == null || event.target.value === '') {
      setSelectedType(null)
    } else {
      setSelectedType(event.target.value as CenterType)
    }
  }

  const handleCsvFormData = (csvFile: Blob) => {
    const formData = new FormData()
    if (!csvFile) {
      toast(t('Ha ocurrido un error en la subida del CSV'), { type: 'error' })
      return formData
    }

    formData.append('file', csvFile)
    return formData
  }

  const uploadFile = async (blob: Blob, uploadService: (formData: FormData) => Promise<AxiosResponse>) => {
    const formData = handleCsvFormData(blob)
    try {
      await uploadService(formData)
      toast(t('Fichero subido correctamente'), { type: 'success' })
    } catch (error) {
      toast(t('Ha ocurrido un error en la subida del fichero'), { type: 'error' })
    }
  }

  const handleDownload = async () => {
    const [from, to] = getValues(['from', 'to'])
    if (from == null) {
      return
    }

    const { data } = await exportPrices({ from, to, centerType: selectedType })
    const dateFrom = from ? dayjs(from).format(DateFormats.fileDate) : 'no_date'
    const dateTo = to ? dayjs(to).format(DateFormats.fileDate) : 'no_date'
    const filename = `day_prices_${dateFrom}_${dateTo}.xlsx`
    downloadBlob(data, filename)
  }

  return (
    <Container container spacing={2}>
      <Grid item xs={6}>
        <ControllerInputDateTimePicker
          mandatory
          onlyDate
          label={t('Desde')}
          name='from'
          control={control}
          error={errors.from}
          size='full'
          pattern={DateFormats.isoCeros}
        />
      </Grid>
      <Grid item xs={6}>
        <ControllerInputDateTimePicker
          onlyDate
          label={t('Hasta')}
          name='to'
          control={control}
          error={errors.to}
          size='full'
          pattern={DateFormats.isoCeros}
        />
      </Grid>

      <Grid item xs={4}>
        <Select
          style={{ width: inputsWidth.full }}
          displayEmpty
          onChange={handleSelectChange}
          label={t`Tipo de centro`}
          input={<OutlinedInput notched />}
          defaultValue=''
          renderValue={selected => {
            if (!selected) {
              return (
                <Typography fontStyle='italic' color='gray'>
                  {t`TODOS`}
                </Typography>
              )
            }

            return selected
          }}
        >
          <MenuItem key='no-selection-value' value=''>
            <Typography fontStyle='italic' color='gray'>
              {t`TODOS`}
            </Typography>
          </MenuItem>

          {CENTER_TYPES.map?.(({ value, text }) => (
            <MenuItem key={value} value={value}>
              {text}
            </MenuItem>
          ))}
        </Select>
      </Grid>

      <Grid item xs={4}>
        <ButtonGridActionCellItem
          onButtonClick={() => handleDownload()}
          label={t('Descargar')}
          Icon={() => <DownloadIcon color='primary' />}
        />
      </Grid>

      <Grid item xs={4}>
        <ButtonGridActionCellItem
          onButtonClick={(blob: Blob | undefined) => (blob ? uploadFile(blob, uploadPrices) : Promise.resolve())}
          key={1}
          label={t('Actualizar')}
          Icon={() => <FileUploadIcon color='primary' />}
          isUpload
          disabled={!canWrite}
        />
      </Grid>
    </Container>
  )
}

export default DayPricesExportForm
