import { useEffect, useRef, useState } from 'react'

const useSubmitButtonVisibility = () => {
  const submitButtonRef = useRef<HTMLDivElement>(null)
  const [isSubmitButtonVisible, setIsSubmitButtonVisible] = useState(true)

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsSubmitButtonVisible(entry.isIntersecting)
      },
      { threshold: 0.1 }
    )

    const currentSubmitButton = submitButtonRef.current

    if (currentSubmitButton) {
      observer.observe(currentSubmitButton)
    }

    return () => {
      if (currentSubmitButton) {
        observer.unobserve(currentSubmitButton)
      }
    }
  }, [])

  return { submitButtonRef, isSubmitButtonVisible }
}

export default useSubmitButtonVisibility
