import React from 'react'

import { useTranslation } from 'react-i18next'

import H3 from 'components/atoms/h3'
import { EvolutionSalesCharts } from 'models/prebooks'
import CircularProgress from 'components/atoms/circular-progress'
import PrebooksEvolutionChart from 'components/molecules/prebooks-evolution-chart'
import { EvolutionChartTypes } from 'config/constants'

interface Props {
  salesData: Array<EvolutionSalesCharts>
  isLoading: boolean
}

const PrebooksSalesEvolution: React.FC<Props> = ({ salesData, isLoading }) => {

  const { t } = useTranslation()

  return (
    <>
      <H3 text={t('Evolución de ventas')} />
      {isLoading ? <CircularProgress /> : <PrebooksEvolutionChart data={salesData} type={EvolutionChartTypes.sales} />}
    </>
  )
}

export default PrebooksSalesEvolution
