import { FC } from 'react'

import { GridSelectionModel } from '@mui/x-data-grid'
import { Control, Controller } from 'react-hook-form'

import { TableTypes } from 'config/constants'
import UsersList from 'components/pages/users/users-list'

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>
  disabled?: boolean
  isRelationsSearch?: boolean
}

const ControllerUsersList: FC<Props> = ({ control, disabled, isRelationsSearch = false }) => {
  return (
    <Controller
      name='managedUsers'
      control={control}
      render={({ field }) => (
        <UsersList
          type={disabled ? TableTypes.disabled : TableTypes.selection}
          selectionModel={!disabled ? (field.value as GridSelectionModel) : []}
          onSelectionModelChange={field.onChange}
          isRelationsSearch={isRelationsSearch}
        />
      )}
    />
  )
}

export default ControllerUsersList
