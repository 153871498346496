import { createTheme } from '@mui/material/styles'
import * as locales from '@mui/material/locale'
import * as dataGridLocales from '@mui/x-data-grid/locales'
import * as datePickerLocales from '@mui/x-date-pickers/locales'
import { createGlobalStyle } from 'styled-components'

import { defaultLanguage } from './i18n'
import { ENVIRONMENT } from './constants'

const palette = {
  orange: '#ff7e00',
  lightOrange: '#F5AC56',
  green: '#1b7742',
  lightGreen: '#b7ffd5',
  red: '#a31f28',
  lightRed: '#ffb3a5',
  deepBlue: '#202245',
  grey: '#e0e0e0',
  lightGrey: '#efefef',
  darkGreyShadow: 'rgba(0, 0, 0, 0.26)',
  darkGrey: '#a0a0a0',
  white: '#ffffff',
  whiteShadow: '#ffffffb3',
  purple: '#bab1fb',
  lightPurple: '#bab1fb88',
}

export const getTheme = code => {
  const muiLocales = Object.keys(locales).find(locale => locale.includes(code || defaultLanguage))

  // eslint-disable-next-line import/namespace
  const muiLocale = locales[muiLocales]
  // eslint-disable-next-line import/namespace
  const dataGridLocale = dataGridLocales[muiLocales]
  // eslint-disable-next-line import/namespace
  const datePickersLocales = datePickerLocales[muiLocales]

  const theme = createTheme(
    {
      palette: {
        primary: {
          main: palette.orange,
          contrastText: palette.white,
          light: palette.orange,
          dark: palette.orange,
        },
        secondary: {
          main: palette.lightOrange,
          contrastText: palette.white,
        },
        success: {
          main: palette.green,
          contrastText: palette.white,
        },
        error: {
          main: palette.red,
          contrastText: palette.white,
        },
        info: {
          main: palette.deepBlue,
          contrastText: palette.white,
        },
        warning: {
          main: palette.grey,
          contrastText: palette.deepBlue,
        },
      },
      typography: {
        fontFamily: 'Poppins-Medium',
        fontSize: 14,
      },
      components: {
        MuiButton: {
          defaultProps: {},
          styleOverrides: {
            root: {
              height: 48,
              boxShadow:
                '0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%)',
              borderRadius: 24,
              '&:hover': {
                opacity: 0.9,
                boxShadow:
                  '0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%)',
              },
              fontSize: '1rem',
            },
          },
        },
        MuiCheckbox: {
          defaultProps: {},
          styleOverrides: {
            root: {
              backgroundColor: palette.white,
              '&:hover': {
                opacity: 0.9,
                backgroundColor: palette.white,
                boxShadow: 'none',
              },
            },
          },
        },
        MuiTypography: {
          defaultProps: {},
          styleOverrides: {
            root: {
              color: palette.deepBlue,
              transition: 'color .2s linear',
            },
          },
        },
        MuiTab: {
          defaultProps: {},
          styleOverrides: {
            root: {
              color: palette.deepBlue,
              fontFamily: 'Poppins-Medium',
              fontWeight: 900,
              fontSize: '1rem',
            },
          },
        },
        MuiChip: {
          defaultProps: {},
          styleOverrides: {
            root: {
              fontFamily: 'Poppins-Medium',
              fontSize: '0.875rem',
              lineHeight: '1.25rem',
              fontWeight: 400,
            },
          },
        },
        MuiPaginationItem: {
          defaultProps: {},
          styleOverrides: {
            root: {
              fontSize: '0.7rem',
              margin: '0 .2rem',
            },
          },
        },
        MuiFormHelperText: {
          defaultProps: {},
          styleOverrides: {
            root: {
              margin: '3px 12px 0px',
            },
          },
        },
        MuiDataGrid: {
          defaultProps: {},
          styleOverrides: {
            root: {
              background: palette.white,
              color: palette.deepBlue,
              borderRadius: '10px 10px 0 0 ',
            },
            columnHeaders: {
              background: palette.lightOrange,
              borderRadius: '10px 10px 0 0 ',
            },
            columnHeaderTitle: {
              color: palette.deepBlue,
              fontFamily: 'Poppins-Bold',
              fontSize: '1rem',
              textTransform: 'uppercase',
            },
            row: {
              background: palette.white,
              color: palette.deepBlue,
              cursor: 'pointer',
            },
            cellContent: {
              color: palette.deepBlue,
              fontSize: '1rem',
            },
            cell: {
              '&:focus': {
                outline: 'none',
              },
            },
            iconSeparator: {
              opacity: 0,
            },
          },
        },
      },
    },
    muiLocale,
    dataGridLocale,
    datePickersLocales
  )

  return theme
}

export const GlobalStyle = createGlobalStyle(() => {
  if (ENVIRONMENT.toUpperCase() === 'DEV') {
    return `
      body {
        background: linear-gradient(180deg,#cfd2ff 0%,#e1e2fc 10.45%,#FFFFFF 41.35%);
        background-color: #cfd2ff;
      }
  `
  }

  if (ENVIRONMENT.toUpperCase() === 'TEST') {
    return `
      body {
        background: linear-gradient(180deg,#a3f1c4 0%,#d7f8e5 10.45%,#FFFFFF 41.35%);
        background-color: #a3f1c4;
      }
  `
  }
})

export const colors = palette
