/* eslint-disable @typescript-eslint/no-explicit-any */
import { TFunction } from 'i18next'

import { colors } from 'config/theme'
import { Point, Profile } from 'models/profiles'
import { OnDragEnd } from 'models/chartjs-plugin-dragdata-types'

export const getChartOptions: any = (t: TFunction, onDragEnd: OnDragEnd, dragEnabled: boolean) => ({
  responsive: true,
  plugins: {
    dragData: dragEnabled
      ? {
          round: 0,
          onDragEnd,
        }
      : false,
    legend: {
      position: 'top' as const,
    },
    title: {
      display: false,
    },
  },
  elements: {
    line: {
      tension: 0.25,
    },
  },
  scales: {
    y: {
      title: {
        display: true,
        text: t('Porcentaje'),
      },
      max: 100,
      min: 0,
    },
    x: {
      title: {
        display: true,
        text: t('Días'),
      },
    },
  },
})

const chartColors = [
  {
    borderColor: colors.purple,
    backgroundColor: colors.lightPurple,
  },
  {
    borderColor: colors.red,
    backgroundColor: colors.lightRed,
  },
  {
    borderColor: colors.green,
    backgroundColor: colors.lightGreen,
  },
  {
    borderColor: colors.grey,
    backgroundColor: colors.lightGrey,
  },
  {
    borderColor: colors.deepBlue,
    backgroundColor: colors.darkGreyShadow,
  },
]

export const getChartData: any = (t: TFunction, profile: Profile, profiles: Array<Profile>, points: Array<Point>) => ({
  labels: Array.from(Array(32).keys()).slice(1),
  datasets: [
    {
      label: profile?.code.substring(0, 20),
      data: points?.map(({ discount }) => `${discount}`),
      borderColor: colors.orange,
      backgroundColor: colors.lightOrange,
    },
    ...(profiles?.map((p, i) => ({
      label: p.code.substring(0, 20),
      data: p.points?.map(({ discount }) => `${discount}`),
      borderColor: chartColors[i % chartColors.length].borderColor,
      backgroundColor: chartColors[i % chartColors.length].backgroundColor,
    })) || []),
  ],
})
