import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { GET_APPLICATION_TOKEN, GET_APPLICATIONS, GET_ONE_APPLICATION } from 'config/queries'
import { updateOneApplication } from 'services/applications'
import { Application, ApplicationToken } from 'models/applications'

const useGetApplicationToken = (appData: Application) => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  const queryKey = [GET_APPLICATION_TOKEN]
  const {
    data: response,
    isLoading,
    isFetching,
    refetch,
    remove,
  } = useQuery({
    queryKey,
    queryFn: () =>
      updateOneApplication({
        id: appData.id,
        payload: { renewToken: true, active: appData.active, name: appData.name, role: appData.role },
      }),
    enabled: false,
    onError: () => {
      toast(t('Ha ocurrido un error'), { type: 'error' })
    },
    onSuccess: res => {
      const applicationQueryKey = [GET_ONE_APPLICATION, `${res?.data?.id}`]
      queryClient.invalidateQueries({ queryKey: applicationQueryKey, refetchType: 'none' })
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [GET_APPLICATIONS] })
    },
  })

  const result: ApplicationToken = response?.data

  return { response: result, isLoading, isFetching, refetch, remove }
}

export default useGetApplicationToken
