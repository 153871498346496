import React from 'react'

import { GridActionsCellItem } from '@mui/x-data-grid'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { Tooltip } from '@mui/material'
import { t } from 'i18next'

interface Props {
  onClick: () => void
}

const ShowGridActionCellItem: React.FC<Props> = ({ onClick }) => (
  <Tooltip title={t('Consultar')}>
    <GridActionsCellItem icon={<VisibilityIcon color='primary' />} label='show' onClick={onClick} />
  </Tooltip>
)

export default ShowGridActionCellItem
