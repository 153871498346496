import React from 'react'

import { useTranslation } from 'react-i18next'

import H3 from 'components/atoms/h3'
import { EvolutionPricesCharts } from 'models/prebooks'
import CircularProgress from 'components/atoms/circular-progress'
import PrebooksEvolutionChart from 'components/molecules/prebooks-evolution-chart'
import { EvolutionChartTypes } from 'config/constants'

interface Props {
  pricesData: EvolutionPricesCharts[]
  isLoading: boolean
}

const PrebooksPricesEvolution: React.FC<Props> = ({ pricesData, isLoading }) => {
  const { t } = useTranslation()

  return (
    <>
      <H3 text={t('Evolución de precios')} />
      {isLoading ? <CircularProgress /> : <PrebooksEvolutionChart data={pricesData} type={EvolutionChartTypes.price} />}
    </>
  )
}

export default PrebooksPricesEvolution
