import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { createUser } from 'services/user'
import { CreateUser } from 'models/users'
import { GET_USERS } from 'config/queries'

const useCreateUser = () => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const createUserMutation = useMutation({
    mutationFn: (payload: CreateUser) => createUser(payload),
    onSuccess: () => {
      toast(t('Usuario creado'), { type: 'success' })
      queryClient.invalidateQueries({ queryKey: [GET_USERS] })
    },
    onError: () => {
      toast(t('Ha ocurrido un error'), { type: 'error' })
    },
  })

  return createUserMutation
}

export default useCreateUser
