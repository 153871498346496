import * as yup from 'yup'

import { isEqualDate, isMinorDate } from './utils'

export const searchPrebooksSchema = yup
  .object({
    from: yup.string().required(),
    to: yup.string().test(isMinorDate).test(isEqualDate).required(),
    carparkCode: yup.string(),
    channelCodes: yup.array().of(yup.string()),
    centerType: yup.string(),
    cancellationFilterDateType: yup.string(),
  })
  .required()

export const exportPrebooksSchema = yup
  .object({
    from: yup.string().required(),
    to: yup.string().test(isMinorDate).required(),
    carparkCodes: yup.array().of(yup.string().required()),
    channelCode: yup.string(),
    centerType: yup.string(),
    cancellationFilterDateType: yup.string(),
  })
  .required()
