import * as React from 'react'

import TextField from '@mui/material/TextField'
import { DatePicker } from '@mui/x-date-pickers'
import { CalendarPickerView } from '@mui/x-date-pickers/internals/models'
import { Dayjs } from 'dayjs'
import CloseIcon from '@mui/icons-material/Close'

import { DateFormats, inputsWidth } from 'config/constants'
import { Sizes } from 'models/sizes'
import { ControllerField } from 'models/form'
import { dateFormatter, zeroTime } from 'utils/date-formatter'

import { StyledBox, StyledIconButton } from './styled'

interface Props {
  disabled?: boolean
  label: string
  field: ControllerField
  size?: Sizes
  helperText?: string
  error?: boolean
  mandatory?: boolean
  minDate?: Dayjs
  pattern?: string
  onlyDate?: boolean
}

const BasicDatePicker: React.FC<Props> = ({
  disabled,
  label,
  field: { value, onChange, ...rest },
  size = 'medium',
  error,
  helperText,
  mandatory,
  minDate,
  pattern,
  onlyDate,
}) => {
  const [isOpen, setIsOpen] = React.useState(false)

  const onlyDateProps = onlyDate && {
    views: ['year', 'month', 'day'] as CalendarPickerView[],
    inputFormat: DateFormats.day,
  }

  const handleOnTimeChange = (date: Dayjs | null) => {
    let newDate = minDate?.isAfter(date) ? minDate : date

    if (onlyDate) {
      newDate = newDate ? zeroTime(newDate) : newDate
    }

    onChange(dateFormatter({ date: newDate, pattern: pattern ?? DateFormats.iso }))
  }

  return (
    <StyledBox width={inputsWidth[size]}>
      <DatePicker
        {...rest}
        {...onlyDateProps}
        value={value}
        label={mandatory ? `${label} *` : label}
        open={isOpen}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        onChange={handleOnTimeChange}
        minDate={minDate}
        renderInput={params => (
          <>
            <TextField
              {...params}
              fullWidth
              error={error}
              helperText={helperText}
              name={rest.name}
              autoComplete={'off'}
              inputProps={{ ...params.inputProps, readOnly: true }}
              onClick={() => !disabled && setIsOpen(true)}
            />
            {!disabled && value && (
              <StyledIconButton onClick={() => onChange(null)}>
                <CloseIcon />
              </StyledIconButton>
            )}
          </>
        )}
        disabled={disabled}
      />
    </StyledBox>
  )
}
export default BasicDatePicker
