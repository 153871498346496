import styled from 'styled-components'
import { IconButton } from '@mui/material'

import BasicInputSwitch from 'components/atoms/basic-input-switch'
import { colors } from 'config/theme'

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 650px;
  margin: 0 auto;

  .date-container {
    max-width: 236px;
  }

  strong {
    color: ${colors.orange};
  }
`

export const InputSwitch = styled(BasicInputSwitch)`
  > .MuiBox-root {
    justify-content: center;
  }
`

export const StyledIconButton = styled(IconButton)`
  place-self: flex-end;
  transform: translateY(20px);
  background-color: ${colors.orange};
  color: ${colors.white};

  &:hover {
    color: ${colors.orange};
  }
`
