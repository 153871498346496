import { QueryKey, useMutation, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { GET_CALENDAR, GET_CENTERS } from 'config/queries'
import { CalendarDay, PostCenterDay } from 'models/center'
import { postOneCenterDay } from 'services/centers'

const usePostCenterDay = (queryKey?: QueryKey) => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  return useMutation({
    mutationFn: ({ centerId, date, price }: PostCenterDay) => postOneCenterDay(centerId, date, price),
    onSuccess: () => toast(t('Precio añadido'), { type: 'success' }),
    onMutate: async newValue => {
      await queryClient.cancelQueries({ queryKey: [GET_CALENDAR] })
      const previous = queryClient.getQueryData<{ data: CalendarDay[] }>(queryKey ?? [GET_CALENDAR])
      previous?.data.push({ date: newValue.date, price: newValue.price })
      return { previous }
    },
    onError: (error, newValue, context) => {
      queryClient.setQueryData([GET_CENTERS], context?.previous)
      toast(t('Ha ocurrido un error'), { type: 'error' })
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [GET_CALENDAR] })
    },
  })
}

export default usePostCenterDay
