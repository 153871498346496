import React from 'react'

import { useTranslation } from 'react-i18next'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Line } from 'react-chartjs-2'

import { EvolutionPricesCharts, EvolutionSalesCharts } from 'models/prebooks'
import { EvolutionChartTypes } from 'config/constants'

import { getChartData, getChartOptions } from './utils'

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend)

interface Props {
  data: Array<EvolutionSalesCharts | EvolutionPricesCharts>
  type: EvolutionChartTypes
}

const PrebooksEvolutionChart: React.FC<Props> = ({ data, type }) => {
  const { t } = useTranslation()

  const chartOptions = getChartOptions(type, t)
  const chartData = getChartData(t, data)

  return <Line options={chartOptions} data={chartData} />
}

export default PrebooksEvolutionChart
