import { FC, useCallback } from 'react'

import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

import { DateFormats, FontFamily } from 'config/constants'
import { PrebooksExportFilters } from 'models/prebooks'
import { exportPurchases } from 'services/prebooks'
import downloadBlob from 'utils/download-blob'

import PrebooksExportForm from '../prebooks/prebooks-export-form'

const PrebooksExport: FC = () => {
  const { t } = useTranslation()

  const handleDownload = useCallback(async (filters: PrebooksExportFilters) => {
    if (!filters) {
      return
    }

    const { data } = await exportPurchases(filters)
    const centers = filters.carparkCodes?.length ? '_' + filters.carparkCodes?.join('-') : ''
    const dateFrom = dayjs(filters.from).format(DateFormats.fileDate)
    const dateTo = dayjs(filters.to).format(DateFormats.fileDate)
    const filename = `prebooks${centers}_${dateFrom}_${dateTo}.xlsx`
    downloadBlob(data, filename)
  }, [])

  return (
    <Paper elevation={2} sx={{ marginTop: 4, paddingY: 2 }}>
      <Typography component={'h4'} fontFamily={FontFamily.bold} textAlign='center'>{t`Exportar ventas`}</Typography>
      <PrebooksExportForm onSubmit={handleDownload} />
    </Paper>
  )
}

export default PrebooksExport
