/* eslint-disable @typescript-eslint/no-explicit-any */
import { TFunction } from 'i18next'

import { colors } from 'config/theme'
import { CreateProfile, IntervalDto, Profile } from 'models/profiles'
import { OnDragEnd } from 'models/chartjs-plugin-dragdata-types'

const chartColors = [
  {
    borderColor: colors.purple,
    backgroundColor: colors.lightPurple,
  },
  {
    borderColor: colors.red,
    backgroundColor: colors.lightRed,
  },
  {
    borderColor: colors.green,
    backgroundColor: colors.lightGreen,
  },
  {
    borderColor: colors.grey,
    backgroundColor: colors.lightGrey,
  },
  {
    borderColor: colors.deepBlue,
    backgroundColor: colors.darkGreyShadow,
  },
]

export const getChartOptions: any = (t: TFunction, onDragEnd: OnDragEnd, dragEnabled: boolean) => ({
  responsive: true,
  plugins: {
    dragData: dragEnabled
      ? {
          round: 0,
          onDragEnd,
        }
      : false,
    legend: {
      position: 'top' as const,
    },
    title: {
      display: false,
    },
  },
  elements: {
    line: {
      tension: 0.25,
    },
  },
  scales: {
    y: {
      title: {
        display: true,
        text: t('(%) Descuento / Sobrecargo'),
      },
      max: 100,
      min: -100,
    },
    x: {
      title: {
        display: true,
        text: t('Intervalos de horas'),
      },
    },
  },
})

export const getChartData = (
  t: TFunction,
  profile: Profile | CreateProfile,
  profiles: Array<Profile>,
  intervals: Array<IntervalDto>
) => {
  const labels = intervals.map(interval => `${interval.from} - ${interval.to}`)
  const data = intervals.map(interval => interval.percentageValue)

  return {
    labels,
    datasets: [
      {
        label: profile?.code.substring(0, 20),
        data: data,
        borderColor: colors.orange,
        backgroundColor: colors.lightOrange,
      },
      ...(profiles?.map((p, i) => ({
        label: p.code.substring(0, 20),
        data: p.intervals?.map((interval: IntervalDto) => interval.percentageValue) || [],
        borderColor: chartColors[i % chartColors.length].borderColor,
        backgroundColor: chartColors[i % chartColors.length].backgroundColor,
      })) || []),
    ],
  }
}
