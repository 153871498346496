import { ChangeEvent, FC } from 'react'

import { useTranslation } from 'react-i18next'

import * as S from './styled'

interface PeriodPriceTileProps {
  dayNumber: number
  price: number
  onChange: (dayNumber: number, price: number) => void
  className?: string
  disabled?: boolean
}

const PeriodPriceTile: FC<PeriodPriceTileProps> = ({ className, dayNumber, disabled, price, onChange }) => {
  const { t } = useTranslation()

  const handleChange = (evt: ChangeEvent<HTMLInputElement>) => {
    const newPrice = isNaN(parseFloat(evt.target.value)) ? 0 : parseFloat(evt.target.value)
    onChange(dayNumber, newPrice)
  }

  return (
    <S.TextField
      label={`${t('Día')} ${dayNumber}`}
      value={price}
      className={className}
      disabled={disabled}
      InputLabelProps={{ shrink: true }}
      inputProps={{ type: 'number', min: '0', step: '0.01' }}
      onChange={handleChange}
    />
  )
}

export default PeriodPriceTile
