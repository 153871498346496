
import { useEffect, useState } from 'react'

import { CHANNELS } from 'config/constants'
import useGetEvolution from 'hooks/queries/prebooks/use-get-evolution'
import {
  EvolutionPricesCharts,
  EvolutionSalesCharts,
  SearchCarparksFormFields,
} from 'models/prebooks'

import PrebooksPricesEvolution from '../prebooks-prices-evolution'
import PrebooksSalesEvolution from '../prebooks-sales-evolution'

interface Props {
  filters: SearchCarparksFormFields;
}

const PrebooksEvolutionCharts = ({ filters }: Props) => {
  const [salesData, setSalesData] = useState<EvolutionSalesCharts[]>([])
  const [pricesData, setPricesData] = useState<EvolutionPricesCharts[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const { response: withoutChannelResponse, isLoading: withoutChannelIsLoading } = useGetEvolution(filters)
  const { response: telparkResponse, isLoading: telparkIsLoading } = useGetEvolution({
    ...filters,
    channelCodes: [CHANNELS[0].value]
  })
  const { response: easyprebooksResponse, isLoading: easyprebooksIsLoading } = useGetEvolution({
    ...filters,
    channelCodes: [CHANNELS[1].value]
  })

  useEffect(() => {
    if (isLoading) return
    const combinedSalesData: EvolutionSalesCharts[] = withoutChannelResponse.map((item, index) => ({
      day: item.day,
      withoutChannelSales: item.numSales,
      telparkSales: telparkResponse[index]?.numSales || 0,
      easyprebooksSales: easyprebooksResponse[index]?.numSales || 0,
      withoutChannelCancelledPurchasesByDay: item.numCancelledPurchasesByDay,
      telparkCancelledPurchasesByDay: telparkResponse[index]?.numCancelledPurchasesByDay || 0,
      easyprebooksCancelledPurchasesByDay: easyprebooksResponse[index]?.numCancelledPurchasesByDay || 0,
    }))

    const combinedPricesData: EvolutionPricesCharts[] = withoutChannelResponse.map((item, index) => ({
      day: item.day,
      withoutChannelBasePrice: item.basePrice,
      withoutChannelFinalPrice: item.finalPrice,
      telparkBasePrice: telparkResponse[index]?.basePrice || 0,
      telparkFinalPrice: telparkResponse[index]?.finalPrice || 0,
      easyprebooksBasePrice: easyprebooksResponse[index]?.basePrice || 0,
      easyprebooksFinalPrice: easyprebooksResponse[index]?.finalPrice || 0,
    }))

    setSalesData(combinedSalesData)
    setPricesData(combinedPricesData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  useEffect(() => {
    setIsLoading(withoutChannelIsLoading || telparkIsLoading || easyprebooksIsLoading)
  }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , [withoutChannelIsLoading, telparkIsLoading, easyprebooksIsLoading])


  return (
    <>
      <PrebooksSalesEvolution salesData={salesData} isLoading={isLoading} />
      <PrebooksPricesEvolution pricesData={pricesData} isLoading={isLoading} />
    </>
  )
}

export default PrebooksEvolutionCharts
