import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { GET_APPLICATIONS } from 'config/queries'
import { CreateApplication } from 'models/applications'
import { createApplication } from 'services/applications'

const useCreateApplication = () => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const createApplicationMutation = useMutation({
    mutationFn: (payload: CreateApplication) => createApplication(payload),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [GET_APPLICATIONS] })
    },
    onError: () => {
      toast(t('Ha ocurrido un error'), { type: 'error' })
      queryClient.invalidateQueries({ queryKey: [GET_APPLICATIONS] })
    },
  })

  return createApplicationMutation
}

export default useCreateApplication
