import { useEffect, useState } from 'react'

import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import CancelButton from 'components/atoms/cancel-button'
import FormButtonsContainer from 'components/atoms/form-buttons-container'
import PageTitle from 'components/atoms/page-title'
import HistoricTable, { TableProps } from 'components/organisms/historic-table'
import { CentersFormTab } from 'config/centers-constants'
import { DateFormats } from 'config/constants'
import routes from 'config/routes'
import useGetCenterDayVersions from 'hooks/queries/centers/use-get-center-day-versions'
import { dateFormatter } from 'utils/date-formatter'

const CenterDaysHistoric = (): JSX.Element => {
  const { centerId = '', id = '' } = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const [tableProps, setTableProps] = useState<TableProps>({} as TableProps)
  const [date, setDate] = useState('')

  const { response, isLoading, count } = useGetCenterDayVersions({ id, ...tableProps })

  useEffect(() => {
    if (!date && response[0]?.date) {
      setDate(response[0].date)
    }
  }, [date, response])

  const navigateToShow = (version: number) => {
    const route = routes.centersDaysHistoricShow
      .replace(':centerId', centerId)
      .replace(':id', id)
      .replace(':version', version.toString())
    navigate(route)
  }

  const backRoute = routes.centersShow.replace(':id', centerId)

  return (
    <Box>
      <PageTitle title={`${t('Histórico de día')} ${dateFormatter({ date, pattern: DateFormats.day })}`} />
      <HistoricTable
        id={id}
        rows={response}
        count={count}
        isLoading={isLoading}
        onTablePropsChange={setTableProps}
        onNavigateToShow={navigateToShow}
      />
      <FormButtonsContainer>
        <CancelButton to={backRoute} navigationOptions={{ state: { tabValue: CentersFormTab.calendar } }} />
      </FormButtonsContainer>
    </Box>
  )
}

export default CenterDaysHistoric
