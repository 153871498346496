import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom'

import routes from 'config/routes'
import AuthLayout from 'components/layouts/auth-layout'
import PrebooksLayout from 'components/layouts/prebooks-layout'
import CentersLayout from 'components/layouts/centers-layout'
import NoMatchPage from 'components/pages/no-match'
import CognitoRedirect from 'components/pages/auth/cognito-redirect'
import LoginPage from 'components/pages/auth/login'
import PrebooksPage from 'components/pages/prebooks'
import CentersListPage from 'components/pages/centers/centers-list'
import CentersEditPage from 'components/pages/centers/centers-edit'
import CentersViewPage from 'components/pages/centers/centers-view'
import CentersHistoricPage from 'components/pages/centers/centers-historic'
import CentersHistoricShowPage from 'components/pages/centers/centers-historic-show'
import CenterPeriodsHistoricPage from 'components/pages/centers/center-periods-historic'
import CenterPeriodsHistoricShowPage from 'components/pages/centers/center-periods-historic-show'
import CenterDaysHistoricPage from 'components/pages/centers/center-days-historic'
import CenterDaysHistoricShowPage from 'components/pages/centers/center-days-historic-show'
import Configuration from 'components/pages/configuration/configuration'
import AlertsLayout from 'components/layouts/alerts-layout'
import AlertsList from 'components/pages/alerts/alerts-list'
import SimulatorLayout from 'components/layouts/simulator-layout'
import Simulator from 'components/pages/simulator'
import ProfilesLayout from 'components/layouts/profiles-layout'
import ProfilesList from 'components/pages/profiles/profiles-list'
import ProfilesCreate from 'components/pages/profiles/profiles-create'
import AdministrationLayout from 'components/layouts/administration-layout'
import ApplicationsListPage from 'components/pages/applications/applications-list'
import ApplicationsEditPage from 'components/pages/applications/applications-edit'
import ApplicationsCreatePage from 'components/pages/applications/applications-create'
import ApplicationsShowPage from 'components/pages/applications/applications-show'
import UsersListPage from 'components/pages/users/users-list'
import UsersEditPage from 'components/pages/users/users-edit'
import UsersCreatePage from 'components/pages/users/users-create'
import UsersShowPage from 'components/pages/users/users-show'
import EventsListPage from 'components/pages/events/events-list'
import EventsShowPage from 'components/pages/events/events-show'
import ProfilesShow from 'components/pages/profiles/profiles-show'
import ProfilesEdit from 'components/pages/profiles/profiles-edit'
import ProfilesHistoricPage from 'components/pages/profiles/profiles-historic'
import ProfilesHistoricShowPage from 'components/pages/profiles/profiles-historic-show'
import RelationsCreate from 'components/pages/relations/relations-create'
import RelationsList from 'components/pages/relations/relations-list'
import RelationsRolesHistoric from 'components/pages/relations/relations-roles-historic'
import RelationsUsersHistoric from 'components/pages/relations/relations-users-historic'
import { Roles } from 'models/auth.d'
import { useAuth } from 'providers/auth'

import {
  RequireAdministrationRolesPermissions,
  RequireAlertsRolesPermissions,
  RequireCentersRolesPermissions,
  RequirePrebooksRolesPermissions,
  RequireProfilesRolesPermissions,
  RequireSimulatorRolesPermissions,
} from './permissions'

const Router = () => {
  const { user } = useAuth()

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route element={<AuthLayout />}>
          <Route
            element={
              <RequireCentersRolesPermissions>
                <CentersLayout />
              </RequireCentersRolesPermissions>
            }
          >
            {user?.role !== Roles['ADMIN-CONSOLA'] && <Route path={routes.root} element={<CentersListPage />} />}
            <Route path={routes.centers} element={<CentersListPage />} />
            <Route path={routes.centersEdit} element={<CentersEditPage />} />
            <Route path={routes.centersShow} element={<CentersViewPage />} />
            <Route path={routes.centersHistoric} element={<CentersHistoricPage />} />
            <Route path={routes.centersHistoricShow} element={<CentersHistoricShowPage />} />
            <Route path={routes.centersPeriodsHistoric} element={<CenterPeriodsHistoricPage />} />
            <Route path={routes.centersPeriodsHistoricShow} element={<CenterPeriodsHistoricShowPage />} />
            <Route path={routes.centersDaysHistoric} element={<CenterDaysHistoricPage />} />
            <Route path={routes.centersDaysHistoricShow} element={<CenterDaysHistoricShowPage />} />
          </Route>

          <Route
            element={
              <RequirePrebooksRolesPermissions>
                <PrebooksLayout />
              </RequirePrebooksRolesPermissions>
            }
          >
            <Route path={routes.prebooks} element={<PrebooksPage />} />
          </Route>

          <Route
            element={
              <RequireAdministrationRolesPermissions>
                <AdministrationLayout />
              </RequireAdministrationRolesPermissions>
            }
          >
            <Route path={routes.administration} element={<Configuration />} />
            <Route path={routes.configuration} element={<Configuration />} />

            <Route path={routes.usersList} element={<UsersListPage />} />
            <Route path={routes.usersEdit} element={<UsersEditPage />} />
            <Route path={routes.usersCreate} element={<UsersCreatePage />} />
            <Route path={routes.usersShow} element={<UsersShowPage />} />

            <Route path={routes.applicationsList} element={<ApplicationsListPage />} />
            <Route path={routes.applicationsEdit} element={<ApplicationsEditPage />} />
            <Route path={routes.applicationsCreate} element={<ApplicationsCreatePage />} />
            <Route path={routes.applicationsShow} element={<ApplicationsShowPage />} />

            <Route path={routes.eventsList} element={<EventsListPage />} />
            <Route path={routes.eventsShow} element={<EventsShowPage />} />

            <Route path={routes.relationsList} element={<RelationsList />} />
            <Route path={routes.relationsUsersCreate} element={<RelationsCreate relationType='user' />} />
            <Route path={routes.relationsUsersShow} element={<RelationsCreate readonly relationType='user' />} />
            <Route path={routes.relationsUsersEdit} element={<RelationsCreate relationType='user' />} />
            <Route path={routes.relationsUsersHistoric} element={<RelationsUsersHistoric />} />
            <Route
              path={routes.relationsUsersHistoricShow}
              element={<RelationsCreate readonly relationType='user' />}
            />
            <Route path={routes.relationsRolesCreate} element={<RelationsCreate relationType='role' />} />
            <Route path={routes.relationsRolesShow} element={<RelationsCreate readonly relationType='role' />} />
            <Route path={routes.relationsRolesEdit} element={<RelationsCreate relationType='role' />} />
            <Route path={routes.relationsRolesHistoric} element={<RelationsRolesHistoric />} />
            <Route
              path={routes.relationsRolesHistoricShow}
              element={<RelationsCreate readonly relationType='role' />}
            />
          </Route>

          <Route
            element={
              <RequireAlertsRolesPermissions>
                <AlertsLayout />
              </RequireAlertsRolesPermissions>
            }
          >
            {user?.role === Roles['ADMIN-CONSOLA'] && <Route path={routes.root} element={<AlertsList />} />}
            <Route path={routes.alerts} element={<AlertsList />} />
          </Route>

          <Route
            element={
              <RequireSimulatorRolesPermissions>
                <SimulatorLayout />
              </RequireSimulatorRolesPermissions>
            }
          >
            <Route path={routes.simulator} element={<Simulator />} />
          </Route>

          <Route
            element={
              <RequireProfilesRolesPermissions>
                <ProfilesLayout />
              </RequireProfilesRolesPermissions>
            }
          >
            <Route path={routes.profiles} element={<ProfilesList />} />
            <Route path={routes.profilesCreate} element={<ProfilesCreate />} />
            <Route path={routes.profilesShow} element={<ProfilesShow />} />
            <Route path={routes.profilesEdit} element={<ProfilesEdit />} />
            <Route path={routes.profilesHistoric} element={<ProfilesHistoricPage />} />
            <Route path={routes.profilesHistoricShow} element={<ProfilesHistoricShowPage />} />
          </Route>
        </Route>

        {/* public routes */}
        <Route path={routes.login} element={<LoginPage />} />
        <Route path={routes.cognitoRedirect} element={<CognitoRedirect />} />
        <Route path='*' element={<NoMatchPage />} />
      </>
    )
  )
  return (
    <>
      <RouterProvider router={router} />
    </>
  )
}

export default Router
