import React from 'react'

import Box from '@mui/material/Box'
import ButtonBase from '@mui/material/ButtonBase'
import Typography from '@mui/material/Typography'

import * as S from './styled'

interface Props {
  title: string
  href: string
  icon: JSX.Element
}

const HeaderLink: React.FC<Props> = ({ title, href, icon }) => (
  <ButtonBase centerRipple>
    <S.StyledLink to={href}>
      <S.HeaderLinkContainer>
        <Box marginRight='5px' display='flex' justifyContent='center'>
          {icon}
        </Box>
        <Typography color='inherit' fontSize='.9rem' letterSpacing='.1em' fontWeight={500} textTransform='uppercase'>
          {title}
        </Typography>
      </S.HeaderLinkContainer>
    </S.StyledLink>
  </ButtonBase>
)

export default HeaderLink
