import styled from 'styled-components'
import Grid from '@mui/material/Grid'

const Container = styled(Grid)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 100%;
  margin-top: 2.5rem;
` as typeof Grid

export default Container
