import { FC } from 'react'

import RolesRelationsForm from 'components/organisms/relations/roles-form'
import UsersRelationsForm from 'components/organisms/relations/users-form'

interface RelationsCreateProps {
  relationType: 'user' | 'role'
  readonly?: boolean
}

const RelationsCreate: FC<RelationsCreateProps> = ({ relationType, readonly }) => {
  return relationType === 'user' ? (
    <UsersRelationsForm readonly={readonly} />
  ) : (
    <RolesRelationsForm readonly={readonly} />
  )
}

export default RelationsCreate
