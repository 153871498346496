import { Version } from './version'

export interface CenterPeriod {
  id: number
  createDate?: string
  updateDate?: string
  deleteDate?: string
  from: string
  to: string
  priority: PeriodPriority
  minDays: number
  maxDays: number
  weekdays: PeriodWeekday[]
  prices: number[]
  centerId: number
}

export type CenterPeriodVersion = CenterPeriod & Version

export enum PeriodPriority {
  HIGHEST = 1,
  HIGH = 2,
  MEDIUM = 3,
  LOW = 4,
  DEFAULT = 5,
}

export enum PeriodWeekday {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
}

export interface GetPeriodsPagination {
  limit: number
  offset: number
  sort: string
  onlyUnactivePeriods: boolean
}

export interface CreatePeriod {
  centerId: number
  from?: string
  to?: string
  priority?: PeriodPriority
  minDays?: number
  maxDays?: number
  weekdays?: PeriodWeekday[]
  prices?: number[]
}

export interface UpdatePeriod extends Partial<CenterPeriod> {
  id: number
}

export interface GetPeriodVersions {
  id: string
  limit: number
  offset: number
  sort: string
}

export interface GetOnePeriodVersion {
  id: string
  version: string
}

export interface UseGetCenterPeriodVersions {
  response: CenterPeriodVersion[]
  count: number
  isLoading: boolean
}

export interface UseGetOneCenterPeriodVersion {
  response?: CenterPeriodVersion
  isLoading: boolean
}
