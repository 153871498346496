import React from 'react'

import { GridActionsCellItem } from '@mui/x-data-grid'
import ToggleOffIcon from '@mui/icons-material/ToggleOffOutlined'
import ToggleOnIcon from '@mui/icons-material/ToggleOnOutlined'
import { Tooltip } from '@mui/material'
import { t } from 'i18next'

interface Props {
  checked?: boolean
  onClick: () => void
}

const ToggleGridActionCellItem: React.FC<Props> = ({ checked, onClick }) => (
  <Tooltip title={t('Activar/Desactivar')}>
    <GridActionsCellItem
      icon={!checked ? <ToggleOffIcon color='warning' /> : <ToggleOnIcon color='primary' />}
      label='toggle'
      onClick={onClick}
    />
  </Tooltip>
)

export default ToggleGridActionCellItem
