import { AxiosResponse } from 'axios'

import {
  CalendarDay,
  Center,
  CenterDayVersion,
  GetCalendar,
  GetCenters,
  GetCentersVersions,
  GetDayVersions,
} from 'models/center'
import { adminApi } from 'services'
import { removeEmpty } from 'utils/remove-empty'

export const getCenters = ({ filters, ...payload }: GetCenters): Promise<AxiosResponse<Center[]>> =>
  adminApi.get('/centers', {
    params: removeEmpty({ ...payload, ...filters }),
  })

export const getCentersVersions = ({ id, ...params }: GetCentersVersions): Promise<AxiosResponse<Center[]>> =>
  adminApi.get(`/v1/centers/${id}/versions`, { params })

export const getOneCenter = (id: number): Promise<AxiosResponse<Center>> => adminApi.get(`/centers/${id}`)

export const getOneCenterVersion = (id?: string, version?: string) =>
  adminApi.get(`/v1/centers/${id}/versions/${version}`)

export const updateOneCenter = (id: number, payload: Partial<Center>): Promise<AxiosResponse<Center>> =>
  adminApi.patch(`/centers/${id}`, payload)

export const updateOneCenterDay = (centerId: number, dayId: number, price: number): Promise<AxiosResponse<void>> =>
  adminApi.patch(`/centers/${centerId}/days/${dayId}`, { price })

export const postOneCenterDay = (centerId: number, date: string, price: number): Promise<AxiosResponse<void>> =>
  adminApi.post(`/centers/${centerId}/days`, { price, date })

export const getCalendar = ({ id, month, year }: GetCalendar): Promise<AxiosResponse<CalendarDay[]>> =>
  adminApi.get(`/centers/${id}/calendar`, {
    params: removeEmpty({ month, year }),
  })

export const getCenterDayVersions = ({ id, ...params }: GetDayVersions): Promise<AxiosResponse<CenterDayVersion[]>> =>
  adminApi.get(`/v1/days/${id}/versions`, { params: removeEmpty({ ...params }) })

export const getOneCenterDayVersion = (id: string, version: string): Promise<AxiosResponse<CenterDayVersion>> =>
  adminApi.get(`/v1/days/${id}/versions/${version}`)

export const updateMFLCenters = (): Promise<AxiosResponse<[]>> => adminApi.post('/centers/mflImport')
