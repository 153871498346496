import React from 'react'

import Typography from '@mui/material/Typography'

import { FontSizes } from 'config/constants'
import { formatNumber, formatPercent } from 'utils/number-formatter'
import { useAuth } from 'providers/auth'

import * as S from './styled'

interface Props {
  label: string
  value?: string | number
  showCurrency?: boolean
  showPercentage?: boolean
}

const InfoCard: React.FC<Props> = ({ label, value, showCurrency, showPercentage }) => {
  const { language } = useAuth()

  return (
    <S.InfoCardContainer>
      <Typography fontSize={FontSizes.lg} textAlign='center' fontWeight={400}>
        {label}
      </Typography>
      <Typography fontSize={'2rem'} textAlign='center' color='secondary'>
        {showPercentage
          ? typeof value === 'number' ? formatPercent(value, language) : value
          : typeof value === 'number' ? formatNumber(value, language, showCurrency) : value
        }
      </Typography>
    </S.InfoCardContainer>
  )
}

export default InfoCard
