import { useCallback, useMemo } from 'react'

import { Roles } from 'models/auth.d'
import { useAuth } from 'providers/auth'
import { CentersFormTab } from 'config/centers-constants'

const ALL_SECTIONS = Object.values(CentersFormTab) as CentersFormTab[]
const ADMIN_CERCA_SECTIONS = ALL_SECTIONS.filter(section => section === CentersFormTab.periodSettings)
const CERCA_SECTIONS = ALL_SECTIONS.filter(section => section === CentersFormTab.periodSettings)

// Specific writable sections per role
const SECTIONS_PER_ROLE: Partial<Record<Roles, CentersFormTab[]>> = {
  [Roles['SUPER-ADMIN']]: ALL_SECTIONS,
  [Roles.ADMINISTRADOR]: ALL_SECTIONS,
  [Roles['ADMIN-GESTOR']]: ALL_SECTIONS,
  [Roles['ADMIN-CERCA']]: ADMIN_CERCA_SECTIONS,
  [Roles.CERCA]: CERCA_SECTIONS,
}

const useCentersPermissions = () => {
  const { user } = useAuth()

  const canRead = useMemo(() => !(!user?.role || user?.role === Roles['ADMIN-CONSOLA']), [user?.role])

  const canWrite = useMemo(() => !!user?.role && Object.keys(SECTIONS_PER_ROLE).includes(user.role), [user?.role])

  const isSectionWritable = useCallback(
    (section: CentersFormTab) => !!user?.role && SECTIONS_PER_ROLE[user.role]?.includes(section),
    [user?.role]
  )

  return { canRead, canWrite, isSectionWritable }
}

export default useCentersPermissions
