import { DataGrid } from '@mui/x-data-grid'
import styled from 'styled-components'

import { colors } from 'config/theme'

export const StyledDataGrid = styled(DataGrid)`
  .MuiDataGrid-virtualScroller {
    min-height: ${({ loading, rows, rowHeight = 80 }) =>
      (loading || rows.length >= 5) && `${rowHeight * (rows.length || 5)}px`};
  }
  .MuiDataGrid-row {
    .strong {
      color: ${colors.orange};
      font-weight: 700;
    }
  }
`
