import { useEffect } from 'react'

import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'

import CancelButton from 'components/atoms/cancel-button'
import FormButtonsContainer from 'components/atoms/form-buttons-container'
import PageTitle from 'components/atoms/page-title'
import { DateFormats, FontFamily, FontSizes } from 'config/constants'
import { dateFormatter } from 'utils/date-formatter'
import PeriodAccordionContent from 'components/molecules/period-accordion-content'
import PeriodPriorityText from 'components/atoms/period-priority-text'
import useGetOneCenter from 'hooks/queries/centers/use-get-one-center'
import { CenterPricingType } from 'models/center'
import { CenterPeriodVersion } from 'models/center-period'
import { useFormStore } from 'store/useFormStore'

interface Props {
  period: CenterPeriodVersion
}

const CenterPeriodsHistoricShowDetails = ({ period }: Props) => {
  const { t } = useTranslation()

  const centerResponse = useGetOneCenter({ id: period.centerId })
  const center = centerResponse?.response
  const isPeriodicPrice = center?.pricingType === CenterPricingType.PERIOD
  const isFormDirty = useFormStore(store => store.isDirty)
  const setDirty = useFormStore(store => store.setDirty)

  // If the form is dirty, set it to false because it's an historic view
  useEffect(() => {
    if (isFormDirty) {
      setDirty(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFormDirty])

  const translationOptions = {
    dateFrom: dateFormatter({ date: period.from, pattern: DateFormats.day }),
    dateTo: dateFormatter({ date: period.to, pattern: DateFormats.day }),
    interpolation: { escapeValue: false },
  }

  const title = `${t(
    'Periodo del {{ versionDateFrom }} al {{ versionDateTo }}',
    translationOptions
  )} - ${t`Versión`} ${period?.version}`

  return (
    <Box data-testid='period-version'>
      <PageTitle title={title} />

      <Paper sx={{ paddingTop: 4 }}>
        <Typography fontFamily={FontFamily.medium} fontSize={FontSizes.xl} textAlign='center'>
          <PeriodPriorityText priority={period.priority} />
        </Typography>

        <PeriodAccordionContent period={period} isPeriodicPrice={isPeriodicPrice} readonly />
      </Paper>

      <FormButtonsContainer>
        <CancelButton />
      </FormButtonsContainer>
    </Box>
  )
}

export default CenterPeriodsHistoricShowDetails
