import { FC } from 'react'

import Box, { BoxProps } from '@mui/material/Box'
import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add'
import { Link } from 'react-router-dom'

interface Props {
  label: string
  to: string
  boxProps?: BoxProps
}

const NewResourceButton: FC<Props> = ({ label, to, boxProps }) => (
  <Box {...boxProps}>
    <Button component={Link} variant='contained' color='primary' startIcon={<AddIcon />} to={to}>
      {label}
    </Button>
  </Box>
)

export default NewResourceButton
