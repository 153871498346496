import styled from 'styled-components'
import { Box } from '@mui/system'
import { IconButton } from '@mui/material'

interface Props {
  width: string
}

export const StyledBox = styled(Box)`
  position: relative;
  width: ${(props: Props) => props.width};
  height: 90px;
  display: flex;
  align-items: flex-start;

  & .MuiFormControl-root {
    width: 100%;
    height: 55px;
  }
`

export const StyledIconButton = styled(IconButton)`
  position: absolute;
  top: 0.5rem;
  margin: auto;
  right: 2rem;
`
