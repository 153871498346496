import React from 'react'

import { GridEnrichedColDef } from '@mui/x-data-grid'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { eventsActionFormatter, eventsTypeFormatter } from 'utils/event-formatter'
import routes from 'config/routes'
import BasicTable from 'components/molecules/basic-table'
import { Event, EventsQueryKey } from 'models/events'
import { DateFormats, FontSizes, RowHeight, TableColumnWidth } from 'config/constants'
import { dateFormatter } from 'utils/date-formatter'
import ShowGridActionCellItem from 'components/atoms/show-grid-action-cell-item'
import TextCell from 'components/atoms/text-cell'
import ChipCell from 'components/atoms/chip-cell'

interface Props {
  rows: Event[]
  count: number
  page: number
  pageSize: number
  isLoading: boolean
  onSortModelChange: (sortString: string) => void
  onPageSizeChange: (pageSize: number) => void
  onPageChange: (page: number) => void
  queryKey?: EventsQueryKey
}

const EventsTable: React.FC<Props> = ({ queryKey, ...props }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const navigateToShow = (id: number) => navigate(routes.eventsShow.replace(':id', id.toString()))

  const columns: GridEnrichedColDef[] = [
    { field: 'id', headerName: '', flex: TableColumnWidth.base, headerAlign: 'center', align: 'center' },
    {
      field: 'updateDate',
      headerName: `${t('Fecha')}`,
      flex: TableColumnWidth.large,
      renderCell: ({ row }: { row: Event }) => (
        <TextCell text={dateFormatter({ date: row.updateDate, pattern: DateFormats.iso, isUTC: false })} />
      ),
    },
    {
      field: 'username',
      headerName: `${t('Nombre')}`,
      flex: TableColumnWidth.large,
      renderCell: ({ row }: { row: Event }) => <TextCell text={row.username} />,
    },
    {
      field: 'email',
      headerName: `${t('Email')}`,
      flex: TableColumnWidth.large,
      renderCell: ({ row }: { row: Event }) => <TextCell text={row.email} />,
    },
    {
      field: 'resourceId',
      headerName: `${t('Id')}`,
      flex: TableColumnWidth.xs,
      renderCell: ({ row }: { row: Event }) => <TextCell text={`${row.resourceId}`} />,
    },
    {
      field: 'resourceType',
      headerName: `${t('Tipo')}`,
      flex: TableColumnWidth.base,
      renderCell: ({ row }: { row: Event }) => (
        <ChipCell fontSize={FontSizes.base} label={eventsTypeFormatter(row.resourceType)} />
      ),
    },
    {
      field: 'resourceName',
      headerName: `${t('Entidad')}`,
      flex: TableColumnWidth.base,
      renderCell: ({ row }: { row: Event }) => <TextCell text={`${row.resourceName}`} />,
    },
    {
      field: 'action',
      headerName: `${t('Acción')}`,
      flex: TableColumnWidth.base,
      renderCell: ({ row }: { row: Event }) => (
        <ChipCell fontSize={FontSizes.base} label={eventsActionFormatter(row.action)} />
      ),
    },
    {
      field: 'version',
      headerName: `${t('Versión')}`,
      flex: TableColumnWidth.base,
      renderCell: ({ row }: { row: Event }) => <TextCell text={`${row.version}`} />,
    },
    {
      field: 'actions',
      type: 'actions',
      flex: TableColumnWidth.xs,
      getActions: ({ row }: { row: Event }) => [
        <ShowGridActionCellItem key={`${row.id}-show`} onClick={() => navigateToShow(row.id)} />,
      ],
    },
  ]

  return (
    <BasicTable
      {...props}
      rowHeight={RowHeight.large}
      columns={columns}
      onRowClick={({ row }) => navigateToShow(row.id)}
    />
  )
}

export default EventsTable
