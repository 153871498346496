import { ChangeEvent, FC } from 'react'

import Box from '@mui/material/Box'

import FilterIconButton from 'components/atoms/filter-icon-button'
import SearchInput from 'components/atoms/search-input'
import { ClearFiltersButton } from 'components/atoms/clear-filter-button'

interface ResourceFilterProps {
  badge: number
  label: string
  openFilter: boolean
  onSearchQ: (event: ChangeEvent<HTMLInputElement>) => void
  searchQuery: string
  onToggleFilter: () => void
  handleClearSearch: () => void
}

const ResourceFilter: FC<ResourceFilterProps> = ({
  searchQuery,
  badge,
  label,
  openFilter,
  onSearchQ,
  onToggleFilter,
  handleClearSearch,
}) => {
  return (
    <Box gap={1} flex={1} flexDirection='row' display='flex' ml={1}>
      <SearchInput value={searchQuery} disabled={openFilter} label={label} onChange={onSearchQ} />
      <ClearFiltersButton handleClearFilters={handleClearSearch} color='default' size='small' iconOnly />
      <FilterIconButton badge={badge} onClick={onToggleFilter} />
    </Box>
  )
}

export default ResourceFilter
