import React from 'react'

import { Typography } from '@mui/material'

import { FontFamily, FontSizes } from 'config/constants'

interface Args {
  text: string
}
const FormTitle: React.FC<Args> = ({ text }) => {
  return (
    <Typography variant='h2' fontSize={FontSizes.xl} fontFamily={FontFamily.bold}>
      {text}
    </Typography>
  )
}

export default FormTitle
