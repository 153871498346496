import { useQuery } from '@tanstack/react-query'

import { GET_ALERTS_BY_USER } from 'config/queries'
import { AlertRecipient, UseGetAlertRecipient } from 'models/alert'
import { getAlertsByEmail } from 'services/alerts'

const useGetAlertsByEmail = (email: string): UseGetAlertRecipient => {
  const queryKey = [GET_ALERTS_BY_USER, email]
  const {
    data: response,
    isLoading,
    isPreviousData,
  } = useQuery({
    queryKey,
    queryFn: () => getAlertsByEmail(email),
    refetchOnMount: true,
    keepPreviousData: true,
  })

  const result: AlertRecipient[] = response?.data ?? []
  const count: number = parseInt(response?.headers?.['x-total-count'] || 0)

  return { response: result, count, isLoading: isLoading || isPreviousData, queryKey }
}

export default useGetAlertsByEmail
