import { QueryKey, useMutation, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { GET_CALENDAR, GET_DAILY_OCCUPATION } from 'config/queries'
import { CenterPeriod } from 'models/center-period'
import { deleteOnePeriod } from 'services/periods'

const useDeleteOnePeriod = (centerId: number, queryKey: QueryKey) => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  return useMutation({
    mutationFn: (id: number) => deleteOnePeriod(id, centerId),
    onSuccess: () => {
      toast(t('Periodo eliminado'), { type: 'success' })
    },
    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey })
      const previous = queryClient.getQueryData<{ data: CenterPeriod[] }>(queryKey)

      return { previous }
    },
    onError: (error, newValue, context) => {
      queryClient.setQueryData(queryKey, context?.previous)
      toast(t('Ha ocurrido un error'), { type: 'error' })
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey })
      queryClient.invalidateQueries({ queryKey: [GET_CALENDAR] })
      queryClient.invalidateQueries({ queryKey: [GET_DAILY_OCCUPATION] })
    },
  })
}

export default useDeleteOnePeriod
