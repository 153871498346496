import React from 'react'

import Typography from '@mui/material/Typography'

import { FontFamily } from 'config/constants'

import * as S from './styled'

interface Props {
  title: string
}

const PageTitle: React.FC<Props> = ({ title }) => (
  <S.PageTitleContainer data-testid='page-title'>
    <Typography
      variant='h1'
      fontSize='2.4rem'
      fontFamily={FontFamily.bold}
      textAlign='center'
      fontWeight={400}
      textTransform='uppercase'
    >
      {title}
    </Typography>
    <S.PageTitleHorizontalLine />
  </S.PageTitleContainer>
)

export default PageTitle
