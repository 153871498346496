import React from 'react'

import { useTranslation } from 'react-i18next'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import dragdataPlugin from 'chartjs-plugin-dragdata'

import { CreateProfile, Point, Profile } from 'models/profiles'
import { OnDragEnd } from 'models/chartjs-plugin-dragdata-types'

import { getChartData, getChartOptions } from './utils'

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend)
ChartJS.register(dragdataPlugin)

interface Props {
  profile: Profile | CreateProfile
  profiles: Array<Profile>
  onDragEnd: OnDragEnd
  points: Array<Point>
  dragEnabled: boolean
}

const ProfilesPointsChart: React.FC<Props> = ({ profile, profiles, onDragEnd, points, dragEnabled }) => {
  const { t } = useTranslation()

  const chartOptions = getChartOptions(t, onDragEnd, dragEnabled)
  const chartData = getChartData(t, profile, profiles, points)

  return (
    <Line
      key={dragEnabled ? 'ProfilesPointsChartCurrent' : 'ProfilesPointsChartAll'} // Force reload
      options={chartOptions}
      data={chartData}
      height={260}
    />
  )
}

export default ProfilesPointsChart
