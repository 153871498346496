import React from 'react'

import Grid from '@mui/material/Grid'

interface Props {
  spacing?: number
  children?: JSX.Element | JSX.Element[]
}

const PapperGridContainer: React.FC<Props> = ({ spacing, children }) => (
  <Grid
    container
    spacing={spacing}
    sx={{
      backgroundColor: 'white',
      boxShadow: '0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%);',
      padding: '2rem',
    }}
  >
    {children}
  </Grid>
)

export default PapperGridContainer
