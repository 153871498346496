import { FC, useCallback, useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'

import CountChip from 'components/atoms/count-chip'
import ResourceHeader from 'components/atoms/resource-header'
import SearchInput from 'components/atoms/search-input'
import { ProfileFilters } from 'models/profiles'
import NewResourceButton from 'components/atoms/new-resource-button'
import routes from 'config/routes'
import BasicInputSwitch from 'components/atoms/basic-input-switch'
import BasicInputSelect from 'components/atoms/basic-input-select'
import useProfilesPermissions from 'hooks/permissions/use-profiles-permissions'
import CurveType from 'models/curve-type'
import { ClearFiltersButton } from 'components/atoms/clear-filter-button'

import { ProfilesActions, ProfilesHeader } from './styled'

interface ProfilesTableHeaderProps {
  count: number
  isLoading?: boolean
  onFilter: (filters: ProfileFilters) => void
  onSearchQ: (event: React.ChangeEvent<HTMLInputElement>) => void
  setIsLoadingStoredData: (value: boolean) => void
}

const CURVE_OPTIONS = [
  { value: 'Todos', text: 'Todos' },
  { value: CurveType.ANTICIPATION, text: 'Anticipación' },
  { value: CurveType.DISCOUNT, text: 'Duración' },
]

const initialData = {
  search: '',
  curveType: CURVE_OPTIONS[0].value as CurveType,
  active: true,
}

const ProfilesTableHeader: FC<ProfilesTableHeaderProps> = ({
  count,
  isLoading,
  onSearchQ,
  onFilter,
  setIsLoadingStoredData,
}) => {
  const { t } = useTranslation()
  const { canWrite } = useProfilesPermissions()

  const [showOnlyActive, setShowOnlyActive] = useState(initialData.active)
  const [selectedCurveType, setSelectedCurveType] = useState<CurveType | undefined>(initialData.curveType)
  const [searchQuery, setSearchQuery] = useState(initialData.search)

  const handleActiveChange = useCallback(
    (event: Event) => {
      const { checked } = event.target as HTMLInputElement
      setShowOnlyActive(checked)

      const active = checked ? true : undefined
      onFilter({ active })
    },
    [onFilter]
  )

  const handleSelectedCurveType = useCallback(
    (event: Event) => {
      const { value } = event.target as HTMLInputElement
      setSelectedCurveType(value as CurveType)
      const status = value !== 'Todos' ?? true
      if (status) {
        onFilter({ curveType: value as CurveType })
      } else {
        onFilter({ curveType: undefined })
      }
    },
    [onFilter]
  )

  const handleSearchQ = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchQuery(event.target.value)
      onSearchQ(event)
    },
    [onSearchQ]
  )

  const handleClearFilters = () => {
    setShowOnlyActive(true)
    setSelectedCurveType(CURVE_OPTIONS[0].value as CurveType)
    setSearchQuery('')
    onFilter({ curveType: undefined, active: true })
    onSearchQ({ target: { value: '' } } as React.ChangeEvent<HTMLInputElement>)
    sessionStorage.removeItem('profiles_filter')
  }

  useEffect(() => {
    const storedData = sessionStorage.getItem('profiles_filter')
    if (storedData) {
      setIsLoadingStoredData(true)
      const parsedData = JSON.parse(storedData)
      setShowOnlyActive(parsedData.showOnlyActive)
      setSelectedCurveType(parsedData.selectedCurveType)
      setSearchQuery(parsedData.searchQuery)
      const status = parsedData.selectedCurveType !== 'Todos' ?? true
      if (status) {
        onFilter({ curveType: parsedData.selectedCurveType, active: parsedData.showOnlyActive })
      } else {
        onFilter({ curveType: undefined, active: parsedData.showOnlyActive })
      }
      onSearchQ({ target: { value: parsedData.searchQuery } } as React.ChangeEvent<HTMLInputElement>)

      setTimeout(() => {
        setIsLoadingStoredData(false)
      }, 500)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (
      selectedCurveType === initialData.curveType &&
      showOnlyActive === initialData.active &&
      searchQuery === initialData.search
    )
      return
    sessionStorage.setItem('profiles_filter', JSON.stringify({ showOnlyActive, selectedCurveType, searchQuery }))
  }, [searchQuery, selectedCurveType, showOnlyActive])

  return (
    <ProfilesHeader>
      <ProfilesActions>
        <ClearFiltersButton handleClearFilters={handleClearFilters} color='default' />
        {canWrite && <NewResourceButton label={t('Nueva curva')} to={routes.profilesCreate} />}
      </ProfilesActions>
      <ResourceHeader>
        <CountChip label={t`curvas`} labelSingular={t`curva`} count={count} isLoading={isLoading} />
        <SearchInput value={searchQuery} label={t`Código, nombre ...`} onChange={handleSearchQ} />
        <BasicInputSelect
          label={t`Tipo curva`}
          options={CURVE_OPTIONS}
          size='small'
          height='auto'
          mandatory
          field={{
            name: 'selectCurveType',
            ref: null,
            value: selectedCurveType,
            onChange: handleSelectedCurveType,
            onBlur: () => undefined,
          }}
        />
        {canWrite && (
          <BasicInputSwitch
            autoHeight
            boxDirection='row'
            label={t`Solo activos`}
            variant='full'
            field={{
              name: 'showOnlyActive',
              ref: null,
              value: showOnlyActive,
              onChange: handleActiveChange,
              onBlur: () => undefined,
            }}
          />
        )}
      </ResourceHeader>
    </ProfilesHeader>
  )
}

export default ProfilesTableHeader
