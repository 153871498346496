import { FC } from 'react'

import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'

import UsersRelationsList from 'components/organisms/relations/users-list'
import RolesRelationsList from 'components/organisms/relations/roles-list'
import { ListProps } from 'models/list'

const RelationsList: FC<ListProps> = listProps => (
  <Box width='100%'>
    <UsersRelationsList {...listProps} />
    <Divider sx={{ margin: '2em 2em  2.5em' }} />
    <RolesRelationsList {...listProps} />
  </Box>
)

export default RelationsList
