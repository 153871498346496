import { useState } from 'react'

interface State {
  sortMode: string
}

const useSort = () => {
  const [sortModel, setSortModel] = useState<State['sortMode']>('')

  return {
    sortModel,
    setSortModel,
  }
}

export default useSort
