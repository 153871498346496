import { useEffect, useState } from 'react'

import { Box } from '@mui/system'
import { t } from 'i18next'

import useGetApplications from 'hooks/queries/application/use-get-applications'
import usePagination from 'hooks/use-pagination'
import useAdministrationPermissions, { AdministrationSections } from 'hooks/permissions/use-administration-permissions'
import useFilters from 'hooks/use-filters'
import CountChip from 'components/atoms/count-chip'
import NewResourceButton from 'components/atoms/new-resource-button'
import ResourceHeader from 'components/atoms/resource-header'
import routes from 'config/routes'
import ApplicationsTable from 'components/organisms/applications/applications-table'
import { applicationFilterDefaultValues } from 'validations/applications'
import ApplicationsFilter from 'components/organisms/applications/applications-filter'
import ResourceFilter from 'components/molecules/resource-filter'

const ApplicationsList = (): JSX.Element => {
  const { pageSize, offset, page, setPageSize, setPage } = usePagination()
  const { canWrite } = useAdministrationPermissions(AdministrationSections.applications)
  const { sortModel, badge, openFilter, filters, setSortModel, onSearchQ, onSearchFilter, onToggleFilter } =
    useFilters(applicationFilterDefaultValues)

  const { response, isLoading, count, queryKey } = useGetApplications({
    pageSize,
    offset,
    filters: { ...filters, showInactive: !filters.showActiveOnly, showActiveOnly: undefined },
    sort: sortModel || 'id',
  })

  const handleOnSortModelChange = (sortingString: string) => {
    const transformedSortingString = sortingString.includes('seniority')
      ? sortingString.replace('seniority', 'createDate')
      : sortingString
    setSortModel(transformedSortingString)
  }

  const [searchQuery, setSearchQuery] = useState('')
  const [loadingStoredQuery, setLoadingStoredQuery] = useState(false)

  const handleSearchQ = (event: React.ChangeEvent<HTMLInputElement>) => {
    sessionStorage.setItem('searchApplicationsQuery', event.target.value)
    setSearchQuery(event.target.value)
    onSearchQ(event)
  }

  const handleClearSearch = () => {
    sessionStorage.removeItem('searchApplicationsQuery')
    setSearchQuery('')
    onSearchQ({ target: { value: '' } } as React.ChangeEvent<HTMLInputElement>)
  }

  useEffect(() => {
    const storedQuery = sessionStorage.getItem('searchApplicationsQuery')
    if (storedQuery) {
      setLoadingStoredQuery(true)
      setSearchQuery(storedQuery)
      onSearchQ({ target: { value: storedQuery } } as React.ChangeEvent<HTMLInputElement>)
      // Simulate loading
      setTimeout(() => {
        setLoadingStoredQuery(false)
      }, 500)
    }
    // eslint-disable-next-line
  }, [])

  return (
    <Box>
      <ResourceHeader>
        <CountChip label={t('aplicaciones')} labelSingular={t('localización')} count={count} isLoading={isLoading} />
        <ResourceFilter
          badge={badge}
          label={t`Nombre, fecha de creación ...`}
          openFilter={openFilter}
          onSearchQ={handleSearchQ}
          onToggleFilter={onToggleFilter}
          handleClearSearch={handleClearSearch}
          searchQuery={searchQuery}
        />
        {canWrite && <NewResourceButton label={t('Nueva aplicación')} to={routes.applicationsCreate} />}
      </ResourceHeader>
      {openFilter && <ApplicationsFilter onSearch={onSearchFilter} />}
      <ApplicationsTable
        rows={response}
        count={count}
        page={page}
        pageSize={pageSize}
        isLoading={isLoading || loadingStoredQuery}
        onSortModelChange={handleOnSortModelChange}
        onPageSizeChange={setPageSize}
        onPageChange={setPage}
        queryKey={queryKey}
      />
    </Box>
  )
}

export default ApplicationsList
