import { TFunction } from 'i18next'

import { DateFormats, EvolutionChartTypes } from 'config/constants'
import { EvolutionPricesCharts, EvolutionSalesCharts } from 'models/prebooks'
import { dateFormatter } from 'utils/date-formatter'
import { colors } from 'config/theme'

type EvolutionCharts = EvolutionPricesCharts | EvolutionSalesCharts

const getTypeText = (type: EvolutionChartTypes, t: TFunction) => {
  switch (type) {
    case EvolutionChartTypes.price:
      return t('Ingresos en euros')
    case EvolutionChartTypes.sales:
      return t('Cantidad de ventas/cancelaciones')
  }
}

export const getChartOptions = (type: EvolutionChartTypes, t: TFunction) => ({
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: false,
    },
  },
  elements: {
    line: {
      tension: 0.25,
    },
  },
  scales: {
    y: {
      title: {
        display: true,
        text: getTypeText(type, t),
      },
    },
    x: {
      title: {
        display: true,
        text: t('Día del mes'),
      },
    },
  },
})

const createDataset = (
  t: TFunction,
  label: string,
  data: (number | undefined)[],
  borderColor: string,
  backgroundColor: string
) => ({
  label: t(label),
  data: data.map(value => (value !== undefined ? `${value}` : null)),
  borderColor,
  backgroundColor,
})

export const getChartData = (t: TFunction, data: EvolutionCharts[]) => {
  const datasets = []

  if (data.some(item => 'telparkFinalPrice' in item)) {
    datasets.push(
      createDataset(
        t,
        'TELPARK | Precio final',
        data.map(item => (item as EvolutionPricesCharts).telparkFinalPrice),
        colors.orange,
        colors.lightOrange
      )
    )
  }
  if (data.some(item => 'telparkBasePrice' in item)) {
    datasets.push(
      createDataset(
        t,
        'TELPARK | Precio base',
        data.map(item => (item as EvolutionPricesCharts).telparkBasePrice),
        colors.lightOrange,
        colors.orange
      )
    )
  }
  if (data.some(item => 'telparkSales' in item)) {
    datasets.push(
      createDataset(
        t,
        'TELPARK | Num. ventas',
        data.map(item => (item as EvolutionSalesCharts).telparkSales),
        colors.orange,
        colors.lightOrange
      )
    )
  }
  if (data.some(item => 'telparkCancelledPurchasesByDay' in item)) {
    datasets.push(
      createDataset(
        t,
        'TELPARK | Num. cancelaciones',
        data.map(item => (item as EvolutionSalesCharts).telparkCancelledPurchasesByDay),
        colors.lightOrange,
        colors.orange
      )
    )
  }

  if (data.some(item => 'easyprebooksFinalPrice' in item)) {
    datasets.push(
      createDataset(
        t,
        'EASYPREBOOKS | Precio final',
        data.map(item => (item as EvolutionPricesCharts).easyprebooksFinalPrice),
        colors.green,
        colors.lightGreen
      )
    )
  }
  if (data.some(item => 'easyprebooksBasePrice' in item)) {
    datasets.push(
      createDataset(
        t,
        'EASYPREBOOKS | Precio base',
        data.map(item => (item as EvolutionPricesCharts).easyprebooksBasePrice),
        colors.lightGreen,
        colors.green
      )
    )
  }
  if (data.some(item => 'easyprebooksSales' in item)) {
    datasets.push(
      createDataset(
        t,
        'EASYPREBOOKS | Num. ventas',
        data.map(item => (item as EvolutionSalesCharts).easyprebooksSales),
        colors.green,
        colors.lightGreen
      )
    )
  }
  if (data.some(item => 'easyprebooksCancelledPurchasesByDay' in item)) {
    datasets.push(
      createDataset(
        t,
        'EASYPREBOOKS | Num. cancelaciones',
        data.map(item => (item as EvolutionSalesCharts).easyprebooksCancelledPurchasesByDay),
        colors.lightGreen,
        colors.green
      )
    )
  }

  if (data.some(item => 'withoutChannelFinalPrice' in item)) {
    datasets.push(
      createDataset(
        t,
        'CANAL NO DEFINIDO | Precio final',
        data.map(item => (item as EvolutionPricesCharts).withoutChannelFinalPrice),
        colors.purple,
        colors.lightPurple
      )
    )
  }
  if (data.some(item => 'withoutChannelBasePrice' in item)) {
    datasets.push(
      createDataset(
        t,
        'CANAL NO DEFINIDO | Precio base',
        data.map(item => (item as EvolutionPricesCharts).withoutChannelBasePrice),
        colors.lightPurple,
        colors.purple
      )
    )
  }
  if (data.some(item => 'withoutChannelSales' in item)) {
    datasets.push(
      createDataset(
        t,
        'CANAL NO DEFINIDO | Num. ventas',
        data.map(item => (item as EvolutionSalesCharts).withoutChannelSales),
        colors.purple,
        colors.lightPurple
      )
    )
  }
  if (data.some(item => 'withoutChannelCancelledPurchasesByDay' in item)) {
    datasets.push(
      createDataset(
        t,
        'CANAL NO DEFINIDO | Num. cancelaciones',
        data.map(item => (item as EvolutionSalesCharts).withoutChannelCancelledPurchasesByDay),
        colors.lightPurple,
        colors.purple
      )
    )
  }

  return {
    labels: data.map(({ day }) => dateFormatter({ date: day, pattern: DateFormats.day })),
    datasets,
  }
}
