import { useCallback, useState } from 'react'

import { INITIAL_PAGE_OPTION } from 'config/constants'

interface State {
  offset: number
  page: number
  pageSize: number
}

const usePagination = (initialState?: Partial<State>) => {
  const [page, setPage] = useState<State['page']>(initialState?.page ?? 0)
  const [pageSize, setPageSize] = useState<State['pageSize']>(initialState?.pageSize ?? INITIAL_PAGE_OPTION)
  const [offset, setOffset] = useState<State['offset']>(initialState?.offset ?? 0)

  const updatePage = useCallback(
    (pageNumber: number) => {
      setPage(pageNumber)
      setOffset(pageNumber * pageSize)
    },
    [pageSize]
  )

  return { offset, page, pageSize, setPageSize, setPage: updatePage }
}

export default usePagination
