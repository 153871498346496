import React from 'react'

import { useTranslation } from 'react-i18next'
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js'
import { Bar } from 'react-chartjs-2'
import annotationPlugin from 'chartjs-plugin-annotation'

import { useAuth } from 'providers/auth'
import { DailyOccupation } from 'models/occupation'
import { CarparkConfiguration } from 'models/prebooks'

import { getChartData, getChartOptions } from './utils'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)
ChartJS.register(annotationPlugin)

interface Props {
  data: Array<DailyOccupation>
  config: CarparkConfiguration
}

const CarparkOccupationChart: React.FC<Props> = ({ data, config }) => {
  const { t } = useTranslation()
  const { language } = useAuth()

  const chartOptions = getChartOptions(t, language, config, data)
  const charData = getChartData(t, data)

  return <Bar options={chartOptions} data={charData} plugins={[annotationPlugin]} />
}

export default CarparkOccupationChart
