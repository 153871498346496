import { useQuery } from '@tanstack/react-query'

import { GET_ONE_CENTER_DAY_VERSION } from 'config/queries'
import { UseGetOneDayVersion } from 'models/center'
import { getOneCenterDayVersion } from 'services/centers'

interface Args {
  id: string
  version: string
}

const useGetOneCenterDayVersion = ({ id, version }: Args): UseGetOneDayVersion => {
  const queryKey = [GET_ONE_CENTER_DAY_VERSION, id, version]

  const { data: response, isLoading } = useQuery({
    queryKey,
    queryFn: () => getOneCenterDayVersion(id, version),
    refetchOnMount: true,
    retry: 1,
  })

  const result = response?.data

  return { response: result, isLoading }
}

export default useGetOneCenterDayVersion
