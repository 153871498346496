import { QueryKey, useQuery } from '@tanstack/react-query'

import { GET_ONE_ROLE_RELATION } from 'config/queries'
import { RoleRelationDTO } from 'models/relation'
import { getOneRoleRelation, getOneRoleRelationVersion } from 'services/relations'

interface UseGetOneRoleRelationProps {
  id?: number
  version?: number
}

interface UseGetOneRoleRelation {
  response: RoleRelationDTO | null
  isLoading: boolean
  queryKey: QueryKey
}

const useGetOneRoleRelation = ({ id, version }: UseGetOneRoleRelationProps): UseGetOneRoleRelation => {
  const queryKey = [GET_ONE_ROLE_RELATION, id, version]

  const {
    data: response,
    isLoading,
    isPreviousData,
  } = useQuery({
    queryKey,
    queryFn: () => (version ? getOneRoleRelationVersion(id!, version) : getOneRoleRelation(id!)),
    refetchOnMount: true,
    keepPreviousData: true,
    enabled: !!id && !isNaN(id),
  })

  const result = response?.data ?? null

  return { response: result, isLoading: isLoading || isPreviousData, queryKey }
}

export default useGetOneRoleRelation
