const options = { minimumFractionDigits: 0, maximumFractionDigits: 3 }

export const formatNumber = (number: number, locale: string, showCurrency?: boolean) =>
  new Intl.NumberFormat(locale, showCurrency ? { ...options, style: 'currency', currency: 'EUR' } : options).format(
    number
  )

export const formatPercent = (number: number, locale: string) =>
  new Intl.NumberFormat(locale, options).format(number) + ' %'

export const roundNumberTo = (number: number, decimals: number) => {
  const tenExpo = Math.pow(10, decimals)
  return Math.round(number * tenExpo) / tenExpo
}
