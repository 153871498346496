import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Grid } from '@mui/material'

import { userFilterDefaultValues, UserFilterFormData, UserFilterFormFields, userFilterSchema } from 'validations/user'
import BasicButton from 'components/atoms/basic-button'
import ControllerInputText from 'components/molecules/controller-input-text'
import ControllerInputSelect from 'components/molecules/controller-input-select'
import ControllerInputDateTimePicker from 'components/molecules/controller-input-datetimepicker'
import ControllerSwitch from 'components/molecules/controller-switch'
import { DateFormats, USER_ROLES } from 'config/constants'
import { ClearFiltersButton } from 'components/atoms/clear-filter-button'

import { FilterGridContainer } from './styled'

interface Props {
  onSearch: (search: UserFilterFormFields) => void
  isRelationsFilter?: boolean
}

const UsersFilter: React.FC<Props> = ({ onSearch, isRelationsFilter = false }) => {
  const { t } = useTranslation()
  const [sessionStorageKey, setSessionStorageKey] = useState<string | null>(null)

  useEffect(() => {
    if (isRelationsFilter) {
      setSessionStorageKey('relationsUsersFilters')
      return
    }
    setSessionStorageKey('usersFilters')

  }, [isRelationsFilter])


  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm<UserFilterFormData>({
    defaultValues: userFilterDefaultValues,
    resolver: yupResolver(userFilterSchema),
  })

  const onSubmit = (payload: UserFilterFormFields) => {
    if (sessionStorageKey) sessionStorage.setItem(sessionStorageKey, JSON.stringify(payload))
    onSearch(payload)
  }

  const handleReset = () => {
    if (sessionStorageKey) sessionStorage.removeItem(sessionStorageKey)
    onSearch(userFilterDefaultValues)
    reset(userFilterDefaultValues)
  }

  useEffect(() => {
    const storedData = sessionStorageKey && sessionStorage.getItem(sessionStorageKey)
    if (storedData) {
      const parsedStoredData = JSON.parse(storedData)
      for (const key in parsedStoredData) {
        setValue(key as keyof Partial<UserFilterFormData>, parsedStoredData[key])
      }
      onSearch(parsedStoredData)
    }
    // eslint-disable-next-line
  }, [sessionStorageKey])

  return (
    <FilterGridContainer container pr={1}>
      <Grid item md={4} pr={1}>
        <ControllerInputText control={control} name='name' error={errors.name} label={t('Nombre')} size='full' />
      </Grid>
      <Grid item md={4} pr={1}>
        <ControllerInputText control={control} name='email' error={errors.email} label={t('Email')} size='full' />
      </Grid>
      <Grid item md={4} pr={1}>
        <ControllerInputSelect
          options={USER_ROLES}
          control={control}
          name='role'
          error={errors.role}
          label={t('Rol')}
          size='full'
        />
      </Grid>
      <Grid item md={6} pr={1}>
        <ControllerInputDateTimePicker
          control={control}
          name='from'
          error={errors.from}
          label={t('Desde')}
          size='full'
          isUTC={true}
          pattern={DateFormats.isoZ}
        />
      </Grid>
      <Grid item md={6} pr={1}>
        <ControllerInputDateTimePicker
          control={control}
          name='to'
          error={errors.to}
          label={t('Hasta')}
          size='full'
          isUTC={true}
          pattern={DateFormats.isoZ}
        />
      </Grid>

      <Grid item md={12} display='flex' justifyContent='flex-end' flexDirection='row' gap={3}>
        <ClearFiltersButton handleClearFilters={handleReset} color='default' size='small' />
        <ControllerSwitch label={t('Solo activos')} name='showActiveOnly' variant='full' control={control} autoHeight />
        <BasicButton handleClick={handleSubmit(onSubmit)} text={t('Buscar')} size='small' />
      </Grid>
    </FilterGridContainer>
  )
}

export default UsersFilter
