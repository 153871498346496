import { FC, PropsWithChildren } from 'react'

import Paper from '@mui/material/Paper'

interface TabPanelProps {
  index: number
  value: number
  className?: string
}

const TabPanel: FC<PropsWithChildren<TabPanelProps>> = ({ children, value, index, className }) => {
  return (
    <div className={className} role='tabpanel' hidden={value !== index}>
      {value === index && (
        <Paper sx={{ p: 3 }} square>
          {children}
        </Paper>
      )}
    </div>
  )
}

export default TabPanel
