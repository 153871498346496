import { useQuery } from '@tanstack/react-query'

import { GET_ALERTS } from 'config/queries'
import { Alert, GetAlerts, UseGetAlerts } from 'models/alert'
import { getAlerts } from 'services/alerts'

const useGetAlerts = ({ filters, limit, offset, sort }: GetAlerts): UseGetAlerts => {
  const queryKey = [GET_ALERTS, filters, limit, offset, sort]
  const {
    data: response,
    isLoading,
    isPreviousData,
  } = useQuery({
    queryKey,
    queryFn: () => getAlerts({ filters, limit, offset, sort }),
    refetchOnMount: true,
    keepPreviousData: true,
  })

  const result: Alert[] = response?.data ?? []
  const count: number = parseInt(response?.headers?.['x-total-count'] || 0)

  return { response: result, count, isLoading: isLoading || isPreviousData, queryKey }
}

export default useGetAlerts
