import { QueryKey, useQuery } from '@tanstack/react-query'

import { GET_ONE_USER_RELATION } from 'config/queries'
import { RelationDTO } from 'models/relation'
import { getOneUserRelationVersion, getOneUsersRelation } from 'services/relations'

interface UseGetOneUsersRelationProps {
  id?: number
  version?: number
}

interface UseGetOneUsersRelation {
  response: RelationDTO | null
  isLoading: boolean
  queryKey: QueryKey
}

const useGetOneUsersRelation = ({ id, version }: UseGetOneUsersRelationProps): UseGetOneUsersRelation => {
  const queryKey = [GET_ONE_USER_RELATION, id, version]

  const {
    data: response,
    isLoading,
    isPreviousData,
  } = useQuery({
    queryKey,
    queryFn: () => (version ? getOneUserRelationVersion(id!, version) : getOneUsersRelation(id!)),
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    keepPreviousData: true,
    enabled: !!id && !isNaN(id),
    cacheTime: 0,
  })

  const result = response?.data ?? null

  return { response: result, isLoading: isLoading || isPreviousData, queryKey }
}

export default useGetOneUsersRelation
