import styled from 'styled-components'
import TextField from '@mui/material/TextField'

import { colors } from 'config/theme'
import { FontFamily } from 'config/constants'

export const StyledInput = styled(TextField)`
  font-family: ${FontFamily.light};
  border-radius: 8px;
  color: ${colors.deepBlue};
  background: ${colors.white};
  border: 1px solid ${colors.grey};
  box-shadow: none;
  overflow: hidden;

  &:has(input:focus) {
    border-color: ${colors.orange};
    box-shadow: 0 0 0 2px ${colors.lightOrange};
  }

  &:hover {
    border-color: ${colors.orange};
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }

  input {
    font-size: 0.875rem;
    font-family: inherit;
    font-weight: 400;
    line-height: 1.5;
    color: ${colors.deepBlue};
    background: inherit;
    border: none;
    border-radius: inherit;
    padding: 8px;
    margin: 0px;
    outline: 0;
    caret-color: ${colors.orange};
  }

  fieldset {
    border: none;
  }
`
