import { useEffect, useState } from 'react'

import { Link, useMatch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'

import useAdministrationPermissions, { AdministrationSections } from 'hooks/permissions/use-administration-permissions'
import routes from 'config/routes'

enum TabValue {
  users,
  applications,
  audit,
  configuration,
  relations,
}

const defaultTab = TabValue.configuration

const AdministrationTabs = (): JSX.Element => {
  const { t } = useTranslation()

  const { canRead: configurationCanRead } = useAdministrationPermissions(AdministrationSections.configuration)
  const { canRead: usersCanRead } = useAdministrationPermissions(AdministrationSections.users)
  const { canRead: applicationsCanRead } = useAdministrationPermissions(AdministrationSections.applications)
  const { canRead: eventsCanRead } = useAdministrationPermissions(AdministrationSections.events)
  const { canRead: relationsCanRead } = useAdministrationPermissions(AdministrationSections.relations)

  const matchesUsers = useMatch(`${routes.usersList}/*`)
  const matchesApplications = useMatch(`${routes.applicationsList}/*`)
  const matchesAudit = useMatch(`${routes.eventsList}/*`)
  const matchesConfiguration = useMatch(`${routes.configuration}/*`)
  const matchesRelations = useMatch(`${routes.relationsList}/*`)

  const [value, setValue] = useState<TabValue>(configurationCanRead ? defaultTab : TabValue.audit)

  useEffect(() => {
    let match: TabValue = defaultTab
    if (matchesUsers) {
      match = TabValue.users
    } else if (matchesApplications) {
      match = TabValue.applications
    } else if (matchesAudit) {
      match = TabValue.audit
    } else if (matchesConfiguration) {
      match = TabValue.configuration
    } else if (matchesRelations) {
      match = TabValue.relations
    }
    setValue(match)
  }, [matchesApplications, matchesAudit, matchesUsers, matchesConfiguration, matchesRelations])

  return (
    <Tabs value={value} textColor='secondary' indicatorColor='secondary' variant='fullWidth'>
      {configurationCanRead && (
        <Tab
          component={Link}
          value={TabValue.configuration}
          label={t('Configuración')}
          to={routes.configuration}
          data-testid='administration-configuration-tab'
        />
      )}
      {usersCanRead && (
        <Tab
          component={Link}
          value={TabValue.users}
          label={t('Usuarios')}
          to={routes.usersList}
          data-testid='administration-users-tab'
        />
      )}
      {applicationsCanRead && (
        <Tab
          component={Link}
          value={TabValue.applications}
          label={t('Aplicaciones')}
          to={routes.applicationsList}
          data-testid='administration-applications-tab'
        />
      )}
      {eventsCanRead && (
        <Tab
          component={Link}
          value={TabValue.audit}
          label={t('Auditoría')}
          to={routes.eventsList}
          data-testid='administration-audit-tab'
        />
      )}
      {relationsCanRead && (
        <Tab
          component={Link}
          value={TabValue.relations}
          label={t('Relaciones')}
          to={routes.relationsList}
          data-testid='administration-relations-tab'
        />
      )}
    </Tabs>
  )
}

export default AdministrationTabs
