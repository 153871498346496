import React from 'react'

import { useTranslation } from 'react-i18next'
import { NavigateOptions, useLocation, useNavigate } from 'react-router-dom'

import BasicButton from 'components/atoms/basic-button'

interface Props {
  to?: string
  text?: string
  navigationOptions?: NavigateOptions
  onClick?: () => void
}

const CancelButton: React.FC<Props> = ({ to, text, navigationOptions, onClick }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  const handleClick = () => {
    if (onClick) {
      onClick()
    } else if (location.state?.from) {
      const { from, ...state } = location.state
      navigate(from, { state, replace: true })
    } else if (to) {
      navigate(to, { ...navigationOptions, replace: true })
    } else {
      navigate(-1)
    }
  }

  return (
    <BasicButton
      text={text || t('Volver')}
      handleClick={handleClick}
      variant='contained'
      size='small'
      type='button'
      color='warning'
    />
  )
}

export default CancelButton
