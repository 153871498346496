import { Grid } from '@mui/material'
import styled from 'styled-components'

export const GridItem = styled(Grid)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  width: 100%;
  margin-bottom: 2rem;
`
