import { useState } from 'react'

import dayjs, { Dayjs } from 'dayjs'

const useMonthSelector = () => {
  const [date, setDate] = useState<Dayjs>(dayjs())

  const onNext = () => setDate(prev => prev.add(1, 'month'))

  const onPrev = () => setDate(prev => prev.add(-1, 'month'))

  return {
    date,
    onNext,
    onPrev,
  }
}

export default useMonthSelector
