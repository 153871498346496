import { QueryKey, useMutation, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { GET_PROFILES } from 'config/queries'
import { updateOneProfile } from 'services/profiles'
import { Profile, UpdateProfile } from 'models/profiles'

export interface ErrorModel {
  response: {
    data: { code: string; timestamp: number; severity: string; description: string; payload: { message: string } }
  }
}

const useUpdateProfile = (queryKey?: QueryKey) => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  return useMutation({
    mutationFn: ({ id, ...payload }: UpdateProfile) => updateOneProfile(id, payload),
    onSuccess: () => {
      toast(t('Curva actualizada'), { type: 'success' })
    },
    onMutate: async newValue => {
      await queryClient.cancelQueries({ queryKey: [GET_PROFILES] })
      const previous = queryClient.getQueryData<{ data: Profile[] }>(queryKey ?? [GET_PROFILES])
      const profile = previous?.data.find(({ id }) => id.toString() === newValue.id)

      if (profile) {
        Object.assign(profile, newValue)
      }
      return { previous }
    },
    onError: (error: ErrorModel, newValue, context) => {
      queryClient.setQueryData([GET_PROFILES], context?.previous)
      if (error.response.data.payload.message) {
        return toast(t(`${error.response.data.payload.message}`), { type: 'error' })
      }
      if (error.response.data.description) {
        return toast(t(`${error.response.data.description}`), { type: 'error' })
      }

      toast(t('Ha ocurrido un error'), { type: 'error' })
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [GET_PROFILES] })
    },
  })
}

export default useUpdateProfile
