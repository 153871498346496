import { FC, useCallback, useMemo } from 'react'

import PeriodPriceTile from 'components/atoms/period-day-tile'

import * as S from './styled'

interface PeriodPricesProps {
  value: number[]
  onChange: (value: number[]) => void
  disabled?: boolean
  startDay?: number
}

const PeriodPrices: FC<PeriodPricesProps> = ({ value, disabled, onChange, startDay = 1 }) => {
  const valueWithIds = useMemo(() => value.map((v, idx) => ({ v, id: idx })), [value])

  const handleChange = useCallback(
    (dayNumber: number, price: number) => {
      const newPrices = [...value]
      newPrices[dayNumber - startDay] = price
      onChange(newPrices)
    },
    [startDay, value, onChange]
  )

  return (
    <S.PeriodPrices>
      {valueWithIds.map((dayPrice, idx) => (
        <PeriodPriceTile
          key={dayPrice.id}
          className='price-tile'
          dayNumber={startDay + idx}
          price={dayPrice.v}
          disabled={disabled}
          onChange={handleChange}
        />
      ))}
    </S.PeriodPrices>
  )
}

export default PeriodPrices
