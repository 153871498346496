import styled from 'styled-components'
import IconButton from '@mui/material/IconButton'

export const Header = styled.div`
  position: relative;
`

export const EditButton = styled(IconButton)`
  position: absolute;
  right: 0;
  top: calc(50% - 0.4em);
  transform: translateY(-50%);
  z-index: 1;
`
