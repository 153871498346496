import { useQuery } from '@tanstack/react-query'

import { GET_ONE_PROFILE } from 'config/queries'
import { Profile, UseGetOneProfile } from 'models/profiles'
import { getOneProfile } from 'services/profiles'

interface Args {
  id?: string
}

const useGetOneProfile = ({ id }: Args): UseGetOneProfile => {
  const queryKey = [GET_ONE_PROFILE, id]
  const { data: response, isLoading } = useQuery({
    queryKey,
    queryFn: () => getOneProfile(id),
    refetchOnMount: true,
    staleTime: 0,
    enabled: !!id,
  })

  const result: Profile = response?.data

  return { response: result, isLoading }
}

export default useGetOneProfile
