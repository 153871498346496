import { useQuery } from '@tanstack/react-query'

import { getImportJobs } from 'services/importjobs'
import { ImportJob, UseGetImportJobs } from 'models/importjobs'
import { GET_IMPORT_JOBS } from 'config/queries'

interface Args {
  limit: number
  offset: number
  sort: string
  type?: string
}

const useGetImportJobs = ({ limit, offset, sort, type }: Args): UseGetImportJobs => {
  const queryKey = [GET_IMPORT_JOBS, limit, offset, sort, type]
  const {
    data: response,
    isLoading,
    isPreviousData,
  } = useQuery({
    queryKey,
    queryFn: () => getImportJobs({ limit, offset, sort, type }),
    refetchOnMount: true,
    keepPreviousData: true,
  })

  const result: Array<ImportJob> = response?.data?.items ?? []
  const count: number = parseInt(response?.data?.meta?.itemCount || 0)

  return { response: result, count, isLoading: isLoading || isPreviousData, queryKey }
}

export default useGetImportJobs
