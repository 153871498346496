import { ButtonBase } from '@mui/material'
import { Link } from 'react-router-dom'

import { ReactComponent as Logo } from 'assets/images/svg/logo.svg'
import routes from 'config/routes'

import * as S from './styled'

const AppBarLogo = (): JSX.Element => (
  <S.AppBarLogoContainer>
    <ButtonBase centerRipple sx={{ height: '100%' }}>
      <Link to={routes.root}>
        <Logo width='128' />
      </Link>
    </ButtonBase>
  </S.AppBarLogoContainer>
)

export default AppBarLogo
