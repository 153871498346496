const downloadBlob = (file: Blob, filename: string): void => {
  const href = URL.createObjectURL(file)

  const link = document.createElement('a')
  link.href = href
  link.setAttribute('download', filename)
  document.body.appendChild(link)
  link.click()

  document.body.removeChild(link)
  URL.revokeObjectURL(href)
}

export default downloadBlob
