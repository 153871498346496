import { useQuery } from '@tanstack/react-query'

import { getOneProfileVersion } from 'services/profiles'
import { Profile, UseGetOneProfileVersion } from 'models/profiles'
import { GET_ONE_PROFILE_VERSION } from 'config/queries'

interface Args {
  id?: string
  version?: string
}

const useGetOneProfileVersion = ({ id, version }: Args): UseGetOneProfileVersion => {
  const queryKey = [GET_ONE_PROFILE_VERSION, id, version]
  const { data: response, isLoading } = useQuery({
    queryKey,
    queryFn: () => getOneProfileVersion(id, version),
    refetchOnMount: true,
    staleTime: 0,
  })

  const result: Profile = response?.data

  return { response: result, isLoading }
}

export default useGetOneProfileVersion
