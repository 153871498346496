import React, { useMemo } from 'react'

import { Outlet, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import PageTitle from 'components/atoms/page-title'
import routes from 'config/routes'
import { HISTORIC_END_PATH } from 'config/constants'

const ProfilesLayout = (): React.JSX.Element => {
  const { t } = useTranslation()

  const { pathname } = useLocation()

  const showTitle = useMemo(() => pathname.endsWith(routes.profiles) || pathname === '/', [pathname])
  const showHistoricTitle = useMemo(() => pathname.endsWith(HISTORIC_END_PATH), [pathname])

  return (
    <>
      {showTitle && <PageTitle title={t('curvas')} />}
      {showHistoricTitle && <PageTitle title={t('Histórico de curvas')} />}
      <Outlet />
    </>
  )
}

export default ProfilesLayout
