import React, { useCallback } from 'react'

import { GridEnrichedColDef } from '@mui/x-data-grid'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import useUpdateUser from 'hooks/queries/user/use-update-user'
import useAdministrationPermissions, { AdministrationSections } from 'hooks/permissions/use-administration-permissions'
import routes from 'config/routes'
import BasicTable from 'components/molecules/basic-table'
import ToggleGridActionCellItem from 'components/atoms/toggle-grid-action-cell-item'
import ShowGridActionCellItem from 'components/atoms/show-grid-action-cell-item'
import EditGridActionCellItem from 'components/atoms/edit-grid-action-cell-item'
import { UserFormFields } from 'validations/user'
import { User, UserQueryKey } from 'models/users'
import { DateFormats, RowHeight, TableColumnWidth, TableTypes } from 'config/constants'
import { dateFormatter } from 'utils/date-formatter'
import TextCell from 'components/atoms/text-cell'
import ChipCell from 'components/atoms/chip-cell'

interface Props {
  rows: User[]
  count: number
  page?: number
  pageSize?: number
  isLoading?: boolean
  queryKey?: UserQueryKey
  type?: TableTypes
  onSortModelChange?: (sortString: string) => void
  onPageSizeChange?: (pageSize: number) => void
  onPageChange?: (page: number) => void
}

const UsersTable: React.FC<Props> = ({ queryKey, type = TableTypes.default, ...props }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { canWrite } = useAdministrationPermissions(AdministrationSections.users)
  const updateUser = useUpdateUser(queryKey)

  const navigateToEdit = useCallback(
    (id: string) => TableTypes.default === type && navigate(routes.usersEdit.replace(':id', id)),
    [navigate, type]
  )

  const navigateToShow = useCallback(
    (id: string) => TableTypes.default === type && navigate(routes.usersShow.replace(':id', id)),
    [navigate, type]
  )

  const handleToggleUserActive = useCallback(
    (userRow: UserFormFields) => {
      const { id, name, email, active, role } = userRow
      updateUser.mutate({ id, name, email, role, active: !active })
    },
    [updateUser]
  )

  const getTableActions = useCallback(
    ({ row }: { row: User }) => {
      const actions = [<ShowGridActionCellItem key={`${row.id}-show`} onClick={() => navigateToShow(`${row.id}`)} />]

      if (canWrite) {
        actions.unshift(
          <ToggleGridActionCellItem
            checked={row.active}
            key={`${row.id}-toggle`}
            onClick={() => handleToggleUserActive(row as UserFormFields)}
          />,
          <EditGridActionCellItem key={`${row.id}-edit`} onClick={() => navigateToEdit(`${row.id}`)} />
        )
      }

      return actions
    },
    [canWrite, navigateToShow, handleToggleUserActive, navigateToEdit]
  )

  const columns: GridEnrichedColDef[] = [
    { field: 'id', headerName: '', flex: TableColumnWidth.xs, headerAlign: 'center', align: 'center' },
    {
      field: 'name',
      headerName: `${t('Nombre')}`,
      flex: TableColumnWidth.base,
      renderCell: ({ row }: { row: User }) => <TextCell text={row.name} />,
    },
    {
      field: 'email',
      headerName: `${t('Email')}`,
      flex: TableColumnWidth.large,
      renderCell: ({ row }: { row: User }) => <TextCell text={row.email} />,
    },
    {
      field: 'role',
      headerName: `${t('Rol')}`,
      flex: TableColumnWidth.base,
      renderCell: ({ row }: { row: User }) => <ChipCell label={row.role} />,
    },
    {
      field: 'lastLoginAt',
      headerName: `${t('Último acceso')}`,
      flex: TableColumnWidth.small,
      renderCell: ({ row }: { row: User }) => (
        <TextCell text={dateFormatter({ date: row.lastLoginAt, pattern: DateFormats.day }) || '-'} />
      ),
    },
  ]

  if (TableTypes.default === type) {
    columns.push({
      field: 'actions',
      type: 'actions',
      flex: TableColumnWidth.base,
      getActions: getTableActions,
    })
  }

  return (
    <BasicTable
      {...props}
      type={type}
      rowHeight={RowHeight.medium}
      columns={columns}
      onRowClick={({ row }) => (canWrite ? navigateToEdit(row.id) : navigateToShow(row.id))}
    />
  )
}

export default UsersTable
