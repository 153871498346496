import { useQuery } from '@tanstack/react-query'

import { GET_CENTER_PERIOD_VERSIONS } from 'config/queries'
import { CenterPeriodVersion, UseGetCenterPeriodVersions } from 'models/center-period'
import { getPeriodVersions } from 'services/periods'

interface Args {
  id: string
  limit: number
  offset: number
  sort: string
}

const useGetCenterPeriodVersions = ({ id, limit, offset, sort }: Args): UseGetCenterPeriodVersions => {
  const queryKey = [GET_CENTER_PERIOD_VERSIONS, id, limit, offset, sort]
  const { data: response, isLoading } = useQuery({
    queryKey,
    queryFn: () => getPeriodVersions({ id, limit, offset, sort }),
    refetchOnMount: true,
    enabled: limit !== undefined && offset !== undefined && sort !== undefined,
  })

  const result: Array<CenterPeriodVersion> = response?.data || []
  const count: number = parseInt(response?.headers?.['x-total-count'] || 0)

  return { response: result, count, isLoading }
}

export default useGetCenterPeriodVersions
